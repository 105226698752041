import { BarChart, Icons, Status, Tooltip } from 'plume-ui';
import { BarChartDataItem } from 'plume-ui/dist/utils/charts/chartTypes';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { HARMONY_SYNC_ID } from '../../../config';
import { useHarmonyChartGroups } from '../hooks/useHarmonyChartGroups';
import { channelInterferenceSelector } from '../state';
import { FrequencyBand, TimeRange } from '../types';
import { timeRangeToNumber } from '../util';

const ChannelInterferenceChart = ({
  frequencyBand,
  referenceLine,
  timeRange,
}: {
  frequencyBand: FrequencyBand;
  referenceLine?: {
    x: string;
    position: 'start' | 'middle' | 'end';
    tooltipHeading: string;
    tooltipLabel: string;
  };
  timeRange: TimeRange;
}) => {
  const { t } = useTranslation();
  const { channelInterferenceGroups } = useHarmonyChartGroups();
  const chartData = useRecoilValue<BarChartDataItem[]>(
    channelInterferenceSelector({
      frequencyBand,
      timeRange: timeRangeToNumber(timeRange),
    }),
  );

  return (
    <div className="HarmonyContainer__card AuraGlow--card">
      <div className="HarmonyContainer__card__title">
        {t('harmony.chart.channelInterference.title')}
        <Tooltip
          label={t('harmony.chart.channelInterference.tooltip')}
          openInPortal
          overrideMaxCharacters
        >
          <Icons.InfoIcon width={16} />
        </Tooltip>
      </div>
      <div className="HarmonyContainer__chartLegend">
        {Array.isArray(chartData) &&
          chartData.length > 0 &&
          channelInterferenceGroups.map((item) => (
            <Status
              key={item.label}
              color={item.stroke}
              label={item.label}
              variant="legend"
            />
          ))}
      </div>
      <div className="HarmonyContainer__card__chartContainer">
        <BarChart
          config={{
            barSize: 8,
            xAxisLabelAngle: 0,
            xAxisTickInterval: timeRange === TimeRange.Last7days ? 0 : 4,
            yAxisFormat: 'percentage',
          }}
          data={chartData}
          domainY={[0, 1]}
          groups={channelInterferenceGroups}
          invertHoverEffect
          outlineOnHover
          referenceLine={referenceLine}
          roundEveryCell
          syncId={HARMONY_SYNC_ID}
        />
      </div>
    </div>
  );
};

export default memo(ChannelInterferenceChart);
