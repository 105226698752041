import { useFetchData } from '../../../hooks/useFetchData';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DependencyContainer from '../../../DependencyContainer';
import {
  inventoryNodesAtom,
  inventorySelectedPageAtom,
  nodesLoadingStatusAtom,
} from '../inventoryState';
import { selectedPartnerSelector } from '../../../state';
import { InventoryNode } from '../types';
import { useEffect } from 'react';
import { selectedMduIdAtom } from '../../editorView/propertyState';

type UseInventoryValues = {
  loading: boolean;
  error: any;
  runFetch: () => Promise<void>;
};

const { inventoryService } = new DependencyContainer();

export const useInventory = (runOnInit = true): UseInventoryValues => {
  const setNodes = useSetRecoilState(inventoryNodesAtom);
  const selectedMduId = useRecoilValue(selectedMduIdAtom);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const setNodesLoadingStatus = useSetRecoilState(nodesLoadingStatusAtom);
  const setInventorySelectedPage = useSetRecoilState(inventorySelectedPageAtom);

  const [loading, , error, runFetch] = useFetchData<InventoryNode[], any>(
    async () => {
      if (selectedMduId && selectedPartner) {
        return await inventoryService.getAllNodes(
          selectedMduId,
          selectedPartner.id,
        );
      }
    },
    (nodes) => {
      setNodes(nodes);
      setNodesLoadingStatus('loaded');
    },
    [selectedMduId, selectedPartner],
    runOnInit,
  );

  useEffect(() => {
    setInventorySelectedPage(0);
  }, [selectedMduId]);

  useEffect(() => {
    if (loading) {
      setNodesLoadingStatus('loading');
    }
    if (error) {
      setNodesLoadingStatus('error');
    }
  }, [loading, error]);

  return {
    loading,
    error,
    runFetch,
  };
};
