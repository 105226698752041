import { Field, Form, Formik, FormikValues } from 'formik';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Space,
  Toggler,
  notify,
} from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import { TogglerElementTypes } from 'plume-ui/dist/components/Toggler/Toggler';
import React, { FunctionComponent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as yup from 'yup';
import DependencyContainer from '../../../../DependencyContainer';
import DropdownInput, {
  DropdownInputStyles,
} from '../../../../components/DropdownInput/DropdownInput';
import FormObserver from '../../../../components/FormObserver/FormObserver';
import FormikInputCheckbox from '../../../../components/FormikInputCheckbox/FormikInputCheckbox';
import InputField from '../../../../components/InputField/InputField';
import ModalHeaderWithProperty from '../../../../components/ModalHeaderWithProperty/ModalHeaderWithProperty';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';
import { MixPanelEvents } from '../../../../mixPanelEvents';
import {
  selectedMDUSelector,
  selectedPartnerSelector,
} from '../../../../state';
import { FormField } from '../../../../types';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import validateEmail from '../../../../utils/isEmail/validateEmail';
import { prepareErrorMessageForInput } from '../../../../utils/prepareErrorMessageForInput';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { useUnitsUpdateActions } from '../../../units/hooks/useUnitsUpdateActions';
import { Unit, UnitStatus, UnitType } from '../../../units/types';
import {
  allUnits,
  businessUnitsAtom,
  residentialUnitsAtom,
} from '../../../units/unitsState';
import {
  allTenants,
  businessTenantsAtom,
  residentialTenantsAtom,
  tenantsSelectedTypeAtom,
  unitToMoveInTenant,
} from '../../tenantsState';
import {
  BusinessTenant,
  ResidentialTenant,
  TenantStatus,
  TenantTypes,
} from '../../types';

type CreateTenantModalProps = {
  isOpen: boolean;
  onRequestClose: (success: boolean) => void;
};

const addStylesToInputField = (current: any) => ({
  ...current,
  root: `${current.root} CreateTenantModal__input`,
});

const addStylesToCheckbox = (current: any) => ({
  ...current,
  root: `${current.root} CreateTenantModal__checkbox`,
});

const addStylesToDropdownField = (current: DropdownInputStyles) => ({
  ...current,
  root: `${current.root} CreateTenantModal__dropdown m-t-s`,
});

const addStylesForToggler = (current: any) => ({
  ...current,
  root: `${current.root} TenantsContainer__toggler`,
  label: `${current.label} TenantsContainer__toggler-label`,
});

const { tenantService } = new DependencyContainer();

type CreateTenantFormValues = {
  type: TenantTypes;
  name: string;
  email: string;
  unit_number: string;
  account_id: string | null;
  invitation: boolean;
};

const tenantToUnitMap = {
  [TenantTypes.Business]: UnitType.Business,
  [TenantTypes.Residence]: UnitType.Residence,
};

const unitToTenantMap = {
  [UnitType.Business]: TenantTypes.Business,
  [UnitType.Residence]: TenantTypes.Residence,
};

const CreateTenantModal: FunctionComponent<CreateTenantModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useRecoilState(
    tenantsSelectedTypeAtom,
  );
  const { updateUnit } = useUnitsUpdateActions();
  const residentialUnits = useRecoilValue(residentialUnitsAtom);
  const businessUnits = useRecoilValue(businessUnitsAtom);
  const selectedMdu = useRecoilValue(selectedMDUSelector);
  const trackEvent = useTrackEvent();
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const unitToMoveIn = useRecoilValue(unitToMoveInTenant);
  const units = useRecoilValue(allUnits);
  const tenants = useRecoilValue(allTenants);
  const [residentialTenants, setResidentialTenants] = useRecoilState(
    residentialTenantsAtom,
  );
  const [businessTenants, setBusinessTenants] = useRecoilState(
    businessTenantsAtom,
  );
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [isDisabledInviteTenant, setIsDisabledInviteTenant] = useState(true);

  const formInputFieldsDefinition: Array<FormField<CreateTenantFormValues>> = [
    {
      name: 'name',
      labelId: 'tenants.actions.create.form.name.label',
      type: 'input',
    },
    {
      name: 'email',
      labelId: 'tenants.actions.create.form.email.label',
      type: 'input',
    },
    {
      name: 'account_id',
      labelId: 'tenants.actions.create.form.account_id.label',
      type: 'input',
      messages: [
        {
          status: 'hint',
          message: t('tenants.actions.create.form.account_id.hint'),
        },
      ],
    },
    {
      name: 'unit_number',
      labelId: 'tenants.actions.create.form.unit_number.label',
      wrapLabel: 'tenants.actions.create.form.unit_number.wrapLabel',
      type: unitToMoveIn ? 'hidden' : 'dropdown',
    },
    {
      name: 'invitation',
      labelId: 'tenants.actions.create.form.invitation.label',
      wrapLabel: 'tenants.actions.create.form.invitation.wrapLabel',
      type: 'checkbox',
    },
  ];

  const togglerValues: TogglerElementTypes[] = [
    {
      key: TenantTypes.Residence,
      label: t('tenants.actions.create.form.type.residential'),
    },
    {
      key: TenantTypes.Business,
      label: t('tenants.actions.create.form.type.commercial'),
    },
  ];

  const getFormInitialValues = (): CreateTenantFormValues => {
    return {
      type: selectedType,
      name: '',
      email: '',
      unit_number: unitToMoveIn ? unitToMoveIn.id : '',
      account_id: null,
      invitation: false,
    };
  };

  const unitOptions = useMemo(() => {
    let filteredUnits: Unit[];
    if (selectedType === TenantTypes.Residence) {
      filteredUnits = residentialUnits.filter(
        (unit) => unit.status === UnitStatus.Unassigned,
      );
    } else {
      filteredUnits = businessUnits.filter(
        (unit) => unit.status === UnitStatus.Unassigned,
      );
    }
    return filteredUnits.map((unit) => ({
      value: unit.id,
      label: unit.name,
      nodes: unit.nodes,
      retailPay: unit.retailPay,
    }));
  }, [selectedType, residentialUnits, businessUnits]);

  const updateTenantList = (tenant: BusinessTenant | ResidentialTenant) => {
    if (selectedType === TenantTypes.Residence) {
      return setResidentialTenants((currentTenants) => [
        ...currentTenants,
        tenant as ResidentialTenant,
      ]);
    }

    setBusinessTenants((currentTenants) => [
      ...currentTenants,
      tenant as BusinessTenant,
    ]);
  };

  const onSubmit = async (values: FormikValues) => {
    if (!selectedMdu || !selectedPartner || submitInProgress) {
      return;
    }
    setSubmitInProgress(true);
    const tenantType = unitToMoveIn
      ? unitToTenantMap[unitToMoveIn.type]
      : selectedType;
    const unitType = unitToMoveIn
      ? unitToMoveIn.type
      : tenantToUnitMap[selectedType];
    try {
      const tenant = await tenantService.addTenant(
        selectedMdu.id,
        {
          email: values.email?.trim(),
          name: values.name?.trim(),
          unitId: values.unit_number?.trim(),
          ...(values.account_id && { accountId: values.account_id?.trim() }),
          type: tenantType,
          status: values.invitation
            ? TenantStatus.Active
            : TenantStatus.Assigned,
        },
        selectedPartner.id,
      );
      const tenantReference = {
        id: tenant.id,
        name: tenant.name,
      };
      updateUnit(
        {
          id: values.unit_number,
          status: UnitStatus.Assigned,
          locationId: tenant.locationId,
          customerId: tenant.customerId,
          ...(tenantType === TenantTypes.Residence && {
            residentialTenant: tenantReference,
          }),
          ...(tenantType === TenantTypes.Business && {
            businessTenant: tenantReference,
          }),
        },
        unitType,
      );
      updateTenantList(tenant);

      notify({
        title: t('success'),
        body: t('tenants.actions.create.tenantAdded'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.ADD_TENANT_SUCCESS,
        additionalContent: {
          unitId: values.unit_number,
          propertyId: selectedMdu!.id,
          tenantId: tenant.id,
          desiredStatus: values.invitation
            ? TenantStatus.Active
            : TenantStatus.Assigned,
        },
      });
      setSubmitInProgress(false);
      onRequestClose(true);
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.ADD_TENANT_FAILURE,
        additionalContent: {
          unitId: values.unit_number,
          propertyId: selectedMdu!.id,
          desiredStatus: values.invitation
            ? TenantStatus.Active
            : TenantStatus.Assigned,
        },
      });
      setSubmitInProgress(false);
    }
  };

  const getValidationSchema = () =>
    yup.object().shape({
      name: yup
        .string()
        .min(3, t('formValidation.minLength', { length: 3 }))
        .trim()
        .max(128, t('formValidation.maxFields', { length: 128 }))
        .required(t('tenants.actions.create.form.name.required')),
      email: yup
        .string()
        .required(t('tenants.actions.create.form.email.required'))
        .max(256, t('formValidation.maxFields', { length: 256 }))
        .test(
          'is-valid-email',
          t('tenants.actions.create.form.email.invalid'),
          (value) => validateEmail(value || ''),
        )
        .test(
          'tenant-unique',
          t('tenants.actions.create.form.email.unique'),
          (value) => {
            for (const tenant of [...residentialTenants, ...businessTenants]) {
              if (tenant.email === value) return false;
            }
            return true;
          },
        )
        .trim(),
      unit_number: yup
        .string()
        .min(3, t('formValidation.minLength', { length: 3 }))
        .trim()
        .max(128, t('formValidation.maxFields', { length: 128 }))
        .required(t('tenants.actions.create.form.unit_number.required')),
      account_id: yup
        .string()
        .optional()
        .nullable()
        .max(36, t('formValidation.maxFields', { length: 36 }))
        .test(
          'accountId-unique',
          t('tenants.actions.create.form.account_id.unique'),
          (value) => {
            if (value === null) return true;
            for (const tenant of tenants) {
              if (!tenant.unit?.retailPay) {
                if (tenant.unit?.accountId === value) return false;
              }
              if (tenant.accountId === value) return false;
            }
            return true;
          },
        )
        .test(
          'unit-accountId-unique',
          t('tenants.actions.create.form.account_id.uniqueUnit'),
          (value) => {
            if (value === null) return true;
            for (const unit of units) {
              if (!unit.retailPay) {
                if (unit.accountId === value) return false;
              }
            }
            return true;
          },
        ),
    });

  if (!selectedMdu || !isOpen) {
    return null;
  }

  const getUnitField = (field: any, values: any, errors: any) => {
    if (unitToMoveIn) {
      return (
        <Field
          key={field.name}
          name={field.name}
          component={InputField}
          value={unitToMoveIn.name}
          label={t(field.labelId)}
          classes={addStylesToInputField}
          noClearIcon
          disabled
        />
      );
    }
    const props = {
      name: field.name,
      value: values[field.name],
      messages: prepareErrorMessageForInput(field.name, errors),
      label: t(field.labelId),
    };
    return (
      <Field
        {...props}
        component={unitToMoveIn ? InputField : DropdownInput}
        options={unitOptions}
        wrapLabel={t(field.wrapLabel)}
        defaultLabel={t(field.labelId)}
        classes={addStylesToDropdownField}
        openInPortal
        expandDirection="auto"
        noItemsWarning={t('tenants.actions.create.noUnitsWarning')}
      />
    );
  };

  const getInvitationCheckbox = (field: FormField<CreateTenantFormValues>) => {
    return (
      <div className="CreateTenantModal__labeled-checkbox">
        <span className="CreateTenantModal__labeled-checkbox-label">
          {t(field.wrapLabel!, {
            plumeService:
              selectedType === TenantTypes.Residence ? 'HomePass' : 'WorkPass',
          })}
        </span>
        <Field
          component={FormikInputCheckbox}
          name={field.name}
          label={t(field.labelId)}
          disabled={isDisabledInviteTenant}
          classes={addStylesToCheckbox}
        />
      </div>
    );
  };

  const handleOnChange = (
    values: FormikValues,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    if (values['unit_number']) {
      const selectedUnit = unitOptions.find(
        (u) => u.value === values['unit_number'],
      );
      if (selectedUnit?.nodes.length) {
        setIsDisabledInviteTenant(false);
      } else {
        setFieldValue('invitation', false);
        setIsDisabledInviteTenant(true);
      }
    }
  };

  return (
    <Formik
      initialValues={getFormInitialValues()}
      validationSchema={getValidationSchema()}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values, errors, setFieldValue, dirty, submitForm }) => (
        <Form onChange={() => handleOnChange(values, setFieldValue)}>
          <>
            <FormObserver
              onChange={(values) => handleOnChange(values, setFieldValue)}
            ></FormObserver>
            <Modal
              appElement={document.getElementById('root') as HTMLElement}
              isOpen={isOpen}
              onRequestClose={() => onRequestClose(false)}
              classes={(current: ModalStyles) => ({
                ...current,
                root: `${current.root} CreateTenantModal`,
              })}
            >
              <ModalHeaderWithProperty
                propertyName={selectedMdu.name}
                title={
                  unitToMoveIn
                    ? t('units.actions.addTenant.modalTitle')
                    : t('tenants.actions.create.modalTitle')
                }
                additionalSubtitle={
                  unitToMoveIn && (
                    <>
                      <Space size="xxs" />
                      <div className="CreateTenantModal__header-unit">
                        {unitToMoveIn?.type} - {unitToMoveIn?.name}
                      </div>
                    </>
                  )
                }
              />
              <ModalBody>
                <div className="CreateTenantModal__content">
                  {!unitToMoveIn && (
                    <Toggler
                      toggleElements={togglerValues}
                      variant="large"
                      value={selectedType}
                      onToggle={async (selection) => {
                        setFieldValue('unit_number', '');
                        await Promise.resolve(); //Needed to stop flashing with wrong value
                        setSelectedType(selection.key as TenantTypes);
                      }}
                      classes={addStylesForToggler}
                    />
                  )}
                  {formInputFieldsDefinition?.length > 0 && (
                    <div className="CreateTenantModal__fields">
                      {formInputFieldsDefinition.map((field) => {
                        if (
                          field.name === 'account_id' &&
                          !unitToMoveIn?.retailPay &&
                          !unitOptions.find(
                            (u) => u.value === values.unit_number,
                          )?.retailPay
                        )
                          return;
                        return (
                          <React.Fragment key={field.name}>
                            {field.type === 'dropdown' &&
                              getUnitField(field, values, errors)}
                            {field.type === 'input' && (
                              <Field
                                key={field.name}
                                name={field.name}
                                component={InputField}
                                requiredAsterix={field.name !== 'account_id'}
                                value={values[field.name]}
                                messages={[
                                  ...(field.messages || []),
                                  ...(prepareErrorMessageForInput(
                                    field.name,
                                    errors,
                                  ) || []),
                                ]}
                                label={t(field.labelId)}
                                classes={addStylesToInputField}
                              />
                            )}
                            {field.type === 'checkbox' &&
                              getInvitationCheckbox(field)}
                          </React.Fragment>
                        );
                      })}
                      {isDisabledInviteTenant && values.unit_number && (
                        <div className="CreateTenantModal__invitation-warningMessage">
                          <FormattedMessage id="tenants.actions.create.form.invitation.warningMessage" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  styleVariant="tertiary-grey"
                  onClick={() => onRequestClose(false)}
                >
                  <FormattedMessage id="cancel" />
                </Button>
                <SubmitButton
                  disabled={!dirty}
                  submitInProgress={submitInProgress}
                  onSubmit={submitForm}
                />
              </ModalFooter>
            </Modal>
          </>
        </Form>
      )}
    </Formik>
  );
};

export default memo(CreateTenantModal);
