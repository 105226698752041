import { CreatedUpdated } from '../../utils/types';
import { BasicNode, NodeReference } from '../inventory/types';
import { IotNetwork } from '../iot/types';
import { NodeWithStatus, Reference, StateMeta } from '../units/types';

export type PropertyNetwork = CreatedUpdated & {
  averageDownloadSpeed: number;
  averageUploadSpeed: number;
  communityWifi: {
    ssid: string;
    bandwidthLimit: {
      enabled: boolean;
      upload: number;
      download: number;
      type: string;
    };
    enabled: boolean;
  };
  customerId: string;
  id: string;
  iotWifi: IotNetwork | null;
  isLocationOffline: boolean;
  isNodeOffline: boolean;
  isNodeQoeAlert: boolean;
  locationId: string;
  name: string;
  nodes: BasicNode[];
  property: Reference;
  propertyWifi: {
    ssid: string;
    password?: string;
  };
  stateMeta: StateMeta;
  status: 'active' | 'inactive';
};

export type PropertyNetworkDetails = {
  id: string;
  status: string;
  nodes: NodeWithStatus[];
  property: Reference;
};

export type SortablePropertyNetworksFieldNames =
  | 'averageDownloadSpeed'
  | 'averageUploadSpeed'
  | 'communitySsid'
  | 'name'
  | 'propertySsid';

export type CreatePropertyNetworkDto = {
  communityWifi?: {
    ssid: string;
    bandwidthLimit: {
      enabled: boolean;
      upload: number;
      download: number;
      type: string;
    };
    enabled: boolean;
  };
  name: string;
  nodes?: NodeReference[];
  propertyWifi: {
    ssid: string;
    password?: string;
  };
};

export type UpdatePropertyNetworkDto = Partial<CreatePropertyNetworkDto>;

export enum PropertyNetworkStatus {
  LocationOffline = 'locationOffline',
  NodeOffline = 'nodeOffline',
  NodeQoeAlert = 'nodeQoeAlert',
  NoNodes = 'noNodes',
  Online = 'online',
}
