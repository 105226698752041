import DependencyContainer from '../../../DependencyContainer';
import {
  HarmonyChannelDistribution,
  HarmonyChannelInterference,
  HarmonyHappinessIndex,
  HarmonyTxAirtimeUtilization,
} from '../../harmony/types';
import { OverviewStats, OverviewStatsSection, SpeedTest } from '../types';

export default class StatService {
  constructor(private readonly factory: DependencyContainer) {}

  async getStatsForOverviewPage(
    mduId: string,
    partnerId: string,
    newStatsAPIsEnabled: boolean,
  ): Promise<OverviewStats> {
    const { data } = newStatsAPIsEnabled
      ? await this.factory.statClient.fetchStatsForOverviewV2Page(
          mduId,
          partnerId,
        )
      : await this.factory.statClient.fetchStatsForOverviewPage(
          mduId,
          partnerId,
        );
    const residences = {
      total: data.residentialUnitCount ?? 0,
      occupancy: {
        current: data.residentialUnitOccupiedCount,
        total: data.residentialUnitCount,
      },
      onlineNodes: {
        current: data.residentialUnitNodeOnlineCount ?? 0,
        total: data.residentialUnitNodeCount ?? 0,
      },
      avgSpeed: `${Math.round(data.residentialAverageDownloadSpeed) ?? 0} Mbps`,
      alertCount: data.residentialUnitAlertCount ?? 0,
      updatedAt: data.residentialAlertsUpdatedAt,
    };
    const businesses = {
      total: data.businessUnitCount ?? 0,
      occupancy: {
        current: data.businessUnitOccupiedCount,
        total: data.businessUnitCount,
      },
      onlineNodes: {
        current: data.businessUnitNodeOnlineCount ?? 0,
        total: data.businessUnitNodeCount ?? 0,
      },
      avgSpeed: `${Math.round(data.businessAverageDownloadSpeed) ?? 0} Mbps`,
      alertCount: data.businessUnitAlertCount ?? 0,
      updatedAt: data.businessAlertsUpdatedAt,
    };
    const harmonyChannelInterference: HarmonyChannelInterference =
      data.harmonyChannelInterference;
    const harmonyChannelDistribution: HarmonyChannelDistribution =
      data.harmonyChannelDistribution;
    const harmonyHappinessIndex: HarmonyHappinessIndex =
      data.harmonyHappinessIndex;
    const harmonyTxAirtimeUtilization: HarmonyTxAirtimeUtilization =
      data.harmonyTxAirtimeUtilization;
    const propertyNetworks: OverviewStatsSection = {
      total: data.propertyNetworkCount ?? 0,
      onlineNodes: {
        current: data.propertyNetworkNodeOnlineCount,
        total: data.propertyNetworkNodeCount ?? 0,
      },
      avgSpeed: `${
        Math.round(data.propertyNetworkAverageDownloadSpeed) ?? 0
      } Mbps`,
      alertCount: data.propertyNetworkAlertCount ?? 0,
      updatedAt: data.propertyNetworkAlertsUpdatedAt,
    };
    const speedTests = data.speedTests as SpeedTest[];
    return {
      residences,
      businesses,
      propertyNetworks,
      harmonyChannelInterference,
      harmonyChannelDistribution,
      harmonyHappinessIndex,
      harmonyTxAirtimeUtilization,
      speedTests,
    };
  }
}
