import { AxiosResponse } from 'axios';
import { HttpClient } from '../../../HttpClient';
import { UpriseApiResponse } from '../../../types';
import { UpriseApiUrls } from '../../../urls';
import {
  CreateIotNetworkDTO,
  CreateIotNetworkResponseDTO,
  IotDevice,
  IotNetwork,
  IotNetworkStatus,
  UpdateIotNetworkDTO,
} from '../types';

export default class IotClient extends HttpClient {
  // Creation and augmentation
  async createIotNetwork(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
    createIotNetworkDTO: CreateIotNetworkDTO,
  ) {
    return await this.post<AxiosResponse<CreateIotNetworkResponseDTO>>(
      UpriseApiUrls.getCreateIotNetworkUrl(propertyNetworkId, mduId, partnerId),
      createIotNetworkDTO,
    );
  }

  async getIotNetwork(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) {
    return await this.get<AxiosResponse<UpriseApiResponse<IotNetwork>>>(
      UpriseApiUrls.getIotNetworkUrl(propertyNetworkId, mduId, partnerId),
    );
  }

  async updateIotNetwork(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
    updateIotNetworkDTO: UpdateIotNetworkDTO,
  ) {
    return await this.patch<AxiosResponse<IotNetwork>>(
      UpriseApiUrls.getUpdateIotNetworkUrl(propertyNetworkId, mduId, partnerId),
      updateIotNetworkDTO,
    );
  }

  async deleteIotNetwork(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) {
    return await this.delete<AxiosResponse<UpriseApiResponse<IotNetwork>>>(
      UpriseApiUrls.getDeleteIotNetworkUrl(propertyNetworkId, mduId, partnerId),
    );
  }

  // Propagation
  async propagateIotNetwork(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) {
    return await this.post<AxiosResponse<UpriseApiResponse<{}>>>(
      UpriseApiUrls.getPropagateIotNetworkUrl(
        propertyNetworkId,
        mduId,
        partnerId,
      ),
      null,
    );
  }
  async propagateSingleIotNetwork(
    unitId: string,
    mduId: string,
    partnerId: string,
  ) {
    return await this.post<AxiosResponse<UpriseApiResponse<{}>>>(
      UpriseApiUrls.getPropagateSingleIotNetworkUrl(unitId, mduId, partnerId),
      null,
    );
  }
  async propagateDeleteIotNetwork(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) {
    return await this.delete<AxiosResponse<UpriseApiResponse<{}>>>(
      UpriseApiUrls.getPropagateDeleteIotNetworkUrl(
        propertyNetworkId,
        mduId,
        partnerId,
      ),
    );
  }
  async propagateDeleteSingleIotNetwork(
    unitId: string,
    mduId: string,
    partnerId: string,
  ) {
    return await this.delete<AxiosResponse<UpriseApiResponse<{}>>>(
      UpriseApiUrls.getPropagateDeleteSingleIotNetworkUrl(
        unitId,
        mduId,
        partnerId,
      ),
    );
  }

  // Devices
  async getIotDevices(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) {
    return await this.get<AxiosResponse<UpriseApiResponse<IotDevice[]>>>(
      UpriseApiUrls.getIotDevicesUrl(propertyNetworkId, mduId, partnerId),
    );
  }

  // Status
  async getIotPropagationStatus(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
    transactionId: string,
  ) {
    return await this.get<AxiosResponse<UpriseApiResponse<IotNetworkStatus>>>(
      UpriseApiUrls.getIotPropagationStatusUrl(
        propertyNetworkId,
        mduId,
        partnerId,
        transactionId,
      ),
    );
  }
}
