import { useRecoilValue } from 'recoil';
import { getFrontlineTier1Url, getFrontlineUrl } from '../environment';
import { userInfoAtom } from '../state';
import { FrontlineUrls } from '../urls';

export const useFrontlineUrl = () => {
  const userInfo = useRecoilValue(userInfoAtom);

  const generateFrontlineUrl = (item: {
    customerId: string;
    locationId: string;
    mac?: string;
  }): string => {
    const frontlineHost = userInfo?.frontlineTier1Access
      ? getFrontlineTier1Url()
      : getFrontlineUrl();

    if (item.mac) {
      return (
        frontlineHost +
        FrontlineUrls.getFrontlineDeviceUrl(
          item.customerId,
          item.locationId,
          item.mac,
        )
      );
    }

    return (
      frontlineHost +
      FrontlineUrls.getFrontlineTenantUrl(item.customerId, item.locationId)
    );
  };

  return { generateFrontlineUrl };
};
