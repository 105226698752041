import { atom, selectorFamily, selector } from 'recoil';
import { DEFAULT_PAGE_SIZE } from '../../config';
import { multiDwellingUnitsAtom } from '../../state';
import { FieldSort } from 'plume-ui/dist/components/Table/Table';
import { SortablePropertyFieldNames } from './types';
import { MDU } from '../../types';
import { UploadModalType } from './components/UploadModal/UploadModal';

export const selectedMduIdAtom = atom<string | undefined>({
  key: 'selectedMduIdAtom',
  default: undefined,
});

export const selectedMduIdInPropertyEditorAtom = atom<string | undefined>({
  key: 'selectedMduIdInPropertyEditorAtom',
  default: undefined,
});

export const getPropertyByIdSelector = selectorFamily({
  key: 'getPropertyByIdSelector',
  get: (id: string | undefined) => ({ get }) => {
    if (!id) {
      return;
    }
    const properties = get(multiDwellingUnitsAtom);
    return properties.find((property) => property.id === id);
  },
});

export const propertySortAtom = atom<FieldSort | null>({
  key: 'propertySortAtom',
  default: null,
});

export const propertiesSelectedFilterAtom = atom<string>({
  key: 'propertiesSelectedFilterAtom',
  default: '',
});

export const getPropertyCount = selector({
  key: 'getPropertyCount',
  get: ({ get }) => {
    const properties = get(multiDwellingUnitsAtom);
    return properties.length;
  },
});

export const propertiesSelectedPageAtom = atom<number>({
  key: 'propertiesSelectedPageAtom',
  default: 0,
});

export const sortedProperties = selector<MDU[]>({
  key: 'sortedProperties',
  get: ({ get }) => {
    const properties = get(multiDwellingUnitsAtom);
    const sort = get(propertySortAtom);
    if (sort) {
      return [...properties].sort(
        (a, b) =>
          a[sort.fieldName as SortablePropertyFieldNames]
            .toString()
            .localeCompare(
              b[sort.fieldName as SortablePropertyFieldNames].toString(),
            ) * sort.direction,
      );
    }
    return properties;
  },
});

const filteredProperties = selector<MDU[]>({
  key: 'filteredProperties',
  get: ({ get }) => {
    let properties = get(sortedProperties);
    const filter = get(propertiesSelectedFilterAtom);

    if (filter) {
      properties = properties.filter((property) =>
        property.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()),
      );
    }

    return properties;
  },
});

export const getCurrentPropertyPage = selector({
  key: 'getCurrentPropertyPage',
  get: ({ get }) => {
    const properties = get(filteredProperties);
    const selectedPage = get(propertiesSelectedPageAtom);
    return properties.slice(
      selectedPage * DEFAULT_PAGE_SIZE,
      (selectedPage + 1) * DEFAULT_PAGE_SIZE,
    );
  },
});

export const filteredPropertiesCount = selector<number>({
  key: 'filteredPropertiesCount',
  get: ({ get }) => {
    return get(filteredProperties).length;
  },
});

export const bulkUploadMduAtom = atom<MDU | null>({
  key: 'bulkUploadMduAtom',
  default: null,
});

export const uploadModalTypeAtom = atom<UploadModalType>({
  key: 'uploadModalTypeAtom',
  default: 'bulkUpload',
});
