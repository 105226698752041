import _ from 'lodash';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { selectedPartnerIdAtom } from '../../../state';
import { selectedMduIdAtom } from '../../editorView/propertyState';
import { propertyNetworksAtom } from '../../property-networks/propertyNetworksState';
import { iotDevicesAtom, iotNetworkAtom } from '../state';
import { IotNetwork } from '../types';

const useIotNetworks = () => {
  const selectedPartnerId = useRecoilValue(selectedPartnerIdAtom);
  const selectedPropertyId = useRecoilValue(selectedMduIdAtom);
  const propertyNetworks = useRecoilValue(propertyNetworksAtom);
  const setIotNetwork = useSetRecoilState(iotNetworkAtom);
  const setIotDevices = useSetRecoilState(iotDevicesAtom);

  useEffect(() => {
    setIotNetwork(null);
    setIotDevices([]);
  }, [selectedPartnerId, selectedPropertyId]);

  useEffect(() => {
    const hasIotNetwork = propertyNetworks.find(
      (propertyNetwork) =>
        Boolean(propertyNetwork.iotWifi) || propertyNetwork.iotWifi?.enabled,
    );
    if (_.isObject(hasIotNetwork)) {
      const iotNetwork = _.cloneDeep(hasIotNetwork.iotWifi);
      if (iotNetwork === null) {
        setIotNetwork(iotNetwork);
        return;
      }
      (iotNetwork as any).propertyNetworkId = hasIotNetwork.id;
      setIotNetwork(iotNetwork as IotNetwork & { propertyNetworkId: string });
    }
  }, [propertyNetworks]);
};

export default useIotNetworks;
