import { useTranslation } from 'react-i18next';
import { TableAction } from '../../../components/TableActionButton/TableActionButton';
import useWithConfirmation from '../../../hooks/useWithConfirmation';
import { Document } from '../types';

export type DocumentAction = TableAction<{
  document: Document;
}>;

const useDocumentActions = () => {
  const { t } = useTranslation();
  const withConfirmation = useWithConfirmation();

  const documentActions: DocumentAction[] = [
    {
      onClick: () => {},
      label: t('documents.actions.edit.actionLabel'),
      isVisible: () => true,
    },
    {
      onClick: () => {
        withConfirmation({
          title: t('documents.actions.delete.confirmationTitle'),
          body: t('documents.actions.delete.confirmationBody'),
          confirmButtonLabel: t('confirm'),
          cancelButtonLabel: t('cancel'),
          onConfirm: () => {},
        });
      },
      label: t('documents.actions.delete.actionLabel'),
      isVisible: () => true,
    },
  ];

  return () => documentActions;
};

export default useDocumentActions;
