import { Heading, IconButton, Icons, Tooltip } from 'plume-ui';
import React, { FunctionComponent, memo } from 'react';

import { useTranslation } from 'react-i18next';
import FormattedMessage from '../../utils/components/FormattedMessage';

type PageHeadingProps = {
  additionalTitle?: string;
  children?: React.ReactNode;
  onBack?: () => void;
  title: string;
  tooltip: string;
};

const PageHeading: FunctionComponent<PageHeadingProps> = ({
  additionalTitle,
  children,
  onBack,
  title,
  tooltip,
}) => {
  const { t } = useTranslation();

  return (
    <Heading
      size="xxxxl"
      medium
      classes={(current) => ({
        ...current,
        root: `${current.root} PageHeading`,
      })}
    >
      {onBack && (
        <IconButton
          onClick={onBack}
          icon={<Icons.ArrowLeftIcon width={24} height={24} />}
        />
      )}
      <FormattedMessage id={title} />
      <Tooltip
        label={t(tooltip)}
        position="right"
        alignment="center"
        overrideMaxCharacters
      >
        <Icons.InfoIcon width={20} />
      </Tooltip>
      {additionalTitle && (
        <span className="PageHeading__additionalTitle">{additionalTitle}</span>
      )}
      {children}
    </Heading>
  );
};

export default memo(PageHeading);
