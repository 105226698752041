import { AxiosError } from 'axios';
import { Field, Form, Formik, FormikValues } from 'formik';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Switch,
  notify,
} from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import React, {
  FunctionComponent,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as yup from 'yup';
import DependencyContainer from '../../../../DependencyContainer';
import DropdownInput, {
  DropdownInputOption,
  DropdownInputStyles,
} from '../../../../components/DropdownInput/DropdownInput';
import InputField from '../../../../components/InputField/InputField';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';
import { getCountries } from '../../../../helpers/countries';
import { MixPanelEvents } from '../../../../mixPanelEvents';
import {
  multiDwellingUnitsAtom,
  selectedPartnerSelector,
  userInfoAtom,
} from '../../../../state';
import { MDU, Permission } from '../../../../types';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { prepareErrorMessageForInput } from '../../../../utils/prepareErrorMessageForInput';
import {
  CreateMduDto,
  NoLocationError,
  UpdateMduDto,
} from '../../../portfolio/types';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { getPropertyByIdSelector } from '../../propertyState';

type CreateMduModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  selectedPropertyId?: string;
};

const addStylesToInputField = (current: any) => ({
  ...current,
  root: `${current.root} CreateMduModal__input`,
});

const { mduService } = new DependencyContainer();

export type CreateMduFormValues = {
  name: string;
  address: string;
  state: string;
  country: string;
  city: string;
  postal_code: string | null;
  partnerId: string;
  lat?: string;
  long?: string;
  optimizationsEnabled: boolean;
  language: string;
};

type MduAdditionalContent = Pick<CreateMduDto, 'address'>;

const geoFieldsDefinition: Array<{
  name: keyof CreateMduFormValues;
  labelId: string;
  type: 'input';
}> = [
  {
    name: 'lat',
    labelId: 'propertyEditor.actions.create.form.lat.label',
    type: 'input',
  },
  {
    name: 'long',
    labelId: 'propertyEditor.actions.create.form.long.label',
    type: 'input',
  },
];

type CreateUpdateDto = CreateMduDto | UpdateMduDto;

const CreateMduModal: FunctionComponent<CreateMduModalProps> = ({
  isOpen,
  onRequestClose,
  selectedPropertyId,
}) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const userInfo = useRecoilValue(userInfoAtom);
  const setMultiDwellingUnits = useSetRecoilState(multiDwellingUnitsAtom);
  const property = useRecoilValue(getPropertyByIdSelector(selectedPropertyId));
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const [initialValues, setInitialValues] = useState<CreateMduFormValues>(
    mduService.getCreateMduFormInitialValues(),
  );
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [hasGeoError, setHasGeoError] = useState(false);
  // const { submitForm } = useFormikContext();

  useEffect(() => {
    setInitialValues(mduService.getCreateMduFormInitialValues(property));
  }, [property]);

  const onClose = () => {
    setHasGeoError(false);
    onRequestClose();
  };

  const languages = useMemo(
    () => [
      { value: 'en', label: t('languages.english') },
      { value: 'en_GB', label: t('languages.english-uk') },
      { value: 'fr', label: t('languages.french') },
      { value: 'fr_CA', label: t('languages.french-canada') },
      { value: 'de', label: t('languages.german') },
      { value: 'ja', label: t('languages.japanese') },
      { value: 'pt', label: t('languages.portuguese') },
      { value: 'es', label: t('languages.spanish') },
    ],
    [t],
  );

  const formInputFieldsDefinition: Array<{
    name: keyof CreateMduFormValues;
    labelId: string;
    type: 'input' | 'dropdown';
    options?: DropdownInputOption[];
  }> = useMemo(
    () => [
      {
        name: 'name',
        labelId: 'propertyEditor.actions.create.form.name.label',
        type: 'input',
      },
      {
        name: 'address',
        labelId: 'propertyEditor.actions.create.form.address.label',
        type: 'input',
      },
      {
        name: 'city',
        labelId: 'propertyEditor.actions.create.form.city.label',
        type: 'input',
      },
      {
        name: 'state',
        labelId: 'propertyEditor.actions.create.form.state.label',
        type: 'input',
      },
      {
        name: 'postal_code',
        labelId: 'propertyEditor.actions.create.form.postalcode.label',
        type: 'input',
      },
      {
        name: 'country',
        labelId: 'propertyEditor.actions.create.form.country.label',
        type: 'dropdown',
        options: getCountries(),
      },
      {
        name: 'language',
        labelId: 'propertyEditor.actions.create.form.language.label',
        type: 'dropdown',
        options: languages,
      },
    ],
    [languages],
  );

  const onSubmit = async (values: FormikValues) => {
    if (!selectedPartner || submitInProgress) {
      return;
    }
    setSubmitInProgress(true);
    const additionalContent: MduAdditionalContent = {
      address: values.address?.trim(),
    };
    const dto: CreateUpdateDto = {
      name: values.name?.trim(),
      state: values.state?.trim(),
      city: values.city?.trim(),
      country: values.country?.trim(),
      language: values.language?.trim(),
      postal_code: values.postal_code?.trim() || null,
      mdu_optimizations_enabled: values.optimizationsEnabled,
      ...additionalContent,
    };

    if (values.lat && values.long) {
      dto.geoInfo = {
        lat: parseFloat(values.lat),
        long: parseFloat(values.long),
      };
    }
    try {
      if (!!property) {
        await onUpdate(property.id, dto, additionalContent, selectedPartner.id);
      } else {
        await onCreate(dto, additionalContent, selectedPartner.id);
      }
      onClose();
    } catch (error) {
      handleApiErrors(error, additionalContent);
    } finally {
      setSubmitInProgress(false);
    }
  };

  const onCreate = async (
    dto: CreateUpdateDto,
    additionalContent: MduAdditionalContent,
    selectedPartnerId: string,
  ) => {
    try {
      const mdu = await mduService.createMdu(dto, selectedPartnerId);
      setMultiDwellingUnits((previousMultiDwellingUnits: MDU[]) => [
        ...previousMultiDwellingUnits,
        {
          ...mdu,
          partnerId: selectedPartnerId,
        },
      ]);
      notify({
        title: t('success'),
        body: t('propertyEditor.actions.create.propertyAdded'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.ADD_MDU_SUCCESS,
        additionalContent,
      });
    } catch (error) {
      throw error;
    }
  };

  const onUpdate = async (
    id: string,
    dto: CreateUpdateDto,
    additionalContent: MduAdditionalContent,
    selectedPartnerId: string,
  ) => {
    try {
      const mdu = await mduService.updateMdu(id, dto, selectedPartnerId);
      setMultiDwellingUnits((previousMultiDwellingUnits: MDU[]) => {
        return previousMultiDwellingUnits.map((currentMdu: MDU) => {
          if (currentMdu.id === mdu.id) {
            return {
              ...mdu,
              partnerId: selectedPartnerId,
            };
          }
          return currentMdu;
        });
      });
      notify({
        title: t('success'),
        body: t('propertyEditor.actions.edit.propertyUpdated'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.ADD_MDU_SUCCESS,
        additionalContent,
      });
    } catch (error) {
      throw error;
    }
  };

  const handleApiErrors = (
    error: AxiosError | NoLocationError,
    additionalContent: MduAdditionalContent,
  ) => {
    trackEvent({
      eventName: MixPanelEvents.ADD_MDU_FAILURE,
      additionalContent,
    });

    if (error instanceof NoLocationError) {
      setHasGeoError(true);
      notify({
        title: t('error'),
        body: error.message,
        type: 'error',
      });
    }
  };

  const getValidationSchema = () =>
    yup.object().shape({
      name: yup
        .string()
        .min(3, t('formValidation.minLength', { length: 3 }))
        .trim()
        .max(128, t('formValidation.maxFields', { length: 128 }))
        .required(t('propertyEditor.actions.create.form.name.required')),
      address: yup
        .string()
        .min(5, t('formValidation.minLength', { length: 5 }))
        .trim()
        .required(t('propertyEditor.actions.create.form.address.required')),
      state: yup
        .string()
        .trim()
        .required(t('propertyEditor.actions.create.form.state.required')),
      city: yup
        .string()
        .trim()
        .required(t('propertyEditor.actions.create.form.city.required')),
      postal_code: yup
        .string()
        .max(20, t('formValidation.maxFields', { length: 20 }))
        .nullable(),
      country: yup
        .string()
        .min(
          3,
          t('propertyEditor.actions.create.form.country.country3CharsCode'),
        )
        .max(
          3,
          t('propertyEditor.actions.create.form.country.country3CharsCode'),
        )
        .trim()
        .required(t('propertyEditor.actions.create.form.country.required')),
      language: yup
        .string()
        .trim()
        .nullable()
        .required(t('propertyEditor.actions.create.form.language.required')),
    });

  const modalTitleId = !!selectedPropertyId
    ? 'propertyEditor.actions.edit.modalTitle'
    : 'propertyEditor.actions.create.modalTitle';

  const hasPermission = (permission: Permission) => {
    return userInfo?.permissions.includes(permission);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={getValidationSchema()}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values, errors, setFieldValue, dirty, submitForm }) => (
        <Form>
          <Modal
            appElement={document.body}
            isOpen={isOpen}
            solidBackground
            scrollable={false}
            onRequestClose={onClose}
            classes={(current: ModalStyles) => ({
              ...current,
              root: `${current.root} CreateMduModal__modal`,
            })}
          >
            <ModalHeader
              title={t(modalTitleId)}
              subtitle={
                !selectedPropertyId
                  ? t('propertyEditor.actions.create.modalSubtitle')
                  : null
              }
            />
            <ModalBody>
              <div className="CreateMduModal__content">
                {formInputFieldsDefinition?.length > 0 && (
                  <div className="CreateMduModal__fields">
                    {formInputFieldsDefinition.map((field) => {
                      const commonProps = {
                        name: field.name,
                        value: values[field.name],
                        messages: prepareErrorMessageForInput(
                          field.name,
                          errors,
                        ),
                        label: t(field.labelId),
                      };
                      return (
                        <React.Fragment key={field.name}>
                          {field.type === 'dropdown' && (
                            <Field
                              {...commonProps}
                              component={DropdownInput}
                              expandDirection="top"
                              openInPortal
                              options={field.options}
                              defaultLabel={t(field.labelId)}
                              classes={(current: DropdownInputStyles) => ({
                                ...current,
                                root: `${current.root} m-t-s`,
                              })}
                              data-testid="name"
                            />
                          )}
                          {field.type === 'input' && (
                            <Field
                              {...commonProps}
                              component={InputField}
                              classes={addStylesToInputField}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                    {(hasGeoError || selectedPropertyId) &&
                      geoFieldsDefinition.map((f) => (
                        <Field
                          key={f.name}
                          name={f.name}
                          value={values[f.name]}
                          messages={prepareErrorMessageForInput(f.name, errors)}
                          label={t(f.labelId)}
                          component={InputField}
                          classes={addStylesToInputField}
                        />
                      ))}
                  </div>
                )}
                <div className="CreateMduModal__optimizationsToggler">
                  <FormattedMessage
                    id={t(
                      'propertyEditor.actions.create.form.optimizations.label',
                    )}
                  />
                  <Switch
                    disabled={!hasPermission(Permission.editMduOptimizations)}
                    title={t(values.optimizationsEnabled ? 'on' : 'off')}
                    selected={values.optimizationsEnabled}
                    onToggle={() => {
                      setFieldValue(
                        'optimizationsEnabled',
                        !values.optimizationsEnabled,
                      );
                    }}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                styleVariant="tertiary-grey"
                onClick={() => onClose()}
              >
                <FormattedMessage id="cancel" />
              </Button>
              <SubmitButton
                disabled={!dirty}
                onSubmit={submitForm}
                submitInProgress={submitInProgress}
                label={selectedPropertyId ? t('saveChanges') : t('submit')}
                inProgressLabel={
                  selectedPropertyId ? t('savingChanges') : undefined
                }
              />
            </ModalFooter>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default memo(CreateMduModal);
