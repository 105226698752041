import { useRecoilState } from 'recoil';
import { apiVersionAtom } from '../state';
import DependencyContainer from '../DependencyContainer';
import { useEffect } from 'react';

const { versionService } = new DependencyContainer();

export const useApiVersion = () => {
  const [apiVersion, setApiVersion] = useRecoilState(apiVersionAtom);

  const getVersion = async () => {
    const version = await versionService.getVersion();
    if (version) {
      setApiVersion(version);
    }
  };
  useEffect(() => {
    if (!apiVersion) {
      getVersion();
    }
  }, []);
};
