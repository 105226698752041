import cx from 'classnames';
import { Icons, Tooltip } from 'plume-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { conditionallyTruncate } from '../../../components/StickyActionTable/util';
import useRenderAnonymized from '../../../hooks/useRenderAnonymized';
import EmptyTableCell from '../../../utils/components/EmptyTableCell';
import { Tenant, TenantStatus } from '../types';

const mapStatusToMessageId = {
  [TenantStatus.Active]: 'tenants.status.active',
  [TenantStatus.Assigned]: 'tenants.status.assigned',
  [TenantStatus.Inactive]: 'tenants.status.inactive',
  [TenantStatus.Suspended]: 'tenants.status.suspended',
  [TenantStatus.Error]: 'tenants.status.error',
};

export type UseTenantMappingsValues = {
  renderTenantName: (name: string, realized: boolean) => JSX.Element;
  renderTenantAccountId: (tenant: Tenant) => JSX.Element | null;
  renderTenantStatus: (status: TenantStatus) => string;
};

const useTenantMappings = (): UseTenantMappingsValues => {
  const { t } = useTranslation();
  const { renderAnonymized } = useRenderAnonymized();

  const renderTenantName = (name: string, realized: boolean) => {
    const classNames = cx({
      unrealized: !realized,
    });
    return (
      <span className={classNames}>{conditionallyTruncate(name, 50)}</span>
    );
  };

  const renderTenantAccountId = (tenant: Tenant) => {
    if (!tenant.unit?.retailPay) {
      if (!tenant.unit?.accountId) {
        return <EmptyTableCell />;
      }
      // Bulk pay - tenant should get acocunt id from unit
      return (
        <div className="TenantsContainer__bulkPayLabel">
          <Tooltip
            openInPortal
            label={renderAnonymized(tenant.unit?.accountId)}
          >
            <span>{t('units.paymentType.bulkPay')}</span>
          </Tooltip>
          <Tooltip openInPortal type="clickType" label={t('copied')}>
            <Icons.CopyIcon
              width={16}
              height={16}
              onClick={() =>
                navigator.clipboard.writeText(
                  renderAnonymized(tenant.unit?.accountId || ''),
                )
              }
            />
          </Tooltip>
        </div>
      );
    }
    // Retail pay - tenant should have their own acocunt id
    return <>{renderAnonymized(tenant.accountId || '')}</>;
  };

  const renderTenantStatus = (status: TenantStatus) => {
    if (status === null) {
      return t('tenants.status.error');
    } else {
      const messageId = mapStatusToMessageId[status];
      return t(messageId);
    }
  };

  return {
    renderTenantName,
    renderTenantAccountId,
    renderTenantStatus,
  };
};
export default useTenantMappings;
