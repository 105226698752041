import { Spinner } from 'plume-ui';
import React, { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';

type SubmittingProps = {
  inProgressLabel?: string | undefined;
};

const Submitting: FunctionComponent<SubmittingProps> = ({
  inProgressLabel,
}) => {
  const { t } = useTranslation();

  return (
    <span className="Submitting">
      <Spinner visible size={'small'}></Spinner>
      {inProgressLabel ? inProgressLabel : t('submitting')}
    </span>
  );
};

export default memo(Submitting);
