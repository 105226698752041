import React, { FunctionComponent, memo } from 'react';
import { LoginContainerAuthErrorProps } from '../types';

const LoginContainerAuthError: FunctionComponent<LoginContainerAuthErrorProps> = ({
  name,
  errorSummary,
}) => {
  return (
    <>
      <h3>{name}</h3>
      <p>{errorSummary}</p>
    </>
  );
};

export default memo(LoginContainerAuthError);
