import DependencyContainer from '../../../DependencyContainer';
import { GeoCode, GeoCodeRequest } from '../../editorView/types';

export default class GeolocationService {
  constructor(private readonly factory: DependencyContainer) {}

  async lookupGeocode(params: GeoCodeRequest): Promise<GeoCode> {
    const { data } = await this.factory.geolocationClient.lookupGeocode(params);
    if (!data || data?.length === 0) {
      return Promise.reject('Unable to get address information');
    }
    return data[0];
  }
}
