import { Icons } from 'plume-ui';
import React from 'react';
import EmptyTableCell from '../../utils/components/EmptyTableCell';
import { InventoryNode } from './types';

export const renderAssignedName = (
  node: InventoryNode,
  renderAnonymized: (string?: string) => void,
) => {
  if (node.businessUnit?.name) {
    return (
      <span className="InventoryContainer__table__assignedTo">
        <Icons.BriefCaseIcon width={16} />
        {renderAnonymized(node.businessUnit.name)}
      </span>
    );
  }
  if (node.residentialUnit?.name) {
    return (
      <span className="InventoryContainer__table__assignedTo">
        <Icons.HomeIcon width={16} />
        {renderAnonymized(node.residentialUnit.name)}
      </span>
    );
  }
  if (node.propertyNetwork?.name) {
    return (
      <span className="InventoryContainer__table__assignedTo">
        <Icons.NetworkIcon width={16} />
        {renderAnonymized(node.propertyNetwork.name)}
      </span>
    );
  }
  return <EmptyTableCell />;
};

export const renderAssignedText = (node: InventoryNode) => {
  if (node.businessUnit?.name) {
    return node.businessUnit.name;
  }
  if (node.residentialUnit?.name) {
    return node.residentialUnit.name;
  }
  if (node.propertyNetwork?.name) {
    return node.propertyNetwork.name;
  }
  return <EmptyTableCell />;
};
