import { Field, Form, Formik, FormikValues } from 'formik';
import { Button, Modal, ModalBody, ModalFooter, Space, notify } from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import React, { FunctionComponent, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as yup from 'yup';
import DependencyContainer from '../../../../DependencyContainer';
import InputField from '../../../../components/InputField/InputField';
import ModalHeaderWithProperty from '../../../../components/ModalHeaderWithProperty/ModalHeaderWithProperty';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';
import { MixPanelEvents } from '../../../../mixPanelEvents';
import {
  selectedMDUSelector,
  selectedPartnerSelector,
} from '../../../../state';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import validateEmail from '../../../../utils/isEmail/validateEmail';
import { prepareErrorMessageForInput } from '../../../../utils/prepareErrorMessageForInput';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { allUnits } from '../../../units/unitsState';
import {
  allTenants,
  businessTenantsAtom,
  residentialTenantsAtom,
  selectedTenantToEditAtom,
} from '../../tenantsState';
import { BusinessTenant, ResidentialTenant, TenantTypes } from '../../types';

type EditTenantModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
};

const addStylesToInputField = (current: any) => ({
  ...current,
  root: `${current.root} EditTenantModal__input`,
});

const { tenantService } = new DependencyContainer();

type EditTenantFormValues = {
  name: string;
  email: string;
  accountId: string | null;
};
const formInputFieldsDefinition: Array<{
  name: keyof EditTenantFormValues;
  labelId: string;
  type: 'input';
}> = [
  {
    name: 'name',
    labelId: 'tenants.actions.edit.form.name.label',
    type: 'input',
  },
  {
    name: 'email',
    labelId: 'tenants.actions.edit.form.email.label',
    type: 'input',
  },
  {
    name: 'accountId',
    labelId: 'tenants.actions.edit.form.accountId.label',
    type: 'input',
  },
];

const EditTenantModal: FunctionComponent<EditTenantModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const selectedMdu = useRecoilValue(selectedMDUSelector);
  const [selectedTenant, setSelectedTenant] = useRecoilState(
    selectedTenantToEditAtom,
  );
  const units = useRecoilValue(allUnits);
  const tenants = useRecoilValue(allTenants);
  const [residentialTenants, setResidentialTenants] = useRecoilState(
    residentialTenantsAtom,
  );
  const [businessTenants, setBusinessTenants] = useRecoilState(
    businessTenantsAtom,
  );
  const [submitInProgress, setSubmitInProgress] = useState(false);

  if (!selectedMdu || !selectedPartner || !selectedTenant) {
    return null;
  }

  const getFormInitialValues = (): EditTenantFormValues => {
    return {
      name: selectedTenant.name,
      email: selectedTenant.email,
      accountId: selectedTenant?.accountId || null,
    };
  };

  const onClose = () => {
    setSelectedTenant(null);
    onRequestClose();
  };

  const updateTenantList = (tenant: BusinessTenant | ResidentialTenant) => {
    if (selectedTenant.type === TenantTypes.Residence) {
      return setResidentialTenants((currentTenants) =>
        currentTenants.map((t) =>
          t.id !== tenant.id ? t : (tenant as ResidentialTenant),
        ),
      );
    }

    setBusinessTenants((currentTenants) =>
      currentTenants.map((t) =>
        t.id !== tenant.id ? t : (tenant as BusinessTenant),
      ),
    );
  };

  const onSubmit = async (values: FormikValues) => {
    if (submitInProgress) {
      return;
    }
    setSubmitInProgress(true);
    try {
      const updatedTenant = await tenantService.updateTenant(
        selectedMdu.id,
        {
          ...(values.name !== selectedTenant.name && {
            name: values.name?.trim(),
          }),
          ...(values.email !== selectedTenant.email && {
            email: values.email.trim(),
          }),
          ...(values.accountId !== selectedTenant.accountId && {
            accountId: values.accountId.trim(),
          }),
        },
        selectedTenant.id,
        selectedTenant.type,
        selectedPartner.id,
      );
      updateTenantList(updatedTenant);

      notify({
        title: t('success'),
        body: t('tenants.actions.edit.tenantUpdated'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.UPDATE_TENANT_SUCCESS,
        additionalContent: {
          unitId: selectedTenant?.unit?.id || null,
          propertyId: selectedMdu.id,
          tenantId: selectedTenant.id,
        },
      });
      setSubmitInProgress(false);

      onClose();
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.UPDATE_TENANT_FAILURE,
        additionalContent: {
          unitId: selectedTenant?.unit?.id || null,
          propertyId: selectedMdu.id,
          tenantId: selectedTenant.id,
        },
      });
      setSubmitInProgress(false);
    }
  };

  const getValidationSchema = () =>
    yup.object().shape({
      name: yup
        .string()
        .min(3, t('formValidation.minLength', { length: 3 }))
        .trim()
        .max(128, t('formValidation.maxFields', { length: 128 }))
        .required(t('tenants.actions.edit.form.name.required')),
      email: yup
        .string()
        .required(t('tenants.actions.edit.form.email.required'))
        .max(256, t('formValidation.maxFields', { length: 256 }))
        .test(
          'is-valid-email',
          t('tenants.actions.create.form.email.invalid'),
          (value) => validateEmail(value || ''),
        )
        .test(
          'tenant-unique',
          t('tenants.actions.create.form.email.unique'),
          (value) => {
            for (const tenant of [...residentialTenants, ...businessTenants]) {
              if (tenant.email === selectedTenant?.email) continue;
              if (tenant.email === value) return false;
            }
            return true;
          },
        )
        .trim(),
      accountId: yup.lazy(() => {
        if (selectedTenant?.accountId) {
          return yup
            .string()
            .trim()
            .max(36, t('formValidation.maxFields', { length: 36 }))
            .required(t('tenants.actions.edit.form.accountId.required'))
            .test(
              'accountId-unique',
              t('tenants.actions.create.form.account_id.unique'),
              (value) => {
                for (const tenant of tenants) {
                  if (tenant.accountId === selectedTenant?.accountId) continue;
                  if (!tenant.unit?.retailPay) {
                    if (tenant.unit?.accountId === value) return false;
                  }
                  if (tenant.accountId === value) return false;
                }
                return true;
              },
            )
            .test(
              'unit-accountId-unique',
              t('tenants.actions.create.form.account_id.uniqueUnit'),
              (value) => {
                if (value === null) return true;
                for (const unit of units) {
                  if (!unit.retailPay) {
                    if (unit.accountId === value) return false;
                  }
                }
                return true;
              },
            );
        }
        return yup
          .string()
          .trim()
          .max(36, t('formValidation.maxFields', { length: 36 }))
          .nullable();
      }),
    });

  if (!isOpen) {
    return null;
  }

  return (
    <Formik
      initialValues={getFormInitialValues()}
      validationSchema={getValidationSchema()}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values, errors, dirty, submitForm }) => (
        <Form>
          <Modal
            appElement={document.getElementById('root') as HTMLElement}
            isOpen={isOpen}
            onRequestClose={onClose}
            classes={(current: ModalStyles) => ({
              ...current,
              root: `${current.root} EditTenantModal`,
            })}
          >
            <ModalHeaderWithProperty
              propertyName={selectedMdu.name}
              title={t('tenants.actions.edit.modalTitle')}
              additionalSubtitle={
                <>
                  <Space size="xxs" />
                  <div className="EditTenantModal__header-unit">
                    {selectedTenant.type} - {selectedTenant.unit?.name}
                  </div>
                </>
              }
            />
            <ModalBody>
              <div className="EditTenantModal__content">
                {formInputFieldsDefinition?.length > 0 && (
                  <div className="EditTenantModal__fields">
                    {formInputFieldsDefinition.map((field) => {
                      if (
                        field.name === 'accountId' &&
                        !selectedTenant.unit?.retailPay
                      )
                        return;
                      return (
                        <Field
                          key={field.name}
                          name={field.name}
                          component={InputField}
                          value={values[field.name]}
                          messages={prepareErrorMessageForInput(
                            field.name,
                            errors,
                          )}
                          label={t(field.labelId)}
                          classes={addStylesToInputField}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                styleVariant="tertiary-grey"
                onClick={onClose}
                data-testid="cancel-button"
              >
                <FormattedMessage id="cancel" />
              </Button>
              <SubmitButton
                disabled={!dirty}
                onSubmit={submitForm}
                submitInProgress={submitInProgress}
                label={t('saveChanges')}
                inProgressLabel={t('savingChanges')}
                data-testid="save-button"
              />
            </ModalFooter>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default memo(EditTenantModal);
