import React, { memo } from 'react';
import useLoginWithOkta from './features/login/hooks/useLoginWithOkta';
import './root.scss';

const ForAuthenticated = ({ children }: { children: React.ReactNode }) => {
  useLoginWithOkta();
  return <>{children}</>;
};

export default memo(ForAuthenticated);
