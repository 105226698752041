import { MDU } from '../../types';

export type CreateMduDto = {
  name: string;
  address: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  language: string;
  geoInfo?: {
    lat: number;
    long: number;
  };
  mdu_optimizations_enabled: boolean;
};

export type UpdateMduDto = CreateMduDto;

export type CreateMduResponse = MDU;

export type UpdateMduResponse = CreateMduResponse;

export class NoLocationError extends Error {
  public readonly name: string = 'NoLocationError';
}

export type HeroDataItem = {
  title: string;
  val: number;
  icon: React.ReactNode;
  iconColor: 'blue' | 'green' | 'red' | 'yellow' | 'purple' | 'gray';
};
