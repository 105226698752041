import { Field, Formik } from 'formik';
import {
  Button,
  InputField,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'plume-ui';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import DropdownInput from '../../../../components/DropdownInput/DropdownInput';
import FormikInputCheckbox from '../../../../components/FormikInputCheckbox/FormikInputCheckbox';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';
import { allTenants } from '../../../tenants/tenantsState';
import { Tenant } from '../../../tenants/types';
import { GenericUnit } from '../../../units/types';
import { allUnits } from '../../../units/unitsState';
import { useEffect, useState } from 'react';
import DropArea from '../../../../components/DropArea/DropArea';

type DocumentModalProps = { isOpen: boolean; onRequestClose: () => void };

const DocumentModal = ({ isOpen, onRequestClose }: DocumentModalProps) => {
  const { t } = useTranslation();

  const [document, setDocument] = useState<File>();

  const units = useRecoilValue(allUnits);
  const residents = useRecoilValue(allTenants);

  useEffect(() => {
    if (!isOpen) setDocument(undefined);
  }, [isOpen]);

  const formInitialValues: {
    name?: string;
    unit?: GenericUnit | 'all';
    resident?: Tenant | 'all';
    sendNotifications: boolean;
  } = {
    name: document?.name,
    unit: 'all',
    resident: 'all',
    sendNotifications: true,
  };

  const fields: {
    name: keyof typeof formInitialValues;
    type?: string;
    options?: { value: GenericUnit | Tenant | string; label: string }[];
  }[] = [
    {
      name: 'name',
    },
    {
      name: 'unit',
      type: 'dropdown',
      options: [
        {
          value: 'all',
          label: t('all'),
        },
        ...units.map((unit) => ({
          value: unit.id,
          label: unit.name,
        })),
      ],
    },
    {
      name: 'resident',
      type: 'dropdown',
      options: [
        {
          value: 'all',
          label: t('all'),
        },
        ...residents.map((resident) => ({
          value: resident.id,
          label: resident.name,
        })),
      ],
    },
    {
      name: 'sendNotifications',
      type: 'checkbox',
    },
  ];

  if (!document) {
    return (
      <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <ModalHeader title={t('documents.actions.upload.modalHeader')} />
        <ModalBody
          classes={(current) => ({
            ...current,
            root: `${current.root} DocumentModal__dropAreaBody`,
          })}
        >
          <DropArea onDocument={(file) => setDocument(file)} />
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalHeader title={t('documents.actions.upload.modalHeader')} />
      <ModalBody
        classes={(current) => ({
          ...current,
          root: `${current.root} DocumentModal`,
        })}
      >
        <Formik initialValues={formInitialValues} onSubmit={() => {}}>
          {({ values }) => (
            <>
              {fields.map((field) => {
                if (field.name === 'resident' && values.unit === 'all') return;
                if (field.type === 'checkbox') {
                  return (
                    <Field
                      component={FormikInputCheckbox}
                      name={field.name}
                      label={t('documents.actions.upload.sendNotifications')}
                    />
                  );
                }
                if (field.type === 'dropdown') {
                  return (
                    <Field
                      key={field.name}
                      wrapLabel={t(`documents.actions.upload.${field.name}`)}
                      openInPortal
                      component={DropdownInput}
                      name={field.name}
                      defaultLabel={t('select')}
                      value={values[field.name]}
                      options={field.options}
                    />
                  );
                }
                return (
                  <Field
                    key={field.name}
                    id={field.name}
                    name={field.name}
                    component={InputField}
                    label={t(`documents.actions.upload.${field.name}`)}
                    value={values[field.name]}
                  />
                );
              })}
            </>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <Button styleVariant="tertiary" onClick={onRequestClose}>
          {t('cancel')}
        </Button>
        <SubmitButton label={t('submit')} submitInProgress={false} />
      </ModalFooter>
    </Modal>
  );
};

export default DocumentModal;
