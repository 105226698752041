import * as yup from 'yup';

const validateSsid = ({
  requiredMessage,
  minLengthMessage,
  maxLengthMessage,
  specialCharacters,
}: {
  requiredMessage: string;
  minLengthMessage: string;
  maxLengthMessage: string;
  specialCharacters: string;
}) => {
  return yup
    .string()
    .required(requiredMessage)
    .min(1, minLengthMessage)
    .max(32, maxLengthMessage)
    .test('includes-special', specialCharacters, (value) => {
      if (value?.includes('<') || value?.includes('>')) return false;
      return true;
    });
};

export default validateSsid;
