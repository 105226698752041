export enum Routes {
  Index = '/',

  Login = '/login',
  LoginCallback = '/implicit/callback',

  Config = '/config',

  PartnerHome = '/partner',
  Partner = '/partner/:partnerId',
  Portfolio = '/partner/:partnerId/properties',
  Users = '/partner/:partnerId/property-managers',

  Property = '/partner/:partnerId/property/:propertyId',

  Documents = '/partner/:partnerId/property/:propertyId/documents',
  Lease = '/partner/:partnerId/property/:propertyId/lease',
  Maintenance = '/partner/:partnerId/property/:propertyId/maintenance',
  Ticket = '/partner/:partnerId/property/:propertyId/ticket',
  PMDashboard = '/partner/:partnerId/property/:propertyId/pm-dashboard',

  Harmony = '/partner/:partnerId/property/:propertyId/harmony',
  Inventory = '/partner/:partnerId/property/:propertyId/inventory',
  Iot = '/partner/:partnerId/property/:propertyId/iot',
  Overview = '/partner/:partnerId/property/:propertyId/overview',
  PropertyNetworks = '/partner/:partnerId/property/:propertyId/property-networks',
  Tenants = '/partner/:partnerId/property/:propertyId/tenants',
  Units = '/partner/:partnerId/property/:propertyId/units',
}

export const LOGIN_SCREEN_ROUTE = Routes.Login;

export const routesRequiringProperty: Routes[] = [
  Routes.Overview,
  Routes.Units,
  Routes.Tenants,
  Routes.PropertyNetworks,
  Routes.Inventory,
  Routes.Iot,
  Routes.Harmony,
];

export const adminRoutes: Routes[] = [Routes.Users];

export const APP_URL = process.env.REACT_APP_URL || window.location.origin;

const translationPath = process.env.REACT_APP_TRANSLATION_FILE_PATH || '/';

export const TRANSLATION_FILE_PATH_TEMPLATE = `${translationPath}{{lng}}.json?${
  process.env.REACT_APP_TRANSLATION_HASH || ''
}`;

export const MIXPANEL_ID =
  process.env.REACT_APP_MIXPANEL_ID || '612ecdbf82e8bb79a0b2f64de48b5713';

export const unitDetailsCacheTTLInSeconds = 15;
export const propertyNetworksCacheTTLInSeconds = 15;

export const DEFAULT_PAGE_SIZE = 10;

export const UPLOAD_UNITS_WITH_NODES_BATCH_SIZE = 5;

export const HARMONY_SYNC_ID = undefined;
