import cx from 'classnames';
import { Icons, Modal, ModalBody, Spinner, Toggler } from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import DependencyContainer from '../../../../DependencyContainer';
import ModalHeaderWithProperty from '../../../../components/ModalHeaderWithProperty/ModalHeaderWithProperty';
import { selectedPartnerSelector, userInfoAtom } from '../../../../state';
import { Permission, Role } from '../../../../types';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { useInventory } from '../../../inventory/hooks/useInventory';
import { useTenants } from '../../../tenants/hooks/useTenants';
import { useUnits } from '../../../units/hooks/useUnits';
import { bulkUploadMduAtom } from '../../propertyState';
import UploadDetailsModal from './UploadDetailsModal';

/* eslint-disable jsx-a11y/anchor-has-content */

export type UploadModalProps = {
  isOpen: boolean;
  type: UploadModalType;
  onRequestClose: () => void;
  allowedImageTypes?: Accept;
};

export type UploadModalType = 'bulkUpload' | 'importTenants' | 'exitTenants';

export type BulkUploadApiResponseRow = {
  UNIT_NAME: string;
  UNIT_TYPE: 'residential' | 'business';
  NODE_ID?: string;
  status: string;
  message?: string;
};

export type BulkUploadApiResponse = {
  rows: BulkUploadApiResponseRow[];
};

export type UploadType =
  | 'nodes'
  | 'units'
  | 'unitsWithNodes'
  | 'importTenants'
  | 'exitTenants';

const { mduService } = new DependencyContainer();

const UploadModal: FunctionComponent<UploadModalProps> = (props) => {
  const { t } = useTranslation();
  const selectedMdu = useRecoilValue(bulkUploadMduAtom);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const allowedImagesTypes = props.allowedImageTypes || {
    'text/plain': ['.csv'],
  };
  const userInfo = useRecoilValue(userInfoAtom);
  const [selectedType, setSelectedType] = useState<UploadType>(
    props.type === 'bulkUpload' ? 'units' : props.type,
  );
  const [
    bulkUploadResult,
    setBulkUploadResult,
  ] = useState<BulkUploadApiResponse | null>(null);
  const { runFetch: fetchUnits } = useUnits(false);
  const { runFetch: fetchTenants } = useTenants(false);
  const { runFetch: fetchNodes } = useInventory(false);

  useEffect(() => {
    if (userInfo?.permissions.includes(Permission.bulkCreateUnits)) {
      setSelectedType('units');
      return;
    }
    if (userInfo?.permissions.includes(Permission.bulkCreateNodes)) {
      setSelectedType('nodes');
      return;
    }
    if (userInfo?.permissions.includes(Permission.bulkImportUnitsAndNodes)) {
      setSelectedType('unitsWithNodes');
      return;
    }
    setSelectedType('units');
  }, [userInfo?.permissions]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!selectedMdu || !selectedPartner) {
        return;
      }
      const formData = new FormData();
      const [file] = acceptedFiles;
      formData.append('file', file);
      setIsUploading(true);

      const type = props.type === 'bulkUpload' ? selectedType : props.type;
      try {
        const result = await mduService.uploadFile(
          selectedMdu.id,
          selectedPartner.id,
          file,
          type,
        );
        switch (type) {
          case 'units':
            await fetchUnits();
            break;
          case 'nodes':
            await fetchNodes();
            break;
          case 'unitsWithNodes':
            await fetchUnits();
            await fetchNodes();
            break;
          case 'importTenants':
          case 'exitTenants':
            await fetchTenants();
            break;
        }
        setBulkUploadResult(result);
        setIsUploading(false);
      } catch (e) {
        setBulkUploadResult(null);
        setIsUploading(false);
      }
    },
    [selectedMdu, selectedPartner, selectedType],
  );

  const dropzone = useDropzone({
    accept: allowedImagesTypes,
    minSize: 1,
    multiple: false,
    preventDropOnDocument: true,
    noClick: false,
    onDrop,
    disabled: isUploading,
  });

  const onClose = () => {
    props.onRequestClose();
  };

  const onDropAreaClick = () => {
    dropzone.open();
  };

  const dropAreaClassNames = cx('UploadModal__drop-area dropzone', {
    'UploadModal__drop-area--accepted': dropzone.isDragAccept,
    'UploadModal__drop-area--active': dropzone.isDragActive,
  });

  const toggleElements = useMemo(() => {
    const elements: {
      key: UploadType;
      label: string;
    }[] = [];
    if (
      userInfo?.role === Role.Support ||
      userInfo?.permissions.includes(Permission.bulkCreateUnits)
    ) {
      elements.push({
        key: 'units',
        label: t('uploadModal.toggler.units'),
      });
    }
    if (
      userInfo?.role === Role.Support ||
      userInfo?.permissions.includes(Permission.bulkCreateNodes)
    ) {
      elements.push({
        key: 'nodes',
        label: t('uploadModal.toggler.inventory'),
      });
    }
    if (
      userInfo?.role === Role.Support ||
      userInfo?.permissions.includes(Permission.bulkImportUnitsAndNodes)
    ) {
      elements.push({
        key: 'unitsWithNodes',
        label: t('uploadModal.toggler.unitsWithInventory'),
      });
    }
    return elements;
  }, [userInfo?.permissions]);

  if (!selectedMdu) return null;

  const getUploadModalSubtext = (selectedType: UploadType) => {
    let subTextFields: {
      subText: string;
      template: string;
      href: string;
      subText2: string;
    } = {
      subText: '',
      template: '',
      href: '',
      subText2: '',
    };
    switch (selectedType) {
      case 'units':
        subTextFields.subText = 'uploadModal.importUnitsSubText';
        subTextFields.template = 'uploadModal.unitTemplate';
        subTextFields.href = '/units.csv';
        subTextFields.subText2 = 'uploadModal.importUnitsSubText2';
        break;
      case 'nodes':
        subTextFields.subText = 'uploadModal.importNodesSubText';
        subTextFields.template = 'uploadModal.nodeTemplate';
        subTextFields.href = '/nodes.csv';
        subTextFields.subText2 = 'uploadModal.importNodesSubText2';
        break;
      case 'unitsWithNodes':
        subTextFields.subText = 'uploadModal.importUnitsWithNodesSubText';
        subTextFields.template = 'uploadModal.unitsWithNodesTemplate';
        subTextFields.href = '/unitsWithNodes.csv';
        subTextFields.subText2 = 'uploadModal.importUnitsWithNodesSubText2';
    }
    return subTextFields;
  };

  const getDownloadTemplateText = () => {
    switch (props.type) {
      case 'bulkUpload':
        return (
          <>
            <div className="UploadModal__subText">
              <p>
                <FormattedMessage
                  id={getUploadModalSubtext(selectedType).subText}
                />
              </p>
              <p>
                <Trans
                  i18nKey={getUploadModalSubtext(selectedType).template}
                  t={t}
                  components={[
                    <a
                      href={getUploadModalSubtext(selectedType).href}
                      download
                    />,
                  ]}
                />
              </p>
              <p>
                <FormattedMessage
                  id={getUploadModalSubtext(selectedType).subText2}
                />
              </p>
            </div>
          </>
        );
      case 'exitTenants':
        return (
          <Trans
            i18nKey="uploadModal.exitTenantsTemplate"
            t={t}
            components={[<a href="/exitTenants.csv" download />]}
          />
        );
      case 'importTenants':
        return (
          <Trans
            i18nKey="uploadModal.importTenantsTemplate"
            t={t}
            components={[<a href="/importTenants.csv" download />]}
          />
        );
    }
  };

  const getDetailsModalHeading = () => {
    switch (props.type) {
      case 'bulkUpload':
        return t('uploadModal.detailsModal.uploadComplete');
      case 'exitTenants':
        return t('uploadModal.detailsModal.exitComplete');
      case 'importTenants':
        return t('uploadModal.detailsModal.importComplete');
    }
  };

  return (
    <>
      <Modal
        appElement={document.getElementById('root') as HTMLElement}
        isOpen={props.isOpen}
        onRequestClose={onClose}
        classes={(current: ModalStyles) => ({
          ...current,
          root: `${current.root} UploadModal`,
        })}
      >
        <ModalHeaderWithProperty
          propertyName={selectedMdu.name}
          title={t('uploadModal.' + props.type)}
        />
        <ModalBody>
          {props.type === 'bulkUpload' && (
            <Toggler
              toggleElements={toggleElements}
              value={selectedType}
              onToggle={(selection) =>
                setSelectedType(selection.key as UploadType)
              }
            />
          )}
          {props.type === 'importTenants' && (
            <div className="UploadModal__subText">
              <p>
                <FormattedMessage id="uploadModal.importTenantsSubText" />
              </p>
              <p>
                <FormattedMessage id="uploadModal.importTenantsSubText2" />
              </p>
              <p>
                <FormattedMessage id="uploadModal.importTenantsSubText3" />
              </p>
            </div>
          )}
          {props.type === 'exitTenants' && (
            <div className="UploadModal__subText">
              <p>
                <FormattedMessage id="uploadModal.exitTenantsSubText" />
              </p>
              <p>
                <FormattedMessage id="uploadModal.exitTenantsSubText2" />
              </p>
            </div>
          )}
          <div className="UploadModal__downloadText">
            {getDownloadTemplateText()}
          </div>
          <div
            {...dropzone.getRootProps()}
            className={dropAreaClassNames}
            onClick={onDropAreaClick}
          >
            <input id="upload" {...dropzone.getInputProps()} />
            {isUploading ? (
              <>
                <Spinner visible />
                <p>
                  <FormattedMessage id="uploadModal.uploading" />
                </p>
              </>
            ) : (
              <>
                <Icons.SignOutIcon />
                <p>
                  <FormattedMessage id="uploadModal.dragAndDrop" />
                </p>
              </>
            )}
          </div>
          {props.type === 'importTenants' && (
            <p className="UploadModal__undoText">
              <FormattedMessage id="uploadModal.importTenantsUndoneText" />
            </p>
          )}
          {isUploading && (
            <p className="UploadModal__info">
              <small>
                <FormattedMessage id="uploadModal.disabledInfo" />
              </small>
            </p>
          )}
        </ModalBody>
      </Modal>
      <UploadDetailsModal
        heading={getDetailsModalHeading()}
        bulkUploadResult={bulkUploadResult}
        unitsWithInventoryType={selectedType === 'unitsWithNodes'}
        isOpen={Boolean(bulkUploadResult)}
        onClose={() => setBulkUploadResult(null)}
      />
    </>
  );
};

export default memo(UploadModal);
