import {
  Button,
  Grid,
  GridItem,
  HeroStat,
  Pagination,
  PendingContent,
  Spinner,
  notify,
} from 'plume-ui';
import { ChangeEvent, FunctionComponent, memo, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import DependencyContainer from '../../../DependencyContainer';
import AuraGlow from '../../../components/AuraGlow/AuraGlow';
import PageHeading from '../../../components/PageHeading/PageHeading';
import TableFilterRow from '../../../components/TableFilter/TableFilterRow';
import useWithConfirmation from '../../../hooks/useWithConfirmation';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { selectedMDUSelector, selectedPartnerSelector } from '../../../state';
import { ModalState, ModalType, Permission } from '../../../types';
import { useHasPermission } from '../../../utils/hooks/useHasPermission';
import { propertyNetworksAtom } from '../../property-networks/propertyNetworksState';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { UnitIotPropagationStatus } from '../../units/types';
import { residentialUnitsAtom } from '../../units/unitsState';
import IotDeviceCard from '../components/IotDeviceCard/IotDeviceCard';
import useIotDevices from '../hooks/useIotDevices';
import {
  currentIotDevicesPage,
  filteredIotDevicesCount,
  filteredIotDevices as filteredIotDevicesSelector,
  hasIotNetworkSelector,
  iotDevicesAtom,
  iotDevicesSelectedFilterAtom,
  iotDevicesSelectedPageAtom,
  iotModalStateAtom,
  iotNetworkAtom,
} from '../state';
import { IotDeviceConnectionState } from '../types';
import { deviceHasAlarms } from '../util';

const { iotService } = new DependencyContainer();

const IotContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const hasPermission = useHasPermission();
  const withConfirmation = useWithConfirmation();
  const selectedProperty = useRecoilValue(selectedMDUSelector);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const setPropertyNetworks = useSetRecoilState(propertyNetworksAtom);
  const residentialUnits = useRecoilValue(residentialUnitsAtom);
  const [iotNetwork, setIotNetwork] = useRecoilState(iotNetworkAtom);
  const hasIotNetwork = useRecoilValue(hasIotNetworkSelector);
  const setIotModalState = useSetRecoilState(iotModalStateAtom);
  const filteredIotDevices = useRecoilValue(filteredIotDevicesSelector);
  const setIotDevices = useSetRecoilState(iotDevicesAtom);
  const iotDevices = useRecoilValue(currentIotDevicesPage);
  const iotDevicesCount = useRecoilValue(filteredIotDevicesCount);
  const [selectedFilter, setSelectedFilter] = useRecoilState(
    iotDevicesSelectedFilterAtom,
  );
  const [selectedPage, setSelectedPage] = useRecoilState(
    iotDevicesSelectedPageAtom,
  );
  const { loading: iotDevicesLoading } = useIotDevices();

  const hasFailedUnits = useMemo(() => {
    if (
      residentialUnits.find(
        (unit) =>
          (unit.iotConfiguration === null && Boolean(unit.locationId)) ||
          (!unit.iotConfiguration?.iot_network_manually_disabled &&
            unit.iotConfiguration?.propagationStatus ===
              UnitIotPropagationStatus.Failed),
      )
    ) {
      return true;
    }
    return false;
  }, [residentialUnits]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value === undefined ? '' : e.target.value;
    setSelectedFilter(value);
    setSelectedPage(0);
  };

  const onDelete = () => {
    withConfirmation({
      title: t('iot.actions.delete.label'),
      body: t('iot.actions.delete.deleteConfirmation'),
      onConfirm: async () => {
        if (!selectedPartner?.id) return;
        if (!selectedProperty?.id) return;
        if (!iotNetwork?.propertyNetworkId) return;
        try {
          await iotService.deleteIotNetwork(
            iotNetwork.propertyNetworkId,
            selectedProperty.id,
            selectedPartner.id,
          );
          notify({
            title: t('inProgress'),
            body: t('iot.actions.delete.scheduled'),
            type: 'info',
          });
          trackEvent({
            eventName: MixPanelEvents.DELETE_IOT_NETWORK,
            additionalContent: {
              propertyNetworkId: iotNetwork.propertyNetworkId,
              propertyId: selectedProperty.id,
            },
          });
          setPropertyNetworks((propertyNetworks) =>
            propertyNetworks.map((propertyNetwork) => {
              if (propertyNetwork.id === iotNetwork.propertyNetworkId) {
                return { ...propertyNetwork, iotWifi: null };
              }
              return propertyNetwork;
            }),
          );
          setIotNetwork(null);
          setIotDevices([]);
        } catch (error) {
          trackEvent({
            eventName: MixPanelEvents.DELETE_IOT_NETWORK_FAILURE,
            additionalContent: {
              propertyNetworkId: iotNetwork.propertyNetworkId,
              propertyId: selectedProperty.id,
            },
          });
        }
      },
    });
  };

  const onRetryIotPropagation = async () => {
    withConfirmation({
      title: t('iot.actions.retryPropagation.label'),
      body: t('iot.actions.retryPropagation.confirmation'),
      onConfirm: async () => {
        if (!selectedPartner?.id) return;
        if (!selectedProperty?.id) return;
        if (!iotNetwork?.propertyNetworkId) return;
        try {
          await iotService.propagateIotNetwork(
            iotNetwork.propertyNetworkId,
            selectedProperty.id,
            selectedPartner.id,
          );
          notify({
            title: t('inProgress'),
            body: t('iot.actions.retryPropagation.successMessage'),
            type: 'info',
          });
        } catch (error) {}
      },
    });
  };

  const getResultText = () => {
    if (!filteredIotDevices || filteredIotDevices.length === 0) {
      return t('iot.search.resultCount', { count: 0 });
    }
    let online = 0;
    let offline = 0;
    let alarmed = 0;
    filteredIotDevices.forEach((iotDevice) => {
      if (deviceHasAlarms(iotDevice)) alarmed += 1;
      if (iotDevice.connectionState === IotDeviceConnectionState.Connected)
        online += 1;
      if (iotDevice.connectionState === IotDeviceConnectionState.Disconnected)
        offline += 1;
    });
    return (
      <div className="IotContainer__result">
        <div>
          {t('iot.search.resultCount', {
            count: filteredIotDevices?.length,
          })}
        </div>
        <div>
          {t('iot.search.resultCountGroup', { online, offline, alarmed })}
        </div>
      </div>
    );
  };

  const getNumberOfOnlineDevices = () => {
    return filteredIotDevices.filter(
      (device) => device.connectionState === IotDeviceConnectionState.Connected,
    ).length;
  };

  const getNumberOfAlarmedDevices = () => {
    return filteredIotDevices.filter(
      (device) => (device.health?.score || 5) < 3,
    ).length;
  };

  const getNumberOfNonAlarmedDevices = () => {
    return Math.max(
      getNumberOfOnlineDevices() - getNumberOfAlarmedDevices(),
      0,
    );
  };

  const getNumberOfQuarantinedDevices = () => {
    return filteredIotDevices.filter((device) => device.quarantined).length;
  };

  return (
    <>
      <Helmet>
        <title>{t('iot.title')}</title>
      </Helmet>
      <div className="IotContainer p-xl">
        <AuraGlow header footer />
        <PageHeading
          title="iot.title"
          tooltip="iot.tooltip"
          additionalTitle={selectedProperty?.name}
        />
        <TableFilterRow
          placeholder="iot.search.placeholder"
          value={selectedFilter}
          onSearch={handleSearch}
          resultText={getResultText()}
          actionButton={{
            isDisabled: hasIotNetwork
              ? !hasPermission(Permission.updateIotNetwork)
              : !hasPermission(Permission.createIotNetwork),
            onClick: () => {
              setIotModalState({
                state: ModalState.Open,
                type: hasIotNetwork ? ModalType.Edit : ModalType.Create,
              });
            },
            label: hasIotNetwork
              ? 'iot.actions.edit.buttonLabel'
              : 'iot.actions.add.buttonLabel',
          }}
        >
          {hasPermission(Permission.postPropagateIotNetwork) && hasFailedUnits && (
            <Button
              classes={(current) => ({
                ...current,
                container: `${current.container} IotContainer__button`,
              })}
              onClick={onRetryIotPropagation}
              styleVariant="secondary"
            >
              {t('iot.actions.retryPropagation.label')}
            </Button>
          )}
          {hasIotNetwork && (
            <Button
              disabled={!hasPermission(Permission.deleteIotNetwork)}
              classes={(current) => ({
                ...current,
                container: `${current.container} IotContainer__button`,
              })}
              onClick={onDelete}
              styleVariant="secondary"
            >
              {t('iot.actions.delete.label')}
            </Button>
          )}
        </TableFilterRow>
        <PendingContent
          loading={iotDevicesLoading}
          loader={Spinner}
          hideContent
        >
          {hasIotNetwork && (
            <div className="IotContainer__heroStats">
              <HeroStat
                statLabel={t('iot.stats.online')}
                stat={getNumberOfOnlineDevices()}
              />
              <HeroStat
                statLabel={t('iot.stats.alarm')}
                stat={getNumberOfAlarmedDevices()}
              />
              <HeroStat
                statLabel={t('iot.stats.health')}
                stat={getNumberOfNonAlarmedDevices()}
              />
              <HeroStat
                statLabel={t('iot.stats.quarantined')}
                stat={getNumberOfQuarantinedDevices()}
              />
            </div>
          )}
          {!iotDevicesLoading && iotDevices.length === 0 && (
            <div className="IotContainer__placeholder" />
          )}
          <Grid>
            {iotDevices.map((iotDevice) => {
              return (
                <GridItem
                  key={iotDevice.deviceType.iconV2 + iotDevice.mac}
                  colSpan="4"
                  tabletColSpan="3"
                >
                  <IotDeviceCard {...iotDevice} />
                </GridItem>
              );
            })}
          </Grid>
          {Math.ceil(iotDevicesCount / 12) > 0 && (
            <Pagination
              classes={(current) => ({
                ...current,
                root: `${current.root} InventoryContainer__pagination`,
              })}
              expandDirection={'top'}
              totalPageCount={Math.ceil(iotDevicesCount / 12) || 1}
              onPageSelect={setSelectedPage}
              currentPage={selectedPage}
            />
          )}
        </PendingContent>
      </div>
    </>
  );
};

export default memo(IotContainer);
