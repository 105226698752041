import React, { FunctionComponent } from 'react';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContainer from './AppContainer';
import { HelmetProvider } from 'react-helmet-async';

const App: FunctionComponent = () => {
  return (
    <HelmetProvider>
      <RecoilRoot>
        <Router>
          <AppContainer />
        </Router>
      </RecoilRoot>
    </HelmetProvider>
  );
};

export default App;
