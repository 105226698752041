import { Field, Formik } from 'formik';
import {
  Button,
  DateRangeSelect,
  FilterDropdown,
  FilterDropdownItem,
  InputField,
} from 'plume-ui';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AuraGlow from '../../../components/AuraGlow/AuraGlow';
import FormInputField from '../../../components/FormInputField/FormInputField';
import FormSection from '../../../components/FormSection/FormSection';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { Routes } from '../../../config';
import { selectedMDUSelector } from '../../../state';
import { isDevelopment, isLocal } from '../../../utils/environment';
import { useRedirectToRoute } from '../../../utils/hooks/useRedirectToRoute';
import { allTenants } from '../../tenants/tenantsState';
import { GenericUnit } from '../../units/types';
import { allUnits } from '../../units/unitsState';
import RecurringFeeModal from '../components/RecurringFeeModal/RecurringFeeModal';
import { LeaseType } from '../types';

const LeaseContainer = () => {
  const { t } = useTranslation();
  const redirectToRoute = useRedirectToRoute();

  const selectedProperty = useRecoilValue(selectedMDUSelector);
  const units = useRecoilValue(allUnits);
  const residents = useRecoilValue(allTenants);

  const [selectedUnit, setSelectedUnit] = useState<GenericUnit>();
  const [editingAddress, setEditingAddress] = useState(false);
  const [editingResidents, setEditingResidents] = useState(false);
  const [isRecurringFeeModalOpen, setIsRecurringFeeModalOpen] = useState(false);

  if (!isLocal() && !isDevelopment()) {
    redirectToRoute(Routes.Index);
  }

  const initialFormValues: {
    unit?: GenericUnit;
    address?: string;
    city?: string;
    postalCode?: string;
    state?: string;
    name?: string;
    email?: string;
    notes?: string;
    leaseType?: LeaseType;
    startDate?: Date;
    endDate?: Date;
    lateFee?: number;
    fixedFee?: number;
    feeDays?: number;
    securityDeposit?: number;
    rent?: number;
  } = {
    lateFee: 0,
    fixedFee: 0,
    feeDays: 0,
    securityDeposit: 0,
    rent: 0,
  };

  const leaseTypeDropdownItems: FilterDropdownItem[] = [
    {
      label: t('lease.leaseDetails.leaseType.fixed'),
      value: LeaseType.Fixed,
    },
  ];

  const onSelectUnit = (
    unitId: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    const unit = units.find((unit) => unit.id === unitId);

    setFieldValue('unit', unit);
    setSelectedUnit(unit);

    if (!selectedProperty) {
      setEditingAddress(true);
    } else {
      setFieldValue('address', selectedProperty?.address);
      setFieldValue('city', selectedProperty?.city);
      setFieldValue('postalCode', selectedProperty?.postal_code);
      setFieldValue('state', selectedProperty?.state);
    }

    const resident = residents.find(
      (resident) => resident.id === unit?.tenant?.id,
    );

    if (!resident) {
      setEditingResidents(true);
    } else {
      setFieldValue('name', resident?.name);
      setFieldValue('email', resident?.email);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('lease.title')}</title>
      </Helmet>
      <div className="LeaseContainer p-xl">
        <AuraGlow header footer />
        <PageHeading title="lease.title" tooltip="lease.tooltip" />
        <Formik initialValues={initialFormValues} onSubmit={() => {}}>
          {({ values, setFieldValue }) => (
            <>
              <form className="LeaseContainer__content">
                <FormSection
                  title={t('lease.unitDetails.title')}
                  rows={[
                    [
                      <FilterDropdown
                        containerLabel={t('lease.unitDetails.dropdownLabel')}
                        defaultLabel={selectedUnit?.name || t('select')}
                        openInPortal
                        items={units.map((unit) => ({
                          label: unit.name,
                          value: unit.id,
                        }))}
                        onSelect={(dropdownItem) =>
                          onSelectUnit(dropdownItem.value, setFieldValue)
                        }
                      />,
                    ],
                  ]}
                />
                <FormSection
                  hide={!selectedUnit}
                  title={t('lease.address.title')}
                  titleSize="xl"
                  headerButton={
                    <Button
                      styleVariant="tertiary"
                      onClick={() => setEditingAddress(!editingAddress)}
                    >
                      {editingAddress
                        ? t('save')
                        : t('lease.address.editButtonLabel')}
                    </Button>
                  }
                  rows={[
                    [
                      <FormInputField
                        isEditing={editingAddress}
                        id="address"
                        name="address"
                        value={values.address}
                        label={t('lease.address.inputs.address')}
                      />,
                      <FormInputField
                        isEditing={editingAddress}
                        id="city"
                        name="city"
                        value={values.city}
                        label={t('lease.address.inputs.city')}
                      />,
                    ],
                    [
                      <FormInputField
                        isEditing={editingAddress}
                        id="postalCode"
                        name="postalCode"
                        value={values.postalCode}
                        label={t('lease.address.inputs.postalCode')}
                      />,
                      <FormInputField
                        isEditing={editingAddress}
                        id="state"
                        name="state"
                        value={values.state}
                        label={t('lease.address.inputs.state')}
                      />,
                    ],
                  ]}
                />
                <FormSection
                  hide={!selectedUnit}
                  title={t('lease.residents.title')}
                  titleSize="xl"
                  headerButton={
                    <Button
                      styleVariant="tertiary"
                      onClick={() => setEditingResidents(!editingResidents)}
                    >
                      {editingResidents
                        ? t('save')
                        : t('lease.residents.editButtonLabel')}
                    </Button>
                  }
                  rows={[
                    [
                      <FormInputField
                        isEditing={editingResidents}
                        id="name"
                        name="name"
                        value={values.name}
                        label={t('lease.residents.inputs.name')}
                      />,
                      <FormInputField
                        isEditing={editingResidents}
                        id="email"
                        name="email"
                        value={values.email}
                        label={t('lease.residents.inputs.email')}
                      />,
                    ],
                  ]}
                />
                <FormSection
                  title={t('lease.notes.title')}
                  rows={[
                    [
                      <Field
                        id="notes"
                        name="notes"
                        component={InputField}
                        value={values.notes}
                        label={t('lease.notes.title')}
                      />,
                    ],
                  ]}
                />
                <FormSection
                  title={t('lease.leaseDetails.title')}
                  rows={[
                    [
                      <FilterDropdown
                        items={leaseTypeDropdownItems}
                        containerLabel={t(
                          'lease.leaseDetails.inputs.leaseType',
                        )}
                        defaultLabel={leaseTypeDropdownItems[0].label}
                        onSelect={() => {}}
                      />,
                    ],
                    [
                      <div className="LeaseContainer__dateRange">
                        <p>{t('lease.leaseDetails.inputs.startDate')}</p>
                        <DateRangeSelect
                          label={values.startDate?.toLocaleDateString()}
                          value={values.startDate}
                          singleDate
                          extraOptions={[]}
                          show={false}
                          openInPortal
                          onSelect={(date) => setFieldValue('startDate', date)}
                        />
                      </div>,
                      <div className="LeaseContainer__dateRange">
                        <p>{t('lease.leaseDetails.inputs.endDate')}</p>
                        <DateRangeSelect
                          label={values.endDate?.toLocaleDateString()}
                          value={values.endDate}
                          singleDate
                          extraOptions={[]}
                          show={false}
                          openInPortal
                          onSelect={(date) => setFieldValue('endDate', date)}
                        />
                      </div>,
                    ],
                  ]}
                />
                <FormSection
                  title={t('lease.lateFee.title')}
                  rows={[
                    [
                      <Field
                        id="lateFee"
                        name="lateFee"
                        type="number"
                        component={InputField}
                        label={t('lease.lateFee.inputs.lateFee')}
                        value={values.lateFee}
                        icon={<>{'%'}</>}
                      />,
                      <Field
                        id="fixedFee"
                        name="fixedFee"
                        type="number"
                        component={InputField}
                        label={t('lease.lateFee.inputs.fixedFee')}
                        value={values.fixedFee}
                        icon={<>{'$'}</>}
                      />,
                      <Field
                        id="feeDays"
                        name="feeDays"
                        type="number"
                        component={InputField}
                        label={t('lease.lateFee.inputs.feeDays')}
                        value={values.feeDays}
                      />,
                    ],
                  ]}
                />
                <FormSection
                  title={t('lease.securityDeposit.title')}
                  rows={[
                    [
                      <Field
                        id="securityDeposit"
                        key="securityDeposit"
                        type="number"
                        component={InputField}
                        value={values.securityDeposit}
                        label={t('lease.amount')}
                        icon={<>{'$'}</>}
                      />,
                    ],
                  ]}
                />
                <FormSection title={t('lease.recurringFees.title')} />
                <FormSection
                  title={t('lease.rent.title')}
                  titleSize="xl"
                  rows={[
                    [
                      <Field
                        id="rent"
                        name="rent"
                        type="number"
                        component={InputField}
                        value={values.rent}
                        label={t('lease.amount')}
                        icon={<>{'$'}</>}
                      />,
                    ],
                  ]}
                />
                <FormSection
                  title={t('lease.otherCharges.title')}
                  titleSize="xl"
                  rows={[
                    [
                      <Button
                        styleVariant="secondary"
                        onClick={() => setIsRecurringFeeModalOpen(true)}
                      >
                        {t('lease.otherCharges.buttonLabel')}
                      </Button>,
                    ],
                  ]}
                />
                <FormSection
                  titleSize="xl"
                  rows={[
                    [<Button styleVariant="superprimary">{t('save')}</Button>],
                  ]}
                />
              </form>
              <RecurringFeeModal
                isOpen={isRecurringFeeModalOpen}
                onRequestClose={() => setIsRecurringFeeModalOpen(false)}
              />
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default LeaseContainer;
