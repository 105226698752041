import {
  HarmonyChannelDistribution,
  HarmonyChannelInterference,
  HarmonyHappinessIndex,
  HarmonyTxAirtimeUtilization,
} from '../harmony/types';

export type PropertyStats = {
  alertCount: number;
  businessAlertsUpdatedAt: string;
  businessAverageDownloadSpeed: number;
  businessAverageUploadSpeed: number;
  businessNodesUpdatedAt: string;
  businessUnitAlertCount: number;
  businessUnitCount: number;
  businessUnitNodeCount: number;
  businessUnitNodeOnlineCount: number;
  businessUnitOccupiedCount: number;
  harmonyChannelDistribution: HarmonyChannelDistribution;
  harmonyChannelInterference: HarmonyChannelInterference;
  harmonyHappinessIndex: HarmonyHappinessIndex;
  harmonyTxAirtimeUtilization: HarmonyTxAirtimeUtilization;
  claimedNodeCount: number;
  inventoryNodeCount: number;
  propertyId: string;
  propertyNetworkAlertCount: number;
  propertyNetworkAlertsUpdatedAt: string;
  propertyNetworkAverageDownloadSpeed: number;
  propertyNetworkAverageUploadSpeed: number;
  propertyNetworkCount: number;
  propertyNetworkNodeCount: number;
  propertyNetworkNodeOnlineCount: number;
  propertyNetworkNodesUpdatedAt: string;
  residentialAlertsUpdatedAt: string;
  residentialAverageDownloadSpeed: number;
  residentialAverageUploadSpeed: number;
  residentialNodesUpdatedAt: string;
  residentialUnitAlertCount: number;
  residentialUnitCount: number;
  residentialUnitNodeCount: number;
  residentialUnitNodeOnlineCount: number;
  residentialUnitOccupiedCount: number;
  speedTests: SpeedTest[];
};

export type SpeedTest = {
  dt: string;
  avg_download_speed: number;
  max_download_speed: number;
  min_download_speed: number;
};

export type LoadingStatus = 'loading' | 'loaded' | 'error';

export type OverviewStatsSection = {
  total: number;
  onlineNodes: {
    current: number;
    total: number;
  };
  alertCount: number;
  avgSpeed: string;
  updatedAt: string | null;
};

export type BusinessStats = OverviewStatsSection & {
  occupancy: {
    current: number;
    total: number;
  };
};

export type ResidencesStats = OverviewStatsSection & {
  occupancy: {
    current: number;
    total: number;
  };
};

export type OverviewStats = {
  residences: ResidencesStats;
  businesses: BusinessStats;
  propertyNetworks: OverviewStatsSection;
  harmonyChannelDistribution: HarmonyChannelDistribution;
  harmonyChannelInterference: HarmonyChannelInterference;
  harmonyHappinessIndex: HarmonyHappinessIndex;
  harmonyTxAirtimeUtilization: HarmonyTxAirtimeUtilization;
  speedTests: SpeedTest[];
};

export enum SpeedTestChartFilters {
  Last7Days = '7days',
  Last30Days = '30days',
}
