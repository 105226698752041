import { Field, Formik } from 'formik';
import {
  Button,
  FilterDropdownItem,
  InputField,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'plume-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownInput from '../../../../components/DropdownInput/DropdownInput';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';

type RecurringFeeModalProps = { isOpen: boolean; onRequestClose: () => void };

const RecurringFeeModal = ({
  isOpen,
  onRequestClose,
}: RecurringFeeModalProps) => {
  const { t } = useTranslation();

  const [addEndMonth, setAddEndMonth] = useState(false);

  const formInitialValues: {
    feeType?: string;
    amount: number;
    startMonth?: FilterDropdownItem;
    endMonth?: FilterDropdownItem;
  } = {
    amount: 0,
  };

  const fields: { name: keyof typeof formInitialValues; type?: string }[] = [
    {
      name: 'feeType',
    },
    {
      name: 'amount',
      type: 'number',
    },
  ];

  const MonthField = ({
    name,
    values,
  }: {
    name: keyof typeof formInitialValues;
    values: typeof formInitialValues;
  }) => {
    return (
      <Field
        wrapLabel={t(`lease.actions.addFee.${name}`)}
        openInPortal
        component={DropdownInput}
        name={name}
        defaultLabel={t('select')}
        value={values[name]}
        options={Array(12)
          .fill(0)
          .map((_, i) => ({
            value: `${i}`,
            label: t(`month.${i}`),
          }))}
      />
    );
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalHeader title={t('lease.actions.addFee.title')} />
      <ModalBody
        classes={(current) => ({
          ...current,
          root: `${current.root} RecurringFeeModal`,
        })}
      >
        <Formik initialValues={formInitialValues} onSubmit={() => {}}>
          {({ values }) => (
            <>
              {fields.map((field) => {
                return (
                  <Field
                    key={field.name}
                    id={field.name}
                    name={field.name}
                    component={InputField}
                    label={t(`lease.actions.addFee.${field.name}`)}
                    value={values[field.name]}
                    type={field.type}
                    icon={field.type === 'number' ? <>{'$'}</> : undefined}
                  />
                );
              })}
              {<MonthField name={'startMonth'} values={values} />}
              {!addEndMonth && (
                <Button
                  styleVariant="secondary"
                  onClick={() => setAddEndMonth(true)}
                >
                  {t('lease.actions.addFee.addEndMonth')}
                </Button>
              )}
              {addEndMonth && <MonthField name={'endMonth'} values={values} />}
            </>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <Button styleVariant="tertiary-grey" onClick={() => onRequestClose()}>
          {t('cancel')}
        </Button>
        <SubmitButton
          label={t('submit')}
          inProgressLabel={t('submitting')}
          submitInProgress={false}
        />
      </ModalFooter>
    </Modal>
  );
};

export default RecurringFeeModal;
