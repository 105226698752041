import { StateMeta } from '../units/types';
import { CreatedUpdated } from '../../utils/types';

export enum NodeStatus {
  Assigned = 'assigned',
  Unassigned = 'unassigned',
  Decommissioned = 'decommissioned',
}

export type BasicNode = CreatedUpdated & {
  businessUnit?: {
    id: string;
    name: string;
  } | null;
  id: string;
  propertyNetwork?: {
    id: string;
    name: string;
  } | null;
  residentialUnit?: {
    id: string;
    name: string;
  } | null;
  status: NodeStatus;
};

export type InventoryNode = BasicNode & {
  customerId: string;
  locationId: string;
  stateMeta: StateMeta;
};

export type NodeReference = {
  id: string;
};

export type SortableNodeFieldNames =
  | 'id'
  | 'status'
  | 'assignedTo'
  | 'createdAt';
