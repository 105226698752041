import { Grid, GridItem, Icons, Toggler } from 'plume-ui';
import { TogglerStyles } from 'plume-ui/dist/components/Toggler/Toggler';
import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AuraGlow from '../../../components/AuraGlow/AuraGlow';
import PageHeading from '../../../components/PageHeading/PageHeading';
import DependencyContainer from '../../../DependencyContainer';
import { useFetchData } from '../../../hooks/useFetchData';
import { MixPanelEvents } from '../../../mixPanelEvents';
import {
  multiDwellingUnitsAtom,
  selectedPartnerSelector,
} from '../../../state';
import { PortfolioStats } from '../../../types';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import EditorViewContainer from '../../editorView/containers/EditorViewContainer';
import MapViewContainer from '../../mapView/MapViewContainer';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { AvailableScreens } from '../../trackingAnalytics/types';
import { HeroDataItem } from '../types';

enum PortfolioType {
  Map = 'map',
  Editor = 'editor',
}

const { mduService } = new DependencyContainer();

const PortfolioContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const multiDwellingUnits = useRecoilValue(multiDwellingUnitsAtom);
  const trackEvent = useTrackEvent();
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const [statsLoading, portfolioStats, statsError] = useFetchData<
    PortfolioStats,
    any
  >(
    async () => {
      if (!selectedPartner) {
        return;
      }
      return await mduService.fetchPortfolioStats(selectedPartner.id);
    },
    undefined,
    [selectedPartner],
  );
  const [portfolioType, setPortfolioType] = useState(PortfolioType.Map);

  const heroData: HeroDataItem[] = [
    {
      title: 'portfolio.heroStats.mduProperties',
      val: multiDwellingUnits?.length,
      icon: <Icons.BuildingIcon width={24} height={24} />,
      iconColor: 'blue',
    },
    {
      title: 'portfolio.heroStats.businessUnitCount',
      val: portfolioStats?.businessUnitCount || 0,
      icon: <Icons.BriefCaseIcon width={24} height={24} />,
      iconColor: 'yellow',
    },
    {
      title: 'portfolio.heroStats.residentialUnitCount',
      val: portfolioStats?.residentialUnitCount || 0,
      icon: <Icons.HomeIcon width={27} />,
      iconColor: 'green',
    },
    {
      title: 'portfolio.heroStats.alerts',
      val: portfolioStats?.alertCount || 0,
      icon: <Icons.BellIcon width={29} height={29} />,
      iconColor: 'red',
    },
    {
      title: 'portfolio.heroStats.inventoryNodeCount',
      val: portfolioStats?.inventoryNodeCount || 0,
      icon: <Icons.PodIoTIcon width={29} height={29} />,
      iconColor: 'blue',
    },
    {
      title: 'portfolio.heroStats.unassignedNodes',
      val: portfolioStats?.inventoryNodesUnassigned || 0,
      icon: <Icons.PodIoTIcon width={29} height={29} />,
      iconColor: 'gray',
    },
  ];

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Portfolio,
      },
    });
  }, []);

  const getOlderUpdatedAt = (dateA: String, dateB: String) => {
    if (!dateA) {
      return dateB;
    } else if (!dateB) {
      return dateA;
    } else if (dateA < dateB) {
      return dateA;
    } else {
      return dateB;
    }
  };

  const printDataWarning = (
    loading: boolean,
    updatedAtA: String,
    updatedAtB: String,
  ) => {
    if (!loading) {
      const olderUpdatedAt = getOlderUpdatedAt(updatedAtA, updatedAtB);
      if (olderUpdatedAt) {
        const olderDate = new Date(olderUpdatedAt.toString()).getTime();
        const now = new Date().getTime();
        return t('updateDataWarning', {
          minsAgo: Math.floor((now - olderDate) / (1000 * 60)),
        });
      }
    }
    return t('defaultUpdateDataWarning');
  };

  return (
    <>
      <Helmet>
        <title>{t('portfolio.title')}</title>
      </Helmet>
      <div className="PortfolioContainer p-xl">
        <AuraGlow header footer />
        <Grid>
          <GridItem colSpan="12">
            <PageHeading title="portfolio.title" tooltip="portfolio.tooltip" />
          </GridItem>
          <GridItem colSpan="12">
            <div className="PortfolioContainer__toggler-warning">
              <Toggler
                classes={(current: TogglerStyles) => ({
                  ...current,
                  root: `${current.root} PortfolioContainer__toggler`,
                })}
                toggleElements={[
                  {
                    key: PortfolioType.Map,
                    label: (
                      <div className="PortfolioContainer__toggler--label">
                        <Icons.MapIcon width={24} height={24} />
                        <FormattedMessage id="portfolio.toggle.map" />
                      </div>
                    ),
                  },
                  {
                    key: PortfolioType.Editor,
                    label: (
                      <div className="PortfolioContainer__toggler--label">
                        <Icons.MapPinIcon width={16.5} height={16.5} />
                        <FormattedMessage id="portfolio.toggle.editor" />
                      </div>
                    ),
                  },
                ]}
                value={portfolioType}
                variant="large"
                onToggle={(selection) =>
                  setPortfolioType(
                    selection.key === PortfolioType.Map
                      ? PortfolioType.Map
                      : PortfolioType.Editor,
                  )
                }
              />
              {portfolioType === PortfolioType.Map && (
                <div className="PortfolioContainer__warning">
                  <Icons.ClockHistoryIcon width={16} />
                  <div>
                    {printDataWarning(
                      statsLoading,
                      portfolioStats?.alertsUpdatedAt || '',
                      portfolioStats?.nodesUpdatedAt || '',
                    )}
                  </div>
                </div>
              )}
            </div>
          </GridItem>
        </Grid>
        {portfolioType === PortfolioType.Map && (
          <MapViewContainer
            statsLoading={statsLoading}
            statsError={statsError}
            heroData={heroData}
          />
        )}
        {portfolioType === PortfolioType.Editor && <EditorViewContainer />}
      </div>
    </>
  );
};

export default memo(PortfolioContainer);
