import {
  BusinessTenant,
  ResidentialTenant,
  Tenant,
  TenantTypes,
} from './types';

export const mapToTenant = (
  tenant: BusinessTenant | ResidentialTenant,
  type: TenantTypes,
): Tenant => {
  const mappedTenant: Partial<Tenant> = { ...tenant };
  mappedTenant.type = type;
  if (type === TenantTypes.Business) {
    mappedTenant.unit = (tenant as BusinessTenant).businessUnit;
  } else {
    mappedTenant.unit = (tenant as ResidentialTenant).residentialUnit;
  }
  return mappedTenant as Tenant;
};
