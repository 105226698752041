import _ from 'lodash';
import { atom, selector } from 'recoil';
import { ModalState, ModalType } from '../../types';
import {
  IotDevice,
  IotDeviceConnectionState,
  IotModalState,
  IotNetwork,
} from './types';

export const iotNetworkAtom = atom<
  (IotNetwork & { propertyNetworkId: string }) | null
>({
  key: 'iotNetworkAtom',
  default: null,
});

export const hasIotNetworkSelector = selector<boolean>({
  key: 'hasIotNetworkSelector',
  get: ({ get }) => {
    const iotNetwork = get(iotNetworkAtom);
    if (_.isObject(iotNetwork) && iotNetwork.enabled) return true;
    return false;
  },
});

export const iotModalStateAtom = atom<IotModalState>({
  key: 'iotModalStateAtom',
  default: {
    state: ModalState.Dismissed,
    type: ModalType.Create,
  },
});

export const iotDevicesAtom = atom<IotDevice[]>({
  key: 'iotDevicesAtom',
  default: [],
});

export const iotDevicesSelectedFilterAtom = atom<string>({
  key: 'iotDevicesSelectedFilterAtom',
  default: '',
});

export const iotDevicesSelectedPageAtom = atom<number>({
  key: 'iotDevicesSelectedPageAtom',
  default: 0,
});

export const filteredIotDevices = selector<IotDevice[]>({
  key: 'filteredIotDevices',
  get: ({ get }) => {
    let iotDevices = get(iotDevicesAtom);
    const filter = get(iotDevicesSelectedFilterAtom);
    if (filter) {
      iotDevices = iotDevices.filter((iotDevice) =>
        iotDevice.deviceType.iconV2
          .toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase()),
      );
    }
    return iotDevices;
  },
});

const sortedIotDevices = selector<IotDevice[]>({
  key: 'sortedIotDevices',
  get: ({ get }) => {
    let iotDevices = get(filteredIotDevices);
    iotDevices = iotDevices.toSorted((deviceA, deviceB) => {
      if (deviceA.quarantined && deviceB.quarantined) return 0;
      if (deviceA.quarantined && !deviceB.quarantined) return -1;
      if (!deviceA.quarantined && deviceB.quarantined) return 1;
      if (deviceA.connectionState === deviceB.connectionState) return 0;
      if (
        deviceA.connectionState === IotDeviceConnectionState.Connected &&
        deviceB.connectionState === IotDeviceConnectionState.Disconnected
      ) {
        return -1;
      }
      return 1;
    });
    return iotDevices;
  },
});

export const currentIotDevicesPage = selector<IotDevice[]>({
  key: 'currentIotDevicesPage',
  get: ({ get }) => {
    let iotDevices = get(sortedIotDevices);
    const selectedPage = get(iotDevicesSelectedPageAtom);
    return iotDevices.slice(selectedPage * 12, (selectedPage + 1) * 12);
  },
});

export const filteredIotDevicesCount = selector<number>({
  key: 'filteredIotDevicesCount',
  get: ({ get }) => {
    return get(filteredIotDevices).length;
  },
});
