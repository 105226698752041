import { MixPanelEvents } from '../../mixPanelEvents';

export type TrackingUser = {
  id?: string;
  emailAddress?: string;
  role?: string;
};

export enum AvailableScreens {
  Portfolio = 'Portfolio',
  CommunityWifi = 'CommunityWifi',
  PropertyEditor = 'PropertyEditor',
  Users = 'Users',
  Overview = 'Overview',
  Units = 'Units',
  Tenants = 'Tenants',
  Login = 'Login',
  Inventory = 'Inventory',
  PropertyNetworks = 'PropertyNetworks',
}
type GenericTrackEventParams = {
  eventName: MixPanelEvents.LOGOUT | MixPanelEvents.AUTHENTICATION_SUCCESS;
  additionalContent?: Record<string, any>;
};

export type TrackEventParams =
  | GenericTrackEventParams
  | {
      eventName: MixPanelEvents.SCREEN;
      additionalContent: {
        SCREEN: AvailableScreens;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_TENANT_SUCCESS;
      additionalContent: {
        unitId: string;
        propertyId: string;
        tenantId: string;
        desiredStatus: string;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_TENANT_FAILURE;
      additionalContent: {
        unitId: string;
        propertyId: string;
        desiredStatus: string;
      };
    }
  | {
      eventName:
        | MixPanelEvents.UPDATE_TENANT_SUCCESS
        | MixPanelEvents.UPDATE_TENANT_FAILURE;
      additionalContent: {
        unitId: string | null;
        propertyId: string;
        tenantId: string;
      };
    }
  | {
      eventName:
        | MixPanelEvents.RELOCATE_TENANT_SUCCESS
        | MixPanelEvents.RELOCATE_TENANT_FAILURE;
      additionalContent: {
        propertyId: string;
      };
    }
  | {
      eventName:
        | MixPanelEvents.DELETE_TENANT_FAILURE
        | MixPanelEvents.DELETE_TENANT_SUCCESS;
      additionalContent: {
        unitId: string;
        propertyId: string;
        tenantId: string;
      };
    }
  | {
      eventName:
        | MixPanelEvents.UN_ASSIGN_INVENTORY_NODE_SUCCESS
        | MixPanelEvents.UN_ASSIGN_INVENTORY_NODE_FAILURE;
      additionalContent: {
        nodeId: string;
        propertyId: string;
      };
    }
  | {
      eventName:
        | MixPanelEvents.DELETE_INVENTORY_NODE_SUCCESS
        | MixPanelEvents.DELETE_INVENTORY_NODE_FAILURE;
      additionalContent: {
        nodeId: string;
        propertyId: string;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_INVENTORY_NODE_SUCCESS;
      additionalContent: {
        nodeId: string;
        propertyId: string;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_INVENTORY_NODE_FAILURE;
      additionalContent: {
        nodeId: string;
        propertyId: string;
      };
    }
  | {
      eventName:
        | MixPanelEvents.ASSIGN_INVENTORY_NODE_TO_UNIT_FAILURE
        | MixPanelEvents.ASSIGN_INVENTORY_NODE_TO_UNIT_SUCCESS;
      additionalContent: {
        nodeId: string;
        unitId: string;
        propertyId: string;
      };
    }
  | {
      eventName:
        | MixPanelEvents.EDIT_PROPERTY_NETWORK_SUCCESS
        | MixPanelEvents.EDIT_PROPERTY_NETWORK_FAILURE;
      additionalContent: {
        propertyNetworkId: string;
        propertyId: string;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_PROPERTY_NETWORK_SUCCESS;
      additionalContent: {
        propertyNetworkId: string;
        propertyId: string;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_PROPERTY_NETWORK_FAILURE;
      additionalContent: {
        propertyId: string;
      };
    }
  | {
      eventName:
        | MixPanelEvents.DELETE_PROPERTY_NETWORK_SUCCESS
        | MixPanelEvents.DELETE_PROPERTY_NETWORK_FAILURE;
      additionalContent: {
        propertyNetworkId: string;
        propertyId: string;
      };
    }
  | {
      eventName:
        | MixPanelEvents.ADD_MDU_SUCCESS
        | MixPanelEvents.ADD_MDU_FAILURE;
      additionalContent: {
        address: string;
      };
    }
  | {
      eventName:
        | MixPanelEvents.DELETE_MDU_SUCCESS
        | MixPanelEvents.DELETE_MDU_FAILURE
        | MixPanelEvents.DELETE_UNIT_SUCCESS
        | MixPanelEvents.DELETE_UNIT_FAILURE;
      additionalContent: {
        ID: string;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_UNIT_SUCCESS;
      additionalContent: {
        id: string;
        propertyId: string;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_UNIT_FAILURE;
      additionalContent: {
        propertyId: string;
      };
    }
  | {
      eventName:
        | MixPanelEvents.UPDATE_UNIT_SUCCESS
        | MixPanelEvents.UPDATE_UNIT_FAILURE;
      additionalContent: {
        unitId: string;
        propertyId: string;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_USER_SUCCESS;
      additionalContent: {
        email: string;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_USER_FAILURE;
      additionalContent: {
        email: string;
      };
    }
  | {
      eventName: MixPanelEvents.EDIT_USER_SUCCESS;
      additionalContent: {
        email: string;
      };
    }
  | {
      eventName: MixPanelEvents.EDIT_USER_FAILURE;
      additionalContent: {
        email: string;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_IOT_NETWORK;
      additionalContent: {
        propertyNetworkId: string;
        propertyId: string;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_IOT_NETWORK_FAILURE;
      additionalContent: {
        propertyNetworkId: string;
        propertyId: string;
      };
    }
  | {
      eventName: MixPanelEvents.DELETE_IOT_NETWORK;
      additionalContent: {
        propertyNetworkId: string;
        propertyId: string;
      };
    }
  | {
      eventName: MixPanelEvents.DELETE_IOT_NETWORK_FAILURE;
      additionalContent: {
        propertyNetworkId: string;
        propertyId: string;
      };
    };
