import { useFetchData } from '../../../hooks/useFetchData';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DependencyContainer from '../../../DependencyContainer';
import { BusinessTenant, ResidentialTenant } from '../types';
import {
  businessTenantsAtom,
  businessTenantsSelectedPageAtom,
  residentialTenantsAtom,
  residentialTenantsSelectedPageAtom,
  tenantsLoadingStatusAtom,
} from '../tenantsState';
import { selectedPartnerSelector } from '../../../state';
import { useEffect } from 'react';
import { selectedMduIdAtom } from '../../editorView/propertyState';

type UseTenantsValues = {
  loading: boolean;
  error: any;
  runFetch: () => Promise<void>;
};

const { tenantService } = new DependencyContainer();

export const useTenants = (runOnInit = true): UseTenantsValues => {
  const selectedMduId = useRecoilValue(selectedMduIdAtom);
  const setTenantsLoadingStatus = useSetRecoilState(tenantsLoadingStatusAtom);
  const setBusinessTenants = useSetRecoilState(businessTenantsAtom);
  const setResidentialTenants = useSetRecoilState(residentialTenantsAtom);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const setBusinessTenantsSelectedPage = useSetRecoilState(
    businessTenantsSelectedPageAtom,
  );
  const setResidentialTenantsSelectedPage = useSetRecoilState(
    residentialTenantsSelectedPageAtom,
  );

  const [loading, , error, runFetch] = useFetchData<
    {
      residentialTenants: ResidentialTenant[];
      businessTenants: BusinessTenant[];
    },
    any
  >(
    async () => {
      if (selectedMduId && selectedPartner) {
        return await tenantService.getAllTenants(
          selectedMduId,
          selectedPartner.id,
        );
      }
    },
    ({ residentialTenants, businessTenants }) => {
      setBusinessTenants(businessTenants);
      setResidentialTenants(residentialTenants);
      setTenantsLoadingStatus('loaded');
    },
    [selectedMduId, selectedPartner],
    runOnInit,
  );

  useEffect(() => {
    setBusinessTenantsSelectedPage(0);
    setResidentialTenantsSelectedPage(0);
  }, [selectedMduId]);

  useEffect(() => {
    if (loading) {
      setTenantsLoadingStatus('loading');
    }
    if (error) {
      setTenantsLoadingStatus('error');
    }
  }, [loading, error]);

  return {
    loading,
    error,
    runFetch,
  };
};
