import { FieldInputProps, FormikProps } from 'formik';
import { Checkbox } from 'plume-ui';
import React, { memo } from 'react';
import { AdjustableComponent } from '../../utils/hooks/useClasses';

type FormikInputToggleProps = {
  field: FieldInputProps<boolean>;
  form: FormikProps<boolean>;
  label: string;
} & Omit<JSX.IntrinsicElements['input'], 'value' | 'type'>;

export type FormikInputCheckboxStyles = {
  root: string;
};

const FormikInputCheckbox: AdjustableComponent<
  FormikInputToggleProps,
  FormikInputCheckboxStyles
> = ({ label, field, form, classes, ...props }) => {
  const isChecked = Boolean(field.value);

  const onChange = () => {
    form.setFieldValue(field.name, !isChecked);
  };

  return (
    <Checkbox
      label={label}
      name={field.name}
      checked={isChecked}
      {...props}
      onChange={() => onChange()}
    />
  );
};

export default memo(FormikInputCheckbox);
