type CachedItemWithTTL<T> = {
  value: T;
  ttl: number;
};

export function getCachedItemWithTTL<T>(key: string) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item: CachedItemWithTTL<T> = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.ttl) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function setCachedItemWithTTL<T>(
  key: string,
  value: T,
  ttlInSeconds: number,
) {
  const now = new Date();
  const ttl = now.getTime() + ttlInSeconds * 1000;

  const item: CachedItemWithTTL<T> = {
    value,
    ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}
