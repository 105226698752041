import { UserClaims } from '@okta/okta-auth-js';
import { atom, selector } from 'recoil';
import { selectedMduIdAtom } from './features/editorView/propertyState';
import { UnitModalAtom } from './features/units/components/UnitModal/types';
import { GenericUnit, UnitDetails } from './features/units/types';
import {
  ConfirmationModalAtom,
  MDU,
  ModalState,
  ModalType,
  Partner,
  UserInfo,
} from './types';
import { isAtLeastVersion } from './utils/isAtLeastVersion';

export const userInfoAtom = atom<UserInfo | undefined>({
  key: 'userInfoAtom',
  default: undefined,
});

export const activeAccountAtom = atom<UserClaims | undefined>({
  key: 'activeAccountAtom',
  default: undefined,
});

export const authErrorAtom = atom<string | undefined>({
  key: 'authErrorAtom',
  default: undefined,
});

export const anonymizedAtom = atom<boolean>({
  key: 'anonymizedAtom',
  default: false,
});

export const multiDwellingUnitsAtom = atom<MDU[]>({
  key: 'multiDwellingUnitsAtom',
  default: [],
});

export const selectedMDUSelector = selector<MDU | undefined>({
  key: 'teamAccountsSelector',
  get: ({ get }) => {
    const allAvailableMDUs = get(multiDwellingUnitsAtom);
    const selectedMDUId = get(selectedMduIdAtom);
    if (!selectedMDUId || !allAvailableMDUs?.length) {
      return undefined;
    }
    return allAvailableMDUs.find((m: MDU) => m.id === selectedMDUId);
  },
});

export const unitModalStateAtom = atom<UnitModalAtom>({
  key: 'unitModalStateAtom',
  default: {
    state: ModalState.Dismissed,
    type: ModalType.Create,
  },
});

export const createUserModalStateAtom = atom<ModalState>({
  key: 'createUserModalStateAtom',
  default: ModalState.Dismissed,
});

export const nodesModalSelectedUnitAtom = atom<{
  unit: GenericUnit;
  details: UnitDetails;
} | null>({
  key: 'nodesModalSelectedUnitAtom',
  default: null,
});

export const createTenantModalStateAtom = atom<ModalState>({
  key: 'createTenantModalStateAtom',
  default: ModalState.Dismissed,
});

export const relocateTenantModalStateAtom = atom<ModalState>({
  key: 'relocateTenantModalStateAtom',
  default: ModalState.Dismissed,
});

export const confirmationModalAtom = atom<ConfirmationModalAtom>({
  key: 'confirmationModalAtom',
  default: {
    isOpen: false,
  },
});

export const partnersAtom = atom<Partner[]>({
  key: 'partnersAtom',
  default: [],
});

export const selectedPartnerIdAtom = atom<string | null>({
  key: 'selectedPartnerIdAtom',
  default: null,
});

export const selectedPartnerSelector = selector<Partner | undefined>({
  key: 'selectedPartnerSelector',
  get: ({ get }) => {
    const partnerId = get(selectedPartnerIdAtom);
    const partners = get(partnersAtom);
    return partners.find((partner) => partner.id === partnerId);
  },
});

export const apiVersionAtom = atom<string | null>({
  key: 'apiVersionAtom',
  default: null,
});

export const isNewStatsAPIsEnabled = selector<boolean>({
  key: 'isNewStatsAPIsEnabled',
  get: ({ get }) => {
    const apiVersion = get(apiVersionAtom);
    if (!apiVersion) {
      return false;
    }
    return isAtLeastVersion('1.96.0', apiVersion);
  },
});
