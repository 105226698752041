import { Grid, GridItem, Pagination, PendingContent, Spinner } from 'plume-ui';
import React, {
  ChangeEvent,
  FunctionComponent,
  memo,
  useEffect,
  useLayoutEffect,
} from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import AuraGlow from '../../../components/AuraGlow/AuraGlow';
import PageHeading from '../../../components/PageHeading/PageHeading';
import StickyActionTable from '../../../components/StickyActionTable/StickyActionTable';
import TableActionButton from '../../../components/TableActionButton/TableActionButton';
import TableFilterRow from '../../../components/TableFilter/TableFilterRow';
import { DEFAULT_PAGE_SIZE } from '../../../config';
import useRenderAnonymized from '../../../hooks/useRenderAnonymized';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { selectedMDUSelector, userInfoAtom } from '../../../state';
import { ModalState, Permission, Role } from '../../../types';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { AvailableScreens } from '../../trackingAnalytics/types';
import AssignNodeModal from '../components/AssignNodeModal';
import CreateNodeModal from '../components/CreateNodeModal';
import useInventoryActions from '../hooks/useInventoryActions';
import useInventoryMappings from '../hooks/useInventoryMappings';
import {
  addNodeModalStateAtom,
  assignNodeModalStateAtom,
  currentNodesPage,
  filteredNodesCount,
  inventorySelectedFilterAtom,
  inventorySelectedPageAtom,
  nodesLoadingStatusAtom,
  nodesSortAtom,
} from '../inventoryState';
import { InventoryNode } from '../types';
import { renderAssignedName } from '../utils';

const InventoryContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const { renderAnonymized } = useRenderAnonymized();
  const userInfo = useRecoilValue(userInfoAtom);
  const { renderNodeSerialNumber, renderNodeStatus } = useInventoryMappings();

  const [selectedPage, setSelectedPage] = useRecoilState(
    inventorySelectedPageAtom,
  );
  const totalFilteredItems = useRecoilValue(filteredNodesCount);
  const loadingStatus = useRecoilValue(nodesLoadingStatusAtom);
  const setNodeSort = useSetRecoilState(nodesSortAtom);
  const nodes = useRecoilValue(currentNodesPage);
  const [selectedFilter, setSelectedFilter] = useRecoilState(
    inventorySelectedFilterAtom,
  );
  const trackEvent = useTrackEvent();
  const [addNodeModalState, setAddNodeModalState] = useRecoilState(
    addNodeModalStateAtom,
  );
  const [assignNodeModalState, setAssignNodeModalState] = useRecoilState(
    assignNodeModalStateAtom,
  );
  const getInventoryActions = useInventoryActions();
  const selectedProperty = useRecoilValue(selectedMDUSelector);

  useLayoutEffect(() => {
    setSelectedFilter('');
  }, []);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Inventory,
      },
    });
  }, []);

  useEffect(() => {
    if (!nodes.length && selectedPage > 0) {
      setSelectedPage(selectedPage - 1);
    }
  }, [nodes]);

  const tableHeaderRow = [
    {
      name: t('inventory.table.nodeId'),
      fieldName: 'id',
      sortable: true,
      render: (node: InventoryNode) =>
        renderNodeSerialNumber(
          renderAnonymized(node.id),
          node.stateMeta.realized,
        ),
    },
    {
      name: t('inventory.table.assignedTo'),
      fieldName: 'assignedTo',
      sortable: true,
      render: (node: InventoryNode) =>
        renderAssignedName(node, renderAnonymized),
    },
    {
      name: t('inventory.table.status'),
      fieldName: 'status',
      sortable: true,
      render: (node: InventoryNode) => renderNodeStatus(node.status),
    },
    {
      name: t('inventory.table.dateAdded'),
      fieldName: 'createdAt',
      sortable: true,
      render: (node: InventoryNode) =>
        new Date(node.createdAt).toLocaleDateString(),
    },
    {
      sortable: false,
      fieldName: 'actions',
      render: (node: InventoryNode) => {
        const nodeActions = getInventoryActions(node);
        if (nodeActions.length === 0) {
          return null;
        }
        return (
          <TableActionButton
            actions={nodeActions}
            item={node}
          ></TableActionButton>
        );
      },
    },
  ];

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value === undefined ? '' : e.target.value;
    setSelectedFilter(value);
    setSelectedPage(0);
  };

  const hasPermission = (permission: Permission) => {
    return userInfo?.permissions.includes(permission);
  };

  return (
    <>
      <Helmet>
        <title>{t('inventory.title')}</title>
      </Helmet>
      <div className="InventoryContainer p-xl">
        <AuraGlow header footer />
        <Grid>
          <GridItem colSpan="12" tabletColSpan="6">
            <PageHeading
              title="inventory.title"
              tooltip="inventory.tooltip"
              additionalTitle={selectedProperty?.name}
            />
          </GridItem>
          <GridItem colSpan="12" tabletColSpan="6">
            <TableFilterRow
              placeholder="inventory.search"
              value={selectedFilter}
              onSearch={handleSearch}
              resultCount={totalFilteredItems}
              actionButton={{
                onClick: () => setAddNodeModalState(ModalState.Open),
                label: 'inventory.actions.add.buttonLabel',
                isDisabled:
                  userInfo?.role !== Role.Support &&
                  !hasPermission(Permission.createNode),
              }}
            />
          </GridItem>
          <GridItem colSpan="12" tabletColSpan="6">
            <PendingContent
              loading={loadingStatus === 'loading'}
              loader={Spinner}
              isError={loadingStatus === 'error'}
              hideContent
            >
              <StickyActionTable
                headerRow={tableHeaderRow as any}
                dataRows={[...nodes] as any}
                truncateCellContent={false}
                externalSort
                onSortChange={setNodeSort}
                noResultsMessage={t('inventory.table.noResults')}
              />
              {Math.ceil(totalFilteredItems / DEFAULT_PAGE_SIZE) > 0 && (
                <Pagination
                  classes={(current) => ({
                    ...current,
                    root: `${current.root} InventoryContainer__pagination`,
                  })}
                  expandDirection={'top'}
                  totalPageCount={
                    Math.ceil(totalFilteredItems / DEFAULT_PAGE_SIZE) || 1
                  }
                  onPageSelect={setSelectedPage}
                  currentPage={selectedPage}
                />
              )}
            </PendingContent>
          </GridItem>
        </Grid>
        <CreateNodeModal
          isOpen={addNodeModalState === ModalState.Open}
          onRequestClose={(success) =>
            setAddNodeModalState(
              success ? ModalState.Success : ModalState.Dismissed,
            )
          }
        />
        <AssignNodeModal
          isOpen={assignNodeModalState === ModalState.Open}
          onRequestClose={(success) =>
            setAssignNodeModalState(
              success ? ModalState.Success : ModalState.Dismissed,
            )
          }
        />
      </div>
    </>
  );
};

export default memo(InventoryContainer);
