import { Status, Toggler } from 'plume-ui';
import { memo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import AuraGlow from '../../../components/AuraGlow/AuraGlow';
import PageHeading from '../../../components/PageHeading/PageHeading';
import StickyActionTable from '../../../components/StickyActionTable/StickyActionTable';
import TableActionButton from '../../../components/TableActionButton/TableActionButton';
import TableFilterRow from '../../../components/TableFilter/TableFilterRow';
import { Routes } from '../../../config';
import { selectedMDUSelector, selectedPartnerIdAtom } from '../../../state';
import { useRedirectToRoute } from '../../../utils/hooks/useRedirectToRoute';
import { selectedTicketAtom } from '../../ticket/state';
import { MaintenanceTicket, MaintenanceTicketStatus } from '../types';
import { isDevelopment, isLocal } from '../../../utils/environment';

const MaintenanceContainer = () => {
  const { t } = useTranslation();
  const redirectToRoute = useRedirectToRoute();

  const selectedPartnerId = useRecoilValue(selectedPartnerIdAtom);
  const selectedProperty = useRecoilValue(selectedMDUSelector);
  const setSelectedTicket = useSetRecoilState(selectedTicketAtom);

  const [maintenanceTicketsFilter, setMaintenanceTicketsFilter] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(
    MaintenanceTicketStatus.Active,
  );

  const tableHeaderRow = [
    {
      name: t('maintenance.table.title'),
      fieldName: 'name',
    },
    {
      name: t('maintenance.table.unit'),
      fieldName: 'unit',
    },
    {
      name: t('maintenance.table.createdDate'),
      fieldName: 'createdDate',
    },
    {
      name: t('maintenance.table.scheduledDate'),
      fieldName: 'scheduledDate',
    },
    {
      name: t('maintenance.table.priority'),
      fieldName: 'priority',
      render: (maintenanceTicket: MaintenanceTicket) => {
        return <Status label={maintenanceTicket.urgency} />;
      },
    },
    {
      fieldName: 'actions',
      render: (maintenanceTicket: MaintenanceTicket) => {
        return (
          <TableActionButton
            actions={[]}
            item={maintenanceTicket}
          ></TableActionButton>
        );
      },
    },
  ];

  if (!isLocal() && !isDevelopment()) {
    redirectToRoute(Routes.Index);
  }

  return (
    <>
      <Helmet>
        <title>{t('maintenance.title')}</title>
      </Helmet>
      <div className="MaintenanceContainer p-xl">
        <AuraGlow header footer />
        <PageHeading
          title="maintenance.title"
          tooltip="maintenance.tooltip"
          additionalTitle={selectedProperty?.name}
        />
        <div className="MaintenanceContainer__content">
          <TableFilterRow
            onSearch={(e) => setMaintenanceTicketsFilter(e.currentTarget.value)}
            placeholder={t('maintenance.search')}
            value={maintenanceTicketsFilter}
            actionButton={{
              onClick: () => {
                setSelectedTicket(null);
                redirectToRoute(Routes.Ticket, {
                  propertyId: selectedProperty?.id,
                  partnerId: selectedPartnerId,
                });
              },
              label: t('maintenance.actions.create.buttonLabel'),
            }}
          />
          <Toggler
            toggleElements={[
              {
                key: MaintenanceTicketStatus.Active,
                label: t('maintenance.status.active', {
                  count: 0,
                }),
              },
              {
                key: MaintenanceTicketStatus.Archived,
                label: t('maintenance.status.archived', {
                  count: 0,
                }),
              },
            ]}
            value={selectedStatus || ''}
            variant="large"
            onToggle={(selection) =>
              setSelectedStatus(selection.key as MaintenanceTicketStatus)
            }
          />
          <StickyActionTable
            headerRow={tableHeaderRow as any}
            dataRows={[]}
            noResultsMessage={t('maintenance.table.noResults')}
          />
        </div>
      </div>
    </>
  );
};

export default memo(MaintenanceContainer);
