import { atom } from 'recoil';
import { Document } from './types';

export const documentsAtom = atom<Document[]>({
  key: 'documentsAtom',
  default: [
    {
      name: 'Mock document',
      createdAt: '10/10/2002',
    },
  ],
});
