import {
  Chip,
  Grid,
  GridItem,
  Pagination,
  PendingContent,
  Spinner,
} from 'plume-ui';
import React, { ChangeEvent, FunctionComponent, memo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import AuraGlow from '../../../components/AuraGlow/AuraGlow';
import PageHeading from '../../../components/PageHeading/PageHeading';
import StickyActionTable from '../../../components/StickyActionTable/StickyActionTable';
import { conditionallyTruncate } from '../../../components/StickyActionTable/util';
import TableActionButton, {
  TableAction,
} from '../../../components/TableActionButton/TableActionButton';
import TableFilterRow from '../../../components/TableFilter/TableFilterRow';
import { DEFAULT_PAGE_SIZE } from '../../../config';
import useRenderAnonymized from '../../../hooks/useRenderAnonymized';
import { MixPanelEvents } from '../../../mixPanelEvents';
import {
  apiVersionAtom,
  createUserModalStateAtom,
  userInfoAtom,
} from '../../../state';
import { ModalState, Permission, Role } from '../../../types';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { AvailableScreens } from '../../trackingAnalytics/types';
import useUserActions from '../hooks/useUserActions';
import { User } from '../types';
import {
  currentUsersPage,
  filteredUsersCount,
  usersLoadingStatusAtom,
  usersSelectedFilterAtom,
  usersSelectedPageAtom,
  usersSortAtom,
} from '../usersState';
import { renderUserName } from '../utils';

const UsersContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const { renderAnonymized, renderAnonymizedEmail } = useRenderAnonymized();
  const [selectedPage, setSelectedPage] = useRecoilState(usersSelectedPageAtom);
  const userInfo = useRecoilValue(userInfoAtom);
  const totalFilteredItems = useRecoilValue(filteredUsersCount);
  const apiVersion = useRecoilValue(apiVersionAtom);
  const loadingStatus = useRecoilValue(usersLoadingStatusAtom);
  const users = useRecoilValue(currentUsersPage);
  const setUserSort = useSetRecoilState(usersSortAtom);
  const trackEvent = useTrackEvent();
  const setCreateUserModalState = useSetRecoilState(createUserModalStateAtom);
  const getUserActions = useUserActions();
  const [selectedFilter, setSelectedFilter] = useRecoilState(
    usersSelectedFilterAtom,
  );

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Users,
      },
    });
    return () => {
      setSelectedFilter('');
    };
  }, []);

  useEffect(() => {
    if (!users.length && selectedPage > 0) {
      setSelectedPage(selectedPage - 1);
    }
  }, [users, selectedPage]);

  const tableHeaderRow: Record<string, unknown>[] = [
    {
      name: t('users.table.name'),
      fieldName: 'name',
      sortable: true,
      render: (user: User) =>
        conditionallyTruncate(renderAnonymized(renderUserName(user))),
    },
    {
      name: t('users.table.email'),
      fieldName: 'email',
      render: (user: User) => renderAnonymizedEmail(user.email),
    },
    {
      name: t('users.table.properties'),
      fieldName: 'properties',
      render: (user: User) => renderUserProperties(user),
    },
    {
      name: t('users.table.dateAdded'),
      fieldName: 'createdAt',
      sortable: true,
      render: (user: User) =>
        user.createdAt ? new Date(user.createdAt).toLocaleDateString() : '',
    },
    {
      fieldName: 'actions',
      render: (user: User) => {
        const userActions = getUserActions(user);
        if (userActions.length === 0) {
          return null;
        }
        return (
          <TableActionButton
            actions={userActions as TableAction<unknown>[]}
            item={user}
          ></TableActionButton>
        );
      },
    },
  ];

  const renderUserProperties = (user: User) => {
    if (user.properties.length) {
      return (
        <div className="UsersContainer__Properties">
          <Chip type="mini" color="freeze">
            {user.properties.length &&
              renderAnonymized(user.properties[0].name)}
          </Chip>
          {user.properties.length > 1 ? (
            <span>+ {user.properties.length - 1} more</span>
          ) : null}
        </div>
      );
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value === undefined ? '' : e.target.value;
    setSelectedFilter(value);
    setSelectedPage(0);
  };

  if (!apiVersion) {
    return null;
  }

  const hasPermission = (permission: Permission) => {
    return userInfo?.permissions.includes(permission);
  };

  return (
    <>
      <Helmet>
        <title>{t('users.title')}</title>
      </Helmet>
      <div className="UsersContainer p-xl">
        <AuraGlow header footer />
        <Grid>
          <GridItem colSpan="12" tabletColSpan="6">
            <PageHeading title="users.title" tooltip="users.tooltip" />
          </GridItem>
          <GridItem colSpan="12" tabletColSpan="6">
            <TableFilterRow
              placeholder="users.search"
              value={selectedFilter}
              onSearch={handleSearch}
              resultCount={totalFilteredItems}
              actionButton={{
                label: 'users.actions.create.buttonLabel',
                onClick: () => setCreateUserModalState(ModalState.Open),
                isDisabled:
                  userInfo?.role !== Role.Support &&
                  !hasPermission(Permission.createUser),
              }}
            />
          </GridItem>
          <GridItem colSpan="12" tabletColSpan="6">
            <PendingContent
              loading={loadingStatus === 'loading'}
              loader={Spinner}
              isError={loadingStatus === 'error'}
              hideContent
            >
              <StickyActionTable
                headerRow={tableHeaderRow}
                dataRows={[...users] as Record<string, any>[]}
                truncateCellContent={false}
                externalSort
                onSortChange={setUserSort}
                noResultsMessage={t('users.table.noResults')}
              />
              {Math.ceil(totalFilteredItems / DEFAULT_PAGE_SIZE) > 0 && (
                <Pagination
                  classes={(current) => ({
                    ...current,
                    root: `${current.root} UsersContainer__pagination`,
                  })}
                  expandDirection={'top'}
                  totalPageCount={
                    Math.ceil(totalFilteredItems / DEFAULT_PAGE_SIZE) || 1
                  }
                  onPageSelect={setSelectedPage}
                  currentPage={selectedPage}
                />
              )}
            </PendingContent>
          </GridItem>
        </Grid>
      </div>
    </>
  );
};

export default memo(UsersContainer);
