import DependencyContainer from '../../../DependencyContainer';
import { AuthError, User } from '../types';
import { Routes } from '../../../config';
import { UserInfo, Partner } from '../../../types';

export default class LoginService {
  constructor(private readonly factory: DependencyContainer) {}
  private readonly localStorageAuthErrorPropName = 'authError';
  private readonly localStorageTargetRoutePropName = 'targetRoute';
  private readonly localStorageOktaToken = 'okta-token-storage';

  getAuthError(): AuthError | undefined {
    const authErrorLS = localStorage.getItem(
      this.localStorageAuthErrorPropName,
    );
    if (!authErrorLS) {
      return undefined;
    }
    const authError = JSON.parse(authErrorLS);
    return authError;
  }

  setAuthError(error: AuthError): void {
    localStorage.setItem(
      this.localStorageAuthErrorPropName,
      JSON.stringify(error),
    );
  }

  removeAuthError(): void {
    localStorage.removeItem(this.localStorageAuthErrorPropName);
  }

  setTargetRoute(targetRoute: Routes): void {
    localStorage.setItem(this.localStorageTargetRoutePropName, targetRoute);
  }

  getTargetRoute(): Routes {
    const targetRoute = localStorage.getItem(
      this.localStorageTargetRoutePropName,
    );
    return (targetRoute as Routes) || Routes.Index;
  }

  getUser(): User {
    const json = localStorage.getItem(this.localStorageOktaToken) || '{}';
    const oktaToken = JSON.parse(json);
    return {
      id: oktaToken.idToken?.claims?.cid,
      email: oktaToken.idToken?.claims?.email,
      firstName: oktaToken.idToken?.claims?.firstName,
      lastName: oktaToken.idToken?.claims?.lastName,
      idToken: oktaToken.idToken?.idToken,
      expiresAt: oktaToken.idToken?.claims?.expiresAt,
    };
  }

  removeTargetRoute(): void {
    localStorage.removeItem(this.localStorageTargetRoutePropName);
  }

  async getUserInfo(): Promise<UserInfo> {
    return await this.factory.loginClient.fetchUserInfo();
  }

  async getPartners(): Promise<Partner[]> {
    return await this.factory.loginClient.fetchPartners();
  }
}
