import { Field, Form, Formik, FormikValues } from 'formik';
import {
  Button,
  DropdownFilterType,
  FilterDropdownGroup,
  FilterDropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  Slider,
  Space,
  Switch,
  notify,
} from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import { DropdownFilter } from 'plume-ui/src/components/FilterDropdownGroup/FilterDropdownGroup';
import React, { FunctionComponent, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as yup from 'yup';
import { ref } from 'yup';
import DependencyContainer from '../../../DependencyContainer';
import { DropdownInputOption } from '../../../components/DropdownInput/DropdownInput';
import InputField from '../../../components/InputField/InputField';
import ModalHeaderWithProperty from '../../../components/ModalHeaderWithProperty/ModalHeaderWithProperty';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import { MixPanelEvents } from '../../../mixPanelEvents';
import {
  selectedMDUSelector,
  selectedPartnerSelector,
  userInfoAtom,
} from '../../../state';
import { Permission } from '../../../types';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import { prepareErrorMessageForInput } from '../../../utils/prepareErrorMessageForInput';
import { inventoryNodesAtom } from '../../inventory/inventoryState';
import { NodeStatus } from '../../inventory/types';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import {
  propertyNetworksAtom,
  selectedPropertyNetworkAtom,
} from '../propertyNetworksState';
import { CreatePropertyNetworkDto, PropertyNetwork } from '../types';
import PropertyNetworkPasswordField from './PropertyNetworkPasswordField';

type CreatePropertyModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
};

const addStylesToModalBody = (current: any) => ({
  ...current,
  root: `${current.root} PlumeUI__ModalBody ManagePropertyNetworkModal__content`,
});

const addStylesToInputField = (current: any) => ({
  ...current,
  root: `${current.root} PlumeUI__InputField ManagePropertyNetworkModal__input`,
});

type CreatePropertyNetworkFormValues = {
  name: string;
  propertySsid: string;
  propertyPassword?: string | null;
  communitySsid: string | null;
  communityBandwidth: number;
  communityNetworkEnabled: boolean;
  nodes: string[];
};

const { propertyNetworksService } = new DependencyContainer();

const ManagePropertyNetworksModal: FunctionComponent<CreatePropertyModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const selectedMdu = useRecoilValue(selectedMDUSelector);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const trackEvent = useTrackEvent();
  const userInfo = useRecoilValue(userInfoAtom);
  const [inventoryNodes, setInventoryNodes] = useRecoilState(
    inventoryNodesAtom,
  );
  const setPropertyNetworks = useSetRecoilState(propertyNetworksAtom);
  const selectedPropertyNetwork = useRecoilValue(selectedPropertyNetworkAtom);

  const formInputFieldsDefinition: Array<{
    name: keyof CreatePropertyNetworkFormValues;
    labelId: string;
    type: 'input' | 'password';
    disabled?: boolean;
    options?: DropdownInputOption[];
  }> = [
    {
      name: 'name',
      labelId: 'propertyNetworks.actions.create.form.name.label',
      type: 'input',
    },
    {
      name: 'propertySsid',
      labelId: 'propertyNetworks.actions.create.form.propertySsid.label',
      type: 'input',
    },
    {
      name: 'propertyPassword',
      labelId: 'propertyNetworks.actions.create.form.propertyPassword.label',
      type: 'password',
    },
  ];

  const [submitInProgress, setSubmitInProgress] = useState(false);

  const getFormInitialValues = (): CreatePropertyNetworkFormValues => {
    if (selectedPropertyNetwork) {
      const formValues: CreatePropertyNetworkFormValues = {
        name: selectedPropertyNetwork.name,
        propertySsid: selectedPropertyNetwork.propertyWifi.ssid,
        propertyPassword: selectedPropertyNetwork.propertyWifi.password,
        communitySsid: '',
        communityBandwidth: 10,
        communityNetworkEnabled: false,
        nodes: selectedPropertyNetwork.nodes.map((node) => node.id),
      };
      if (selectedPropertyNetwork.communityWifi) {
        formValues.communitySsid = selectedPropertyNetwork.communityWifi.ssid;
        formValues.communityBandwidth =
          selectedPropertyNetwork.communityWifi.bandwidthLimit.download;
        formValues.communityNetworkEnabled =
          selectedPropertyNetwork.communityWifi.enabled;
      }
      return formValues;
    }
    return {
      name: '',
      propertySsid: '',
      propertyPassword: '',
      communitySsid: '',
      communityBandwidth: 10,
      communityNetworkEnabled: false,
      nodes: [],
    };
  };

  const getValidationSchema = () =>
    yup.object().shape({
      name: yup
        .string()
        .min(3, t('formValidation.minLength', { length: 3 }))
        .trim()
        .max(128, t('formValidation.maxFields', { length: 128 }))
        .required(t('propertyNetworks.actions.create.form.name.required')),
      propertySsid: yup
        .string()
        .min(1, t('formValidation.minLength', { length: 1 }))
        .trim()
        .max(32, t('formValidation.maxFields', { length: 32 }))
        .required(
          t('propertyNetworks.actions.create.form.propertySsid.required'),
        ),
      propertyPassword:
        !selectedPropertyNetwork ||
        userInfo?.permissions.includes(Permission.getPropertyWifiPassword)
          ? yup
              .string()
              .min(8, t('formValidation.minLength', { length: 8 }))
              .trim()
              .max(63, t('formValidation.maxFields', { length: 63 }))
              .required(
                t(
                  'propertyNetworks.actions.create.form.propertyPassword.required',
                ),
              )
          : yup
              .string()
              .min(8, t('formValidation.minLength', { length: 8 }))
              .trim()
              .max(63, t('formValidation.maxFields', { length: 63 })),
      communitySsid: yup
        .string()
        .min(1, t('formValidation.minLength', { length: 1 }))
        .trim()
        .max(32, t('formValidation.maxFields', { length: 32 }))
        .when('communityNetworkEnabled', {
          is: true,
          then: yup
            .string()
            .notOneOf(
              [ref('propertySsid')],
              t(
                'propertyNetworks.actions.create.form.communitySsid.shouldDiffer',
              ),
            )
            .required(
              t('propertyNetworks.actions.create.form.communitySsid.required'),
            ),
        })
        .nullable(),
      communityNetworkEnabled: yup.boolean(),
    });

  const getNodeOptions = () => {
    const unassignedNodes = inventoryNodes
      .filter((node) => node.status === NodeStatus.Unassigned)
      .map((node) => ({ label: node.id, value: node.id }));
    if (selectedPropertyNetwork) {
      return [
        ...selectedPropertyNetwork.nodes.map((n) => ({
          label: n.id,
          value: n.id,
        })),
        ...unassignedNodes,
      ];
    }
    return unassignedNodes;
  };

  const filterGroupItems: Record<string, DropdownFilter> = {
    nodes: {
      label: t('propertyNetworks.actions.create.form.nodes.label'),
      defaultLabel: t('propertyNetworks.actions.create.form.nodes.selectLabel'),
      type: DropdownFilterType.MULTI,
      noSearchMatchMessage: t(
        'propertyNetworks.actions.create.form.nodes.noMatch',
      ),
      searchPlaceholder: t('propertyNetworks.actions.create.form.nodes.search'),
      searchBar: true,
      openInPortal: true,
      expandDirection: 'auto',
      noItemsWarning: t(
        'propertyNetworks.actions.create.form.nodes.noNodesWarning',
      ),
      items: getNodeOptions(),
    },
  };

  if (!selectedMdu || !selectedPartner) {
    return null;
  }

  const assignNodes = (propertyNetwork: PropertyNetwork) => {
    setInventoryNodes((nodes) => {
      return nodes.map((n) => {
        if (propertyNetwork.nodes.some((un) => un.id === n.id)) {
          const updatedNode = { ...n, status: NodeStatus.Assigned };
          updatedNode.propertyNetwork = {
            id: propertyNetwork.id,
            name: propertyNetwork.name,
          };
          return updatedNode;
        }
        return n;
      });
    });
  };

  const updateNodes = (updatedPropertyNetwork: PropertyNetwork) => {
    const addedNodes = updatedPropertyNetwork.nodes.filter(
      (n) => !selectedPropertyNetwork!.nodes.some((sn) => sn.id === n.id),
    );
    const removedNodes = selectedPropertyNetwork!.nodes.filter(
      (n) => !updatedPropertyNetwork.nodes.some((sn) => sn.id === n.id),
    );
    setInventoryNodes((nodes) => {
      return nodes.map((n) => {
        if (addedNodes.some((an) => an.id === n.id)) {
          const updatedNode = { ...n, status: NodeStatus.Assigned };
          updatedNode.propertyNetwork = {
            id: updatedPropertyNetwork.id,
            name: updatedPropertyNetwork.name,
          };
          return updatedNode;
        }

        if (removedNodes.some((rn) => rn.id === n.id)) {
          const updatedNode = { ...n, status: NodeStatus.Unassigned };
          updatedNode.propertyNetwork = null;
          return updatedNode;
        }

        return n;
      });
    });
  };

  const onSubmit = async (values: FormikValues) => {
    if (submitInProgress) return;
    setSubmitInProgress(true);
    if (selectedPropertyNetwork) {
      onUpdate(values);
    } else {
      onCreate(values);
    }
  };

  const onCreate = async (values: FormikValues) => {
    try {
      const createPropertyNetworkDto: CreatePropertyNetworkDto = {
        name: values.name?.trim(),
        nodes: values.nodes.map(
          (selectedFilter: string) =>
            inventoryNodes.find((node) => node.id === selectedFilter)!,
        ),
        propertyWifi: {
          ssid: values.propertySsid?.trim(),
          password: values.propertyPassword?.trim(),
        },
      };

      if (!userInfo?.permissions.includes(Permission.canUpdateUnitNodes)) {
        delete createPropertyNetworkDto.nodes;
      }

      if (values.communityNetworkEnabled) {
        createPropertyNetworkDto.communityWifi = {
          ssid: values.communitySsid?.trim(),
          enabled: true,
          bandwidthLimit: {
            enabled: values.communityNetworkEnabled,
            upload: values.communityNetworkEnabled
              ? values.communityBandwidth
              : null,
            download: values.communityNetworkEnabled
              ? values.communityBandwidth
              : null,
            type: 'percentage',
          },
        };
      }

      const propertyNetwork = await propertyNetworksService.createPropertyNetwork(
        selectedMdu.id,
        createPropertyNetworkDto,
        selectedPartner.id,
      );

      setPropertyNetworks((pn) => [...pn, propertyNetwork]);
      assignNodes(propertyNetwork);
      notify({
        title: t('success'),
        body: t('propertyNetworks.actions.create.propertyNetworkAdded'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.ADD_PROPERTY_NETWORK_SUCCESS,
        additionalContent: {
          propertyNetworkId: propertyNetwork.id,
          propertyId: selectedMdu.id,
        },
      });
      onRequestClose();
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.ADD_PROPERTY_NETWORK_FAILURE,
        additionalContent: {
          propertyId: selectedMdu.id,
        },
      });
    } finally {
      setSubmitInProgress(false);
    }
  };

  const onUpdate = async (values: FormikValues) => {
    try {
      const updatePropertyNetworkDto: CreatePropertyNetworkDto = {
        name: values.name,
        nodes: values.nodes.map(
          (selectedFilter: string) =>
            inventoryNodes.find((node) => node.id === selectedFilter)!,
        ),
        propertyWifi: {
          ssid: values.propertySsid,
          password: values.propertyPassword,
        },
      };

      if (!userInfo?.permissions.includes(Permission.canUpdateUnitNodes)) {
        delete updatePropertyNetworkDto.nodes;
      }

      if (values.communityNetworkEnabled || values.communitySsid) {
        updatePropertyNetworkDto.communityWifi = {
          ssid: values.communitySsid,
          bandwidthLimit: {
            enabled: true,
            upload: values.communityBandwidth,
            download: values.communityBandwidth,
            type: 'percentage',
          },
          enabled: values.communityNetworkEnabled,
        };
      }

      const propertyNetwork = await propertyNetworksService.updatePropertyNetwork(
        selectedMdu.id,
        updatePropertyNetworkDto,
        selectedPartner.id,
        selectedPropertyNetwork!.id,
      );

      setPropertyNetworks((pn) =>
        pn.map((n) => (n.id === propertyNetwork.id ? propertyNetwork : n)),
      );
      updateNodes(propertyNetwork);
      notify({
        title: t('success'),
        body: t('propertyNetworks.actions.edit.propertyNetworkUpdated'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.EDIT_PROPERTY_NETWORK_SUCCESS,
        additionalContent: {
          propertyNetworkId: propertyNetwork.id,
          propertyId: selectedMdu.id,
        },
      });
      setSubmitInProgress(false);
      onRequestClose();
    } catch (error) {
      setSubmitInProgress(false);
      trackEvent({
        eventName: MixPanelEvents.EDIT_PROPERTY_NETWORK_FAILURE,
        additionalContent: {
          propertyId: selectedMdu.id,
          propertyNetworkId: selectedPropertyNetwork!.id,
        },
      });
    }
  };

  const onClose = () => {
    onRequestClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Formik
      initialValues={getFormInitialValues()}
      validationSchema={getValidationSchema()}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values, setValues, errors, setFieldValue, dirty, submitForm }) => (
        <Form>
          <Modal
            appElement={document.getElementById('root') as HTMLElement}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            classes={(current: ModalStyles) => ({
              ...current,
              root: `${current.root} ManagePropertyNetworkModal`,
            })}
          >
            <ModalHeaderWithProperty
              propertyName={selectedMdu.name}
              title={
                selectedPropertyNetwork
                  ? t('propertyNetworks.actions.edit.modalTitle')
                  : t('propertyNetworks.actions.create.modalTitle')
              }
            />

            <ModalBody classes={addStylesToModalBody}>
              {formInputFieldsDefinition?.length > 0 && (
                <div className="ManagePropertyNetworkModal__fields">
                  {formInputFieldsDefinition.map((field) => {
                    if (
                      field.type === 'password' &&
                      Boolean(selectedPropertyNetwork) &&
                      !userInfo?.permissions.includes(
                        Permission.getPropertyWifiPassword,
                      )
                    ) {
                      return (
                        <PropertyNetworkPasswordField
                          key={field.name}
                          name={field.name}
                          messages={prepareErrorMessageForInput(
                            field.name,
                            errors,
                          )}
                          label={t(field.labelId)}
                          value={values[field.name]}
                          setFieldValue={setFieldValue}
                        />
                      );
                    }
                    return (
                      <Field
                        key={field.name}
                        name={field.name}
                        component={InputField}
                        value={values[field.name]}
                        messages={prepareErrorMessageForInput(
                          field.name,
                          errors,
                        )}
                        type={field.type === 'password' ? 'password' : null}
                        label={t(field.labelId)}
                        classes={addStylesToInputField}
                      />
                    );
                  })}
                </div>
              )}
              <FilterDropdownGroup
                items={filterGroupItems}
                selectedItems={{
                  nodes: filterGroupItems.nodes.items.filter((node) =>
                    values.nodes.includes(node.value),
                  ),
                }}
                onSelect={(
                  filterStateKey: string,
                  filter: FilterDropdownItem | FilterDropdownItem[],
                ) => {
                  setValues({
                    ...values,
                    nodes: (filter as FilterDropdownItem[]).map((e) => e.value),
                  });
                }}
                disabled={
                  !userInfo?.permissions.includes(Permission.canUpdateUnitNodes)
                }
              />
              <Space size="m" />
              <div className="ManagePropertyNetworkModal__communityWifiToggler">
                <FormattedMessage
                  id={t(
                    'propertyNetworks.actions.create.form.communityNetwork.label',
                  )}
                />

                <Switch
                  title={t(values.communityNetworkEnabled ? 'on' : 'off')}
                  selected={values.communityNetworkEnabled}
                  onToggle={() => {
                    setFieldValue(
                      'communityNetworkEnabled',
                      !values.communityNetworkEnabled,
                    );
                  }}
                />
              </div>
              {values.communityNetworkEnabled && (
                <>
                  <Field
                    key="communitySsid"
                    name="communitySsid"
                    component={InputField}
                    value={values.communitySsid}
                    messages={prepareErrorMessageForInput(
                      'communitySsid',
                      errors,
                    )}
                    label={t(
                      'propertyNetworks.actions.create.form.communitySsid.label',
                    )}
                    classes={addStylesToInputField}
                  />
                  <Space size="m" />
                  <div className="slider-container">
                    <label>
                      {t(
                        'propertyNetworks.actions.create.form.communityBandwidth.label',
                      )}
                    </label>
                    <span>{values.communityBandwidth}%</span>
                  </div>
                  <Space size="m" />
                  <Slider
                    min={0}
                    max={100}
                    value={values.communityBandwidth}
                    onChange={(event) =>
                      setFieldValue(
                        'communityBandwidth',
                        parseInt(event.currentTarget.value),
                      )
                    }
                  />
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                styleVariant="tertiary-grey"
                onClick={onClose}
              >
                <FormattedMessage id="cancel" />
              </Button>
              <SubmitButton
                disabled={!dirty}
                onSubmit={submitForm}
                submitInProgress={submitInProgress}
                label={selectedPropertyNetwork ? t('saveChanges') : t('submit')}
                inProgressLabel={
                  selectedPropertyNetwork ? t('savingChanges') : undefined
                }
              />
            </ModalFooter>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default memo(ManagePropertyNetworksModal);
