export const mockLeases = [
  {
    title: 'Rik Williams',
    unitId: 'Unit 101',
    id: '1',
    expiringAt: new Date(),
  },
  {
    title: 'Rik Williams',
    unitId: 'Unit 101',
    id: '2',
    expiringAt: new Date(),
  },
  {
    title: 'Rik Williams',
    unitId: 'Unit 101',
    id: '3',
    expiringAt: new Date(),
  },
  {
    title: 'Rik Williams',
    unitId: 'Unit 101',
    id: '4',
    expiringAt: new Date(),
  },
  {
    title: 'Rik Williams',
    unitId: 'Unit 101',
    id: '5',
    expiringAt: new Date(),
  },
  {
    title: 'Rik Williams',
    unitId: 'Unit 101',
    id: '6',
    expiringAt: new Date(),
  },
  {
    title: 'Rik Williams',
    unitId: 'Unit 101',
    id: '7',
    expiringAt: new Date(),
  },
];
