import { ButtonStyles } from 'plume-ui/dist/components/Button/Button';
import { InputFieldStyles } from 'plume-ui/dist/components/InputField/InputField';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import { ModalBodyStyles } from 'plume-ui/dist/components/Modal/ModalBody';
import { TogglerStyles } from 'plume-ui/dist/components/Toggler/Toggler';
import { InventoryNode, NodeStatus } from '../../../inventory/types';
import { GenericUnit, UnitType } from '../../types';
import { UnitFormValues } from './types';

export const addStylesToUnitModal = (current: ModalStyles) => ({
  ...current,
  root: `${current.root} UnitModal`,
});

export const addStylesToUnitModalToggler = (current: TogglerStyles) => ({
  ...current,
  root: `${current.root} UnitModal__toggler`,
});

export const addStylesToPaymentTypeToggler = (current: TogglerStyles) => ({
  ...current,
  root: `${current.root} UnitModal__paymentTypeToggler`,
});

export const addStylesToUnitModalBody = (current: ModalBodyStyles) => ({
  ...current,
  root: `${current.root} UnitModal__body`,
});

export const addStylesToUnitModalInputField = (current: InputFieldStyles) => ({
  ...current,
  root: `${current.root} UnitModal__body__inputField`,
  messages: `${current.messages} UnitModal__body__inputField__messages`,
});

export const addStylesAddNodeButton = (current: ButtonStyles) => ({
  ...current,
  root: `${current.root} UnitModal__body__assignButton`,
});

export const formInputFieldsDefinition: Array<{
  name: keyof UnitFormValues;
  labelId: string;
  type?: 'toggler';
}> = [
  {
    name: 'name',
    labelId: 'units.actions.create.form.name.label',
  },
  {
    name: 'paymentType',
    labelId: 'units.actions.create.form.paymentType.label',
    type: 'toggler',
  },
];

export const getUnassignedNodes = (inventory: InventoryNode[]) => {
  return inventory
    .filter(
      (node) =>
        node.status === NodeStatus.Unassigned && node.stateMeta.realized,
    )
    .map((node) => ({ label: node.id, value: node.id }));
};

export const getUnassignedAndSelectedNodes = (
  inventory: InventoryNode[],
  selectedNodes: InventoryNode[],
) => {
  return inventory
    .filter(
      (node) =>
        node.status === NodeStatus.Unassigned && node.stateMeta.realized,
    )
    .concat(selectedNodes)
    .map((node) => ({ label: node.id, value: node.id }));
};

export const getSelectedNodes = (
  inventory: InventoryNode[],
  selectedUnit: GenericUnit | null,
) => {
  if (!selectedUnit) {
    return [];
  }
  return inventory.filter((node) => {
    if (selectedUnit.type === UnitType.Business) {
      return node.businessUnit?.id === selectedUnit.id;
    } else {
      return node.residentialUnit?.id === selectedUnit.id;
    }
  });
};

export const hasNoDuplicateUnitName = (
  units: GenericUnit[],
  value: string | undefined,
  unitType: UnitType,
  currentUnitId: string | undefined,
): boolean => {
  if (currentUnitId) {
    return !units.some(
      (unit) =>
        unit.name === value?.toUpperCase() &&
        unit.type === unitType &&
        unit.id !== currentUnitId,
    );
  } else {
    return !units.some(
      (unit) => unit.name === value?.toUpperCase() && unit.type === unitType,
    );
  }
};

export const hasNoDuplicateNodes = (
  nodeSerialNumbers: string[],
  value: string | undefined,
): boolean => {
  if (!value || value === '') return true;
  return (
    nodeSerialNumbers.filter((nodeSerialNumber) => nodeSerialNumber === value)
      .length === 1
  );
};
