import {
  Button,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  Status,
  Table,
} from 'plume-ui';
import React, { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  NodeWithStatus,
  UnitNodeConnectionStates,
} from '../../features/units/types';
import useRenderAnonymized from '../../hooks/useRenderAnonymized';
import { selectedMDUSelector } from '../../state';
import FormattedMessage from '../../utils/components/FormattedMessage';
import ModalHeaderWithProperty from '../ModalHeaderWithProperty/ModalHeaderWithProperty';

type ShowNodesStatusModalProps = {
  isOpen: boolean;
  nodes?: NodeWithStatus[];
  title?: string;
  onRequestClose: () => void;
};

type NodeStatus = 'online' | 'offline' | 'alert';

const unitNodeConnectionStateMapping: {
  [key in UnitNodeConnectionStates]: NodeStatus;
} = {
  connected: 'online',
  disconnected: 'offline',
  unavailable: 'offline',
};

const statusColor: { [key in NodeStatus]: string } = {
  online: 'ok',
  offline: 'error',
  alert: 'error',
};

const ShowNodesStatusModal: FunctionComponent<ShowNodesStatusModalProps> = ({
  isOpen,
  nodes = [],
  title,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const { renderAnonymized } = useRenderAnonymized();

  const selectedMdu = useRecoilValue(selectedMDUSelector);

  const getStatus = (node: NodeWithStatus): NodeStatus => {
    if (node.alerts?.length) {
      return 'alert';
    }
    if (!node.connectionState) {
      return 'offline';
    }
    return unitNodeConnectionStateMapping[node.connectionState];
  };

  const tableHeaderRow: Record<string, unknown>[] = [
    {
      fieldName: 'icon',
      render: () => <Icons.HaystackIcon width={24} />,
    },
    {
      name: t('units.actions.showNodesStatus.table.serialNumber'),
      fieldName: 'id',
      render: (node: NodeWithStatus) => renderAnonymized(node.id),
    },
    {
      name: t('units.actions.showNodesStatus.table.status'),
      fieldName: 'status',
      render: (node: NodeWithStatus) => {
        const status = getStatus(node);
        return (
          <Status
            label={t(`units.actions.showNodesStatus.nodeStatus.${status}`)}
            color={statusColor[status]}
          />
        );
      },
    },
  ];

  if (!selectedMdu) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      appElement={document.getElementById('root') as HTMLElement}
    >
      <ModalHeaderWithProperty
        propertyName={selectedMdu.name}
        title={t('units.actions.showNodesStatus.modalTitle', {
          unitName: renderAnonymized(title),
        })}
      />
      <ModalBody
        classes={(current) => ({
          ...current,
          root: `${current.root} ShowNodesStatusModal__body`,
        })}
      >
        {Boolean(nodes.length) && (
          <Table
            headerRow={tableHeaderRow}
            dataRows={[...nodes] as Record<string, any>[]}
            truncateCellContent={false}
            classes={(current) => ({
              ...current,
              root: `${current.root} ShowNodesStatusModal__table`,
              cell: `${current.root} ShowNodesStatusModal__table-cell`,
            })}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          classes={(current) => ({
            ...current,
            root: `${current.root} ShowNodesStatusModal__button`,
          })}
          type="button"
          styleVariant="tertiary"
          onClick={onRequestClose}
        >
          <FormattedMessage id="confirmation.close" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(ShowNodesStatusModal);
