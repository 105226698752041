import { AxiosResponse } from 'axios';
import { HttpClient } from '../HttpClient';
import { UpriseApiUrls } from '../urls';

export type VersionResponse = {
  version: string;
  name: string;
};
export default class VersionClient extends HttpClient {
  async getApiVersion(): Promise<AxiosResponse<VersionResponse>> {
    return await this.get<AxiosResponse<VersionResponse>>(
      UpriseApiUrls.getApiVersionUrl(),
    );
  }
}
