import { debounce } from 'lodash';
import { Button, SearchInputField } from 'plume-ui';
import React, { ChangeEvent, FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import FormattedMessage from '../../utils/components/FormattedMessage';

type TableFilterRowProps = {
  actionButton?: {
    onClick: () => void;
    label: string;
    isDisabled?: boolean;
  };
  children?: React.ReactNode;
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  resultCount?: string | number;
  resultText?: string | JSX.Element;
  value: string;
};

const TableFilterRow: FunctionComponent<TableFilterRowProps> = ({
  actionButton,
  children,
  onSearch,
  placeholder,
  resultCount,
  resultText,
  value,
}) => {
  const { t } = useTranslation();

  return (
    <div className="TableFilterRow">
      <div className="TableFilterRow__filter">
        <SearchInputField
          placeholder={t(placeholder)}
          value={value}
          rounded
          onInput={debounce(onSearch, 400)}
        />
        <span className="TableFilterRow__filter-results">
          {!resultText &&
            t('tableFilter.resultCount', {
              count: parseInt((resultCount || 0)?.toString()),
            })}
          {resultText}
        </span>
      </div>
      {children}
      {actionButton && (
        <Button
          styleVariant="superprimary"
          onClick={actionButton.onClick}
          disabled={actionButton.isDisabled}
          classes={(current) => ({
            ...current,
            root: `${current.root} TableFilterRow__action-btn`,
          })}
        >
          <FormattedMessage id={actionButton.label} />
        </Button>
      )}
    </div>
  );
};

export default memo(TableFilterRow);
