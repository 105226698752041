import {
  Dropdown,
  DropdownSelectableItem,
  DropdownWrapWithLabel,
  Grid,
  GridItem,
  Icons,
  Pagination,
  PendingContent,
  Spinner,
  Status,
  Tooltip,
} from 'plume-ui';
import React, {
  ChangeEvent,
  FunctionComponent,
  memo,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import DependencyContainer from '../../../DependencyContainer';
import AuraGlow from '../../../components/AuraGlow/AuraGlow';
import PageHeading from '../../../components/PageHeading/PageHeading';
import ShowNodesStatusModal from '../../../components/ShowNodesStatusModal/ShowNodesStatusModal';
import StickyActionTable from '../../../components/StickyActionTable/StickyActionTable';
import TableActionButton from '../../../components/TableActionButton/TableActionButton';
import TableFilterRow from '../../../components/TableFilter/TableFilterRow';
import { DEFAULT_PAGE_SIZE } from '../../../config';
import useRenderAnonymized from '../../../hooks/useRenderAnonymized';
import { MixPanelEvents } from '../../../mixPanelEvents';
import {
  apiVersionAtom,
  selectedMDUSelector,
  selectedPartnerSelector,
  userInfoAtom,
} from '../../../state';
import { ModalState, Permission, Role } from '../../../types';
import EmptyTableCell from '../../../utils/components/EmptyTableCell';
import { AvailableHeroStats } from '../../overview/components/HeroStat/LargeHeroStat';
import { tableFormatterBitrate } from '../../overview/helpers/chartHelpers';
import { useOverviewStats } from '../../overview/hooks/useOverviewStats';
import { overviewStatsAtom } from '../../overview/overviewState';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { AvailableScreens } from '../../trackingAnalytics/types';
import ManagePropertyNetworksModal from '../components/ManagePropertyNetworkModal';
import usePropertyNetworksActions from '../hooks/usePropertyNetworksActions';
import usePropertyNetworksMappings from '../hooks/usePropertyNetworksMappings';
import {
  currentPropertyNetworksPage,
  getFilteredPropertyNetworkCount,
  managePropertyNetworksModalStateAtom,
  propertyNetworksFilterStatusAtom,
  propertyNetworksLoadingStatusAtom,
  propertyNetworksSelectedFilterAtom,
  propertyNetworksSelectedPageAtom,
  propertyNetworksSortAtom,
  selectedPropertyNetworkAtom,
  selectedPropertyNetworkToViewNodesAtom,
} from '../propertyNetworksState';
import { PropertyNetwork, PropertyNetworkDetails } from '../types';

const { propertyNetworksService } = new DependencyContainer();

const PropertyNetworksContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const { renderAnonymized } = useRenderAnonymized();
  const trackEvent = useTrackEvent();
  const userInfo = useRecoilValue(userInfoAtom);
  const getPropertyNetworksActions = usePropertyNetworksActions();
  const totalFilteredItems = useRecoilValue(getFilteredPropertyNetworkCount);
  const setPropertyNetworksSort = useSetRecoilState(propertyNetworksSortAtom);
  const loadingStatus = useRecoilValue(propertyNetworksLoadingStatusAtom);
  const propertyNetworks = useRecoilValue(currentPropertyNetworksPage);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const [selectedPage, setSelectedPage] = useRecoilState(
    propertyNetworksSelectedPageAtom,
  );
  const [selectedFilter, setSelectedFilter] = useRecoilState(
    propertyNetworksSelectedFilterAtom,
  );
  const apiVersion = useRecoilValue(apiVersionAtom);
  const [detailsMap, setDetailsMap] = useState<
    Record<string, PropertyNetworkDetails | null>
  >({});
  const [statusFilter, setStatusFilter] = useRecoilState(
    propertyNetworksFilterStatusAtom,
  );

  const [
    managePropertyNetworksModalState,
    setManagePropertyNetworksModalState,
  ] = useRecoilState(managePropertyNetworksModalStateAtom);

  const setSelectedPropertyNetwork = useSetRecoilState(
    selectedPropertyNetworkAtom,
  );
  const [
    selectedPropertyNetworkToViewNodes,
    setSelectedPropertyNetworkToViewNodes,
  ] = useRecoilState(selectedPropertyNetworkToViewNodesAtom);

  const {
    renderPropertyNetworkName,
    renderConnectionStatus,
    getConnectionStatusColorCode,
  } = usePropertyNetworksMappings();
  const selectedProperty = useRecoilValue(selectedMDUSelector);

  useOverviewStats();
  const overviewStats = useRecoilValue(overviewStatsAtom);

  const getDetails = async (propertyNetwork: PropertyNetwork) => {
    const details = await propertyNetworksService.getPropertyNetworkDetails(
      propertyNetwork,
      selectedPartner!.id,
      propertyNetwork.property.id,
    );
    setDetailsMap((map) => ({
      ...map,
      [propertyNetwork.id]: details,
    }));
  };

  useEffect(() => {
    if (!propertyNetworks.length && selectedPage > 0) {
      setSelectedPage(selectedPage - 1);
    }
  }, [propertyNetworks, selectedPage]);

  useEffect(() => {
    propertyNetworks.forEach((pn) => {
      if (!pn.nodes.length) {
        setDetailsMap((map) => ({
          ...map,
          [pn.id]: null,
        }));
      } else {
        getDetails(pn);
      }
    });
  }, [propertyNetworks]);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.PropertyNetworks,
      },
    });
    return () => {
      setSelectedFilter('');
    };
  }, []);

  const tableHeaderRow: Record<string, unknown>[] = [
    {
      name: t('propertyNetworks.table.propertyNetwork'),
      fieldName: 'name',
      sortable: true,
      render: (propertyNetwork: PropertyNetwork) =>
        renderPropertyNetworkName(propertyNetwork),
    },
    {
      name: t('propertyNetworks.table.propertySsid'),
      fieldName: 'propertySsid',
      sortable: true,
      render: (propertyNetWork: PropertyNetwork) => {
        return (
          <span className="PropertyNetworksContainer__propertyWifi-cell">
            {renderAnonymized(propertyNetWork.propertyWifi.ssid)}
            {userInfo?.permissions.includes(
              Permission.getPropertyWifiPassword,
            ) && (
              <Tooltip
                openInPortal
                label={
                  t('propertyNetworks.table.password') +
                  '\n' +
                  renderAnonymized(propertyNetWork.propertyWifi.password)
                }
                position="right"
                alignment="center"
                classes={(current) => ({
                  ...current,
                  root: `${current.root} PropertyNetworksContainer__passwordTooltip`,
                })}
              >
                <Icons.KeySolidIcon width={20} />
              </Tooltip>
            )}
          </span>
        );
      },
    },
    {
      name: t('propertyNetworks.table.communitySsid'),
      fieldName: 'communitySsid',
      sortable: true,
      render: (propertyNetwork: PropertyNetwork) => (
        <>
          {propertyNetwork.communityWifi?.enabled ? (
            <span>{renderAnonymized(propertyNetwork.communityWifi.ssid)}</span>
          ) : (
            <EmptyTableCell />
          )}
        </>
      ),
    },
    {
      name: t('units.table.uploadSpeed'),
      fieldName: 'averageUploadSpeed',
      sortable: true,
      render: (propertyNetwork: PropertyNetwork) => (
        <>
          {tableFormatterBitrate(
            propertyNetwork.averageUploadSpeed?.toString(),
          ) || <EmptyTableCell />}
        </>
      ),
    },
    {
      name: t('units.table.downloadSpeed'),
      fieldName: 'averageDownloadSpeed',
      sortable: true,
      render: (propertyNetwork: PropertyNetwork) => (
        <>
          {tableFormatterBitrate(
            propertyNetwork.averageDownloadSpeed?.toString(),
          ) || <EmptyTableCell />}
        </>
      ),
    },
    {
      name: t('propertyNetworks.table.connection'),
      sortable: false,
      fieldName: 'connection',
      render: (propertyNetwork: PropertyNetwork) => {
        return (
          <Status
            label={renderConnectionStatus(
              propertyNetwork,
              detailsMap[propertyNetwork.id],
            )}
            color={getConnectionStatusColorCode(
              propertyNetwork,
              detailsMap[propertyNetwork.id],
            )}
          />
        );
      },
    },
    {
      fieldName: 'actions',
      render: (propertyNetwork: PropertyNetwork) => {
        const propertyNetworksActions = getPropertyNetworksActions(
          propertyNetwork,
        );
        return (
          <TableActionButton
            actions={propertyNetworksActions as any}
            item={propertyNetwork}
          ></TableActionButton>
        );
      },
    },
  ];

  const onFilterSelect = (key: string) => {
    setSelectedPage(0);
    setStatusFilter((selectedFilter) => {
      if (selectedFilter === key) {
        return '';
      }
      return key;
    });
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value === undefined ? '' : e.target.value;
    setSelectedFilter(value);
    setSelectedPage(0);
  };

  if (!apiVersion) {
    return null;
  }

  const getNodes = () => {
    if (!selectedPropertyNetworkToViewNodes) {
      return [];
    }
    const details = detailsMap[selectedPropertyNetworkToViewNodes.id];

    return details ? details.nodes : [];
  };

  const hasPermission = (permission: Permission) => {
    return userInfo?.permissions.includes(permission);
  };

  const hasAlerts = () => {
    return Boolean(
      overviewStats &&
        overviewStats[AvailableHeroStats.PropertyNetworks].alertCount,
    );
  };

  return (
    <>
      <Helmet>
        <title>{t('propertyNetworks.title')}</title>
      </Helmet>
      <div className="PropertyNetworksContainer p-xl">
        <AuraGlow header footer />
        <Grid>
          <GridItem colSpan="12" tabletColSpan="6">
            <PageHeading
              title="propertyNetworks.title"
              tooltip="propertyNetworks.tooltip"
              additionalTitle={selectedProperty?.name}
            />
          </GridItem>
          <GridItem colSpan="12" tabletColSpan="6">
            <TableFilterRow
              placeholder="propertyNetworks.search"
              value={selectedFilter}
              onSearch={handleSearch}
              resultCount={totalFilteredItems}
              actionButton={{
                onClick: () =>
                  setManagePropertyNetworksModalState(ModalState.Open),
                label: 'propertyNetworks.actions.add.buttonLabel',
                isDisabled:
                  userInfo?.role !== Role.Support &&
                  !hasPermission(Permission.createPropertyNetwork),
              }}
            >
              <DropdownWrapWithLabel label={t('propertyNetworks.filterBy')}>
                <Dropdown
                  label={
                    statusFilter
                      ? t('propertyNetworks.filter.' + statusFilter)
                      : t('propertyNetworks.filter.showAll')
                  }
                >
                  <DropdownSelectableItem
                    key="alerted"
                    onClick={() => onFilterSelect('alerted')}
                    selected={'alerted' === statusFilter}
                    classes={(current) => ({
                      ...current,
                      root: `${current} PropertyNetworksContainer__filter--alerted`,
                    })}
                  >
                    <div
                      className={`PropertyNetworksContainer__filter--alerted__content ${
                        hasAlerts()
                          ? 'PropertyNetworksContainer__filter--alerted__content--hasAlerts'
                          : ''
                      }`}
                    >
                      {t('propertyNetworks.filter.alerted')}
                      <span> {t('propertyNetworks.filter.last60min')}</span>
                    </div>
                  </DropdownSelectableItem>
                  <DropdownSelectableItem
                    key="all"
                    onClick={() => onFilterSelect('')}
                    selected={statusFilter === ''}
                  >
                    {t('propertyNetworks.filter.showAll')}
                  </DropdownSelectableItem>
                </Dropdown>
              </DropdownWrapWithLabel>
            </TableFilterRow>
          </GridItem>
          <GridItem colSpan="12" tabletColSpan="6">
            <PendingContent
              loading={loadingStatus === 'loading'}
              loader={Spinner}
              isError={loadingStatus === 'error'}
              hideContent
            >
              <StickyActionTable
                headerRow={tableHeaderRow}
                dataRows={[...propertyNetworks] as any}
                truncateCellContent={false}
                externalSort
                onSortChange={setPropertyNetworksSort}
                noResultsMessage={t('propertyNetworks.table.noResults')}
              />
              {Math.ceil(totalFilteredItems / DEFAULT_PAGE_SIZE) > 0 && (
                <Pagination
                  classes={(current) => ({
                    ...current,
                    root: `${current.root} PropertyNetworksContainer__pagination`,
                  })}
                  expandDirection={'top'}
                  totalPageCount={
                    Math.ceil(totalFilteredItems / DEFAULT_PAGE_SIZE) || 1
                  }
                  onPageSelect={setSelectedPage}
                  currentPage={selectedPage}
                />
              )}
            </PendingContent>
          </GridItem>
        </Grid>
      </div>
      <ManagePropertyNetworksModal
        isOpen={managePropertyNetworksModalState === ModalState.Open}
        onRequestClose={() => {
          setManagePropertyNetworksModalState(ModalState.Dismissed);
          setSelectedPropertyNetwork(null);
        }}
      />
      <ShowNodesStatusModal
        isOpen={!!selectedPropertyNetworkToViewNodes}
        title={selectedPropertyNetworkToViewNodes?.name}
        nodes={getNodes()}
        onRequestClose={() => setSelectedPropertyNetworkToViewNodes(null)}
      />
    </>
  );
};

export default memo(PropertyNetworksContainer);
