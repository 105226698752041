import { notify } from 'plume-ui';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DependencyContainer from '../../../DependencyContainer';
import { getFrontlineUrl } from '../../../environment';
import { useFrontlineUrl } from '../../../hooks/useFrontlineUrl';
import useWithConfirmation from '../../../hooks/useWithConfirmation';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { selectedPartnerSelector, userInfoAtom } from '../../../state';
import { ModalState, Permission } from '../../../types';
import { FrontlineUrls } from '../../../urls';
import { inventoryNodesAtom } from '../../inventory/inventoryState';
import { NodeStatus } from '../../inventory/types';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import {
  managePropertyNetworksModalStateAtom,
  propertyNetworksAtom,
  selectedPropertyNetworkAtom,
  selectedPropertyNetworkToViewNodesAtom,
} from '../propertyNetworksState';
import { PropertyNetwork } from '../types';

export type PropertyNetworksAction = {
  onClick: (propertyNetworks: PropertyNetwork) => void;
  label: string;
  isVisible: (propertyNetworks: PropertyNetwork) => boolean;
  isDisabled?: boolean; // determines whether to disable an action or not
  subtexts?: string[];
};

const { propertyNetworksService, iotService } = new DependencyContainer();

const usePropertyNetworksActions = (): ((
  propertyNetworks: PropertyNetwork,
) => PropertyNetworksAction[]) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const userInfo = useRecoilValue(userInfoAtom);
  const withConfirmation = useWithConfirmation();
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const setSelectedPropertyNetwork = useSetRecoilState(
    selectedPropertyNetworkAtom,
  );
  const setPropertyNetworks = useSetRecoilState(propertyNetworksAtom);
  const setInventoryNodes = useSetRecoilState(inventoryNodesAtom);
  const setSelectedPropertyNetworkToViewNodes = useSetRecoilState(
    selectedPropertyNetworkToViewNodesAtom,
  );

  const setManagePropertyNetworksModalState = useSetRecoilState(
    managePropertyNetworksModalStateAtom,
  );
  const { generateFrontlineUrl } = useFrontlineUrl();

  const deletePropertyNetwork = async (propertyNetwork: PropertyNetwork) => {
    if (propertyNetwork.iotWifi?.enabled) {
      await new Promise((r) => setTimeout(r, 400));
      withConfirmation({
        title: t(
          'propertyNetworks.actions.deleteWithIot.deleteConfirmation.title',
        ),
        body: t(
          'propertyNetworks.actions.deleteWithIot.deleteConfirmation.body',
        ),
        onConfirm: () => deletePropertyNetworkAndIotNetwork(propertyNetwork),
      });
      return;
    }

    try {
      await propertyNetworksService.deletePropertyNetwork(
        propertyNetwork.property.id,
        selectedPartner!.id,
        propertyNetwork.id,
      );

      trackEvent({
        eventName: MixPanelEvents.DELETE_PROPERTY_NETWORK_SUCCESS,
        additionalContent: {
          propertyId: propertyNetwork.property.id,
          propertyNetworkId: propertyNetwork.id,
        },
      });
      setPropertyNetworks((propertyNetworks) =>
        propertyNetworks.filter((pn) => pn.id !== propertyNetwork.id),
      );

      if (propertyNetwork.nodes.length) {
        setInventoryNodes((nodes) =>
          nodes.map((n) => {
            if (
              propertyNetwork.nodes.some(
                (propertyNode) => propertyNode.id === n.id,
              )
            ) {
              return { ...n, status: NodeStatus.Unassigned };
            }
            return n;
          }),
        );
      }

      notify({
        title: t('success'),
        body: t('propertyNetworks.actions.delete.deleted'),
        type: 'success',
      });
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.DELETE_PROPERTY_NETWORK_FAILURE,
        additionalContent: {
          propertyId: propertyNetwork.property.id,
          propertyNetworkId: propertyNetwork.id,
        },
      });
    }
  };

  const deletePropertyNetworkAndIotNetwork = async (
    propertyNetwork: PropertyNetwork,
  ) => {
    try {
      await iotService.deleteIotNetwork(
        propertyNetwork.id,
        propertyNetwork.property.id,
        selectedPartner!.id,
      );

      trackEvent({
        eventName: MixPanelEvents.DELETE_IOT_NETWORK,
        additionalContent: {
          propertyId: propertyNetwork.property.id,
          propertyNetworkId: propertyNetwork.id,
        },
      });

      setPropertyNetworks((propertyNetworks) =>
        propertyNetworks.map((pn) => {
          if (pn.id === propertyNetwork.id) {
            return { ...propertyNetwork, iotWifi: null };
          }
          return pn;
        }),
      );

      notify({
        title: t('inProgress'),
        body: t('iot.actions.delete.scheduled'),
        type: 'info',
      });
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.DELETE_IOT_NETWORK_FAILURE,
        additionalContent: {
          propertyId: propertyNetwork.property.id,
          propertyNetworkId: propertyNetwork.id,
        },
      });
    }
  };

  const hasPermission = (permission: Permission) => {
    return userInfo?.permissions.includes(permission);
  };

  const reasonIsReadOnly = () => {
    return t('propertyNetworks.readOnlyIsNotAllowed');
  };

  const propertyNetworkActions: PropertyNetworksAction[] = [
    {
      onClick: (propertyNetwork: PropertyNetwork) => {
        setSelectedPropertyNetwork(propertyNetwork);
        return setManagePropertyNetworksModalState(ModalState.Open);
      },
      label: t('propertyNetworks.table.actionsDropdown.edit'),
      isVisible: () => true,
      isDisabled: !hasPermission(Permission.updatePropertyNetwork),
      subtexts: !hasPermission(Permission.updatePropertyNetwork)
        ? [reasonIsReadOnly()]
        : undefined,
    },
    {
      onClick: (propertyNetwork: PropertyNetwork) =>
        setSelectedPropertyNetworkToViewNodes(propertyNetwork),
      label: t('propertyNetworks.table.actionsDropdown.viewNodes'),
      isVisible: (propertyNetworks: PropertyNetwork) =>
        !!propertyNetworks.nodes.length,
    },
    {
      onClick: async (propertyNetwork: PropertyNetwork) => {
        return window.open(generateFrontlineUrl(propertyNetwork), '_blank');
      },
      label: t('applications.frontline'),
      isVisible: () => true,
      isDisabled: !userInfo?.permissions.includes(
        Permission.getPropertyNetworkFrontlineLink,
      ),
      subtexts: !userInfo?.permissions.includes(
        Permission.getPropertyNetworkFrontlineLink,
      )
        ? [t('propertyNetworks.onlyAdminsAndTechsAreAllowed')]
        : undefined,
    },
    {
      onClick: async (propertyNetwork: PropertyNetwork) => {
        return window.open(
          getFrontlineUrl() +
            FrontlineUrls.getFrontlineCaptivePortal(
              propertyNetwork.customerId,
              propertyNetwork.locationId,
            ),
          '_blank',
        );
      },
      label: t('propertyNetworks.table.actionsDropdown.captivePortalEditor'),
      isVisible: (propertyNetwork: PropertyNetwork) =>
        Boolean(
          userInfo?.permissions.includes(Permission.getCaptivePortalEditorLink),
        ) && propertyNetwork.communityWifi?.enabled,
    },
    {
      onClick: (propertyNetwork: PropertyNetwork) =>
        withConfirmation({
          title: t('propertyNetworks.actions.delete.deleteConfirmation.title'),
          body: t('propertyNetworks.actions.delete.deleteConfirmation.body'),
          onConfirm: () => deletePropertyNetwork(propertyNetwork),
        }),
      label: t('propertyNetworks.table.actionsDropdown.delete'),
      isVisible: () => true,
      isDisabled: !hasPermission(Permission.deletePropertyNetwork),
      subtexts: !hasPermission(Permission.deletePropertyNetwork)
        ? [reasonIsReadOnly()]
        : undefined,
    },
  ];

  return (propertyNetwork: PropertyNetwork) =>
    propertyNetworkActions.filter((action) =>
      action.isVisible(propertyNetwork),
    );
};

export default usePropertyNetworksActions;
