import { Table } from 'plume-ui';
import { TableProps } from 'plume-ui/src/components/Table/Table';
import React, { FunctionComponent, memo } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

const StickyActionTable: FunctionComponent<TableProps> = ({ ...props }) => {
  const emitScroll = () => {
    window.dispatchEvent(new CustomEvent('scroll'));
  };

  return (
    <PerfectScrollbar
      className="StickyActionTable__scrollbar"
      style={{ maxWidth: '100%' }}
      onScrollX={emitScroll}
    >
      <Table
        {...props}
        classes={(current) => ({
          ...current,
          cell: `${current.cell} StickyActionTable__cell`,
          heading: `${current.heading} StickyActionTable__heading`,
          dataRow: `${current.dataRow} StickyActionTable__row`,
          headerRow: `${current.headerRow} StickyActionTable__headerRow`,
        })}
      />
    </PerfectScrollbar>
  );
};

export default memo(StickyActionTable);
