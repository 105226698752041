import cx from 'classnames';
import { FieldInputProps, FormikProps } from 'formik';
import { Dropdown, DropdownItem, DropdownWrapWithLabel } from 'plume-ui';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AdjustableComponent, useClasses } from '../../utils/hooks/useClasses';

export type DropdownInputOption = {
  value: string;
  label: string;
};

export type DropdownInputError = {
  status: string;
  message: string;
};

export type DropdownInputProps = {
  defaultLabel: string;
  disabled?: boolean;
  expandDirection?: 'top' | 'bottom';
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  messages?: DropdownInputError[];
  noItemsWarning?: string;
  openInPortal?: boolean;
  options: DropdownInputOption[];
  wrapLabel?: string;
};

export type DropdownInputStyles = {
  root: string;
  dropdown: string;
};

const DropdownInput: AdjustableComponent<
  DropdownInputProps,
  DropdownInputStyles
> = ({
  classes,
  defaultLabel,
  disabled = false,
  expandDirection = 'bottom',
  field,
  form,
  messages,
  noItemsWarning,
  openInPortal = false,
  options,
  wrapLabel,
}) => {
  const { t } = useTranslation();
  const onChange = (option: DropdownInputOption) => {
    form.setFieldValue(field.name, option.value);
  };

  const styles = useClasses(
    {
      root: 'DropdownInput',
      dropdown: 'DropdownInput__dropdown',
    },
    classes,
  );

  const label = field.value
    ? options.find((option) => option.value === field.value)?.label
    : defaultLabel;

  const dropdown = () => {
    return (
      <>
        <Dropdown
          label={label}
          closeOnItemClick
          listPosition="left"
          noSearchMatchMessage={t('dropdownInput.dropdownInput')}
          searchPlaceholder={t('dropdownInput.searchPlaceholder')}
          searchBar
          expandDirection={expandDirection}
          openInPortal={openInPortal}
          disabled={disabled}
          classes={(current) => ({
            ...current,
            root: `${current.root} ${styles.dropdown}`,
            list: `${current.list} DropdownInput__list`,
            button: `${current.button} DropdownInput__button`,
            icon: `${current.icon} DropdownInput__icon`,
          })}
          noItemsWarning={noItemsWarning}
        >
          {options?.map((option) => {
            return (
              <DropdownItem onClick={() => onChange(option)} key={option.value}>
                {option.label}
              </DropdownItem>
            );
          })}
        </Dropdown>
        {messages?.map((error: DropdownInputError) => (
          <span key={error.message} className="DropdownInput__message">
            {error.message}
          </span>
        ))}
      </>
    );
  };

  return (
    <div
      className={cx('DropdownInput', {
        'DropdownInput--error': messages?.length,
      })}
    >
      {wrapLabel ? (
        <DropdownWrapWithLabel label={wrapLabel || ''}>
          {dropdown()}
        </DropdownWrapWithLabel>
      ) : (
        dropdown()
      )}
    </div>
  );
};
export default memo(DropdownInput);
