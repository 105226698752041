import { useEffect, useState } from 'react';

type UseFetchDataValues<T, K> = [
  boolean,
  T | undefined,
  K | undefined,
  () => Promise<void>,
];

export const useFetchData = <T = any, K = any>(
  fetcherFn: () => Promise<T | void>,
  setterFn?: (data: T) => void,
  deps?: any[],
  runOnInit = true,
): UseFetchDataValues<T, K> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<K>();
  const [data, setData] = useState<T>();

  const postFetchAction = (response: T | void) => {
    if (!response) {
      return;
    }
    if (setterFn) {
      setterFn(response);
    } else {
      setData(response);
    }
  };

  const fetchAsyncData = async () => {
    setLoading(true);
    try {
      const response = await fetcherFn();
      postFetchAction(response);
      setLoading(false);
      setError(undefined);
    } catch (error) {
      setError(error as K);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (runOnInit) {
      fetchAsyncData();
    }
  }, deps || []);

  return [loading, data, error, fetchAsyncData];
};
