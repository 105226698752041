import { useEffect } from 'react';
import { useFetchData } from '../../../hooks/useFetchData';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { OverviewStats } from '../types';
import {
  overviewStatsAtom,
  overviewStatsLoadingStatusAtom,
} from '../overviewState';
import { isNewStatsAPIsEnabled, selectedPartnerSelector } from '../../../state';
import DependencyContainer from '../../../DependencyContainer';
import {
  LargeHeroStatProperty,
  LargeHeroStatType,
} from '../components/HeroStat/LargeHeroStat';
import { LargeHeroStatAlert } from '../components/HeroStat/LargeHeroStatAlerts';
import { selectedMduIdAtom } from '../../editorView/propertyState';

export type OverviewStat = OverviewNormalStat | OverviewAlertStat;

export type OverviewNormalStat = {
  stat: LargeHeroStatType;
  properties?: LargeHeroStatProperty[];
  alerts: number;
  updatedAt: string | null;
};

export type OverviewAlertStat = {
  stat: LargeHeroStatType;
  alerts: LargeHeroStatAlert[];
};

type UseOverviewStatsValues = {
  loading: boolean;
  error: any;
  runFetch: () => Promise<void>;
};

const { statService } = new DependencyContainer();

export const useOverviewStats = (): UseOverviewStatsValues => {
  const setOverviewStats = useSetRecoilState(overviewStatsAtom);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const selectedMduId = useRecoilValue(selectedMduIdAtom);
  const newStatsAPIsEnabled = useRecoilValue(isNewStatsAPIsEnabled);
  const setOverviewStatsLoadingStatus = useSetRecoilState(
    overviewStatsLoadingStatusAtom,
  );

  const [loading, , error, runFetch] = useFetchData<OverviewStats>(
    async () => {
      if (selectedMduId && selectedPartner) {
        return await statService.getStatsForOverviewPage(
          selectedMduId,
          selectedPartner.id,
          newStatsAPIsEnabled,
        );
      }
    },
    (overviewStats) => {
      setOverviewStats(overviewStats);
      setOverviewStatsLoadingStatus('loaded');
    },
    [selectedPartner, selectedMduId],
  );

  useEffect(() => {
    if (loading) {
      setOverviewStatsLoadingStatus('loading');
    }
    if (error) {
      setOverviewStatsLoadingStatus('error');
    }
  }, [loading, error]);

  return {
    loading,
    error,
    runFetch,
  };
};
