import * as yup from 'yup';

// From https://www.npmjs.com/package/validatorjs
export const isURL = (string: string) => {
  return (
    string.length < 2083 &&
    /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&/=]*)/i.test(
      string,
    )
  );
};

export const validateUrl = ({
  invalidUrlMessage,
}: {
  invalidUrlMessage: string;
}) => {
  return yup.string().test({
    name: 'url-valid',
    message: invalidUrlMessage,
    test: (value) => {
      if (isURL(value as string)) return true;
      return false;
    },
  });
};
