import { Button } from 'plume-ui';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AuraGlow from '../../../components/AuraGlow/AuraGlow';
import PageHeading from '../../../components/PageHeading/PageHeading';
import StickyActionTable from '../../../components/StickyActionTable/StickyActionTable';
import TableActionButton from '../../../components/TableActionButton/TableActionButton';
import TableFilterRow from '../../../components/TableFilter/TableFilterRow';
import { Routes } from '../../../config';
import { selectedMDUSelector } from '../../../state';
import EmptyTableCell from '../../../utils/components/EmptyTableCell';
import { isDevelopment, isLocal } from '../../../utils/environment';
import { useRedirectToRoute } from '../../../utils/hooks/useRedirectToRoute';
import DocumentModal from '../components/DocumentModal/DocumentModal';
import useDocumentActions from '../hooks/useDocumentActions';
import { documentsAtom } from '../state';
import { Document } from '../types';

const DocumentsContainer = () => {
  const { t } = useTranslation();
  const redirectToRoute = useRedirectToRoute();
  const getDocumentActions = useDocumentActions();

  const selectedProperty = useRecoilValue(selectedMDUSelector);
  const documents = useRecoilValue(documentsAtom);

  const [documentsFilter, setDocumentsFilter] = useState('');
  const [documentModalOpen, setDocumentModalOpen] = useState(false);

  const tableHeaderRow: Record<string, unknown>[] = [
    {
      name: t('documents.table.name'),
      fieldName: 'name',
      sortable: true,
    },
    {
      name: t('documents.table.unit'),
      fieldName: 'unit',
      sortable: true,
      render: (document: Document) => (
        <>{document.unit?.name || <EmptyTableCell />}</>
      ),
    },
    {
      name: t('documents.table.send'),
      fieldName: 'send',
      sortable: false,
      render: () => (
        <Button
          styleVariant="superprimary"
          classes={(current) => ({
            ...current,
            root: `${current.root} DocumentsContainer__sendButton`,
          })}
        >
          {t('documents.actions.send.buttonLabel')}
        </Button>
      ),
    },
    {
      name: t('documents.table.createdAt'),
      fieldName: 'createdAt',
      sortable: true,
      render: (document: Document) => (
        <>{document.createdAt || <EmptyTableCell />}</>
      ),
    },
    {
      name: t('documents.table.updatedAt'),
      fieldName: 'updatedAt',
      sortable: true,
      render: (document: Document) => (
        <>{document.updatedAt || <EmptyTableCell />}</>
      ),
    },
    {
      fieldName: 'actions',
      render: (document: Document) => {
        const documentsActions = getDocumentActions();
        if (documentsActions.length === 0) {
          return null;
        }
        return (
          <TableActionButton actions={documentsActions} item={{ document }} />
        );
      },
    },
  ];

  if (!isLocal() && !isDevelopment()) {
    redirectToRoute(Routes.Index);
  }

  return (
    <>
      <Helmet>
        <title>{t('documents.title')}</title>
      </Helmet>
      <div className="DocumentsContainer p-xl">
        <AuraGlow header footer />
        <PageHeading
          title="documents.title"
          tooltip="documents.tooltip"
          additionalTitle={selectedProperty?.name}
        />
        <div className="DocumentsContainer__content">
          <TableFilterRow
            onSearch={(e) => setDocumentsFilter(e.currentTarget.value)}
            placeholder={t('documents.search')}
            value={documentsFilter}
            actionButton={{
              label: t('documents.actions.upload.buttonLabel'),
              onClick: () => setDocumentModalOpen(true),
            }}
          />
          <StickyActionTable
            headerRow={tableHeaderRow}
            dataRows={documents as Record<string, any>[]}
            externalSort
            noResultsMessage={t('documents.table.noResults')}
          />
        </div>
        <DocumentModal
          isOpen={documentModalOpen}
          onRequestClose={() => setDocumentModalOpen(false)}
        />
      </div>
    </>
  );
};

export default DocumentsContainer;
