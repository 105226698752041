import _ from 'lodash';
import { notify } from 'plume-ui';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import DependencyContainer from '../../../DependencyContainer';
import { TableAction } from '../../../components/TableActionButton/TableActionButton';
import { useFrontlineUrl } from '../../../hooks/useFrontlineUrl';
import useWithConfirmation from '../../../hooks/useWithConfirmation';
import { MixPanelEvents } from '../../../mixPanelEvents';
import {
  createTenantModalStateAtom,
  nodesModalSelectedUnitAtom,
  selectedMDUSelector,
  selectedPartnerSelector,
  userInfoAtom,
} from '../../../state';
import { ModalState, Permission } from '../../../types';
import { useHasPermission } from '../../../utils/hooks/useHasPermission';
import { inventoryNodesAtom } from '../../inventory/inventoryState';
import { NodeStatus } from '../../inventory/types';
import { hasIotNetworkSelector } from '../../iot/state';
import {
  businessTenantsAtom,
  residentialTenantsAtom,
  tenantsSelectedTypeAtom,
  unitToMoveInTenant,
} from '../../tenants/tenantsState';
import { TenantTypes } from '../../tenants/types';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { TrackEventParams } from '../../trackingAnalytics/types';
import {
  GenericUnit,
  ResidentialUnit,
  UnitDetails,
  UnitIotPropagationStatus,
  UnitStatus,
  UnitType,
} from '../types';
import { selectedUnitToEditAtom } from '../unitsState';
import { useUnits } from './useUnits';
import { useUnitsUpdateActions } from './useUnitsUpdateActions';

export type UnitAction = TableAction<{
  unit: GenericUnit;
  unitDetails?: UnitDetails | null;
}>;

const { unitsService, tenantService, iotService } = new DependencyContainer();

enum Actions {
  Delete,
  ActivateUnit,
  AddTenant,
  DeleteTenant,
  SuspendTenant,
  MakeAvailable,
  MakeUnavailable,
  RetryIotPropagation,
  DisableUnitIot,
  EnableUnitIot,
}

const actionToMixpanelEventMap: Record<
  Actions,
  {
    success: MixPanelEvents;
    failure: MixPanelEvents;
    body: string;
  }
> = {
  [Actions.Delete]: {
    success: MixPanelEvents.DELETE_UNIT_SUCCESS,
    failure: MixPanelEvents.DELETE_UNIT_FAILURE,
    body: 'units.actions.delete.unitDeleted',
  },
  [Actions.ActivateUnit]: {
    success: MixPanelEvents.ACTIVATE_UNIT_SUCCESS,
    failure: MixPanelEvents.ACTIVATE_UNIT_FAILURE,
    body: 'units.actions.activate.unitActivated',
  },
  [Actions.AddTenant]: {
    success: MixPanelEvents.ADD_TENANT_SUCCESS,
    failure: MixPanelEvents.ADD_TENANT_FAILURE,
    body: 'units.actions.addTenant.tenantAdded',
  },
  [Actions.DeleteTenant]: {
    success: MixPanelEvents.DELETE_TENANT_SUCCESS,
    failure: MixPanelEvents.DELETE_TENANT_FAILURE,
    body: 'units.actions.deleteTenant.tenantDeleted',
  },
  [Actions.SuspendTenant]: {
    success: MixPanelEvents.SUSPEND_TENANT_SUCCESS,
    failure: MixPanelEvents.SUSPEND_TENANT_FAILURE,
    body: 'units.actions.suspendTenant.tenantSuspended',
  },
  [Actions.MakeAvailable]: {
    success: MixPanelEvents.MAKE_AVAILABLE_UNIT_SUCCESS,
    failure: MixPanelEvents.MAKE_UNAVAILABLE_UNIT_FAILURE,
    body: 'units.actions.makeAvailable.successMessage',
  },
  [Actions.MakeUnavailable]: {
    success: MixPanelEvents.MAKE_UNAVAILABLE_UNIT_SUCCESS,
    failure: MixPanelEvents.MAKE_UNAVAILABLE_UNIT_FAILURE,
    body: 'units.actions.makeUnavailable.successMessage',
  },
  [Actions.RetryIotPropagation]: {
    success: MixPanelEvents.RETRY_IOT_PROPAGATION,
    failure: MixPanelEvents.RETRY_IOT_PROPAGATION_FAILURE,
    body: 'units.actions.retryIotPropagation.successMessage',
  },
  [Actions.DisableUnitIot]: {
    success: MixPanelEvents.DISABLE_UNIT_IOT,
    failure: MixPanelEvents.DISABLE_UNIT_IOT_FAILURE,
    body: 'units.actions.disableUnitIot.successMessage',
  },
  [Actions.EnableUnitIot]: {
    success: MixPanelEvents.ENABLE_UNIT_IOT,
    failure: MixPanelEvents.ENABLE_UNIT_IOT_FAILURE,
    body: 'units.actions.enableUnitIot.successMessage',
  },
};

const unitToTenantMap = {
  [UnitType.Business]: TenantTypes.Business,
  [UnitType.Residence]: TenantTypes.Residence,
};

const useUnitActions = (): ((
  unit: GenericUnit,
  unitDetails?: UnitDetails | null,
) => UnitAction[]) => {
  const { t } = useTranslation();
  const hasPermission = useHasPermission();
  const { runFetch: fetchUnits } = useUnits(false);
  const userInfo = useRecoilValue(userInfoAtom);
  const selectedMdu = useRecoilValue(selectedMDUSelector);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const hasIotNetwork = useRecoilValue(hasIotNetworkSelector);
  const withConfirmation = useWithConfirmation();
  const trackEvent = useTrackEvent();
  const setSelectedUnit = useSetRecoilState(selectedUnitToEditAtom);
  const setSelectedTenantType = useSetRecoilState(tenantsSelectedTypeAtom);
  const [createTenantModalState, setCreateTenantModalState] = useRecoilState(
    createTenantModalStateAtom,
  );
  const setShowNodesModalSelectedUnit = useSetRecoilState(
    nodesModalSelectedUnitAtom,
  );
  const [moveInUnit, setMoveInUnit] = useRecoilState(unitToMoveInTenant);
  const { updateUnit, removeUnit } = useUnitsUpdateActions();
  const setInventoryNodes = useSetRecoilState(inventoryNodesAtom);
  const setResidentialTenants = useSetRecoilState(residentialTenantsAtom);
  const setBusinessTenants = useSetRecoilState(businessTenantsAtom);

  const hasPermissionForAll = (permissions: Permission[]) => {
    return permissions.every((p) => userInfo?.permissions.includes(p));
  };

  const { generateFrontlineUrl } = useFrontlineUrl();

  const reasonIsReadOnly = () => {
    return t('units.readOnlyIsNotAllowed');
  };

  const reasonIsAdminsAndTechsOnly = () => {
    return t('units.onlyAdminsAndTechsAreAllowed');
  };

  const deleteTenant = async (unit: GenericUnit) => {
    if (!selectedPartner || !selectedMdu) return;

    const tenantType =
      unit.type === UnitType.Business
        ? TenantTypes.Business
        : TenantTypes.Residence;
    const tenantId = unit.tenant?.id;
    if (tenantId) {
      await tenantService.deleteTenant(
        selectedMdu.id,
        tenantId,
        tenantType,
        selectedPartner.id,
      );
    }

    const updatedUnitPartial = { id: unit.id, status: UnitStatus.Unassigned };

    updateUnit(updatedUnitPartial, unit.type);
    if (unit.type === UnitType.Business) {
      setBusinessTenants((tenants) => tenants.filter((t) => t.id !== tenantId));
    } else {
      setResidentialTenants((tenants) =>
        tenants.filter((t) => t.id !== tenantId),
      );
    }
  };

  useEffect(() => {
    if (createTenantModalState === ModalState.Success && moveInUnit) {
      setCreateTenantModalState(ModalState.Dismissed);
      setMoveInUnit(null);
    }

    if (createTenantModalState === ModalState.Dismissed && moveInUnit) {
      setMoveInUnit(null);
    }
  }, [createTenantModalState]);

  const activateUnit = async (unit: GenericUnit) => {
    const activatedUnit = await unitsService.updateUnit(
      selectedMdu!.id,
      {
        status: UnitStatus.Unassigned,
      },
      unit.id,
      unit.type,
      selectedPartner!.id,
    );
    await unitsService.getUnitDetails(
      unit,
      selectedPartner!.id,
      unit.property.id,
      unit.type,
      false,
    );
    await fetchUnits();
    updateUnit(
      { id: unit.id, ...{ status: activatedUnit?.status } },
      unit.type,
    );
  };

  const addTenant = (unit: GenericUnit) => {
    setMoveInUnit(unit);
    setSelectedTenantType(unitToTenantMap[unit.type]);
    setCreateTenantModalState(ModalState.Open);
  };

  const retryIotPropagation = async (unit: GenericUnit) => {
    if (unit.type === UnitType.Business) return;
    if (!selectedMdu || !selectedPartner) return;
    await iotService.propagateSingleIotNetwork(
      unit.id,
      selectedMdu?.id,
      selectedPartner?.id,
    );
    fetchUnits();
  };

  const disableUnitIot = async (unit: GenericUnit) => {
    if (unit.type === UnitType.Business) return;
    if (!selectedMdu || !selectedPartner) return;
    await iotService.propagateDeleteSingleIotNetwork(
      unit.id,
      selectedMdu?.id,
      selectedPartner?.id,
    );
    fetchUnits();
  };

  const toggleAvailability = async (unit: GenericUnit) => {
    if (!selectedMdu || !selectedPartner) return;

    const updatedStatus = {
      status:
        unit.status === UnitStatus.Unavailable
          ? UnitStatus.Unassigned
          : UnitStatus.Unavailable,
    };
    await unitsService.updateUnit(
      selectedMdu.id,
      updatedStatus,
      unit.id,
      unit.type,
      selectedPartner.id,
    );
    updateUnit({ id: unit.id, ...updatedStatus }, unit.type);
  };

  const suspend = async (unit: GenericUnit) => {
    if (!selectedPartner) return;

    const tenant = await tenantService.getTenantById(
      selectedMdu!.id,
      unit.tenant!.id,
      unit.type === UnitType.Business
        ? TenantTypes.Business
        : TenantTypes.Residence,
      selectedPartner!.id,
    );

    await tenantService.suspendTenant(
      selectedMdu!.id,
      tenant.id,
      unit.type === UnitType.Business
        ? TenantTypes.Business
        : TenantTypes.Residence,
      selectedPartner.id,
    );
  };

  const deleteAction = async (unit: GenericUnit) => {
    if (!selectedPartner) return;

    await unitsService.deleteUnit(
      unit.type,
      unit.id,
      selectedMdu!.id,
      selectedPartner.id,
    );
    removeUnit(unit.id, unit.type);
    if (unit.nodes.length) {
      setInventoryNodes((nodes) =>
        nodes.map((node) =>
          unit.nodes.some((n) => n.id === node.id)
            ? {
                ...node,
                status: NodeStatus.Unassigned,
                businessUnit: null,
                residentialUnit: null,
              }
            : node,
        ),
      );
    }
  };

  const executeAndNotify = async (
    executeFn: (unit: GenericUnit) => Promise<any>,
    unit: GenericUnit,
    action: Actions,
  ) => {
    try {
      await executeFn(unit);

      trackEvent(({
        eventName: actionToMixpanelEventMap[action].success,
        additionalContent: {
          id: unit.id,
          propertyId: selectedMdu!.id,
        },
      } as any) as TrackEventParams);

      notify({
        title: t('success'),
        body: t(actionToMixpanelEventMap[action].body),
        type: 'success',
      });
    } catch (error) {
      trackEvent(({
        eventName: actionToMixpanelEventMap[action].failure,
        additionalContent: {
          id: unit.id,
          propertyId: selectedMdu!.id,
        },
      } as any) as TrackEventParams);
    }
  };

  const unitActions: UnitAction[] = [
    {
      onClick: ({ unit }) =>
        executeAndNotify(activateUnit, unit, Actions.ActivateUnit),
      label: t('units.table.actionsDropdown.activate'),
      isVisible: ({ unit }) => unit.status === UnitStatus.Inactive,
    },
    {
      onClick: ({ unit }) => addTenant(unit),
      label: t('units.table.actionsDropdown.addTenant'),
      isVisible: ({ unit }) => unit.status === UnitStatus.Unassigned,
      isDisabled: !hasPermissionForAll([
        Permission.createBusinessTenant,
        Permission.createResidentialTenant,
      ]),
      subtexts: !hasPermissionForAll([
        Permission.createBusinessTenant,
        Permission.createResidentialTenant,
      ])
        ? [reasonIsReadOnly()]
        : undefined,
    },
    {
      onClick: ({ unit }) => setSelectedUnit(unit),
      label: t('units.table.actionsDropdown.edit'),
      isVisible: () => true,
      isDisabled: !hasPermissionForAll([
        Permission.updateBusinessUnit,
        Permission.updateResidentialUnit,
      ]),
      subtexts: !hasPermissionForAll([
        Permission.updateBusinessUnit,
        Permission.updateResidentialUnit,
      ])
        ? [reasonIsReadOnly()]
        : undefined,
    },
    {
      onClick: ({ unit }) =>
        executeAndNotify(
          retryIotPropagation,
          unit,
          Actions.RetryIotPropagation,
        ),
      label: t('units.table.actionsDropdown.retryIotPropagation'),
      isVisible: ({ unit }) =>
        hasIotNetwork &&
        unit.type === UnitType.Residence &&
        (((unit as unknown) as ResidentialUnit).iotConfiguration
          ?.propagationStatus === UnitIotPropagationStatus.Failed ||
          !_.isObject(
            ((unit as unknown) as ResidentialUnit).iotConfiguration,
          )) &&
        ((unit as unknown) as ResidentialUnit).iotConfiguration
          ?.propagationStatus !== UnitIotPropagationStatus.InProgress,
      isDisabled: !hasPermission(Permission.postSinglePropagateIotNetwork),
    },
    {
      onClick: ({ unit }) =>
        executeAndNotify(disableUnitIot, unit, Actions.DisableUnitIot),
      label: t('units.table.actionsDropdown.disableUnitIot'),
      isVisible: ({ unit }) =>
        hasIotNetwork &&
        unit.type === UnitType.Residence &&
        ((unit as unknown) as ResidentialUnit).iotConfiguration
          ?.propagationStatus !== UnitIotPropagationStatus.Failed &&
        _.isObject(((unit as unknown) as ResidentialUnit).iotConfiguration) &&
        ((unit as unknown) as ResidentialUnit).iotConfiguration
          ?.propagationStatus !== UnitIotPropagationStatus.InProgress &&
        !((unit as unknown) as ResidentialUnit).iotConfiguration
          ?.iot_network_manually_disabled,
      isDisabled: !hasPermission(Permission.deleteSinglePropagateIotNetwork),
    },
    {
      onClick: ({ unit }) =>
        executeAndNotify(retryIotPropagation, unit, Actions.EnableUnitIot),
      label: t('units.table.actionsDropdown.enableUnitIot'),
      isVisible: ({ unit }) =>
        hasIotNetwork &&
        unit.type === UnitType.Residence &&
        ((unit as unknown) as ResidentialUnit).iotConfiguration
          ?.propagationStatus !== UnitIotPropagationStatus.Failed &&
        ((unit as unknown) as ResidentialUnit).iotConfiguration
          ?.propagationStatus !== UnitIotPropagationStatus.InProgress &&
        ((unit as unknown) as ResidentialUnit).iotConfiguration
          ?.iot_network_manually_disabled === true,
      isDisabled: !hasPermission(Permission.postSinglePropagateIotNetwork),
    },
    {
      onClick: ({ unit }) =>
        executeAndNotify(toggleAvailability, unit, Actions.MakeAvailable),
      label: t('units.table.actionsDropdown.makeAvailable'),
      isVisible: ({ unit }) => unit.status === UnitStatus.Unavailable,
      isDisabled: !hasPermissionForAll([
        Permission.updateBusinessUnit,
        Permission.updateResidentialUnit,
      ]),
      subtexts: !hasPermissionForAll([
        Permission.updateBusinessUnit,
        Permission.updateResidentialUnit,
      ])
        ? [reasonIsReadOnly()]
        : undefined,
    },
    {
      onClick: ({ unit }) =>
        executeAndNotify(toggleAvailability, unit, Actions.MakeUnavailable),
      label: t('units.table.actionsDropdown.makeUnavailable'),
      isVisible: ({ unit }) => unit.status === UnitStatus.Unassigned,
      isDisabled: !hasPermissionForAll([
        Permission.updateBusinessUnit,
        Permission.updateResidentialUnit,
      ]),
      subtexts: !hasPermissionForAll([
        Permission.updateBusinessUnit,
        Permission.updateResidentialUnit,
      ])
        ? [reasonIsReadOnly()]
        : undefined,
    },
    {
      onClick: ({ unit, unitDetails }) =>
        setShowNodesModalSelectedUnit({ unit, details: unitDetails! }),
      label: t('units.actions.showNodesStatus.title'),
      isVisible: ({ unit }) => unit.nodes.length > 0,
    },
    {
      onClick: async ({ unit }) => {
        if (unit.customerId === null || unit.locationId === null) return;
        return window.open(
          generateFrontlineUrl({
            ...unit,
            customerId: unit.customerId,
            locationId: unit.locationId,
          }),
          '_blank',
        );
      },
      label: t('applications.frontline'),
      isVisible: ({ unit }) =>
        unit.customerId !== null && unit.locationId !== null,
      isDisabled: !hasPermissionForAll([
        Permission.getBusinessUnitFrontlineLink,
        Permission.getResidentialUnitFrontlineLink,
      ]),
      subtexts: !hasPermissionForAll([
        Permission.getBusinessUnitFrontlineLink,
        Permission.getResidentialUnitFrontlineLink,
      ])
        ? [reasonIsAdminsAndTechsOnly()]
        : undefined,
    },
    {
      onClick: ({ unit }) =>
        withConfirmation({
          title: t('units.actions.delete.deleteConfirmation.title'),
          body: t('units.actions.delete.deleteConfirmation.body'),
          onConfirm: () => executeAndNotify(deleteAction, unit, Actions.Delete),
        }),
      label: t('units.table.actionsDropdown.delete'),
      isVisible: ({ unit }) => unit.status !== UnitStatus.Assigned,
      isDisabled: !hasPermissionForAll([
        Permission.deleteBusinessUnit,
        Permission.deleteResidentialUnit,
      ]),
      subtexts: !hasPermissionForAll([
        Permission.deleteBusinessUnit,
        Permission.deleteResidentialUnit,
      ])
        ? [reasonIsReadOnly()]
        : undefined,
    },
    {
      onClick: ({ unit }) =>
        withConfirmation({
          title: t('units.actions.suspendTenant.suspendConfirmation.title'),
          body: t('units.actions.suspendTenant.suspendConfirmation.body'),
          onConfirm: () =>
            executeAndNotify(suspend, unit, Actions.SuspendTenant),
        }),
      label: t('units.table.actionsDropdown.suspendTenant'),
      isVisible: ({ unit }) => false, // Disable activate and suspend for now. FES-2114
    },
    {
      onClick: ({ unit }) =>
        withConfirmation({
          title: t('units.actions.deleteTenant.deleteConfirmation.title'),
          body: t('units.actions.deleteTenant.deleteConfirmation.body'),
          onConfirm: () =>
            executeAndNotify(deleteTenant, unit, Actions.DeleteTenant),
        }),
      label: t('units.table.actionsDropdown.deleteTenant'),
      isVisible: ({ unit }) => unit.status === UnitStatus.Assigned,
      isDisabled: !hasPermissionForAll([
        Permission.deleteResidentialTenant,
        Permission.deleteBusinessTenant,
      ]),
      subtexts: !hasPermissionForAll([
        Permission.deleteResidentialTenant,
        Permission.deleteBusinessTenant,
      ])
        ? [reasonIsReadOnly()]
        : undefined,
    },
  ];

  return (unit: GenericUnit, unitDetails?: UnitDetails | null) =>
    unitActions.filter((action) => action.isVisible({ unit, unitDetails }));
};

export default useUnitActions;
