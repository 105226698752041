import { InventoryNode, NodeReference } from '../types';
import DependencyContainer from '../../../DependencyContainer';
import { Unit, UnitType } from '../../units/types';
import { PropertyNetwork } from '../../property-networks/types';

export default class InventoryService {
  constructor(private readonly factory: DependencyContainer) {}

  async getAllNodes(
    mduId: string,
    partnerId: string,
  ): Promise<InventoryNode[]> {
    const response = await this.factory.inventoryClient.fetchInventoryNodes(
      mduId,
      partnerId,
    );

    let nodes = [...response.data.items];

    if (response.data.meta.totalPages > 1) {
      const additionalPageNodesResponse = await Promise.all(
        Array.from(Array(response.data.meta.totalPages - 1)).map((_, i) => {
          return this.factory.inventoryClient.fetchInventoryNodes(
            mduId,
            partnerId,
            i + 2,
          );
        }),
      );
      additionalPageNodesResponse.forEach((response) => {
        nodes = [...nodes, ...response.data.items];
      });
    }
    return nodes;
  }

  async addNode(
    mduId: string,
    newNode: NodeReference,
    partnerId: string,
  ): Promise<InventoryNode> {
    const response = await this.factory.inventoryClient.addInventoryNode(
      mduId,
      newNode,
      partnerId,
    );
    return response.data;
  }

  async deleteNode(
    mduId: string,
    node: InventoryNode,
    partnerId: string,
  ): Promise<void> {
    await this.factory.inventoryClient.deleteInventoryNode(
      mduId,
      node.id,
      partnerId,
    );
  }

  async assignNodeToUnit(
    mduId: string,
    partnerId: string,
    node: InventoryNode,
    unit: Unit,
    unitType: UnitType,
  ): Promise<Unit> {
    const strategy =
      unitType === UnitType.Business
        ? 'updateBusinessUnit'
        : 'updateResidentialUnit';

    const unitPartial = { nodes: [...unit.nodes, node] };
    const response = await this.factory.unitsClient[strategy](
      unit.id,
      mduId,
      unitPartial,
      partnerId,
    );
    return response.data;
  }

  async unassignNodeFromUnit(
    mduId: string,
    partnerId: string,
    node: InventoryNode,
    unit: Unit,
    unitType: UnitType,
  ): Promise<Unit> {
    const strategy =
      unitType === UnitType.Business
        ? 'updateBusinessUnit'
        : 'updateResidentialUnit';

    const unitPartial = { nodes: unit.nodes.filter((n) => n.id !== node.id) };
    const response = await this.factory.unitsClient[strategy](
      unit.id,
      mduId,
      unitPartial,
      partnerId,
    );
    return response.data;
  }

  async unassignNodeFromPropertyNetwork(
    mduId: string,
    partnerId: string,
    node: InventoryNode,
    propertyNetwork: PropertyNetwork,
  ): Promise<PropertyNetwork> {
    const propertyNetworkPartial = {
      nodes: propertyNetwork.nodes.filter((n) => n.id !== node.id),
    };
    const response = await this.factory.propertyNetworksClient.updatePropertyNetwork(
      mduId,
      propertyNetworkPartial,
      partnerId,
      propertyNetwork.id,
    );
    return response.data;
  }
}
