import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useRedirectToRoute } from '../../../utils/hooks/useRedirectToRoute';
import { isDevelopment, isLocal } from '../../../utils/environment';
import { Routes } from '../../../config';
import AuraGlow from '../../../components/AuraGlow/AuraGlow';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { useRecoilValue } from 'recoil';
import { selectedMDUSelector } from '../../../state';
import PMDashboardStatCard from '../components/PMDashboardStatCard/PMDashboardStatCard';
import { BarChart, Button } from 'plume-ui';
import { PMDashboardStatType } from '../types';
import { mockTickets } from '../../maintenance/maintenance.fixture';
import PMDashboardStatItem from '../components/PMDashboardStatItem/PMDashboardStatItem';
import { mockLeases } from '../../lease/lease.fixture';

const PMDashboard = () => {
  const { t } = useTranslation();
  const redirectToRoute = useRedirectToRoute();

  const selectedProperty = useRecoilValue(selectedMDUSelector);

  if (!isLocal() && !isDevelopment()) {
    redirectToRoute(Routes.Index);
  }

  return (
    <>
      <Helmet>
        <title>{t('pmDashboard.title')}</title>
      </Helmet>
      <div className="PMDashboard p-xl">
        <AuraGlow header footer />
        <PageHeading
          title="pmDashboard.title"
          tooltip="pmDashboard.tooltip"
          additionalTitle={selectedProperty?.name}
        />
        <div className="PMDashboard__content">
          <div className="PMDashboard__chartCard">
            <BarChart data={undefined} groups={[]} />
          </div>
          <div className="PMDashboard__stats">
            <PMDashboardStatCard stat={0} type={PMDashboardStatType.Payments} />
            <PMDashboardStatCard
              stat={mockLeases.length}
              type={PMDashboardStatType.Leases}
            >
              {mockLeases.slice(0, 5).map((lease) => (
                <PMDashboardStatItem
                  key={lease.id}
                  labelRenderer={() => (
                    <div className="PMDashboard__expiringLease">
                      {lease.expiringAt.toLocaleDateString()}
                    </div>
                  )}
                  onViewClick={() => {}}
                  bodyLabel={lease.unitId}
                  title={lease.title}
                  type={PMDashboardStatType.Leases}
                />
              ))}
              {mockLeases.length > 5 && (
                <Button styleVariant="tertiary">
                  {t('viewAll', { count: mockLeases.length })}
                </Button>
              )}
            </PMDashboardStatCard>
            <PMDashboardStatCard
              stat={mockTickets.length}
              type={PMDashboardStatType.Tickets}
            >
              {mockTickets.slice(0, 5).map((ticket) => (
                <PMDashboardStatItem
                  key={ticket.id}
                  bodyLabel={ticket.unitId}
                  labelRenderer={() => {
                    if (ticket.scheduledMaintenanceDate) {
                      return (
                        <div className="PMDashboard__scheduledTicket">
                          {ticket.scheduledMaintenanceDate.toLocaleDateString()}
                        </div>
                      );
                    }
                    return (
                      <Button styleVariant="tertiary">
                        {t('pmDashboard.schedule')}
                      </Button>
                    );
                  }}
                  onViewClick={() => {}}
                  title={ticket.title}
                  type={PMDashboardStatType.Tickets}
                />
              ))}
              {mockTickets.length > 5 && (
                <Button styleVariant="tertiary">
                  {t('viewAll', { count: mockTickets.length })}
                </Button>
              )}
            </PMDashboardStatCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(PMDashboard);
