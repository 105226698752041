import { AxiosResponse } from 'axios';
import { HttpClient } from '../../../HttpClient';
import { UpriseApiUrls } from '../../../urls';
import { GeoCode, GeoCodeRequest } from '../../editorView/types';

export default class GeolocationClient extends HttpClient {
  async lookupGeocode(
    request: GeoCodeRequest,
  ): Promise<AxiosResponse<GeoCode[]>> {
    const response = await this.post<AxiosResponse<GeoCode[]>>(
      UpriseApiUrls.getGeocodeUrl(),
      request,
    );
    return response;
  }
}
