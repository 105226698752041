import { NodeStatus } from '../types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import React from 'react';

const mapStatusToMessageId = {
  [NodeStatus.Assigned]: 'inventory.status.assigned',
  [NodeStatus.Unassigned]: 'inventory.status.unassigned',
  [NodeStatus.Decommissioned]: 'inventory.status.decomissioned',
};

export type UseInventoryMappingsValues = {
  renderNodeSerialNumber: (name: string, realized: boolean) => JSX.Element;
  renderNodeStatus: (status: NodeStatus) => string;
};

const useInventoryMappings = (): UseInventoryMappingsValues => {
  const { t } = useTranslation();

  const renderNodeSerialNumber = (name: string, realized: boolean) => {
    const classNames = cx({
      unrealized: !realized,
    });
    return <span className={classNames}>{name}</span>;
  };

  const renderNodeStatus = (status: NodeStatus) => {
    const messageId = mapStatusToMessageId[status];
    return t(messageId);
  };

  return {
    renderNodeSerialNumber,
    renderNodeStatus,
  };
};
export default useInventoryMappings;
