import { Field, Form, Formik } from 'formik';
import { Button, Modal, ModalBody, ModalFooter, notify } from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as yup from 'yup';
import DependencyContainer from '../../../../DependencyContainer';
import DropdownInput from '../../../../components/DropdownInput/DropdownInput';
import InputField from '../../../../components/InputField/InputField';
import ModalHeaderWithProperty from '../../../../components/ModalHeaderWithProperty/ModalHeaderWithProperty';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';
import { MixPanelEvents } from '../../../../mixPanelEvents';
import {
  selectedMDUSelector,
  selectedPartnerSelector,
  userInfoAtom,
} from '../../../../state';
import { FormField, ModalType, Permission } from '../../../../types';
import { prepareErrorMessageForInput } from '../../../../utils/prepareErrorMessageForInput';
import validateSsid from '../../../../utils/validateSsid';
import validateWifiPassword from '../../../../utils/validateWifiPassword';
import PropertyNetworkPasswordField from '../../../property-networks/components/PropertyNetworkPasswordField';
import { propertyNetworksAtom } from '../../../property-networks/propertyNetworksState';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { iotModalStateAtom, iotNetworkAtom } from '../../state';
import {
  SecurityPolicyProtection,
  SecurityPolicyWhitelist,
  UpdateIotNetworkDTO,
} from '../../types';
import { defaultSecurityPolicy } from '../../util';
import SecurityPolicy from '../SecurityPolicy/SecurityPolicy';

const { iotService } = new DependencyContainer();

type IotModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
};

export type IotFormValues = {
  ssid: string;
  password?: string | null;
  propertyNetwork: string | null;
  vlanId: string | null;
  [SecurityPolicyProtection.OnlineProtection]: boolean;
  [SecurityPolicyProtection.IotProtection]: boolean;
  [SecurityPolicyProtection.RemoteAccessProtection]: boolean;
  [SecurityPolicyWhitelist.Fqdn]: string[];
  [SecurityPolicyWhitelist.IPv4]: string[];
  [SecurityPolicyWhitelist.IPv6]: string[];
};

const IotModal = ({ isOpen, onRequestClose }: IotModalProps) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const userInfo = useRecoilValue(userInfoAtom);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const selectedMdu = useRecoilValue(selectedMDUSelector);
  const iotModalState = useRecoilValue(iotModalStateAtom);
  const [propertyNetworks, setPropertyNetworks] = useRecoilState(
    propertyNetworksAtom,
  );
  const [iotNetwork, setIotNetwork] = useRecoilState(iotNetworkAtom);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const getInitialSecurityPolicy = () => {
    const securityPolicy = iotNetwork?.iotSecurityPolicy;
    return {
      [SecurityPolicyProtection.OnlineProtection]:
        securityPolicy?.spamPhisProtectionEnabled !== undefined
          ? securityPolicy?.spamPhisProtectionEnabled
          : defaultSecurityPolicy.spamPhisProtectionEnabled,
      [SecurityPolicyProtection.IotProtection]:
        securityPolicy?.iotProtectionEnabled !== undefined
          ? securityPolicy?.iotProtectionEnabled
          : defaultSecurityPolicy.iotProtectionEnabled,
      [SecurityPolicyProtection.RemoteAccessProtection]:
        securityPolicy?.remoteAccessProtectionEnabled !== undefined
          ? securityPolicy?.remoteAccessProtectionEnabled
          : defaultSecurityPolicy.remoteAccessProtectionEnabled,
      [SecurityPolicyWhitelist.Fqdn]:
        securityPolicy?.whitelists.fqdn ||
        defaultSecurityPolicy.whitelists.fqdn,
      [SecurityPolicyWhitelist.IPv4]:
        securityPolicy?.whitelists.ipv4 ||
        defaultSecurityPolicy.whitelists.ipv4,
      [SecurityPolicyWhitelist.IPv6]:
        securityPolicy?.whitelists.ipv6 ||
        defaultSecurityPolicy.whitelists.ipv6,
    };
  };

  const getFormInitialValues = (): IotFormValues => ({
    ssid: iotNetwork?.ssid || '',
    password: iotNetwork?.password,
    propertyNetwork:
      propertyNetworks.find(
        (propertyNetwork) =>
          propertyNetwork.iotWifi?.ssid &&
          propertyNetwork.iotWifi?.ssid === iotNetwork?.ssid,
      )?.id || null,
    vlanId: iotNetwork?.vlanConfig.vlanId.toString() || null,
    ...getInitialSecurityPolicy(),
  });

  const getValidationSchema = () => {
    return yup.object().shape({
      ssid: validateSsid({
        requiredMessage: t('iot.actions.add.form.ssid.required'),
        minLengthMessage: t('formValidation.ssid.length'),
        maxLengthMessage: t('formValidation.ssid.length'),
        specialCharacters: t('formValidation.ssid.special'),
      }),
      password: validateWifiPassword({
        requiredMessage: t('iot.actions.add.form.password.required'),
        minLengthMessage: t('formValidation.password.min'),
        maxLengthMessage: t('formValidation.password.max'),
        specialCharacters: t('formValidation.password.special'),
        isRequired:
          iotModalState.type === ModalType.Create ||
          userInfo?.permissions.includes(Permission.getPropertyWifiPassword),
      }),
      propertyNetwork: yup
        .string()
        .nullable(true)
        .required(t('iot.actions.add.form.propertyNetwork.required')),
      vlanId: yup
        .number()
        .nullable(true)
        .positive(t('formValidation.positiveNumber'))
        .max(4095, t('formValidation.maxNumber', { value: 4095 }))
        .required(t('iot.actions.add.form.vlanId.required')),
    });
  };

  const onSubmit = async (values: IotFormValues) => {
    if (submitInProgress) return;
    setSubmitInProgress(true);
    if (iotModalState.type === ModalType.Create) {
      onCreate(values);
      return;
    }
    if (iotModalState.type === ModalType.Edit) {
      onEdit(values);
      return;
    }
    setSubmitInProgress(false);
  };

  const onCreate = async (values: IotFormValues) => {
    if (!values.propertyNetwork) return;
    if (!selectedMdu) return;
    if (!selectedPartner) return;
    try {
      const iotNetwork = await iotService.createIotNetwork(
        values.propertyNetwork,
        selectedMdu.id,
        selectedPartner.id,
        {
          ssid: values.ssid,
          password: values.password || '',
          vlanConfig: {
            vlanId: parseInt(values.vlanId!),
          },
          iotSecurityPolicy: {
            spamPhisProtectionEnabled:
              values[SecurityPolicyProtection.OnlineProtection],
            iotProtectionEnabled:
              values[SecurityPolicyProtection.IotProtection],
            remoteAccessProtectionEnabled:
              values[SecurityPolicyProtection.RemoteAccessProtection],
            whitelists: {
              fqdn: values[SecurityPolicyWhitelist.Fqdn],
              ipv4: values[SecurityPolicyWhitelist.IPv4],
              ipv6: values[SecurityPolicyWhitelist.IPv6],
            },
          },
        },
      );

      setPropertyNetworks((propertyNetworks) =>
        propertyNetworks.map((pn) => {
          if (pn.id === values.propertyNetwork) {
            return { ...pn, iotWifi: { ...iotNetwork } };
          }
          return pn;
        }),
      );

      setIotNetwork({
        ...iotNetwork,
        propertyNetworkId: values.propertyNetwork,
      });

      onRequestClose();
      notify({
        title: t('success'),
        body: t('iot.actions.add.iotNetworkAdded'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.ADD_IOT_NETWORK,
        additionalContent: {
          propertyNetworkId: values.propertyNetwork,
          propertyId: selectedMdu!.id,
        },
      });
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.ADD_IOT_NETWORK_FAILURE,
        additionalContent: {
          propertyNetworkId: values.propertyNetwork,
          propertyId: selectedMdu!.id,
        },
      });
    } finally {
      setSubmitInProgress(false);
    }
  };

  const onEdit = async (values: IotFormValues) => {
    if (!values.propertyNetwork) return;
    if (!selectedMdu) return;
    if (!selectedPartner) return;
    try {
      const updateIotNetworkDto: UpdateIotNetworkDTO = {
        ssid: values.ssid?.trim(),
        password: values.password?.trim(),
        vlanConfig: {
          vlanId: parseInt(values.vlanId!),
        },
        iotSecurityPolicy: {
          spamPhisProtectionEnabled:
            values[SecurityPolicyProtection.OnlineProtection],
          iotProtectionEnabled: values[SecurityPolicyProtection.IotProtection],
          remoteAccessProtectionEnabled:
            values[SecurityPolicyProtection.RemoteAccessProtection],
          whitelists: {
            fqdn: values[SecurityPolicyWhitelist.Fqdn],
            ipv4: values[SecurityPolicyWhitelist.IPv4],
            ipv6: values[SecurityPolicyWhitelist.IPv6],
          },
        },
      };
      const iotNetwork = await iotService.updateIotNetwork(
        values.propertyNetwork,
        selectedMdu.id,
        selectedPartner.id,
        updateIotNetworkDto,
      );
      setIotNetwork({
        ...iotNetwork,
        propertyNetworkId: values.propertyNetwork,
      });
      onRequestClose();
      notify({
        title: t('success'),
        body: t('iot.actions.edit.iotNetworkEdited'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.ADD_IOT_NETWORK,
        additionalContent: {
          propertyNetworkId: values.propertyNetwork,
          propertyId: selectedMdu!.id,
        },
      });
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.ADD_IOT_NETWORK_FAILURE,
        additionalContent: {
          propertyNetworkId: values.propertyNetwork,
          propertyId: selectedMdu!.id,
        },
      });
    } finally {
      setSubmitInProgress(false);
    }
  };

  const formInputFields: Array<FormField<IotFormValues>> = [
    {
      name: 'ssid',
      labelId: 'iot.actions.add.form.ssid.label',
      type: 'input',
    },
    {
      name: 'password',
      labelId: 'iot.actions.add.form.password.label',
      type: 'password',
    },
    {
      name: 'propertyNetwork',
      labelId: 'iot.actions.add.form.propertyNetwork.label',
      wrapLabel: 'iot.actions.add.form.propertyNetwork.wrapLabel',
      type: 'dropdown',
    },
    {
      name: 'vlanId',
      labelId: 'iot.actions.add.form.vlanId.label',
      type: 'number',
    },
  ];

  const getPropertyNetworkDropdown = (field: any, values: any, errors: any) => {
    const propertyNetworkOptions = propertyNetworks.map((propertyNetwork) => ({
      value: propertyNetwork.id,
      label: propertyNetwork.name,
    }));
    return (
      <Field
        key={field.name}
        name={field.name}
        component={DropdownInput}
        defaultLabel={t(field.labelId)}
        label={t(field.labelId)}
        messages={prepareErrorMessageForInput(field.name, errors)}
        noClearIcon
        noItemsWarning={t('iot.actions.add.noPropertyNetworks')}
        openInPortal
        options={propertyNetworkOptions}
        value={values[field.name]}
        wrapLabel={t(field.wrapLabel)}
        disabled={iotModalState.type === ModalType.Edit}
      />
    );
  };

  if (!selectedMdu || !isOpen) {
    return null;
  }

  return (
    <Formik
      initialValues={getFormInitialValues()}
      validationSchema={getValidationSchema()}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        dirty,
        submitForm,
        setFieldValue,
        setFieldError,
      }) => (
        <Form>
          <Modal
            appElement={document.getElementById('root') as HTMLElement}
            isOpen={isOpen}
            onRequestClose={() => onRequestClose()}
            classes={(current: ModalStyles) => ({
              ...current,
              root: `${current.root} IotModal`,
            })}
          >
            <ModalHeaderWithProperty
              propertyName={selectedMdu.name}
              title={
                iotModalState.type === ModalType.Create
                  ? t('iot.actions.add.modalTitle')
                  : t('iot.actions.edit.modalTitle')
              }
            />
            <ModalBody>
              <div className="IotModal__inputs">
                {formInputFields.map((field) => {
                  if (field.type === 'dropdown') {
                    return getPropertyNetworkDropdown(field, values, errors);
                  }
                  if (
                    field.type === 'password' &&
                    iotModalState.type === ModalType.Edit &&
                    !userInfo?.permissions.includes(
                      Permission.getIotNetworkPassword,
                    )
                  ) {
                    return (
                      <PropertyNetworkPasswordField
                        key={field.name}
                        name={field.name}
                        messages={prepareErrorMessageForInput(
                          field.name,
                          errors,
                        )}
                        label={t(field.labelId)}
                        value={values[field.name]}
                        setFieldValue={setFieldValue}
                      />
                    );
                  }
                  return (
                    <Field
                      key={field.name}
                      name={field.name}
                      id={field.name}
                      component={InputField}
                      requiredAsterix
                      value={values[field.name]}
                      messages={prepareErrorMessageForInput(field.name, errors)}
                      label={t(field.labelId)}
                      type={field.type !== 'input' && field.type}
                    />
                  );
                })}
              </div>
              <SecurityPolicy
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                styleVariant="tertiary-grey"
                onClick={() => onRequestClose()}
              >
                {t('cancel')}
              </Button>
              <SubmitButton
                disabled={!dirty}
                submitInProgress={submitInProgress}
                onSubmit={submitForm}
              />
            </ModalFooter>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default memo(IotModal);
