import { Field } from 'formik';
import { Button, InputField } from 'plume-ui';
import { InputMessageStatus } from 'plume-ui/dist/components/InputField/InputField';
import { ChangeEvent, memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type PropertyNetworkPasswordFieldProps = {
  name: string;
  value: string | number | boolean | string[] | null | undefined;
  label: string;
  messages:
    | {
        status: InputMessageStatus;
        message: string;
      }[]
    | undefined;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
};

const PropertyNetworkPasswordField = ({
  name,
  value,
  label,
  messages,
  setFieldValue,
}: PropertyNetworkPasswordFieldProps) => {
  const { t } = useTranslation();
  const [changingPassword, setChangingPassword] = useState(false);
  const [password, setPassword] = useState(value);
  const passwordRef = useRef(value);

  return (
    <div className="ManagePropertyNetworkModal__passwordField">
      <Field
        name={name}
        component={() => (
          <InputField
            disabled={!changingPassword}
            onInput={(e: ChangeEvent<HTMLInputElement>) => {
              if (!changingPassword) return;
              passwordRef.current = e.target.value;
            }}
            label={label}
            messages={messages}
            name={name}
            type="password"
            value={password as any}
          />
        )}
      ></Field>
      <div className="ManagePropertyNetworkModal__passwordField__buttons">
        {!changingPassword && (
          <Button
            styleVariant="tertiary"
            onClick={() => {
              passwordRef.current = value;
              setChangingPassword(true);
            }}
          >
            {t(
              'propertyNetworks.actions.create.form.propertyPassword.buttonLabel',
            )}
          </Button>
        )}
        {changingPassword && (
          <div className="ManagePropertyNetworkModal__passwordField__buttons--confirmation">
            <Button
              styleVariant="tertiary"
              onClick={() => setChangingPassword(false)}
            >
              {t('cancel')}
            </Button>
            <Button
              styleVariant="tertiary"
              onClick={() => {
                const password =
                  passwordRef.current === '' ? undefined : passwordRef.current;
                setPassword(password);
                setFieldValue(name, password);
                setChangingPassword(false);
              }}
            >
              {t('submit')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(PropertyNetworkPasswordField);
