const floridaPolygon = [
  {
    lat: 30.470584026701754,
    lng: -86.35367711695933,
  },
  {
    lat: 30.129104649594098,
    lng: -85.67252477320933,
  },
  {
    lat: 29.633766333543058,
    lng: -84.94742711695933,
  },
  {
    lat: 30.062567991573733,
    lng: -84.13443883570933,
  },
  {
    lat: 29.441673380532862,
    lng: -83.14146360072398,
  },
  {
    lat: 28.739922655174762,
    lng: -82.67583211573863,
  },
  {
    lat: 27.867506844389975,
    lng: -82.82122930201793,
  },
  {
    lat: 26.84897261365987,
    lng: -82.27706273707652,
  },
  {
    lat: 25.28212083214716,
    lng: -81.07886632594371,
  },
  {
    lat: 25.246881017407752,
    lng: -80.35239537867808,
  },
  {
    lat: 26.756658357685133,
    lng: -80.02005895289683,
  },
  {
    lat: 28.48741272775595,
    lng: -80.69571813258433,
  },
  {
    lat: 29.561523445327715,
    lng: -81.16263707789683,
  },
  {
    lat: 30.52974730072656,
    lng: -81.43180211695933,
  },
  {
    lat: 30.560497976036068,
    lng: -84.42008336695933,
  },
];

const floridaBounds = {
  southWest: {
    lat: 25.246881017407752,
    lng: -86.35367711695933,
  },
  northEast: {
    lat: 30.560497976036068,
    lng: -80.02005895289683,
  },
};

const getRandom = (
  minInclusive: number,
  maxExclusive: number,
  seed: number,
) => {
  const x = Math.sin(seed) * 10000;
  const random = x - Math.floor(x);
  return random * (maxExclusive - minInclusive) + minInclusive;
};

const isInsideFlorida = (x: number, y: number) => {
  var inside = false;
  for (
    var i = 0, j = floridaPolygon.length - 1;
    i < floridaPolygon.length;
    j = i++
  ) {
    var xi = floridaPolygon[i].lat,
      yi = floridaPolygon[i].lng;
    var xj = floridaPolygon[j].lat,
      yj = floridaPolygon[j].lng;

    var intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};

export const randomFloridaLocation = (lat: number, lng: number) => {
  const top = floridaBounds.northEast;
  const bottom = floridaBounds.southWest;

  const height = top.lat - bottom.lat;
  const width = top.lng - bottom.lng;

  let x = lat || 0;
  let y = lng || 0;
  let i = 0;

  while (true) {
    x = getRandom(top.lat, top.lat - width, Math.abs(x * 1000));
    y = getRandom(top.lng, top.lng - height, Math.abs(y * 1000));
    if (isInsideFlorida(x, y)) break;
    if (i >= 30) break;
    i += 1;
  }

  if (i === 30) return { lat: lat, long: lng };

  return { lat: x, long: y };
};
