import { useTranslation } from 'react-i18next';

const useChartGroups = () => {
  const { t } = useTranslation();
  return [
    {
      label: t('overview.chart.max'),
      backgroundColor: {
        from: 'rgba(87,91,207,1)', // #575bcf
        to: 'rgba(87,91,207,0)',
      },
      stroke: '#575bcf',
      strokeWidth: 2,
      key: 'max_download_speed',
    },
    {
      label: t('overview.chart.averageDailySpeed'),
      backgroundColor: {
        from: 'rgba(169,122,255,1)', // #a97ae1
        to: 'rgba(169,122,255,0)',
      },
      stroke: '#999eff',
      strokeWidth: 2,
      key: 'avg_download_speed',
    },
    {
      label: t('overview.chart.min'),
      backgroundColor: {
        from: 'rgba(229,231,255,1)', // #e5e7ff
        to: 'rgba(229,231,255,0)',
      },
      stroke: '#e5e7ff',
      strokeWidth: 2,
      key: 'min_download_speed',
    },
  ];
};

export default useChartGroups;
