import DependencyContainer from '../DependencyContainer';

export default class VersionService {
  requestMade = false;
  constructor(private readonly factory: DependencyContainer) {}

  async getVersion(): Promise<string | void> {
    if (this.requestMade) {
      return;
    }
    this.requestMade = true;
    const { data } = await this.factory.versionClient.getApiVersion();
    return data.version;
  }
}
