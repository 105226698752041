import _ from 'lodash';
import { notify } from 'plume-ui';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import DependencyContainer from '../../../DependencyContainer';
import { useFrontlineUrl } from '../../../hooks/useFrontlineUrl';
import useWithConfirmation from '../../../hooks/useWithConfirmation';
import { MixPanelEvents } from '../../../mixPanelEvents';
import {
  relocateTenantModalStateAtom,
  selectedMDUSelector,
  selectedPartnerSelector,
  userInfoAtom,
} from '../../../state';
import { ModalState, Permission } from '../../../types';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { TrackEventParams } from '../../trackingAnalytics/types';
import { useUnitsUpdateActions } from '../../units/hooks/useUnitsUpdateActions';
import { UnitStatus, UnitType } from '../../units/types';
import {
  businessTenantsAtom,
  residentialTenantsAtom,
  selectedTenantToEditAtom,
  selectedTenantToRelocateAtom,
} from '../tenantsState';
import {
  BusinessTenant,
  ResidentialTenant,
  Tenant,
  TenantStatus,
  TenantTypes,
} from '../types';

export type TenantAction = {
  onClick: (tenant: Tenant) => void;
  label: string;
  isVisible: (tenant: Tenant) => boolean; // determines whether to show action or not
  isDisabled?: boolean; // determines whether to disable an action or not
  subtexts?: string[];
};

enum Actions {
  Invite,
  Reinvite,
  Activate,
  Suspend,
  Relocate,
  Delete,
  Exit,
}

const actionToMixpanelEventMap: Record<
  Actions,
  {
    success: MixPanelEvents;
    failure: MixPanelEvents;
    body: string;
  }
> = {
  [Actions.Invite]: {
    success: MixPanelEvents.INVITE_TENANT_SUCCESS,
    failure: MixPanelEvents.INVITE_TENANT_FAILURE,
    body: 'tenants.actions.invite.tenantInvited',
  },
  [Actions.Reinvite]: {
    success: MixPanelEvents.REINVITE_TENANT_SUCCESS,
    failure: MixPanelEvents.REINVITE_TENANT_FAILURE,
    body: 'tenants.actions.reinvite.tenantReinvited',
  },
  [Actions.Activate]: {
    success: MixPanelEvents.ACTIVATE_TENANT_SUCCESS,
    failure: MixPanelEvents.ACTIVATE_TENANT_FAILURE,
    body: 'tenants.actions.activate.tenantActivated',
  },
  [Actions.Suspend]: {
    success: MixPanelEvents.SUSPEND_TENANT_SUCCESS,
    failure: MixPanelEvents.SUSPEND_TENANT_FAILURE,
    body: 'tenants.actions.suspend.tenantSuspended',
  },
  [Actions.Relocate]: {
    success: MixPanelEvents.RELOCATE_TENANT_SUCCESS,
    failure: MixPanelEvents.RELOCATE_TENANT_FAILURE,
    body: 'tenants.actions.relocate.tenantRelocated',
  },
  [Actions.Delete]: {
    success: MixPanelEvents.DELETE_TENANT_SUCCESS,
    failure: MixPanelEvents.DELETE_TENANT_FAILURE,
    body: 'tenants.actions.delete.tenantDeleted',
  },
  [Actions.Exit]: {
    success: MixPanelEvents.EXIT_TENANT_SUCCESS,
    failure: MixPanelEvents.EXIT_TENANT_FAILURE,
    body: 'tenants.actions.exit.tenantExited',
  },
};

const useTenantActions = (): ((tenant: Tenant) => TenantAction[]) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const userInfo = useRecoilValue(userInfoAtom);
  const selectedMdu = useRecoilValue(selectedMDUSelector);
  const setSelectedTenantToEdit = useSetRecoilState(selectedTenantToEditAtom);
  const setSelectedTenantToRelocate = useSetRecoilState(
    selectedTenantToRelocateAtom,
  );
  const [
    relocateTenantModalState,
    setRelocateTenantModalState,
  ] = useRecoilState(relocateTenantModalStateAtom);
  const withConfirmation = useWithConfirmation();
  const setResidentialTenants = useSetRecoilState(residentialTenantsAtom);
  const setBusinessTenants = useSetRecoilState(businessTenantsAtom);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const { tenantService } = new DependencyContainer();
  const { updateUnit } = useUnitsUpdateActions();
  const { generateFrontlineUrl } = useFrontlineUrl();

  useEffect(() => {
    if (relocateTenantModalState === ModalState.Success) {
      setRelocateTenantModalState(ModalState.Dismissed);
      setSelectedTenantToRelocate(null);
    }

    if (relocateTenantModalState === ModalState.Dismissed) {
      setSelectedTenantToRelocate(null);
    }
  }, [relocateTenantModalState]);

  const updateTenantList = (
    tenant: BusinessTenant | ResidentialTenant,
    type: TenantTypes,
  ) => {
    if (type === TenantTypes.Residence) {
      setResidentialTenants((currentTenants) =>
        currentTenants.map((t) =>
          t.id !== tenant.id ? t : (tenant as ResidentialTenant),
        ),
      );
    } else {
      setBusinessTenants((currentTenants) =>
        currentTenants.map((t) =>
          t.id !== tenant.id ? t : (tenant as BusinessTenant),
        ),
      );
    }
  };

  const invite = async (tenant: Tenant) => {
    if (!selectedPartner) {
      return;
    }
    const updatedTenant = await tenantService.inviteTenant(
      selectedMdu!.id,
      tenant.id,
      tenant.type,
      selectedPartner.id,
    );

    updateTenantList(updatedTenant, tenant.type);
  };

  const reinvite = async (tenant: Tenant) => {
    if (!selectedPartner) {
      return;
    }
    await tenantService.reinviteTenant(
      selectedMdu!.id,
      tenant.id,
      tenant.type,
      selectedPartner.id,
    );
  };

  const suspend = async (tenant: Tenant) => {
    if (!selectedPartner) {
      return;
    }
    const updatedTenant = await tenantService.suspendTenant(
      selectedMdu!.id,
      tenant.id,
      tenant.type,
      selectedPartner.id,
    );
    updateTenantList(updatedTenant, tenant.type);
  };

  const activate = async (tenant: Tenant) => {
    if (!selectedPartner) {
      return;
    }
    const updatedTenant = await tenantService.activateTenant(
      selectedMdu!.id,
      tenant.id,
      tenant.type,
      selectedPartner.id,
    );

    updateTenantList(updatedTenant, tenant.type);
  };

  const deleteTenant = async (tenant: Tenant) => {
    if (!selectedPartner) {
      return;
    }
    await tenantService.deleteTenant(
      selectedMdu!.id,
      tenant.id,
      tenant.type,
      selectedPartner.id,
    );
    if (tenant.type === TenantTypes.Residence) {
      setResidentialTenants((currentTenants) =>
        currentTenants.filter((t) => t.id !== tenant.id),
      );
    } else {
      setBusinessTenants((currentTenants) =>
        currentTenants.filter((t) => t.id !== tenant.id),
      );
    }

    updateUnit(
      { id: tenant.unit?.id, status: UnitStatus.Unassigned },
      tenant.type === TenantTypes.Residence
        ? UnitType.Residence
        : UnitType.Business,
    );
  };

  const exitTenant = async (tenant: Tenant) => {
    if (!selectedPartner) {
      return;
    }
    await tenantService.exitResidentialTenant(
      selectedMdu!.id,
      tenant.id,
      selectedPartner.id,
    );
    setResidentialTenants((currentTenants) =>
      currentTenants.filter((t) => t.id !== tenant.id),
    );
    updateUnit(
      { id: tenant.unit?.id, status: UnitStatus.Unassigned },
      UnitType.Residence,
    );
  };

  const executeAndNotify = async (
    executeFn: (tenant: Tenant) => Promise<any>,
    tenant: Tenant,
    action: Actions,
  ) => {
    try {
      await executeFn(tenant);

      trackEvent(({
        eventName: actionToMixpanelEventMap[action].success,
        additionalContent: {
          unitId: tenant.unit?.id,
          propertyId: selectedMdu!.id,
          tenantId: tenant.id,
        },
      } as any) as TrackEventParams);

      notify({
        title: t('success'),
        body: t(actionToMixpanelEventMap[action].body),
        type: 'success',
      });
    } catch (error) {
      trackEvent(({
        eventName: actionToMixpanelEventMap[action].failure,
        additionalContent: {
          unitId: tenant.unit?.id,
          propertyId: selectedMdu!.id,
          tenantId: tenant.id,
        },
      } as any) as TrackEventParams);
    }
  };

  const relocateTenant = (tenant: Tenant) => {
    setSelectedTenantToRelocate(tenant);
    setRelocateTenantModalState(ModalState.Open);
  };

  const hasPermissionForAll = (permissions: Permission[]) => {
    return permissions.every((p) => userInfo?.permissions.includes(p));
  };

  const reasonIsAdminsAndTechsOnly = () => {
    return t('tenants.onlyAdminsAndTechsAreAllowed');
  };

  const reasonIsReadOnly = () => {
    return t('tenants.readOnlyIsNotAllowed');
  };

  const availableTenantActions: TenantAction[] = [
    {
      onClick: (tenant: Tenant) => setSelectedTenantToEdit(tenant),
      label: t('tenants.table.actionsDropdown.edit'),
      isVisible: () => true,
      isDisabled: !hasPermissionForAll([
        Permission.updateBusinessTenant,
        Permission.updateResidentialTenant,
      ]),
      subtexts: !hasPermissionForAll([
        Permission.updateBusinessTenant,
        Permission.updateResidentialTenant,
      ])
        ? [reasonIsReadOnly()]
        : undefined,
    },
    {
      onClick: (tenant: Tenant) =>
        withConfirmation({
          title: t('tenants.actions.invite.inviteConfirmation.title'),
          body: t('tenants.actions.invite.inviteConfirmation.body'),
          onConfirm: () => executeAndNotify(invite, tenant, Actions.Invite),
        }),
      label: t('tenants.table.actionsDropdown.invite'),
      isVisible: (tenant: Tenant) => tenant.status === TenantStatus.Assigned,
      isDisabled: !hasPermissionForAll([
        Permission.updateBusinessTenant,
        Permission.updateResidentialTenant,
      ]),
      subtexts: !hasPermissionForAll([
        Permission.updateBusinessTenant,
        Permission.updateResidentialTenant,
      ])
        ? [reasonIsReadOnly()]
        : undefined,
    },
    {
      onClick: (tenant: Tenant) =>
        withConfirmation({
          title: t('tenants.actions.reinvite.reinviteConfirmation.title'),
          body: t('tenants.actions.reinvite.reinviteConfirmation.body'),
          onConfirm: () => executeAndNotify(reinvite, tenant, Actions.Reinvite),
        }),
      label: t('tenants.table.actionsDropdown.reinvite'),
      isVisible: (tenant: Tenant) => tenant.status === TenantStatus.Active,
      isDisabled: !hasPermissionForAll([
        Permission.updateBusinessTenant,
        Permission.updateResidentialTenant,
      ]),
      subtexts: !hasPermissionForAll([
        Permission.updateBusinessTenant,
        Permission.updateResidentialTenant,
      ])
        ? [reasonIsReadOnly()]
        : undefined,
    },
    {
      onClick: (tenant: Tenant) => relocateTenant(tenant),
      label: t('tenants.table.actionsDropdown.relocate'),
      isVisible: () => true,
    },
    {
      onClick: (tenant: Tenant) =>
        withConfirmation({
          title: t('tenants.actions.suspend.suspendConfirmation.title'),
          body: t('tenants.actions.suspend.suspendConfirmation.body'),
          onConfirm: () => executeAndNotify(suspend, tenant, Actions.Suspend),
        }),
      label: t('tenants.table.actionsDropdown.suspend'),
      isVisible: (tenant: Tenant) => false, // Disable activate and suspend for now. FES-2114
      isDisabled: !hasPermissionForAll([
        Permission.updateBusinessTenant,
        Permission.updateResidentialTenant,
      ]),
      subtexts: !hasPermissionForAll([
        Permission.updateBusinessTenant,
        Permission.updateResidentialTenant,
      ])
        ? [reasonIsReadOnly()]
        : undefined,
    },
    {
      onClick: (tenant: Tenant) =>
        withConfirmation({
          title: t('tenants.actions.activate.activateConfirmation.title'),
          body: t('tenants.actions.activate.activateConfirmation.body'),
          onConfirm: () => executeAndNotify(activate, tenant, Actions.Activate),
        }),
      label: t('tenants.table.actionsDropdown.activate'),
      isVisible: (tenant: Tenant) => tenant.status === TenantStatus.Suspended,
      isDisabled: !hasPermissionForAll([
        Permission.updateBusinessTenant,
        Permission.updateResidentialTenant,
      ]),
      subtexts: !hasPermissionForAll([
        Permission.updateBusinessTenant,
        Permission.updateResidentialTenant,
      ])
        ? [reasonIsReadOnly()]
        : undefined,
    },
    {
      onClick: (tenant: Tenant) => {
        if (tenant.customerId === null || tenant.locationId === null) return;
        window.open(
          generateFrontlineUrl({
            ...tenant,
            customerId: tenant.customerId,
            locationId: tenant.locationId,
          }),
          '_blank',
        );
      },
      label: t('applications.frontline'),
      isVisible: (tenant: Tenant) =>
        tenant.customerId !== null && tenant.locationId !== null,
      isDisabled: !hasPermissionForAll([
        Permission.getBusinessUnitFrontlineLink,
        Permission.getResidentialUnitFrontlineLink,
      ]),
      subtexts: !hasPermissionForAll([
        Permission.getBusinessUnitFrontlineLink,
        Permission.getResidentialUnitFrontlineLink,
      ])
        ? [reasonIsAdminsAndTechsOnly()]
        : undefined,
    },
    {
      onClick: (tenant: Tenant) =>
        withConfirmation({
          title: t('tenants.actions.delete.deleteConfirmation.title'),
          body: t('tenants.actions.delete.deleteConfirmation.body', {
            accountType:
              tenant.type === TenantTypes.Residence ? 'HomePass' : 'WorkPass',
          }),
          onConfirm: () =>
            executeAndNotify(deleteTenant, tenant, Actions.Delete),
        }),
      label: t('tenants.table.actionsDropdown.delete'),
      isVisible: () => true,
      isDisabled: !hasPermissionForAll([
        Permission.deleteBusinessTenant,
        Permission.deleteResidentialTenant,
      ]),
      subtexts: !hasPermissionForAll([
        Permission.deleteBusinessTenant,
        Permission.deleteResidentialTenant,
      ])
        ? [reasonIsReadOnly()]
        : undefined,
    },
    {
      onClick: (tenant: Tenant) =>
        withConfirmation({
          title: t('tenants.actions.exit.exitConfirmation.title'),
          body: t('tenants.actions.exit.exitConfirmation.body'),
          onConfirm: () => executeAndNotify(exitTenant, tenant, Actions.Exit),
        }),
      label: t('tenants.table.actionsDropdown.exit'),
      isVisible: (tenant: Tenant) =>
        tenant.type === TenantTypes.Residence && _.isString(tenant.accountId),
      isDisabled: !hasPermissionForAll([Permission.exitResidentialTenant]),
      subtexts: !hasPermissionForAll([Permission.exitResidentialTenant])
        ? [reasonIsReadOnly()]
        : undefined,
    },
  ];

  return (tenant: Tenant) =>
    availableTenantActions.filter((action) => action.isVisible(tenant));
};

export default useTenantActions;
