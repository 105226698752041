import { memo, ReactNode } from 'react';
import { PMDashboardStatType } from '../../types';
import { Button, Icons } from 'plume-ui';
import { useTranslation } from 'react-i18next';

type PMDashboardStatItemProps = {
  bodyLabel: string;
  labelRenderer: () => ReactNode;
  onViewClick: () => void;
  title: string;
  type: PMDashboardStatType;
};

const PMDashboardStatItem = ({
  bodyLabel,
  labelRenderer,
  onViewClick,
  title,
  type,
}: PMDashboardStatItemProps) => {
  const { t } = useTranslation();

  const getIcon = () => {
    switch (type) {
      case PMDashboardStatType.Payments:
        return <Icons.StarIcon width={24} height={24} />;
      case PMDashboardStatType.Leases:
        return <Icons.CalendarIcon width={24} height={24} />;
      case PMDashboardStatType.Tickets:
        return <Icons.StarIcon width={24} height={24} />;
    }
  };

  return (
    <div className="PMDashboardStatItem">
      <div className="PMDashboardStatItem__header">
        <div className="PMDashboardStatItem__title">
          <div
            className={`PMDashboardStatItem__icon PMDashboardStatItem__icon--${type}`}
          >
            {getIcon()}
          </div>
          {title}
        </div>
        <div className="PMDashboardStatItem__headerLabel">
          {labelRenderer()}
        </div>
      </div>
      <div className="PMDashboardStatItem__body">
        <div className="PMDashboardStatItem__bodyLabel">{bodyLabel}</div>
        <Button styleVariant="tertiary" onClick={onViewClick}>
          {t('view')}
        </Button>
      </div>
    </div>
  );
};

export default memo(PMDashboardStatItem);
