import cx from 'classnames';
import { PendingContent, Spinner, Status } from 'plume-ui';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import TableActionButton from '../../../../components/TableActionButton/TableActionButton';
import { residentialUnitsAtom } from '../../../units/unitsState';
import useIcon from '../../hooks/useIcon';
import useIotDeviceActions from '../../hooks/useIotDeviceActions';
import { IotDevice, IotDeviceConnectionState } from '../../types';
import { deviceHasAlarms, getIotDeviceStatusColor } from '../../util';
import HealthBar from '../HealthBar/HealthBar';

type IotDeviceProps = IotDevice;

const IotDeviceCard = (props: IotDeviceProps) => {
  const {
    connectionState,
    deviceType,
    health,
    locationId,
    mac,
    quarantined,
  } = props;
  const { t } = useTranslation();
  const getIotDeviceActions = useIotDeviceActions();
  const residentialUnits = useRecoilValue(residentialUnitsAtom);
  const { loading, icon } = useIcon(deviceType.iconV3);

  const renderName = () => (
    <div className="IotDeviceCard__infoItems__name">{deviceType.name}</div>
  );

  const renderMac = () => (
    <div className="IotDeviceCard__infoItems__mac">{mac}</div>
  );

  const renderConnectedTo = () => {
    const unit = residentialUnits.find(
      (unit) => unit.locationId === locationId,
    );
    if (!unit) return null;
    return (
      <div className="IotDeviceCard__infoItems__connectedTo">
        <div className="IotDeviceCard__infoItems__label">
          {t('iot.device.connectedTo')}
        </div>
        {unit.name}
      </div>
    );
  };

  const renderHealth = () => {
    if (connectionState !== IotDeviceConnectionState.Connected) return;
    if (!health?.status) return;
    return (
      <div>
        <div className="IotDeviceCard__infoItems__label">
          {t('iot.device.health')}
        </div>
        <HealthBar quarantined={quarantined} healthStatus={health.status} />
      </div>
    );
  };

  const getStatusLabel = () => {
    if (quarantined) return t('iot.device.status.quarantined');
    if (
      deviceHasAlarms(props) &&
      props.connectionState === IotDeviceConnectionState.Connected
    ) {
      return t('iot.device.status.alarm');
    }
    return t(`iot.device.status.${connectionState}`);
  };

  return (
    <div
      className={cx('IotDeviceCard', {
        'IotDeviceCard--quarantined': quarantined,
      })}
    >
      <div className="IotDeviceCard__statusRow">
        <Status
          label={getStatusLabel()}
          color={getIotDeviceStatusColor(props)}
        />
        <TableActionButton
          actions={getIotDeviceActions()}
          item={{ iotDevice: props }}
        />
      </div>
      <div className="IotDeviceCard__infoRow">
        <PendingContent loading={loading} loader={Spinner} hideContent>
          <div className="IotDeviceCard__infoRow__icon">
            <div
              className="IotDeviceCard__infoRow__icon--mask"
              style={{
                maskImage: `url(${icon})`,
              }}
            />
          </div>
        </PendingContent>
        <div className="IotDeviceCard__infoItems">
          {renderName()}
          {renderMac()}
          {renderConnectedTo()}
          {renderHealth()}
        </div>
      </div>
    </div>
  );
};

export default memo(IotDeviceCard);
