import { useRecoilValue } from 'recoil';
import { selectedPartnerIdAtom } from '../state';

export enum FeatureFlags {
  IotNetwork = 'iotNetwork',
}

type FeatureFlag = {
  featureId: string;
  partnerRestricted: boolean;
  partnerIdsEnabled: string[];
};

const useFeatureFlags = () => {
  const selectedPartnerId = useRecoilValue(selectedPartnerIdAtom);
  const featureFlags = process.env.REACT_APP_UPRISE_FEATURE_FLAGS;

  const isFeatureEnabled = (featureFlag: FeatureFlags) => {
    // If there's something wrong with the feature flags variable then show the feature
    if (!featureFlags) return true;
    try {
      const featureFlagsArray: FeatureFlag[] = JSON.parse(featureFlags);
      if (!Array.isArray(featureFlagsArray)) return true;

      const feature = featureFlagsArray.find(
        (feature) => feature.featureId === featureFlag,
      );
      // If the feature is not the feature flags array then show the feature
      if (!feature) return true;

      if (feature.partnerRestricted) {
        // If the feature is partnerRestricted and the partner id array is empty, hide the feature
        if (feature.partnerIdsEnabled.length === 0) return false;
        // If the current partner id is in the partnerIdsEnabled then show the feature
        return (
          feature.partnerIdsEnabled.find(
            (partnerId) => partnerId === selectedPartnerId,
          ) !== undefined
        );
      } else {
        // If not partnerRestricted then show the feature
        return true;
      }
    } catch (error) {
      return true;
    }
  };

  return {
    isFeatureEnabled,
  };
};

export default useFeatureFlags;
