import { ModalState, ModalType } from '../../../../types';

export type UnitModalAtom = {
  state: ModalState;
  type: ModalType;
};

export type UnitFormValues = {
  name: string;
  paymentType: UnitPaymentType;
  accountId?: string;
  nodes: any[];
};

export enum UnitPaymentType {
  Bulk = 'bulk',
  Retail = 'retail',
}
