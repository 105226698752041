import { APP_URL, Routes } from './config';

type Urls = Record<string, CallableFunction>;

const TEST_PARTNERID = 'testpartner2';
export const LoginUrls: Urls = {
  getRedirectUriForOkta: () => APP_URL + Routes.LoginCallback,
};

export const UpriseApiUrls = {
  // MDU & Properties

  getCreateMduUrl: (partnerId: string = TEST_PARTNERID) =>
    `/uprise/v1/partners/${partnerId}/properties`,

  getUpdateMduUrl: (mduId: string, partnerId: string = TEST_PARTNERID) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}`,

  getGetMdusUrl: (partnerId: string = TEST_PARTNERID) =>
    `/uprise/v1/partners/${partnerId}/properties`,

  getDeleteMduUrl: (mduId: string, partnerId: string = TEST_PARTNERID) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}`,

  getPortfolioStatsUrl: (partnerId: string = TEST_PARTNERID) =>
    `/uprise/v1/partners/${partnerId}/propertyStats`,

  getPropertyStatsUrl: (mduId: string, partnerId: string = TEST_PARTNERID) =>
    `/uprise/v1/partners/${partnerId}/propertyStats/${mduId}`,

  getPortfolioStatsV2Url: (partnerId: string = TEST_PARTNERID) =>
    `/uprise/v1/partners/${partnerId}/stats`,

  getPropertyStatsV2Url: (mduId: string, partnerId: string = TEST_PARTNERID) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/stats`,

  getUploadUnitsUrl: (mduId: string, partnerId: string = TEST_PARTNERID) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/units/import`,

  getUploadNodesUrl: (mduId: string, partnerId: string = TEST_PARTNERID) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/nodes/import`,

  getUploadUnitsWithNodesUrl: (
    mduId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/units-with-nodes/import`,

  getImportTenantsUrl: (mduId: string, partnerId: string = TEST_PARTNERID) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/plume-accounts/import`,

  getExitTenantsUrl: (mduId: string, partnerId: string = TEST_PARTNERID) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/plume-accounts/exit`,

  // Units

  getCreateResidentialUnitUrl: (
    mduId: string,
    partnerId: string = TEST_PARTNERID,
  ) => `/uprise/v1/partners/${partnerId}/properties/${mduId}/residentialUnits`,

  getUpdateResidentialUnitUrl: (
    unitId: string,
    mduId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/residentialUnits/${unitId}`,

  getGetResidentialUnitsUrl: (
    mduId: string,
    page: number,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/residentialUnits?page=${page}`,

  getGetResidentialUnitDetailsUrl: (
    unitId: string,
    mduId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/residentialUnits/${unitId}`,

  getDeleteResidentialUnitUrl: (
    unitId: string,
    mduId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/residentialUnits/${unitId}`,

  getCreateBusinessUnitUrl: (
    mduId: string,
    partnerId: string = TEST_PARTNERID,
  ) => `/uprise/v1/partners/${partnerId}/properties/${mduId}/businessUnits`,

  getUpdateBusinessUnitUrl: (
    unitId: string,
    mduId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/businessUnits/${unitId}`,

  getGetBusinessUnitsUrl: (
    mduId: string,
    page: number,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/businessUnits?page=${page}`,

  getGetBusinessUnitDetailsUrl: (
    unitId: string,
    mduId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/businessUnits/${unitId}`,

  getDeleteBusinessUnitUrl: (
    unitId: string,
    mduId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/businessUnits/${unitId}`,

  // Tenants

  createResidentialTenantsUrl: (
    mduId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/residentialTenants`,

  createBusinessTenantsUrl: (
    mduId: string,
    partnerId: string = TEST_PARTNERID,
  ) => `/uprise/v1/partners/${partnerId}/properties/${mduId}/businessTenants`,

  getResidentialTenantsUrl: (
    mduId: string,
    page: number,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/residentialTenants?page=${page}`,

  getBusinessTenantsUrl: (
    mduId: string,
    page: number,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/businessTenants?page=${page}`,

  getUpdateResidentialTenantUrl: (
    mduId: string,
    tenantId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/residentialTenants/${tenantId}`,

  getUpdateBusinessTenantUrl: (
    mduId: string,
    tenantId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/businessTenants/${tenantId}`,

  getRelocateResidentialTenantNewNodesUrl: (
    mduId: string,
    tenantId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/residentialTenants/${tenantId}`,

  getRelocateResidentialTenantKeepingNodesUrl: (
    mduId: string,
    tenantId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/residentialTenants/${tenantId}/residentialUnit`,

  getRelocateBusinessTenantNewNodesUrl: (
    mduId: string,
    tenantId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/businessTenants/${tenantId}`,

  getRelocateBusinessTenantKeepingNodesUrl: (
    mduId: string,
    tenantId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/businessTenants/${tenantId}/businessUnit`,

  getDeleteResidentialTenantUrl: (
    mduId: string,
    tenantId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/residentialTenants/${tenantId}`,

  getDeleteBusinessTenantUrl: (
    mduId: string,
    tenantId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/businessTenants/${tenantId}`,

  getExitResidentialTenantUrl: (
    mduId: string,
    tenantId: string,
    partnerId: string,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/residentialTenants/${tenantId}/exit`,

  getPartnersUrl: () => '/uprise/v1/partners',

  getUserInfoUrl: () => '/uprise/v1/userinfo',

  getPatchResidentialTenantUrl: (
    mduId: string,
    tenantId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/residentialTenants/${tenantId}`,

  getPatchBusinessTenantUrl: (
    mduId: string,
    tenantId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/businessTenants/${tenantId}`,

  getTenantBusinessById: (
    tenantId: string,
    mduId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/businessTenants/${tenantId}`,

  getTenantResidentialById: (
    tenantId: string,
    mduId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/residentialTenants/${tenantId}`,

  // Inventory

  getInventoryNodeUrl: (
    mduId: string,
    page: number,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/nodes?page=${page}`,

  getAddInventoryNodeUrl: (mduId: string, partnerId: string = TEST_PARTNERID) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/nodes`,

  getDeleteInventoryNodeUrl: (
    mduId: string,
    nodeId: string,
    partnerId: string = TEST_PARTNERID,
  ) => `/uprise/v1/partners/${partnerId}/properties/${mduId}/nodes/${nodeId}`,

  //API version

  getApiVersionUrl: () => `/uprise/version`,

  // Property Networks

  getPropertyNetworksUrl: (
    mduId: string,
    page: number,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/propertyNetworks?page=${page}`,

  getAddPropertyNetworksUrl: (
    mduId: string,
    partnerId: string = TEST_PARTNERID,
  ) => `/uprise/v1/partners/${partnerId}/properties/${mduId}/propertyNetworks`,

  getUpdatePropertyNetworksUrl: (
    mduId: string,
    partnerId: string = TEST_PARTNERID,
    propertyNetworkId: string,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/propertyNetworks/${propertyNetworkId}`,

  getPropertyNetworkDetailsUrl: (
    propertyNetworkId: string,
    mduId: string,
    partnerId: string = TEST_PARTNERID,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/propertyNetworks/${propertyNetworkId}`,

  getDeletePropertyNetworksUrl: (
    mduId: string,
    partnerId: string = TEST_PARTNERID,
    propertyNetworkId: string,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/propertyNetworks/${propertyNetworkId}`,

  // Users

  getUsersUrl: (page: number, partnerId: string = TEST_PARTNERID) =>
    `/uprise/v1/partners/${partnerId}/users?page=${page}`,

  getAddUserUrl: (partnerId: string = TEST_PARTNERID) =>
    `/uprise/v1/partners/${partnerId}/users`,

  getUpdateUserUrl: (partnerId: string = TEST_PARTNERID, userId: string) =>
    `/uprise/v1/partners/${partnerId}/users/${userId}`,

  getDeleteUserUrl: (userId: string, partnerId: string = TEST_PARTNERID) =>
    `/uprise/v1/partners/${partnerId}/users/${userId}`,

  getGeocodeUrl: () => `/uprise/v1/geocode`,

  // Iot

  getCreateIotNetworkUrl: (
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/propertyNetworks/${propertyNetworkId}/iotNetworks`,

  getIotNetworkUrl: (
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/propertyNetworks/${propertyNetworkId}/iotNetworks`,

  getUpdateIotNetworkUrl: (
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/propertyNetworks/${propertyNetworkId}/iotNetworks`,

  getDeleteIotNetworkUrl: (
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/propertyNetworks/${propertyNetworkId}/iotNetworks`,

  getPropagateIotNetworkUrl: (
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/propertyNetworks/${propertyNetworkId}/propagateIotNetwork`,

  getPropagateSingleIotNetworkUrl: (
    unitId: string,
    mduId: string,
    partnerId: string,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/residentialUnits/${unitId}/propagateIotNetwork`,

  getPropagateDeleteIotNetworkUrl: (
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/propertyNetworks/${propertyNetworkId}/propagateIotNetwork`,

  getPropagateDeleteSingleIotNetworkUrl: (
    unitId: string,
    mduId: string,
    partnerId: string,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/residentialUnits/${unitId}/propagateIotNetwork`,

  getIotDevicesUrl: (
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/propertyNetworks/${propertyNetworkId}/iotNetworks/devices`,

  getIotPropagationStatusUrl: (
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
    transactionId: string,
  ) =>
    `/uprise/v1/partners/${partnerId}/properties/${mduId}/propertyNetworks/${propertyNetworkId}/iotNetworks/propagationStatus?transactionId=${transactionId}`,
};

export const FrontlineUrls = {
  getFrontlineTenantUrl: (customerId: string, locationId: string) =>
    `/customer/${customerId}/location/${locationId}`,

  getFrontlineDeviceUrl: (
    customerId: string,
    locationId: string,
    mac: string,
  ) => `/customer/${customerId}/location/${locationId}/devices/${mac}`,

  getFrontlineCaptivePortal: (customerId: string, locationId: string) =>
    `/captiveportal/customer/${customerId}/location/${locationId}/network/guest/uprise`,
};

export const MetasaurusUrls = {
  getIconV3Url: (
    iconV3: string,
    size: 'small' | 'medium' | 'large' = 'small',
  ) => {
    // Metasaurus bug + shouldn't have to be escaped
    const escapedIconV3 = iconV3.replace('+', '%2B');
    return `https://digital-wellbeing-apptime.plumenet.io/v2/assets/devices/icons/${escapedIconV3}/icon-${size}.svg`;
  },

  getIconV3CatalogUrl: () =>
    `https://digital-wellbeing-apptime.plumenet.io/v2/assets/devices/icons/catalog.json`,
};
