import { useSetRecoilState } from 'recoil';
import { usersSelectedFilterAtom } from '../features/users/usersState';
import { unitsSelectedFilterAtom } from '../features/units/unitsState';
import { tenantsSelectedFilterAtom } from '../features/tenants/tenantsState';
import { propertyNetworksSelectedFilterAtom } from '../features/property-networks/propertyNetworksState';
import { inventorySelectedFilterAtom } from '../features/inventory/inventoryState';
import { propertiesSelectedFilterAtom } from '../features/editorView/propertyState';

export const useClearFilters = () => {
  const setSelectedUnitsFilter = useSetRecoilState(unitsSelectedFilterAtom);
  const setSelectedTenantsFilter = useSetRecoilState(tenantsSelectedFilterAtom);
  const setSelectedPropertyNetworksFilter = useSetRecoilState(
    propertyNetworksSelectedFilterAtom,
  );
  const setSelectedInventoryFilter = useSetRecoilState(
    inventorySelectedFilterAtom,
  );
  const setSelectedUsersFilter = useSetRecoilState(usersSelectedFilterAtom);
  const setSelectedPropertiesFilter = useSetRecoilState(
    propertiesSelectedFilterAtom,
  );

  const clearFilters = () => {
    setSelectedUnitsFilter('');
    setSelectedTenantsFilter('');
    setSelectedPropertyNetworksFilter('');
    setSelectedInventoryFilter('');
    setSelectedUsersFilter('');
    setSelectedPropertiesFilter('');
  };

  return { clearFilters };
};
