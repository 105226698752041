import { notify } from 'plume-ui';
import React, { FunctionComponent, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Routes } from '../config';
import DocumentsContainer from '../features/documents/containers/DocumentsContainer';
import { selectedMduIdAtom } from '../features/editorView/propertyState';
import HarmonyContainer from '../features/harmony/containers/HarmonyContainer';
import InventoryContainer from '../features/inventory/containers/InventoryContainer';
import IotContainer from '../features/iot/containers/IotContainer';
import LeaseContainer from '../features/lease/containers/LeaseContainer';
import MaintenanceContainer from '../features/maintenance/containers/MaintenanceContainer';
import OverviewContainer from '../features/overview/containers/OverviewContainer';
import PropertyNetworksContainer from '../features/property-networks/containers/PropertyNetworksContainer';
import TenantsContainer from '../features/tenants/containers/TenantsContainer';
import TicketContainer from '../features/ticket/containers/TicketContainer';
import UnitsContainer from '../features/units/containers/UnitsContainer';
import { multiDwellingUnitsAtom, selectedPartnerIdAtom } from '../state';
import { useRedirectToRoute } from '../utils/hooks/useRedirectToRoute';
import PMDashboard from '../features/pmDashboard/containers/PMDashboard';

const PropertyRoutes: FunctionComponent = () => {
  const { t } = useTranslation();
  const properties = useRecoilValue(multiDwellingUnitsAtom);
  const selectedPartnerId = useRecoilValue(selectedPartnerIdAtom);
  const setSelectedProperty = useSetRecoilState(selectedMduIdAtom);
  const redirectToRoute = useRedirectToRoute();

  let { propertyId } = useParams<{ propertyId: string }>();

  useEffect(() => {
    if (properties.length) {
      const noProperty = !properties.find((p) => p.id === propertyId);
      if (noProperty) {
        notify({
          type: 'alert',
          title: t('warnings.noPropertyTitle'),
          body:
            properties.length === 1
              ? t('warnings.noPropertyMessageButOnlyProperty')
              : t('warnings.noPropertyMessage'),
        });
        if (properties.length === 1) {
          setSelectedProperty(properties[0].id);
          redirectToRoute(Routes.Overview, {
            partnerId: selectedPartnerId,
            propertyId: properties[0].id,
          });
        } else {
          setSelectedProperty('');
          redirectToRoute(Routes.Portfolio, { partnerId: selectedPartnerId });
        }
      } else {
        setSelectedProperty(propertyId);
      }
    }
  }, [propertyId, properties]);

  return (
    <>
      <Switch>
        {/* Property management routes */}
        <Route exact path={Routes.PMDashboard}>
          <PMDashboard />
        </Route>
        <Route exact path={Routes.Maintenance}>
          <MaintenanceContainer />
        </Route>
        <Route exact path={Routes.Documents}>
          <DocumentsContainer />
        </Route>
        <Route exact path={Routes.Lease}>
          <LeaseContainer />
        </Route>
        <Route exact path={Routes.Ticket}>
          <TicketContainer />
        </Route>
        {/* Connectivity routes */}
        <Route exact path={Routes.PropertyNetworks}>
          <PropertyNetworksContainer />
        </Route>
        <Route exact path={Routes.Units}>
          <UnitsContainer />
        </Route>
        <Route exact path={Routes.Tenants}>
          <TenantsContainer />
        </Route>
        <Route exact path={Routes.Overview}>
          <OverviewContainer />
        </Route>
        <Route exact path={Routes.Inventory}>
          <InventoryContainer />
        </Route>
        <Route exact path={Routes.Iot}>
          <IotContainer />
        </Route>
        <Route exact path={Routes.Harmony}>
          <HarmonyContainer />
        </Route>
        <Route>
          <Redirect to={Routes.PartnerHome} />
        </Route>
      </Switch>
    </>
  );
};

export default memo(PropertyRoutes);
