import { AxiosResponse } from 'axios';
import { UpriseApiUrls } from '../../../urls';
import { HttpClient } from '../../../HttpClient';
import { UserInfo, Partner } from '../../../types';

export default class LoginClient extends HttpClient {
  async fetchUserInfo(): Promise<UserInfo> {
    let returnUserInfo;
    try {
      const response = await this.get<AxiosResponse<UserInfo>>(
        UpriseApiUrls.getUserInfoUrl(),
      );
      returnUserInfo = response.data;
    } catch {
      returnUserInfo = ({ role: 'cspAdmin' } as unknown) as UserInfo;
    }

    return returnUserInfo;
  }
  async fetchPartners(): Promise<Partner[]> {
    const response = await this.get<AxiosResponse<Partner[]>>(
      UpriseApiUrls.getPartnersUrl(),
    );
    return response.data;
  }
}
