import cx from 'classnames';
import _ from 'lodash';
import { Badge, Button, Icons } from 'plume-ui';
import { BadgeStyles } from 'plume-ui/dist/components/Badge/Badge';
import { ButtonStyles } from 'plume-ui/dist/components/Button/Button';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Routes } from '../../../../config';
import { selectedPartnerIdAtom } from '../../../../state';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import {
  AdjustableComponent,
  useClasses,
} from '../../../../utils/hooks/useClasses';
import { useRedirectToRoute } from '../../../../utils/hooks/useRedirectToRoute';
import { XOR } from '../../../../utils/xor';
import { selectedMduIdAtom } from '../../../editorView/propertyState';
import {
  propertyNetworksFilterStatusAtom,
  propertyNetworksSelectedFilterAtom,
} from '../../../property-networks/propertyNetworksState';
import { UnitType } from '../../../units/types';
import {
  unitsFilterStatusAtom,
  unitsSelectedFilterAtom,
  unitsSelectedTypeAtom,
} from '../../../units/unitsState';
import LargeHeroStatAlerts, { LargeHeroStatAlert } from './LargeHeroStatAlerts';
import LargeHeroStatProperties, {
  LargeHeroStatPropertiesStyles,
} from './LargeHeroStatProperties';

export enum AvailableHeroStats {
  Residences = 'residences',
  Businesses = 'businesses',
  PropertyNetworks = 'propertyNetworks',
  Alerts = 'alerts',
}

export type LargeHeroStatType = {
  type: AvailableHeroStats;
  value?: number;
  hint?: string;
};

export type LargeHeroStatProperty =
  | {
      label: string;
      isProgressBar: false;
      value: XOR<string | number, { current: number; of: number }>;
    }
  | {
      label: string;
      isProgressBar: true;
      hint?: string;
      value: { current: number; of: number };
    };

export type LargeHeroStatProps = {
  stat: LargeHeroStatType;
  properties?: LargeHeroStatProperty[];
  alerts?: number | LargeHeroStatAlert[];
  setAlertsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
} & JSX.IntrinsicElements['div'];

type LargeHeroStatStyles = {
  root: string;
  alertBadge: string;
  header: string;
  headerIcon: string;
  headerTitle: string;
  value: string;
  properties: string;
  property: string;
  propertyLabel: string;
  propertyValue: string;
};

const mapStatTypeToId: Record<AvailableHeroStats, string> = {
  [AvailableHeroStats.Businesses]: 'LargeHeroStat__businesses',
  [AvailableHeroStats.Residences]: 'LargeHeroStat__residences',
  [AvailableHeroStats.PropertyNetworks]: 'LargeHeroStat__propertyNetworks',
  [AvailableHeroStats.Alerts]: 'LargeHeroStat__alerts',
};

const mapStatTypeToHeader: Record<
  AvailableHeroStats,
  {
    icon: JSX.Element;
    labelId: string;
  }
> = {
  [AvailableHeroStats.Businesses]: {
    icon: <Icons.BriefCaseIcon width={26} />,
    labelId: 'overview.stats.businesses',
  },
  [AvailableHeroStats.Residences]: {
    icon: <Icons.HomeIcon width={34} height={29} />,
    labelId: 'overview.stats.residences',
  },
  [AvailableHeroStats.PropertyNetworks]: {
    icon: <Icons.NetworkIcon height={29} />,
    labelId: 'overview.stats.propertyNetworks',
  },
  [AvailableHeroStats.Alerts]: {
    icon: <Icons.BellIcon width={28} height={29} />,
    labelId: 'overview.stats.alerts',
  },
};

export const statLabelsAndColPositions = {
  [AvailableHeroStats.Residences]: 1,
  [AvailableHeroStats.Businesses]: 3,
  [AvailableHeroStats.PropertyNetworks]: 5,
  [AvailableHeroStats.Alerts]: 7,
};

const LargeHeroStat: AdjustableComponent<
  LargeHeroStatProps,
  LargeHeroStatStyles
> = ({ classes, properties, stat, alerts, setAlertsModalOpen }) => {
  const { t } = useTranslation();
  const styles = useClasses(
    {
      root: 'LargeHeroStat AuraGlow--card',
      alertBadge: 'LargeHeroStat__header-alertBadge',
      header: 'LargeHeroStat__header',
      headerIcon: 'LargeHeroStat__header__icon',
      headerTitle: 'LargeHeroStat__header__title',
      value: cx('LargeHeroStat__header__value', {
        'LargeHeroStat__header__value-zero': !stat.value,
        'LargeHeroStat__header__value-alert':
          stat.type === AvailableHeroStats.Alerts &&
          Array.isArray(alerts) &&
          Boolean(alerts.length),
      }),
      properties: 'LargeHeroStat__properties',
      property: 'LargeHeroStat__property',
      propertyLabel: 'LargeHeroStat__property-label',
      propertyValue: 'LargeHeroStat__property-value',
    },
    classes,
  );
  const header = mapStatTypeToHeader[stat.type];

  const redirectToRoute = useRedirectToRoute();
  const setSelectedUnitType = useSetRecoilState(unitsSelectedTypeAtom);
  const partnerId = useRecoilValue(selectedPartnerIdAtom);
  const propertyId = useRecoilValue(selectedMduIdAtom);
  const setUnitsFilterStatus = useSetRecoilState(unitsFilterStatusAtom);
  const setSelectedUnitsFilter = useSetRecoilState(unitsSelectedFilterAtom);
  const setPropertyNetworksFilterStatus = useSetRecoilState(
    propertyNetworksFilterStatusAtom,
  );
  const setSelectedPropertyNetworksFilter = useSetRecoilState(
    propertyNetworksSelectedFilterAtom,
  );

  const navigateToPage = () => {
    const params = {
      partnerId,
      propertyId,
    };
    switch (stat.type) {
      case AvailableHeroStats.Residences:
        setSelectedUnitType(UnitType.Residence);
        redirectToRoute(Routes.Units, params);
        break;
      case AvailableHeroStats.Businesses:
        setSelectedUnitType(UnitType.Business);
        redirectToRoute(Routes.Units, params);
        break;
      case AvailableHeroStats.PropertyNetworks:
        redirectToRoute(Routes.PropertyNetworks, params);
        break;
    }
  };

  const goToPage = () => {
    if (stat.type === AvailableHeroStats.PropertyNetworks) {
      setPropertyNetworksFilterStatus('');
      setSelectedPropertyNetworksFilter('');
    } else {
      setUnitsFilterStatus('');
      setSelectedUnitsFilter('');
    }
    navigateToPage();
  };

  const goToAlerted = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (stat.type === AvailableHeroStats.PropertyNetworks) {
      setPropertyNetworksFilterStatus('alerted');
      setSelectedPropertyNetworksFilter('');
    } else {
      setUnitsFilterStatus('alerted');
      setSelectedUnitsFilter('');
    }
    navigateToPage();
  };

  const isAlertType = (type: AvailableHeroStats) => {
    return type === AvailableHeroStats.Alerts;
  };

  return (
    <div
      className={styles.root}
      onClick={(e) => {
        goToPage();
      }}
    >
      {!isAlertType(stat.type) && Boolean(alerts) && _.isNumber(alerts) && (
        <div onClick={goToAlerted}>
          <Badge
            classes={(current: BadgeStyles) => ({
              ...current,
              root: `${current.root} ${styles.alertBadge}`,
            })}
            badgeContent={t('overview.stats.badgeLabel', {
              count: alerts,
            })}
            size="mini"
          />
        </div>
      )}
      <div className={styles.header}>
        <div className={`${styles.headerIcon} ${stat.type}`}>{header.icon}</div>
        <div>
          <div id={mapStatTypeToId[stat.type]} className={styles.value}>
            {!isAlertType(stat.type) && stat.value}
            {isAlertType(stat.type) && Array.isArray(alerts) && alerts.length}
          </div>
          <div className={styles.headerTitle}>{t(header.labelId)}</div>
        </div>
      </div>
      {properties?.length && stat.value !== 0 && (
        <LargeHeroStatProperties
          classes={(current: LargeHeroStatPropertiesStyles) => ({
            ...current,
            root: styles.properties,
            property: styles.property,
            propertyLabel: styles.propertyLabel,
            propertyValue: styles.propertyValue,
          })}
          properties={properties}
        />
      )}
      {isAlertType(stat.type) &&
        Array.isArray(alerts) &&
        Boolean(alerts.length) && (
          <LargeHeroStatAlerts alerts={alerts as LargeHeroStatAlert[]} />
        )}
      {!isAlertType(stat.type) && (
        <Button
          styleVariant="tertiary"
          classes={(current: ButtonStyles) => ({
            ...current,
            root: `${current.root} LargeHeroStat__viewResidences-btn`,
          })}
          onClick={goToPage}
        >
          <FormattedMessage id="overview.stats.viewResidences" />
        </Button>
      )}
      {isAlertType(stat.type) &&
        Array.isArray(alerts) &&
        Boolean(alerts.length) && (
          <Button
            styleVariant="tertiary"
            classes={(current: ButtonStyles) => ({
              ...current,
              root: `${current.root} LargeHeroStat__viewAll-btn`,
            })}
            onClick={() => setAlertsModalOpen(true)}
          >
            <FormattedMessage id="overview.stats.viewAll" />
          </Button>
        )}
      {!isAlertType(stat.type) && Boolean(alerts) && (
        <Button
          styleVariant="tertiary"
          classes={(current: ButtonStyles) => ({
            ...current,
            root: `${current.root} LargeHeroStat__alerts-btn`,
          })}
          onClick={goToAlerted}
        >
          <FormattedMessage id="overview.stats.viewAlerts" />
        </Button>
      )}
    </div>
  );
};

export default memo(LargeHeroStat);
