import cx from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { IotDeviceHealthStatus } from '../../types';

type HealthBarProps = {
  healthStatus: IotDeviceHealthStatus;
  quarantined?: boolean;
};

const HealthBar = ({ healthStatus, quarantined }: HealthBarProps) => {
  const { t } = useTranslation();
  const getBarWidth = () => {
    switch (healthStatus) {
      case IotDeviceHealthStatus.Excellent:
        return '100%';
      case IotDeviceHealthStatus.Good:
        return '75%';
      case IotDeviceHealthStatus.Fair:
        return '50%';
      case IotDeviceHealthStatus.Poor:
        return '25%';
      default:
        return '0%';
    }
  };

  return (
    <>
      {healthStatus !== IotDeviceHealthStatus.Calculating && (
        <div className="HealthBar">
          <div className="HealthBar__rail" />
          <div
            className={cx('HealthBar__bar', healthStatus, {
              'HealthBar__bar--quarantined': quarantined,
            })}
            style={{ width: getBarWidth() }}
          />
        </div>
      )}
      {healthStatus === IotDeviceHealthStatus.Calculating && (
        <div className="HealthBar__calculating">
          {t('iot.device.calculating')}
        </div>
      )}
    </>
  );
};

export default memo(HealthBar);
