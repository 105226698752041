import {
  Button,
  Dropdown,
  DropdownSectionItem,
  DropdownSelectableItem,
  DropdownWrapWithLabel,
  Grid,
  GridItem,
  Icons,
  Pagination,
  PendingContent,
  Spinner,
  Status,
  Toggler,
  Tooltip,
} from 'plume-ui';
import { FieldSort } from 'plume-ui/dist/components/Table/Table';
import React, {
  ChangeEvent,
  FunctionComponent,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import DependencyContainer from '../../../DependencyContainer';
import AuraGlow from '../../../components/AuraGlow/AuraGlow';
import PageHeading from '../../../components/PageHeading/PageHeading';
import ShowNodesStatusModal from '../../../components/ShowNodesStatusModal/ShowNodesStatusModal';
import StickyActionTable from '../../../components/StickyActionTable/StickyActionTable';
import TableActionButton, {
  TableAction,
} from '../../../components/TableActionButton/TableActionButton';
import TableFilterRow from '../../../components/TableFilter/TableFilterRow';
import { DEFAULT_PAGE_SIZE } from '../../../config';
import useRenderAnonymized from '../../../hooks/useRenderAnonymized';
import { MixPanelEvents } from '../../../mixPanelEvents';
import {
  nodesModalSelectedUnitAtom,
  selectedMDUSelector,
  selectedPartnerSelector,
  unitModalStateAtom,
  userInfoAtom,
} from '../../../state';
import { ModalState, ModalType, Permission, Role } from '../../../types';
import EmptyTableCell from '../../../utils/components/EmptyTableCell';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import { hasIotNetworkSelector } from '../../iot/state';
import { AvailableHeroStats } from '../../overview/components/HeroStat/LargeHeroStat';
import { tableFormatterBitrate } from '../../overview/helpers/chartHelpers';
import { useOverviewStats } from '../../overview/hooks/useOverviewStats';
import { overviewStatsAtom } from '../../overview/overviewState';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { AvailableScreens } from '../../trackingAnalytics/types';
import ActivateUnitsModal from '../components/ActivateUnitsModal/ActivateUnitsModal';
import { UnitPaymentType } from '../components/UnitModal/types';
import useUnitActions from '../hooks/useUnitActions';
import useUnitMappings from '../hooks/useUnitMappings';
import {
  GenericUnit,
  ResidentialUnit,
  UnitConnectionStatus,
  UnitDetails,
  UnitIotPropagationStatus,
  UnitStatus,
  UnitType,
} from '../types';
import {
  businessUnitsAtom,
  currentUnitsPage,
  getFilteredUnitCount,
  residentialUnitsAtom,
  unitsBusinessSelectedPageAtom,
  unitsFilterStatusAtom,
  unitsLoadingStatusAtom,
  unitsResidentialSelectedPageAtom,
  unitsSelectedFilterAtom,
  unitsSelectedTypeAtom,
  unitsSortAtom,
} from '../unitsState';

const { unitsService } = new DependencyContainer();

const UnitsContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const { renderAnonymized } = useRenderAnonymized();
  const trackEvent = useTrackEvent();
  const userInfo = useRecoilValue(userInfoAtom);
  const {
    renderUnitName,
    renderUnitStatus,
    renderUnitPaymentType,
    renderConnectionStatus,
    getConnectionStatusColorCode,
    getIotStatusColorCode,
  } = useUnitMappings();
  const [selectedUnitType, setSelectedUnitType] = useRecoilState(
    unitsSelectedTypeAtom,
  );
  const [unitsLoadingStatus] = useRecoilState(unitsLoadingStatusAtom);
  const [selectedFilter, setSelectedFilter] = useRecoilState(
    unitsSelectedFilterAtom,
  );
  const [statusFilter, setStatusFilter] = useRecoilState(unitsFilterStatusAtom);
  const setUnitModalState = useSetRecoilState(unitModalStateAtom);
  const getUnitActions = useUnitActions();

  const residentialUnits = useRecoilValue(residentialUnitsAtom);
  const businessUnits = useRecoilValue(businessUnitsAtom);
  const units = useRecoilValue(currentUnitsPage);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const hasIotNetwork = useRecoilValue(hasIotNetworkSelector);
  const [detailsMap, setDetailsMap] = useState<
    Record<string, UnitDetails | null>
  >({});
  const [detailsAreLoadingMap, setDetailsAreLoadingMap] = useState<
    Record<string, boolean>
  >({});
  const setUnitsSort = useSetRecoilState(unitsSortAtom);

  useOverviewStats();
  const overviewStats = useRecoilValue(overviewStatsAtom);

  // Page Navigation Handlers

  const totalFilteredItems = useRecoilValue(getFilteredUnitCount);
  const [currentBusinessUnitPage, setCurrentBusinessUnitsPage] = useRecoilState(
    unitsBusinessSelectedPageAtom,
  );
  const [
    currentResidentialUnitPage,
    setCurrentResidentialUnitsPage,
  ] = useRecoilState(unitsResidentialSelectedPageAtom);
  const selectedProperty = useRecoilValue(selectedMDUSelector);

  const [nodesModalSelectedUnit, setNodesModalSelectedUnit] = useRecoilState(
    nodesModalSelectedUnitAtom,
  );
  const [activateUnitsModalOpen, setActivateUnitsModalOpen] = useState(false);

  const updateDetailsAreLoadingMap = (unitId: string, areLoading: boolean) => {
    setDetailsAreLoadingMap((map) => ({
      ...map,
      [unitId]: areLoading,
    }));
  };

  const getDetails = async (unit: GenericUnit) => {
    updateDetailsAreLoadingMap(unit.id, true);
    const details = await unitsService.getUnitDetails(
      unit,
      selectedPartner!.id,
      unit.property.id,
      unit.type,
    );
    setDetailsMap((map) => ({
      ...map,
      [unit.id]: details,
    }));
    updateDetailsAreLoadingMap(unit.id, false);
  };

  const getAllUnitDetails = () => {
    units.forEach((unit) => {
      getDetails(unit);
    });
  };

  const currentPage: number = useMemo(() => {
    return selectedUnitType === UnitType.Business
      ? currentBusinessUnitPage
      : currentResidentialUnitPage;
  }, [selectedUnitType, currentResidentialUnitPage, currentBusinessUnitPage]);

  useEffect(() => {
    setDetailsAreLoadingMap({});
    if (!units.length && currentPage > 0) {
      if (selectedUnitType === UnitType.Business) {
        setCurrentBusinessUnitsPage(currentPage - 1);
      } else {
        setCurrentResidentialUnitsPage(currentPage - 1);
      }
    }
  }, [units, currentPage]);

  useEffect(() => {
    units.forEach((unit) => {
      if (!unit.nodes.length) {
        setDetailsMap((map) => ({
          ...map,
          [unit.id]: null,
        }));
      } else {
        getDetails(unit);
      }
    });
  }, [units]);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Units,
      },
    });
    return () => {
      setSelectedFilter('');
    };
  }, []);

  const onSortChange = (sort: FieldSort) => {
    setUnitsSort(sort);
  };

  const getTableHeaderRow = () => {
    const tableHeaderRow = [
      {
        name: t('units.table.unit'),
        fieldName: 'name',
        sortable: true,
        render: (unit: GenericUnit) =>
          renderUnitName(renderAnonymized(unit.name), unit.stateMeta.realized),
      },
      {
        name: t('units.table.status'),
        fieldName: 'status',
        sortable: true,
        render: (unit: GenericUnit) => renderUnitStatus(unit.status),
      },
      {
        name: t('units.table.accountId'),
        fieldName: 'accountId',
        sortable: true,
        render: (unit: GenericUnit) =>
          unit.retailPay ? (
            <EmptyTableCell />
          ) : unit.accountId ? (
            renderAnonymized(unit.accountId)
          ) : (
            <EmptyTableCell />
          ),
      },
      {
        name: t('units.paymentType.label'),
        fieldName: 'retailPay',
        sortable: true,
        render: (unit: GenericUnit) => renderUnitPaymentType(unit.retailPay),
      },
      {
        name: t('units.table.uploadSpeed'),
        fieldName: 'averageUploadSpeed',
        sortable: true,
        render: (unit: GenericUnit) => (
          <>
            {tableFormatterBitrate(
              unit.averageUploadSpeed ? unit.averageUploadSpeed.toString() : '',
            ) || <EmptyTableCell />}
          </>
        ),
      },
      {
        name: t('units.table.downloadSpeed'),
        fieldName: 'averageDownloadSpeed',
        sortable: true,
        render: (unit: GenericUnit) => (
          <>
            {tableFormatterBitrate(
              unit.averageDownloadSpeed
                ? unit.averageDownloadSpeed.toString()
                : '',
            ) || <EmptyTableCell />}
          </>
        ),
      },
      {
        name: t('units.table.iotStatus'),
        fieldName: 'iotConfiguration',
        sortable: false,
        render: (unit: GenericUnit) => {
          if (unit.type !== UnitType.Residence) return null;
          const residentialUnit = (unit as unknown) as ResidentialUnit;
          let iotStatus = residentialUnit.iotConfiguration?.propagationStatus;
          if (residentialUnit.iotConfiguration?.iot_network_manually_disabled) {
            iotStatus = UnitIotPropagationStatus.Disabled;
          }
          if (!iotStatus) return <EmptyTableCell />;
          return (
            <Status
              label={t(`units.iotStatus.${iotStatus}`)}
              color={getIotStatusColorCode(residentialUnit)}
            />
          );
        },
      },
      {
        name: t('units.table.connectionStatus'),
        fieldName: 'connectionStatus',
        sortable: false,
        render: (unit: GenericUnit) => {
          const detailsAreLoading = detailsAreLoadingMap[unit.id];
          const connection = renderConnectionStatus(unit, detailsMap[unit.id]);
          return (
            <PendingContent
              classes={(current) => ({
                ...current,
                loader: `${current.loader} UnitsContainer__status--pendingContent__loader`,
                root: `${current.root} UnitsContainer__status--pendingContent`,
              })}
              loading={detailsAreLoading}
              loader={Spinner}
            >
              {!detailsAreLoading && (
                <>
                  <Status
                    label={t(connection.translationRef)}
                    color={getConnectionStatusColorCode(
                      unit,
                      detailsMap[unit.id],
                    )}
                  />
                  {connection.connectionStatus ===
                    UnitConnectionStatus.Inactive && (
                    <Tooltip
                      style={{ zIndex: 99999 }}
                      label={t('units.table.inactiveInfo')}
                      openInPortal
                      overrideMaxCharacters
                    >
                      <Icons.InfoIcon width={14} />
                    </Tooltip>
                  )}
                </>
              )}
            </PendingContent>
          );
        },
      },
      {
        fieldName: 'actions',
        render: (unit: GenericUnit) => {
          const unitActions = getUnitActions(unit, detailsMap[unit.id]);
          if (unitActions.length === 0) {
            return null;
          }
          return (
            <TableActionButton
              actions={unitActions as TableAction<unknown>[]}
              item={{ unit, unitDetails: detailsMap[unit.id] }}
            ></TableActionButton>
          );
        },
      },
    ];

    if (!hasIotNetwork || selectedUnitType === UnitType.Business) {
      return tableHeaderRow.filter(
        (row) => String(row.fieldName) !== 'iotConfiguration',
      );
    }
    return tableHeaderRow;
  };

  const tableDataRows = units.map((unit: GenericUnit) => ({
    ...unit,
    connectionStatus: '',
  }));

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value === undefined ? '' : e.target.value;
    setSelectedFilter(value);
    if (selectedUnitType === UnitType.Business) {
      setCurrentBusinessUnitsPage(0);
    } else {
      setCurrentResidentialUnitsPage(0);
    }
  };

  const handleSetPage = (page: number) => {
    if (selectedUnitType === UnitType.Business) {
      setCurrentBusinessUnitsPage(page);
    }
    if (selectedUnitType === UnitType.Residence) {
      setCurrentResidentialUnitsPage(page);
    }
  };

  const hasPermissionForAll = (permissions: Permission[]) => {
    return permissions.every((p) => userInfo?.permissions.includes(p));
  };

  const hasAlerts = () => {
    const statType: AvailableHeroStats =
      selectedUnitType === UnitType.Residence
        ? AvailableHeroStats.Residences
        : AvailableHeroStats.Businesses;
    return Boolean(overviewStats && overviewStats[statType].alertCount);
  };

  const onFilterSelect = (key: string) => {
    if (selectedUnitType === UnitType.Business) {
      setCurrentBusinessUnitsPage(0);
    } else {
      setCurrentResidentialUnitsPage(0);
    }
    setStatusFilter((selectedFilter) => {
      if (selectedFilter === key) {
        return '';
      }
      return key;
    });
  };

  const hasInactiveUnits = () => {
    if (selectedUnitType === UnitType.Residence) {
      const inactiveUnit = residentialUnits.find(
        (unit) => unit.status === UnitStatus.Inactive,
      );
      return Boolean(inactiveUnit);
    }
    if (selectedUnitType === UnitType.Business) {
      const inactiveUnit = businessUnits.find(
        (unit) => unit.status === UnitStatus.Inactive,
      );
      return Boolean(inactiveUnit);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('units.title')}</title>
      </Helmet>
      <div className="UnitsContainer p-xl">
        <AuraGlow header footer />
        <Grid>
          <GridItem colSpan="12" tabletColSpan="6">
            <PageHeading
              title="units.title"
              tooltip="units.tooltip"
              additionalTitle={selectedProperty?.name}
            />
          </GridItem>
          <GridItem colSpan="12" tabletColSpan="6">
            <Toggler
              toggleElements={[
                {
                  key: UnitType.Residence,
                  label: t('units.filters.residential'),
                },
                {
                  key: UnitType.Business,
                  label: t('units.filters.commercial'),
                },
              ]}
              value={selectedUnitType}
              variant="large"
              onToggle={(selection) =>
                setSelectedUnitType(selection.key as any)
              }
            />
            <div className="UnitsContainer__filter">
              {hasInactiveUnits() && (
                <Button
                  styleVariant="secondary"
                  onClick={() => setActivateUnitsModalOpen(true)}
                >
                  <FormattedMessage id="units.actions.activate.buttonLabel" />
                </Button>
              )}
              <DropdownWrapWithLabel label={t('units.filterByStatus')}>
                <Dropdown
                  label={
                    statusFilter
                      ? t('units.filter.' + statusFilter)
                      : t('units.filter.showAll')
                  }
                >
                  <DropdownSectionItem label={t('units.table.status')} />
                  {Object.values(UnitStatus).map((key) => {
                    return (
                      <DropdownSelectableItem
                        key={key}
                        onClick={() => onFilterSelect(key)}
                        selected={key === statusFilter}
                      >
                        {t(`units.filter.${key}`)}
                      </DropdownSelectableItem>
                    );
                  })}
                  <DropdownSectionItem label={t('units.paymentType.label')} />
                  {Object.values(UnitPaymentType).map((key) => {
                    return (
                      <DropdownSelectableItem
                        key={key}
                        onClick={() => onFilterSelect(key)}
                        selected={key === statusFilter}
                      >
                        {t(`units.filter.${key}`)}
                      </DropdownSelectableItem>
                    );
                  })}
                  <DropdownSelectableItem
                    key="alerted"
                    onClick={() => onFilterSelect('alerted')}
                    selected={'alerted' === statusFilter}
                    classes={(current) => ({
                      ...current,
                      root: `${current} UnitsContainer__filter--alerted`,
                    })}
                  >
                    <div
                      className={`UnitsContainer__filter--alerted__content ${
                        hasAlerts()
                          ? 'UnitsContainer__filter--alerted__content--hasAlerts'
                          : ''
                      }`}
                    >
                      {t('units.filter.alerted')}
                      <span> {t('units.filter.last60min')}</span>
                    </div>
                  </DropdownSelectableItem>
                  <DropdownSelectableItem
                    key="all"
                    onClick={() => onFilterSelect('')}
                    selected={statusFilter === ''}
                  >
                    {t('units.filter.showAll')}
                  </DropdownSelectableItem>
                </Dropdown>
              </DropdownWrapWithLabel>
              <Button
                styleVariant="superprimary"
                onClick={() =>
                  setUnitModalState({
                    state: ModalState.Open,
                    type: ModalType.Create,
                  })
                }
                classes={(current) => ({
                  ...current,
                  root: `${current.root} UnitsContainer__create-unit-btn`,
                })}
                disabled={
                  userInfo?.role !== Role.Support &&
                  !hasPermissionForAll([
                    Permission.createBusinessUnit,
                    Permission.createResidentialUnit,
                  ])
                }
              >
                <FormattedMessage id="units.actions.create.buttonLabel" />
              </Button>
            </div>
          </GridItem>
          <GridItem colSpan="12" tabletColSpan="6" justify={'start'}>
            <TableFilterRow
              placeholder="units.search"
              value={selectedFilter}
              onSearch={handleSearch}
              resultCount={totalFilteredItems}
            />
          </GridItem>
          <GridItem colSpan="12" tabletColSpan="6">
            <PendingContent
              classes={(current) => ({
                ...current,
                root: `${current.root} UnitsContainer__pendingContent`,
              })}
              loading={unitsLoadingStatus === 'loading'}
              loader={Spinner}
              isError={unitsLoadingStatus === 'error'}
              transparent
            >
              <StickyActionTable
                headerRow={getTableHeaderRow() as any}
                dataRows={tableDataRows as any}
                externalSort
                onSortChange={onSortChange}
                noResultsMessage={t('units.table.noResults')}
              />
              {Math.ceil(totalFilteredItems / DEFAULT_PAGE_SIZE) > 0 && (
                <Pagination
                  classes={(current) => ({
                    ...current,
                    root: `${current.root} UnitsContainer__pagination`,
                  })}
                  expandDirection={'top'}
                  totalPageCount={
                    Math.ceil(totalFilteredItems / DEFAULT_PAGE_SIZE) || 1
                  }
                  onPageSelect={handleSetPage}
                  currentPage={currentPage}
                />
              )}
            </PendingContent>
          </GridItem>
        </Grid>
      </div>
      <ShowNodesStatusModal
        isOpen={!!nodesModalSelectedUnit}
        title={nodesModalSelectedUnit?.unit.name}
        nodes={nodesModalSelectedUnit?.details.nodes}
        onRequestClose={() => setNodesModalSelectedUnit(null)}
      />
      <ActivateUnitsModal
        isOpen={activateUnitsModalOpen}
        onClose={() => {
          getAllUnitDetails();
          setActivateUnitsModalOpen(false);
        }}
      />
    </>
  );
};

export default memo(UnitsContainer);
