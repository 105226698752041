import { Icons, ModalHeader } from 'plume-ui';
import React, { FunctionComponent, memo, ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import useRenderAnonymized from '../../hooks/useRenderAnonymized';
import { anonymizedAtom } from '../../state';

export type ModalHeaderWithPropertyProps = {
  propertyName: string;
  title: string;
  additionalSubtitle?: ReactNode;
} & JSX.IntrinsicElements['div'];

const ModalHeaderWithProperty: FunctionComponent<ModalHeaderWithPropertyProps> = ({
  propertyName,
  title,
  additionalSubtitle = null,
}) => {
  const anonymized = useRecoilValue(anonymizedAtom);
  const { renderAnonymized } = useRenderAnonymized();
  return (
    <ModalHeader
      title={title}
      subtitle={
        <>
          <div className="ModalHeaderWithProperty__subtitle-property">
            <Icons.MapPinIcon className="m-r-xxs" height={14} />
            {renderAnonymized(propertyName)}
          </div>
          {!anonymized && additionalSubtitle}
        </>
      }
    />
  );
};

export default memo(ModalHeaderWithProperty);
