import { User, UserDto } from '../types';
import DependencyContainer from '../../../DependencyContainer';

export default class UsersService {
  constructor(private readonly factory: DependencyContainer) {}

  async getAllUsers(partnerId: string): Promise<User[]> {
    const response = await this.factory.usersClient.fetchUsers(partnerId);

    let users = [...response.data.items];

    if (response.data.meta.totalPages > 1) {
      const additionalPageUsersResponse = await Promise.all(
        Array.from(Array(response.data.meta.totalPages - 1)).map((_, i) => {
          return this.factory.usersClient.fetchUsers(partnerId, i + 2);
        }),
      );
      additionalPageUsersResponse.forEach((response) => {
        users = [...users, ...response.data.items];
      });
    }
    return users;
  }

  async addUser(newUser: UserDto, partnerId: string): Promise<User> {
    const response = await this.factory.usersClient.addUser(newUser, partnerId);
    return response.data;
  }

  async updateUser(
    updateUser: UserDto,
    partnerId: string,
    userId: string,
  ): Promise<User> {
    const response = await this.factory.usersClient.updateUser(
      updateUser,
      partnerId,
      userId,
    );
    return response.data;
  }

  async deleteUser(user: User, partnerId: string): Promise<void> {
    await this.factory.usersClient.deleteUser(user.id, partnerId);
  }
}
