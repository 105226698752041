export const isLocal = () => {
  return window.location.hostname.startsWith('localhost');
};

export const isDevelopment = () => {
  return window.location.hostname.includes('uprise-dev');
};

export const isStaging = () => {
  return window.location.hostname.includes('uprise-stg');
};

export const isProduction = () => {
  return !isLocal() && !isDevelopment() && !isStaging();
};
