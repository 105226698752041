import { InventoryNode, NodeStatus } from '../types';
import { useTranslation } from 'react-i18next';
import { notify } from 'plume-ui';
import DependencyContainer from '../../../DependencyContainer';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../mixPanelEvents';
import useWithConfirmation from '../../../hooks/useWithConfirmation';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ModalState, Permission } from '../../../types';
import { Unit, UnitType } from '../../units/types';
import { TrackEventParams } from '../../trackingAnalytics/types';
import { selectedMDUSelector, selectedPartnerSelector } from '../../../state';
import {
  businessUnitsAtom,
  residentialUnitsAtom,
} from '../../units/unitsState';
import {
  assignNodeModalStateAtom,
  inventoryNodesAtom,
  selectedNodeAtom,
} from '../inventoryState';
import { userInfoAtom } from '../../../state';
import { useUnitsUpdateActions } from '../../units/hooks/useUnitsUpdateActions';
import { PropertyNetwork } from '../../property-networks/types';
import { propertyNetworksAtom } from '../../property-networks/propertyNetworksState';
import { Maybe } from '../../../utils/types';

export type InventoryNodeAction = {
  onClick: (node: InventoryNode) => void;
  label: string;
  isVisible: (node: InventoryNode) => boolean; // determines whether to show action or not
  isDisabled?: boolean; // determines whether to disable an action or not
  subtexts?: string[];
};

enum Actions {
  Assign,
  UnAssign,
  Delete,
}

const actionToMixpanelEventMap: Record<
  Actions.UnAssign | Actions.Delete,
  {
    success: MixPanelEvents;
    failure: MixPanelEvents;
    body: string;
  }
> = {
  [Actions.UnAssign]: {
    success: MixPanelEvents.UN_ASSIGN_INVENTORY_NODE_SUCCESS,
    failure: MixPanelEvents.UN_ASSIGN_INVENTORY_NODE_FAILURE,
    body: 'inventory.actions.unassign.nodeUnassigned',
  },
  [Actions.Delete]: {
    success: MixPanelEvents.DELETE_INVENTORY_NODE_SUCCESS,
    failure: MixPanelEvents.DELETE_INVENTORY_NODE_FAILURE,
    body: 'inventory.actions.delete.nodeDeleted',
  },
};

const useInventoryActions = (): ((
  node: InventoryNode,
) => InventoryNodeAction[]) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const userInfo = useRecoilValue(userInfoAtom);
  const selectedMdu = useRecoilValue(selectedMDUSelector);
  const withConfirmation = useWithConfirmation();
  const residentialUnits = useRecoilValue(residentialUnitsAtom);
  const businessUnits = useRecoilValue(businessUnitsAtom);
  const [propertyNetworks, setPropertyNetworks] = useRecoilState(
    propertyNetworksAtom,
  );
  const setNodes = useSetRecoilState(inventoryNodesAtom);
  const setAssignNodeModalState = useSetRecoilState(assignNodeModalStateAtom);
  const setSelectedNode = useSetRecoilState(selectedNodeAtom);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const { inventoryService } = new DependencyContainer();
  const { updateUnit } = useUnitsUpdateActions();

  const unAssignPropertyNetwork = async (node: InventoryNode) => {
    if (!selectedPartner) {
      throw new Error('Missing parameters');
    }
    const assignedPropertyNetwork: Maybe<PropertyNetwork> = propertyNetworks.find(
      (pn) => pn.id === node.propertyNetwork?.id,
    );

    if (!assignedPropertyNetwork) {
      throw new Error('Missing parameters');
    }
    const updatedPropertyNetwork = await inventoryService.unassignNodeFromPropertyNetwork(
      selectedMdu!.id,
      selectedPartner.id,
      node,
      assignedPropertyNetwork,
    );
    const updatedNode: InventoryNode = {
      ...node,
      status: NodeStatus.Unassigned,
      propertyNetwork: null,
    };

    setNodes((currentNodes) =>
      currentNodes.map((n) => (n.id !== node.id ? n : updatedNode)),
    );

    setPropertyNetworks((propertyNetworks) =>
      propertyNetworks.map((pn) =>
        pn.id === updatedPropertyNetwork.id ? updatedPropertyNetwork : pn,
      ),
    );
  };

  const unAssignUnit = async (node: InventoryNode) => {
    if (!selectedPartner) {
      throw new Error('Missing parameters');
    }
    let unitType: UnitType;
    let assignedUnit: Unit | undefined;
    if (node.residentialUnit?.id) {
      unitType = UnitType.Residence;
      assignedUnit = residentialUnits.find(
        (u) => u.id === node.residentialUnit!.id,
      );
    } else {
      unitType = UnitType.Business;
      assignedUnit = businessUnits.find((u) => u.id === node.businessUnit!.id);
    }

    if (!assignedUnit) {
      throw new Error('Missing parameters');
    }
    const updatedUnit = await inventoryService.unassignNodeFromUnit(
      selectedMdu!.id,
      selectedPartner.id,
      node,
      assignedUnit,
      unitType,
    );
    const updatedNode: InventoryNode = {
      ...node,
      status: NodeStatus.Unassigned,
    };
    if (unitType === UnitType.Business) {
      updatedNode.businessUnit = null;
    } else {
      updatedNode.residentialUnit = null;
    }

    setNodes((currentNodes) =>
      currentNodes.map((n) => (n.id !== node.id ? n : updatedNode)),
    );

    updateUnit(updatedUnit, unitType);
  };

  const deleteAction = async (node: InventoryNode) => {
    if (!selectedPartner) {
      return;
    }
    await inventoryService.deleteNode(
      selectedMdu!.id,
      node,
      selectedPartner.id,
    );

    setNodes((currentNodes) => currentNodes.filter((t) => t.id !== node.id));
  };

  const executeAndNotify = async (
    executeFn: (node: InventoryNode) => Promise<any>,
    node: InventoryNode,
    action: Actions.UnAssign | Actions.Delete,
  ) => {
    try {
      await executeFn(node);

      trackEvent(({
        eventName: actionToMixpanelEventMap[action].success,
        additionalContent: {
          nodeId: node.id,
          propertyId: selectedMdu!.id,
        },
      } as any) as TrackEventParams);

      notify({
        title: t('success'),
        body: t(actionToMixpanelEventMap[action].body),
        type: 'success',
      });
    } catch (error) {
      trackEvent(({
        eventName: actionToMixpanelEventMap[action].failure,
        additionalContent: {
          nodeId: node.id,
          propertyId: selectedMdu!.id,
        },
      } as any) as TrackEventParams);
    }
  };

  const hasPermission = (permission: Permission) => {
    return userInfo?.permissions.includes(permission);
  };

  const hasPermissionForAll = (permissions: Permission[]) => {
    return permissions.every((p) => userInfo?.permissions.includes(p));
  };

  const notAllowedMessage = () => {
    return t('inventory.notForThisUser');
  };

  const availableNodeActions: InventoryNodeAction[] = [
    {
      onClick: (node: InventoryNode) => {
        setSelectedNode(node);
        setAssignNodeModalState(ModalState.Open);
      },
      label: t('inventory.table.actionsDropdown.assign'),
      isVisible: (node: InventoryNode) => {
        return (
          node.status === NodeStatus.Unassigned || !node.stateMeta.realized
        );
      },
      isDisabled: !hasPermissionForAll([
        Permission.updateBusinessUnit,
        Permission.updateResidentialUnit,
        Permission.canUpdateUnitNodes,
      ]),
      subtexts: !hasPermissionForAll([
        Permission.updateBusinessUnit,
        Permission.updateResidentialUnit,
        Permission.canUpdateUnitNodes,
      ])
        ? [notAllowedMessage()]
        : undefined,
    },
    {
      onClick: (node: InventoryNode) =>
        withConfirmation({
          title: t('inventory.actions.unassign.unassignConfirmation.title'),
          body: t('inventory.actions.unassign.unassignConfirmation.body'),
          onConfirm: () =>
            executeAndNotify(
              node.propertyNetwork ? unAssignPropertyNetwork : unAssignUnit,
              node,
              Actions.UnAssign,
            ),
        }),
      label: t('inventory.table.actionsDropdown.unassign'),
      isVisible: (node: InventoryNode) => {
        return node.status === NodeStatus.Assigned || !node.stateMeta.realized;
      },
      isDisabled: !hasPermissionForAll([
        Permission.updateBusinessUnit,
        Permission.updateResidentialUnit,
        Permission.canUpdateUnitNodes,
      ]),
      subtexts: !hasPermissionForAll([
        Permission.updateBusinessUnit,
        Permission.updateResidentialUnit,
        Permission.canUpdateUnitNodes,
      ])
        ? [notAllowedMessage()]
        : undefined,
    },
    {
      onClick: (node: InventoryNode) =>
        withConfirmation({
          title: t('inventory.actions.delete.deleteConfirmation.title'),
          body: t('inventory.actions.delete.deleteConfirmation.body'),
          onConfirm: () => executeAndNotify(deleteAction, node, Actions.Delete),
        }),
      label: t('inventory.table.actionsDropdown.delete'),
      isVisible: (node: InventoryNode) => {
        return (
          node.status === NodeStatus.Unassigned || !node.stateMeta.realized
        );
      },
      isDisabled: !hasPermission(Permission.deleteNode),
      subtexts: !hasPermission(Permission.deleteNode)
        ? [notAllowedMessage()]
        : undefined,
    },
  ];

  return (node: InventoryNode) =>
    availableNodeActions.filter((action) => action.isVisible(node));
};

export default useInventoryActions;
