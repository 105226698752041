import { AxiosResponse } from 'axios';
import { HttpClient } from '../../../HttpClient';
import { UpriseApiResponse } from '../../../types';
import { UpriseApiUrls } from '../../../urls';
import {
  BusinessTenant,
  NewBusinessTenantDto,
  NewResidentialTenantDto,
  ResidentialTenant,
  UpdateTenantDto,
} from '../types';

export default class TenantsClient extends HttpClient {
  async fetchResidentialTenants(
    mduId: string,
    partnerId: string,
    page: number = 1,
  ): Promise<AxiosResponse<UpriseApiResponse<ResidentialTenant[]>>> {
    return await this.get<
      AxiosResponse<UpriseApiResponse<ResidentialTenant[]>>
    >(UpriseApiUrls.getResidentialTenantsUrl(mduId, page, partnerId));
  }

  async fetchBusinessTenants(
    mduId: string,
    partnerId: string,
    page: number = 1,
  ): Promise<AxiosResponse<UpriseApiResponse<BusinessTenant[]>>> {
    return await this.get<AxiosResponse<UpriseApiResponse<BusinessTenant[]>>>(
      UpriseApiUrls.getBusinessTenantsUrl(mduId, page, partnerId),
    );
  }

  async addBusinessTenant(
    mduId: string,
    newTenantDto: NewBusinessTenantDto,
    partnerId: string,
  ): Promise<AxiosResponse<BusinessTenant>> {
    return await this.post<AxiosResponse<BusinessTenant>>(
      UpriseApiUrls.createBusinessTenantsUrl(mduId, partnerId),
      newTenantDto,
    );
  }

  async addResidentialTenant(
    mduId: string,
    newTenantDto: NewResidentialTenantDto,
    partnerId: string,
  ): Promise<AxiosResponse<ResidentialTenant>> {
    return await this.post<AxiosResponse<ResidentialTenant>>(
      UpriseApiUrls.createResidentialTenantsUrl(mduId, partnerId),
      newTenantDto,
    );
  }

  async updateBusinessTenant(
    mduId: string,
    tenant: UpdateTenantDto,
    tenantId: string,
    partnerId: string,
  ): Promise<AxiosResponse<BusinessTenant>> {
    return await this.patch<AxiosResponse<BusinessTenant>>(
      UpriseApiUrls.getUpdateBusinessTenantUrl(mduId, tenantId, partnerId),
      tenant,
    );
  }

  async updateResidentialTenant(
    mduId: string,
    tenant: UpdateTenantDto,
    tenantId: string,
    partnerId: string,
  ): Promise<AxiosResponse<ResidentialTenant>> {
    return await this.patch<AxiosResponse<ResidentialTenant>>(
      UpriseApiUrls.getUpdateResidentialTenantUrl(mduId, tenantId, partnerId),
      tenant,
    );
  }

  async relocateBusinessTenant(
    mduId: string,
    tenant: UpdateTenantDto,
    move_nodes_with_tenant: boolean,
    tenantId: string,
    partnerId: string,
  ): Promise<AxiosResponse<BusinessTenant>> {
    if (move_nodes_with_tenant) {
      return await this.patch<AxiosResponse<BusinessTenant>>(
        UpriseApiUrls.getRelocateBusinessTenantKeepingNodesUrl(
          mduId,
          tenantId,
          partnerId,
        ),
        tenant,
      );
    } else {
      return await this.patch<AxiosResponse<BusinessTenant>>(
        UpriseApiUrls.getRelocateBusinessTenantNewNodesUrl(
          mduId,
          tenantId,
          partnerId,
        ),
        tenant,
      );
    }
  }

  async relocateResidentialTenant(
    mduId: string,
    tenant: UpdateTenantDto,
    move_nodes_with_tenant: boolean = false,
    tenantId: string,
    partnerId: string,
  ): Promise<AxiosResponse<ResidentialTenant>> {
    if (move_nodes_with_tenant) {
      return await this.patch<AxiosResponse<ResidentialTenant>>(
        UpriseApiUrls.getRelocateResidentialTenantKeepingNodesUrl(
          mduId,
          tenantId,
          partnerId,
        ),
        tenant,
      );
    } else {
      return await this.patch<AxiosResponse<ResidentialTenant>>(
        UpriseApiUrls.getRelocateResidentialTenantNewNodesUrl(
          mduId,
          tenantId,
          partnerId,
        ),
        tenant,
      );
    }
  }

  async deleteBusinessTenant(
    mduId: string,
    tenantId: string,
    partnerId: string,
  ): Promise<BusinessTenant> {
    return await this.delete<BusinessTenant>(
      UpriseApiUrls.getDeleteBusinessTenantUrl(mduId, tenantId, partnerId),
    );
  }

  async deleteResidentialTenant(
    mduId: string,
    tenantId: string,
    partnerId: string,
  ): Promise<ResidentialTenant> {
    return await this.delete<ResidentialTenant>(
      UpriseApiUrls.getDeleteResidentialTenantUrl(mduId, tenantId, partnerId),
    );
  }

  async exitResidentialTenant(
    mduId: string,
    tenantId: string,
    partnerId: string,
  ) {
    return await this.delete<ResidentialTenant>(
      UpriseApiUrls.getExitResidentialTenantUrl(mduId, tenantId, partnerId),
    );
  }

  async inviteBusinessTenant(
    mduId: string,
    tenantId: string,
    partnerId: string,
  ): Promise<BusinessTenant> {
    return await this.patch<BusinessTenant>(
      UpriseApiUrls.getPatchBusinessTenantUrl(mduId, tenantId, partnerId),
      { status: 'active' },
    );
  }

  async reinviteBusinessTenant(
    mduId: string,
    tenantId: string,
    partnerId: string,
  ): Promise<BusinessTenant> {
    return await this.patch<BusinessTenant>(
      UpriseApiUrls.getPatchBusinessTenantUrl(mduId, tenantId, partnerId),
      {
        welcomeEmailSent: false,
        status: 'active',
      },
    );
  }

  async inviteResidentialTenant(
    mduId: string,
    tenantId: string,
    partnerId: string,
  ): Promise<ResidentialTenant> {
    return await this.patch<ResidentialTenant>(
      UpriseApiUrls.getPatchResidentialTenantUrl(mduId, tenantId, partnerId),
      { status: 'active' },
    );
  }

  async reinviteResidentialTenant(
    mduId: string,
    tenantId: string,
    partnerId: string,
  ): Promise<ResidentialTenant> {
    return await this.patch<ResidentialTenant>(
      UpriseApiUrls.getPatchResidentialTenantUrl(mduId, tenantId, partnerId),
      {
        welcomeEmailSent: false,
        status: 'active',
      },
    );
  }

  async activateBusinessTenant(
    mduId: string,
    tenantId: string,
    partnerId: string,
  ): Promise<BusinessTenant> {
    return await this.patch<BusinessTenant>(
      UpriseApiUrls.getPatchBusinessTenantUrl(mduId, tenantId, partnerId),
      { status: 'active' },
    );
  }

  async activateResidentialTenant(
    mduId: string,
    tenantId: string,
    partnerId: string,
  ): Promise<ResidentialTenant> {
    return await this.patch<ResidentialTenant>(
      UpriseApiUrls.getPatchResidentialTenantUrl(mduId, tenantId, partnerId),
      { status: 'active' },
    );
  }

  async suspendBusinessTenant(
    mduId: string,
    tenantId: string,
    partnerId: string,
  ): Promise<BusinessTenant> {
    return await this.patch<BusinessTenant>(
      UpriseApiUrls.getPatchBusinessTenantUrl(mduId, tenantId, partnerId),
      { status: 'suspended' },
    );
  }

  async suspendResidentialTenant(
    mduId: string,
    tenantId: string,
    partnerId: string,
  ): Promise<ResidentialTenant> {
    return await this.patch<ResidentialTenant>(
      UpriseApiUrls.getPatchResidentialTenantUrl(mduId, tenantId, partnerId),
      { status: 'suspended' },
    );
  }

  async fetchResidentialTenantById(
    tenantId: string,
    mduId: string,
    partnerId: string,
  ): Promise<AxiosResponse<ResidentialTenant>> {
    return await this.get<AxiosResponse<ResidentialTenant>>(
      UpriseApiUrls.getTenantResidentialById(tenantId, mduId, partnerId),
    );
  }

  async fetchBusinessTenantById(
    tenantId: string,
    mduId: string,
    partnerId: string,
  ): Promise<AxiosResponse<BusinessTenant>> {
    return await this.get<AxiosResponse<BusinessTenant>>(
      UpriseApiUrls.getTenantBusinessById(tenantId, mduId, partnerId),
    );
  }
}
