export enum MixPanelEvents {
  AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS',
  LOGOUT = 'LOGOUT',
  SCREEN = 'SCREEN',

  /* Tenants */
  ACTIVATE_TENANT_SUCCESS = 'ACTIVATE_TENANT_SUCCESS',
  ACTIVATE_TENANT_FAILURE = 'ACTIVATE_TENANT_FAILURE',
  ADD_TENANT_SUCCESS = 'ADD_TENANT_SUCCESS',
  ADD_TENANT_FAILURE = 'ADD_TENANT_FAILURE',
  DELETE_TENANT_SUCCESS = 'DELETE_TENANT_SUCCESS',
  DELETE_TENANT_FAILURE = 'DELETE_TENANT_FAILURE',
  EXIT_TENANT_SUCCESS = 'EXIT_TENANT_SUCCESS',
  EXIT_TENANT_FAILURE = 'EXIT_TENANT_FAILURE',
  INVITE_TENANT_SUCCESS = 'INVITE_TENANT_SUCCESS',
  INVITE_TENANT_FAILURE = 'INVITE_TENANT_FAILURE',
  REINVITE_TENANT_SUCCESS = 'REINVITE_TENANT_SUCCESS',
  REINVITE_TENANT_FAILURE = 'REINVITE_TENANT_FAILURE',
  RELOCATE_TENANT_SUCCESS = 'RELOCATE_TENANT_SUCCESS',
  RELOCATE_TENANT_FAILURE = 'RELOCATE_TENANT_FAILURE',
  SUSPEND_TENANT_SUCCESS = 'SUSPEND_TENANT_SUCCESS',
  SUSPEND_TENANT_FAILURE = 'SUSPEND_TENANT_FAILURE',
  UPDATE_TENANT_SUCCESS = 'UPDATE_TENANT_SUCCESS',
  UPDATE_TENANT_FAILURE = 'UPDATE_TENANT_FAILURE',

  /* MDUs */
  ADD_MDU_SUCCESS = 'ADD_MDU_SUCCESS',
  ADD_MDU_FAILURE = 'ADD_MDU_FAILURE',
  DELETE_MDU_SUCCESS = 'DELETE_MDU_SUCCESS',
  DELETE_MDU_FAILURE = 'DELETE_MDU_FAILURE',

  /* Units */
  ACTIVATE_UNIT_SUCCESS = 'ACTIVATE_UNIT_SUCCESS',
  ACTIVATE_UNIT_FAILURE = 'ACTIVATE_UNIT_FAILURE',
  ADD_UNIT_SUCCESS = 'ADD_UNIT_SUCCESS',
  ADD_UNIT_FAILURE = 'ADD_UNIT_FAILURE',
  MAKE_AVAILABLE_UNIT_SUCCESS = 'MAKE_AVAILABLE_UNIT_SUCCESS',
  MAKE_AVAILABLE_UNIT_FAILURE = 'MAKE_AVAILABLE_UNIT_FAILURE',
  MAKE_UNAVAILABLE_UNIT_SUCCESS = 'MAKE_UNAVAILABLE_UNIT_SUCCESS',
  MAKE_UNAVAILABLE_UNIT_FAILURE = 'MAKE_UNAVAILABLE_UNIT_FAILURE',
  UPDATE_UNIT_SUCCESS = 'UPDATE_UNIT_SUCCESS',
  UPDATE_UNIT_FAILURE = 'UPDATE_UNIT_FAILURE',
  DELETE_UNIT_SUCCESS = 'DELETE_UNIT_SUCCESS',
  DELETE_UNIT_FAILURE = 'DELETE_UNIT_FAILURE',

  /* Nodes */
  UN_ASSIGN_INVENTORY_NODE_SUCCESS = 'UNASSIGN_INVENTORY_NODE_SUCCESS',
  UN_ASSIGN_INVENTORY_NODE_FAILURE = 'UNASSIGN_INVENTORY_NODE_FAILURE',
  ASSIGN_INVENTORY_NODE_TO_UNIT_SUCCESS = 'ASSIGN_INVENTORY_NODE_TO_UNIT_SUCCESS',
  ASSIGN_INVENTORY_NODE_TO_UNIT_FAILURE = 'ASSIGN_INVENTORY_NODE_TO_UNIT_FAILURE',
  DELETE_INVENTORY_NODE_SUCCESS = 'DELETE_INVENTORY_NODE_SUCCESS',
  DELETE_INVENTORY_NODE_FAILURE = 'DELETE_INVENTORY_NODE_FAILURE',
  ADD_INVENTORY_NODE_SUCCESS = 'ADD_INVENTORY_NODE_SUCCESS',
  ADD_INVENTORY_NODE_FAILURE = 'ADD_INVENTORY_NODE_FAILURE',

  /* Property networks */
  EDIT_PROPERTY_NETWORK_SUCCESS = 'EDIT_PROPERTY_NETWORK_SUCCESS',
  EDIT_PROPERTY_NETWORK_FAILURE = 'EDIT_PROPERTY_NETWORK_FAILURE',
  DELETE_PROPERTY_NETWORK_SUCCESS = 'DELETE_PROPERTY_NETWORK_SUCCESS',
  DELETE_PROPERTY_NETWORK_FAILURE = 'DELETE_PROPERTY_NETWORK_FAILURE',
  ADD_PROPERTY_NETWORK_SUCCESS = 'ADD_PROPERTY_NETWORK_SUCCESS',
  ADD_PROPERTY_NETWORK_FAILURE = 'ADD_PROPERTY_NETWORK_FAILURE',

  /* Users */
  ADD_USER_SUCCESS = 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE = 'ADD_USER_FAILURE',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE = 'DELETE_USER_FAILURE',
  EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE = 'EDIT_USER_FAILURE',

  /* IoT Network */
  ADD_IOT_NETWORK = 'ADD_IOT_NETWORK',
  ADD_IOT_NETWORK_FAILURE = 'ADD_IOT_NETWORK_FAILURE',
  DELETE_IOT_NETWORK = 'DELETE_IOT_NETWORK',
  DELETE_IOT_NETWORK_FAILURE = 'DELETE_IOT_NETWORK_FAILURE',
  RETRY_IOT_PROPAGATION = 'RETRY_IOT_PROPAGATION',
  RETRY_IOT_PROPAGATION_FAILURE = 'RETRY_IOT_PROPAGATION_FAILURE',
  DISABLE_UNIT_IOT = 'DISABLE_UNIT_IOT',
  DISABLE_UNIT_IOT_FAILURE = 'DISABLE_UNIT_IOT_FAILURE',
  ENABLE_UNIT_IOT = 'ENABLE_UNIT_IOT',
  ENABLE_UNIT_IOT_FAILURE = 'ENABLE_UNIT_IOT_FAILURE',
}
