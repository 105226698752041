import { uniqueId } from 'lodash';
import { BarLineStatistic, Modal, ModalBody } from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import { ModalBodyStyles } from 'plume-ui/dist/components/Modal/ModalBody';
import { FunctionComponent, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import ModalHeaderWithProperty from '../../../../components/ModalHeaderWithProperty/ModalHeaderWithProperty';
import { UPLOAD_UNITS_WITH_NODES_BATCH_SIZE } from '../../../../config';
import DependencyContainer from '../../../../DependencyContainer';
import { selectedPartnerSelector } from '../../../../state';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { percentageFormatter } from '../../../../utils/percentageFormatter';
import { selectedMduIdAtom } from '../../../editorView/propertyState';
import { useInventory } from '../../../inventory/hooks/useInventory';
import { useUnits } from '../../hooks/useUnits';
import { Unit, UnitStatus, UnitType } from '../../types';
import {
  businessUnitsAtom,
  residentialUnitsAtom,
  unitsSelectedTypeAtom,
} from '../../unitsState';

type ActivateUnitsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ActivateUnitsModal: FunctionComponent<ActivateUnitsModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { runFetch: fetchUnits } = useUnits(false);
  const { runFetch: fetchNodes } = useInventory(false);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const selectedPropertyId = useRecoilValue(selectedMduIdAtom);
  const residentialUnits = useRecoilValue(residentialUnitsAtom);
  const businessUnits = useRecoilValue(businessUnitsAtom);
  const selectedUnitType = useRecoilValue(unitsSelectedTypeAtom);
  const [activatingUnits, setActivatingUnits] = useState(false);
  const [unitsActivated, setUnitsActivated] = useState(0);

  const allUnitsCount = useRef(0);
  const mounted = useRef(false);
  const processID = useRef(uniqueId());

  const { unitsService } = new DependencyContainer();

  useEffect(() => {
    mounted.current = isOpen;
  });

  useEffect(() => {
    if (isOpen) {
      setActivatingUnits(false);
      setUnitsActivated(0);
      allUnitsCount.current = getAllInactiveUnits().length;
      processID.current = uniqueId();
      startUnitActivation();
    }
  }, [isOpen]);

  const onRequestClose = () => {
    fetchUnits();
    fetchNodes();
    setActivatingUnits(false);
    setUnitsActivated(0);
    allUnitsCount.current = 0;
    onClose();
  };

  const activateUnit = async (unit: Unit) => {
    if (!unit) return;
    const currentProcessID = processID.current;
    try {
      await unitsService.updateUnit(
        selectedPropertyId || '',
        {
          status: UnitStatus.Unassigned,
        },
        unit.id,
        selectedUnitType,
        selectedPartner!.id,
      );
    } catch (error) {}
    if (currentProcessID === processID.current) {
      await unitsService.getUnitDetails(
        {
          ...unit,
          tenant: null,
          type: selectedUnitType,
        },
        selectedPartner!.id,
        unit.property.id,
        selectedUnitType,
        false,
      );
      setUnitsActivated((unitsActivated) => unitsActivated + 1);
    }
  };

  const activateBatch = (batch: Unit[]) => {
    let promises = [];
    for (const unit of batch) {
      promises.push(activateUnit(unit));
    }
    return promises;
  };

  const startUnitActivation = async () => {
    if (activatingUnits) return;
    await fetchUnits();
    const units = getAllInactiveUnits();
    setActivatingUnits(true);
    while (units.length !== 0) {
      if (!mounted.current) return;
      let batch = [];
      for (let i = 0; i < UPLOAD_UNITS_WITH_NODES_BATCH_SIZE; i++) {
        if (units.length === 0) break;
        const unit = units.pop();
        if (!unit) continue;
        batch.push(unit);
      }
      await Promise.all(activateBatch(batch));
    }
    await fetchUnits();
    setActivatingUnits(false);
  };

  const getAllInactiveUnits = () => {
    if (selectedUnitType === UnitType.Residence) {
      return residentialUnits.filter(
        (unit) => unit.status === UnitStatus.Inactive,
      );
    }
    if (selectedUnitType === UnitType.Business) {
      return businessUnits.filter(
        (unit) => unit.status === UnitStatus.Inactive,
      );
    }
    return [];
  };

  if (!selectedPropertyId) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      classes={(current: ModalStyles) => ({
        ...current,
        root: `${current.root} ActivateUnitsModal`,
      })}
    >
      <ModalHeaderWithProperty
        propertyName={selectedPropertyId}
        title={
          unitsActivated === allUnitsCount.current
            ? t('units.actions.activate.modalTitleSuccess')
            : t('units.actions.activate.modalTitle')
        }
      />
      <ModalBody
        classes={(current: ModalBodyStyles) => ({
          ...current,
          root: `${current.root} ActivateUnitsModal__body`,
        })}
      >
        <BarLineStatistic
          current={unitsActivated}
          total={allUnitsCount.current}
          valueFormatter={percentageFormatter}
          classes={(current) => ({
            ...current,
            header: `${current.header} ActivateUnitsModal-progress__header`,
            bar: `${current.bar} ActivateUnitsModal-progress__bar`,
            barGuide: `${current.barGuide} ActivateUnitsModal-progress__barGuide`,
            root: `${current.root} ActivateUnitsModal-progress`,
          })}
        />
        <div className="ActivateUnitsModal__title-wrapper">
          {unitsActivated === allUnitsCount.current && <span>🎉</span>}
          <div className="ActivateUnitsModal__title">
            <FormattedMessage
              id={
                unitsActivated === allUnitsCount.current
                  ? 'uploadModal.detailsModal.activatingUnits.successTitle'
                  : 'uploadModal.detailsModal.activatingUnits.title'
              }
              params={{
                activated: unitsActivated,
                total: allUnitsCount.current,
              }}
            />
          </div>
        </div>
        <div className="ActivateUnitsModal__message">
          <FormattedMessage
            id={
              unitsActivated === allUnitsCount.current
                ? 'uploadModal.detailsModal.activatingUnits.successMessage'
                : 'uploadModal.detailsModal.activatingUnits.message'
            }
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default memo(ActivateUnitsModal);
