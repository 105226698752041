import { BarChart, Icons, Status, Tooltip } from 'plume-ui';
import { BarChartDataItem } from 'plume-ui/dist/utils/charts/chartTypes';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { HARMONY_SYNC_ID } from '../../../config';
import { useHarmonyChartGroups } from '../hooks/useHarmonyChartGroups';
import { happinessIndexSelector } from '../state';
import { FrequencyBand, ReferenceLine, TimeRange } from '../types';
import { timeRangeToNumber } from '../util';

const HappinessIndexChart = ({
  frequencyBand,
  referenceLine,
  timeRange,
}: {
  frequencyBand: FrequencyBand;
  referenceLine?: ReferenceLine;
  timeRange: TimeRange;
}) => {
  const { t } = useTranslation();
  const { happinessIndexGroups } = useHarmonyChartGroups();
  const chartData = useRecoilValue<BarChartDataItem[]>(
    happinessIndexSelector({
      frequencyBand,
      timeRange: timeRangeToNumber(timeRange),
    }),
  );
  return (
    <div className="HarmonyContainer__card AuraGlow--card">
      <div className="HarmonyContainer__card__title">
        {t('harmony.chart.happinessIndex.title')}
        <Tooltip
          label={t('harmony.chart.happinessIndex.tooltip')}
          openInPortal
          overrideMaxCharacters
        >
          <Icons.InfoIcon width={16} />
        </Tooltip>
      </div>
      <div className="HarmonyContainer__chartLegend">
        {Array.isArray(chartData) &&
          chartData.length > 0 &&
          happinessIndexGroups.map((item) => (
            <Status
              key={item.label}
              color={item.stroke}
              label={item.label}
              variant="legend"
            />
          ))}
      </div>
      <div className="HarmonyContainer__card__chartContainer">
        <BarChart
          config={{
            barSize: 8,
            xAxisLabelAngle: 0,
            xAxisTickInterval: timeRange === TimeRange.Last7days ? 0 : 4,
            yAxisFormat: 'percentage',
          }}
          data={chartData}
          domainY={[0, 1]}
          groups={happinessIndexGroups}
          invertHoverEffect
          outlineOnHover
          referenceLine={referenceLine}
          roundEveryCell
          syncId={HARMONY_SYNC_ID}
        />
      </div>
    </div>
  );
};

export default memo(HappinessIndexChart);
