import { Switch } from 'plume-ui';
import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import AuraGlow from '../../../components/AuraGlow/AuraGlow';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { anonymizedAtom, userInfoAtom } from '../../../state';
import { Role } from '../../../types';

const ConfigContainer = () => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userInfoAtom);
  const [anonymized, setAnonymized] = useRecoilState(anonymizedAtom);

  return (
    <>
      <Helmet>
        <title>{t('config.title')}</title>
      </Helmet>
      <div className="ConfigContainer p-xl">
        <AuraGlow header footer />
        <PageHeading title="config.title" tooltip="config.tooltip" />
        <div className="ConfigContainer__toggles">
          {userInfo?.role === Role.Support && (
            <Switch
              title={t('config.anonymize')}
              selected={anonymized}
              onToggle={() => {
                if (userInfo?.role !== Role.Support) return;
                setAnonymized(!anonymized);
              }}
              style={{ marginRight: 60 }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default memo(ConfigContainer);
