import { BarLineStatistic } from 'plume-ui';
import React, { memo } from 'react';
import {
  AdjustableComponent,
  useClasses,
} from '../../../../utils/hooks/useClasses';
import { percentageFormatter } from '../../../../utils/percentageFormatter';
import { LargeHeroStatProperty } from './LargeHeroStat';

export type LargeHeroStatPropertiesProps = {
  properties: LargeHeroStatProperty[];
};

export type LargeHeroStatPropertiesStyles = {
  root: string;
  property: string;
  propertyLabel: string;
  propertyValue: string;
};

const getPropertyValue = (property: LargeHeroStatProperty) => {
  if (
    typeof property.value === 'string' ||
    typeof property.value === 'number'
  ) {
    return property.value;
  } else if (typeof property.value.current === 'number') {
    return (
      <>
        {property.value.current} <span>/ {property.value.of}</span>
      </>
    );
  } else {
    return '-';
  }
};

const LargeHeroStatProperties: AdjustableComponent<
  LargeHeroStatPropertiesProps,
  LargeHeroStatPropertiesStyles
> = ({ classes, properties }) => {
  const styles = useClasses(
    {
      root: 'LargeHeroStat__properties',
      property: 'LargeHeroStat__property',
      propertyLabel: 'LargeHeroStat__property-label',
      propertyValue: 'LargeHeroStat__property-value',
    },
    classes,
  );

  return (
    <div className={styles.root}>
      {properties.map((property: LargeHeroStatProperty, index: number) => (
        <div key={index} className={styles.property}>
          {property.isProgressBar ? (
            <BarLineStatistic
              label={property.label}
              hint={property.hint}
              current={property.value.current}
              total={property.value.of}
              valueFormatter={percentageFormatter}
              classes={(current) => ({
                ...current,
                root: `${current.root} LargeHeroStat__property-progress`,
                label: `${current.label} LargeHeroStat__property-progress-label`,
              })}
            />
          ) : (
            <>
              <span className={styles.propertyLabel}>{property.label}</span>
              <span className={styles.propertyValue}>
                {getPropertyValue(property)}
              </span>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default memo(LargeHeroStatProperties);
