import { InventoryNode, NodeReference } from '../types';
import { AxiosResponse } from 'axios';
import { UpriseApiResponse } from '../../../types';
import { HttpClient } from '../../../HttpClient';
import { UpriseApiUrls } from '../../../urls';

type DeleteNodeResponse = {
  count: number;
};

export default class InventoryClient extends HttpClient {
  async fetchInventoryNodes(
    mduId: string,
    partnerId: string,
    page: number = 1,
  ): Promise<AxiosResponse<UpriseApiResponse<InventoryNode[]>>> {
    return await this.get<AxiosResponse<UpriseApiResponse<InventoryNode[]>>>(
      UpriseApiUrls.getInventoryNodeUrl(mduId, page, partnerId),
    );
  }

  async addInventoryNode(
    mduId: string,
    newNode: NodeReference,
    partnerId: string,
  ): Promise<AxiosResponse<InventoryNode>> {
    return await this.post<AxiosResponse<InventoryNode>>(
      UpriseApiUrls.getAddInventoryNodeUrl(mduId, partnerId),
      newNode,
    );
  }

  async deleteInventoryNode(
    mduId: string,
    nodeId: string,
    partnerId: string,
  ): Promise<AxiosResponse<DeleteNodeResponse>> {
    return await this.delete<AxiosResponse<DeleteNodeResponse>>(
      UpriseApiUrls.getDeleteInventoryNodeUrl(mduId, nodeId, partnerId),
    );
  }
}
