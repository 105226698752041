import L, { divIcon } from 'leaflet';
import React, { FunctionComponent, memo } from 'react';
import {
  CircleMarker,
  FeatureGroup,
  LayerGroup,
  MapConsumer,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Tooltip,
  ZoomControl,
} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import { renderToStaticMarkup } from 'react-dom/server';
import useRenderAnonymized from '../../hooks/useRenderAnonymized';
import { MDU } from '../../types';
import icon from './images/marker-icon.png';

let zoomSet = 4;

export type LeafletMarker = MDU & {
  count: number;
};
export type LeafletBlob = LeafletMarker;

type LeafletMapProps = {
  lat?: number;
  long?: number;
  noZoom?: boolean;
  zoom?: number;
  label?: string;
  blobs?: LeafletBlob[];
  markers?: LeafletMarker[];
  onMarkerClick?: (mdu: MDU) => void;
  limit?: number;
  zoomControl?: boolean;
  dragging?: boolean;
};

let blobData: any = [];
let markerData: any = [];

const blobRadius = (count: number): number => {
  if (count < 10) {
    return 2;
  }
  if (count < 50) {
    return 5;
  }
  if (count < 100) {
    return 10;
  }
  if (count < 150) {
    return 15;
  }
  if (count < 200) {
    return 20;
  }
  return 15;
};

const customMarkerIcon = divIcon({
  html: renderToStaticMarkup(<div className="LeafletMap__marker" />),
});

const LeafletMap: FunctionComponent<LeafletMapProps> = ({
  zoom = zoomSet,
  lat = 27.6462,
  long = -81.9227,
  blobs = [],
  markers = [],
  limit = 0,
  onMarkerClick,
  dragging,
}) => {
  const { renderAnonymized } = useRenderAnonymized();
  const reduceBlobs = (): any => {
    if (blobs?.length > 0) {
      const dataMap = new Map();
      blobs.forEach((item: LeafletMarker) => {
        dataMap.set(item.id, item);
      });
      const arr: any = [];
      dataMap.forEach((value, name) => arr.push(value));
      return arr;
    }
    return [];
  };

  const reduceMarkers = (): any => {
    if (markers?.length > 0) {
      const dataMap = new Map();
      markers.forEach((item: LeafletMarker) => {
        dataMap.set(item.id, item);
      });
      const arr: any = [];
      dataMap.forEach((value, name) => arr.push(value));
      return arr;
    }
    return [];
  };

  blobData = reduceBlobs();
  markerData = reduceMarkers();

  if (limit > 0) {
    blobData = blobData.slice(0, limit);
    markerData = markerData.slice(0, limit);
  }

  const blobPathOptions = { color: '#6269FF' };
  L.Marker.prototype.options.icon = L.icon({
    iconUrl: icon,
  });

  /*
  Assigning only one representative Lat/Long per mdu_id (cluster id)
  Size of circle corresponds to the size of cluster

  https://docs.google.com/document/d/1TuTD4BN_p9ARXTLkN-uA_qleUTAbvA1ygBitpyy6H5w/edit#
*/

  const RenderBlobs = (): JSX.Element => {
    return (
      <LayerGroup>
        {blobData.map((loc: LeafletMarker) => (
          <CircleMarker
            key={loc.id}
            stroke={false}
            center={[loc.geoInfo.lat, loc.geoInfo.long]}
            pathOptions={blobPathOptions}
            fillOpacity={0.75}
            radius={blobRadius(loc.count)}
          >
            <Popup>
              MDU ID: {loc.id}
              <br />
              Count: {loc.count}
            </Popup>
          </CircleMarker>
        ))}
      </LayerGroup>
    );
  };

  const RenderCircleMarkers = (): JSX.Element => {
    return (
      <FeatureGroup>
        <MarkerClusterGroup showCoverageOnHover={false}>
          {markerData.map((marker: LeafletMarker) => (
            <LayerGroup key={marker.id}>
              <Marker
                position={[marker.geoInfo.lat, marker.geoInfo.long]}
                icon={customMarkerIcon}
                // @ts-ignore
                eventHandlers={{
                  click: () => {
                    onMarkerClick?.(marker);
                  },
                }}
              >
                <Tooltip direction="top" offset={[1, -7]} opacity={1} permanent>
                  {renderAnonymized(marker.name)}
                </Tooltip>
              </Marker>
            </LayerGroup>
          ))}
        </MarkerClusterGroup>
      </FeatureGroup>
    );
  };

  return (
    <div className="LeafletMap">
      <MapContainer
        center={[lat, long]}
        zoom={zoom}
        minZoom={1}
        zoomControl={false}
        dragging={dragging}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        style={{ height: '100%', width: '100%' }}
        attributionControl={false}
        className="mapZoom-container"
      >
        <ZoomControl position="topright" />
        <MapConsumer>
          {(map: L.Map) => {
            if (markers?.length > 0) {
              const bounds = L.latLngBounds(
                markers?.map((item: LeafletMarker) => [
                  item.geoInfo.lat,
                  item.geoInfo.long,
                ]),
              );
              if (markers?.length > 1) {
                map?.fitBounds(bounds, {
                  padding: [30, 30],
                });
              }
            }
            return null;
          }}
        </MapConsumer>

        <TileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png" />
        <RenderCircleMarkers />
        <RenderBlobs />
      </MapContainer>
    </div>
  );
};

export default memo(LeafletMap);
