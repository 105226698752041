import { useRecoilValue } from 'recoil';
import { userInfoAtom } from '../../state';
import { Permission } from '../../types';

export const useHasPermission = () => {
  const userInfo = useRecoilValue(userInfoAtom);

  const hasPermission = (permission: Permission) => {
    if (userInfo?.permissions.includes(permission)) return true;
    return false;
  };

  return hasPermission;
};
