import { useTranslation } from 'react-i18next';

export const useHarmonyChartGroups = () => {
  const { t } = useTranslation();
  const happinessIndexGroups = [
    {
      label: t('harmony.chart.high'),
      color: 'var(--color-still-200)',
      stroke: 'var(--color-still-200)',
      key: 'countHighHappiness',
    },
    {
      label: t('harmony.chart.medium'),
      color: 'var(--color-hot-400)',
      stroke: 'var(--color-hot-400)',
      key: 'countMediumHappiness',
    },
    {
      label: t('harmony.chart.low'),
      color: 'var(--color-sore-400)',
      stroke: 'var(--color-sore-400)',
      key: 'countLowHappiness',
    },
  ];
  const channelInterferenceGroups = [
    {
      label: t('harmony.chart.low'),
      color: 'var(--color-still-200)',
      stroke: 'var(--color-still-200)',
      key: 'countLowInterference',
    },
    {
      label: t('harmony.chart.medium'),
      color: 'var(--color-hot-400)',
      stroke: 'var(--color-hot-400)',
      key: 'countMediumInterference',
    },
    {
      label: t('harmony.chart.high'),
      color: 'var(--color-sore-400)',
      stroke: 'var(--color-sore-400)',
      key: 'countHighInterference',
    },
  ];
  const channelDistributionGroups = {
    '2.4G': [
      {
        label: '1',
        color: 'var(--color-hot-500)',
        stroke: 'var(--color-hot-500)',
        key: '1',
      },
      {
        label: '6',
        color: 'var(--color-cold-500)',
        stroke: 'var(--color-cold-500)',
        key: '6',
      },
      {
        label: '11',
        color: 'var(--color-good-500)',
        stroke: 'var(--color-good-500)',
        key: '11',
      },
    ],
    '5G': [
      {
        label: '44',
        color: 'var(--color-hot-500)',
        stroke: 'var(--color-hot-500)',
        key: '44',
      },
      {
        label: '60',
        color: 'var(--color-cold-500)',
        stroke: 'var(--color-cold-500)',
        key: '60',
      },
      {
        label: '108',
        color: 'var(--color-good-500)',
        stroke: 'var(--color-good-500)',
        key: '108',
      },
      {
        label: '124',
        color: 'var(--color-freeze-500)',
        stroke: 'var(--color-freeze-500)',
        key: '124',
      },
      {
        label: '157',
        color: 'var(--color-warmX-500)',
        stroke: 'var(--color-warmX-500)',
        key: '157',
      },
    ],
  };
  const txAirtimeUtilizationGroups = [
    {
      label: t('harmony.chart.txAirtime.25percentile'),
      backgroundColor: 'transparent',
      stroke: 'var(--color-hot-200)',
      strokeWidth: 2,
      key: 'p25TxAirtimeUtilization',
    },
    {
      label: t('harmony.chart.txAirtime.median'),
      backgroundColor: 'transparent',
      stroke: 'var(--color-good-400)',
      strokeWidth: 2,
      key: 'medianTxAirtimeUtilization',
    },
    {
      label: t('harmony.chart.txAirtime.75percentile'),
      backgroundColor: 'transparent',
      stroke: 'var(--color-hot-400)',
      strokeWidth: 2,
      key: 'p75TxAirtimeUtilization',
    },
  ];
  return {
    happinessIndexGroups,
    channelInterferenceGroups,
    channelDistributionGroups,
    txAirtimeUtilizationGroups,
  };
};
