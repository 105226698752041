export enum MaintenanceTicketStatus {
  Active = 'active',
  Archived = 'archived',
}

export type MaintenanceTicket = {
  closeReason: string;
  createdAt: Date;
  createdBy: string;
  description: string;
  id: string;
  propertyId: string;
  scheduledMaintenanceDate?: Date;
  status: MaintenanceTicketStatus;
  tenantAvailability: string;
  title: string;
  unitAccess: boolean;
  unitId: string;
  updatedAt: Date;
  urgency: string;
};
