import { FormikValues, useFormikContext } from 'formik';
import React, { memo, useEffect } from 'react';

const FormObserver: React.FunctionComponent<{
  onChange: (values: FormikValues) => void;
}> = ({ onChange }) => {
  const { values } = useFormikContext<FormikValues>();

  useEffect(() => {
    onChange(values);
  }, [values]);

  return null;
};

export default memo(FormObserver);
