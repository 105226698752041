import { memo, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import cx from 'classnames';
import { Icons, Spinner } from 'plume-ui';
import { useTranslation } from 'react-i18next';

type DropAreaProps = { onDocument: (file: File) => void };

const DropArea = ({ onDocument }: DropAreaProps) => {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const [file] = acceptedFiles;
    setIsUploading(true);
    onDocument(file);
    setIsUploading(false);
  }, []);

  const dropzone = useDropzone({
    minSize: 1,
    multiple: false,
    preventDropOnDocument: true,
    noClick: false,
    onDrop,
    disabled: isUploading,
  });

  const onDropAreaClick = () => {
    dropzone.open();
  };

  return (
    <div
      {...dropzone.getRootProps()}
      className={cx('DropArea dropzone', {
        'DropArea--accepted': dropzone.isDragAccept,
        'DropArea--active': dropzone.isDragActive,
      })}
      onClick={onDropAreaClick}
    >
      <input id="upload" {...dropzone.getInputProps()} />
      {isUploading ? (
        <>
          <Spinner visible />
          <p>{t('uploadModal.uploading')}</p>
        </>
      ) : (
        <>
          <Icons.SignOutIcon />
          <p>{t('uploadModal.dragAndDrop')}</p>
        </>
      )}
    </div>
  );
};

export default memo(DropArea);
