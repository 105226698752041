import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DependencyContainer from '../../../DependencyContainer';
import { useFetchData } from '../../../hooks/useFetchData';
import { selectedPartnerSelector, userInfoAtom } from '../../../state';
import { Permission, Role } from '../../../types';
import { User } from '../types';
import {
  usersAtom,
  usersLoadingStatusAtom,
  usersSelectedPageAtom,
} from '../usersState';

type UseUsersValues = {
  loading: boolean;
  error: any;
  runFetch: () => Promise<void>;
};

const { usersService } = new DependencyContainer();

export const useUsers = (runOnInit = true): UseUsersValues => {
  const setUsers = useSetRecoilState(usersAtom);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const userInfo = useRecoilValue(userInfoAtom);
  const setUsersLoadingStatus = useSetRecoilState(usersLoadingStatusAtom);
  const setUsersSelectedPage = useSetRecoilState(usersSelectedPageAtom);

  const [loading, , error, runFetch] = useFetchData<User[], any>(
    async () => {
      if (
        selectedPartner &&
        userInfo &&
        userInfo.permissions.includes(Permission.getUsers) &&
        Object.values(Role).includes(userInfo.role)
      ) {
        return await usersService.getAllUsers(selectedPartner.id);
      }
    },
    (users) => {
      setUsers(users);
      setUsersLoadingStatus('loaded');
    },
    [selectedPartner],
    runOnInit,
  );

  useEffect(() => {
    setUsersSelectedPage(0);
  }, [selectedPartner]);

  useEffect(() => {
    if (loading) {
      setUsersLoadingStatus('loading');
    }
    if (error) {
      setUsersLoadingStatus('error');
    }
  }, [loading, error]);

  return {
    loading,
    error,
    runFetch,
  };
};
