import {
  AreaChart,
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Icons,
  Status,
  Toggler,
} from 'plume-ui';
import React, {
  FunctionComponent,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet-async';

import { ButtonStyles } from 'plume-ui/dist/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import AuraGlow from '../../../components/AuraGlow/AuraGlow';
import LeafletMap from '../../../components/LeafletMap';
import PageHeading from '../../../components/PageHeading/PageHeading';
import useRenderAnonymized from '../../../hooks/useRenderAnonymized';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { selectedMDUSelector, userInfoAtom } from '../../../state';
import { Permission, Required } from '../../../types';
import CreateMduModal from '../../editorView/components/CreateMduModal/CreateMduModal';
import { prepareMarkersForMap } from '../../portfolio/helpers';
import { propertyNetworksAtom } from '../../property-networks/propertyNetworksState';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { AvailableScreens } from '../../trackingAnalytics/types';
import { UnitStatus, UnitType } from '../../units/types';
import {
  businessUnitsAtom,
  residentialUnitsAtom,
} from '../../units/unitsState';
import AlertsModal from '../components/AlertsModal';
import LargeHeroStat, {
  AvailableHeroStats,
  LargeHeroStatProperty,
  statLabelsAndColPositions,
} from '../components/HeroStat/LargeHeroStat';
import {
  LargeHeroStatAlert,
  LargeHeroStatAlertType,
} from '../components/HeroStat/LargeHeroStatAlerts';
import { tickFormatterBitrate } from '../helpers/chartHelpers';
import useChartGroups from '../hooks/useChartGroups';
import {
  OverviewNormalStat,
  OverviewStat,
  useOverviewStats,
} from '../hooks/useOverviewStats';
import {
  overviewStatsAtom,
  overviewStatsLoadingStatusAtom,
  selectedSpeedTestDataSelector,
  selectedSpeedTestTimeRangeAtom,
} from '../overviewState';
import { SpeedTest, SpeedTestChartFilters } from '../types';

const OverviewContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const { renderAnonymizedLeafletMarkers } = useRenderAnonymized();
  const trackEvent = useTrackEvent();
  const userInfo = useRecoilValue(userInfoAtom);
  const selectedMdu = useRecoilValue(selectedMDUSelector);
  useOverviewStats();
  const loadingStatus = useRecoilValue(overviewStatsLoadingStatusAtom);
  const [selectedTimeRange, setSelectedTimeRange] = useRecoilState(
    selectedSpeedTestTimeRangeAtom,
  );
  const speedTestData = useRecoilValue<Array<Required<SpeedTest>>>(
    selectedSpeedTestDataSelector(
      selectedTimeRange === SpeedTestChartFilters.Last7Days ? 7 : 30,
    ),
  );
  const chartGroups = useChartGroups();
  const overviewStats = useRecoilValue(overviewStatsAtom);
  const selectedProperty = useRecoilValue(selectedMDUSelector);
  const businessUnits = useRecoilValue(businessUnitsAtom);
  const residentialUnits = useRecoilValue(residentialUnitsAtom);
  const propertyNetworks = useRecoilValue(propertyNetworksAtom);
  const [editMduModalOpen, setEditMduModalOpen] = useState(false);
  const [alertsModalOpen, setAlertsModalOpen] = useState(false);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Overview,
      },
    });
  }, []);

  const markers = useMemo(() => {
    if (!selectedMdu) return;
    return renderAnonymizedLeafletMarkers(prepareMarkersForMap([selectedMdu]));
  }, [selectedMdu]);

  const mapChartToggleElementsToCopyId = {
    [SpeedTestChartFilters.Last7Days]: 'overview.chart.last7Days',
    [SpeedTestChartFilters.Last30Days]: 'overview.chart.last30Days',
  };

  const chartToggleElements = Object.values(SpeedTestChartFilters).map((v) => ({
    key: v,
    label: t(mapChartToggleElementsToCopyId[v]),
  }));

  const getCommonProperties = (
    type: AvailableHeroStats,
  ): LargeHeroStatProperty[] => {
    if (!overviewStats) return [];
    if (type === AvailableHeroStats.Alerts) return [];
    return [
      {
        label: t('overview.stats.onlineNodes'),
        isProgressBar: false,
        value: {
          current: overviewStats[type].onlineNodes.current,
          of: overviewStats[type].onlineNodes.total,
        },
      },
      {
        label: t('overview.stats.avgSpeed'),
        isProgressBar: false,
        value: overviewStats[type].avgSpeed,
      },
    ];
  };

  const getAlerts = () => {
    let alerts: LargeHeroStatAlert[] = [];
    [
      ...residentialUnits.map((u) => ({ ...u, unitType: UnitType.Residence })),
      ...businessUnits.map((u) => ({ ...u, unitType: UnitType.Business })),
    ].forEach((unit) => {
      if (!unit.stateMeta.realized || unit.status === UnitStatus.Inactive)
        return;
      const alert = {
        unitName: unit.name,
        unitType: unit.unitType,
      };
      if (unit.isLocationOffline) {
        alerts.push({
          ...alert,
          alertType: LargeHeroStatAlertType.LocationOffline,
        });
        return;
      }
      if (unit.isNodeOffline) {
        alerts.push({
          ...alert,
          alertType: LargeHeroStatAlertType.NodeOffline,
        });
        return;
      }
      if (unit.isNodeQoeAlert) {
        alerts.push({
          ...alert,
          alertType: LargeHeroStatAlertType.NodeQoeAlert,
        });
        return;
      }
    });
    propertyNetworks.forEach((propertyNetwork) => {
      if (!propertyNetwork.stateMeta.realized) return;
      if (propertyNetwork.isLocationOffline) {
        alerts.push({
          propertyNetworkName: propertyNetwork.name,
          alertType: LargeHeroStatAlertType.LocationOffline,
        });
        return;
      }
      if (propertyNetwork.isNodeOffline) {
        alerts.push({
          propertyNetworkName: propertyNetwork.name,
          alertType: LargeHeroStatAlertType.NodeOffline,
        });
        return;
      }
      if (propertyNetwork.isNodeQoeAlert) {
        alerts.push({
          propertyNetworkName: propertyNetwork.name,
          alertType: LargeHeroStatAlertType.NodeQoeAlert,
        });
        return;
      }
    });
    return alerts;
  };

  function* getStats(): Generator<OverviewStat> {
    if (!overviewStats) {
      return;
    }
    yield {
      stat: {
        type: AvailableHeroStats.Residences,
        value: overviewStats[AvailableHeroStats.Residences].total,
      },
      properties: [
        {
          label: t('overview.stats.occupancy'),
          isProgressBar: true,
          hint: t('overview.stats.occupancyHint'),
          value: {
            current:
              overviewStats[AvailableHeroStats.Residences].occupancy.current,
            of: overviewStats[AvailableHeroStats.Residences].occupancy.total,
          },
        },
        ...getCommonProperties(AvailableHeroStats.Residences),
      ],
      alerts: overviewStats[AvailableHeroStats.Residences].alertCount,
      updatedAt: overviewStats[AvailableHeroStats.Residences].updatedAt,
    } as OverviewStat;
    yield {
      stat: {
        type: AvailableHeroStats.Businesses,
        value: overviewStats[AvailableHeroStats.Businesses].total,
      },
      properties: [
        {
          label: t('overview.stats.occupancy'),
          isProgressBar: true,
          hint: t('overview.stats.occupancyHint'),
          value: {
            current:
              overviewStats[AvailableHeroStats.Businesses].occupancy.current,
            of: overviewStats[AvailableHeroStats.Businesses].occupancy.total,
          },
        },
        ...getCommonProperties(AvailableHeroStats.Businesses),
      ],
      alerts: overviewStats[AvailableHeroStats.Businesses].alertCount,
      updatedAt: overviewStats[AvailableHeroStats.Businesses].updatedAt,
    };
    yield {
      stat: {
        type: AvailableHeroStats.PropertyNetworks,
        value: overviewStats[AvailableHeroStats.PropertyNetworks].total,
      },
      properties: getCommonProperties(AvailableHeroStats.PropertyNetworks),
      alerts: overviewStats[AvailableHeroStats.PropertyNetworks].alertCount,
      updatedAt: overviewStats[AvailableHeroStats.PropertyNetworks].updatedAt,
    };
    yield {
      stat: {
        type: AvailableHeroStats.Alerts,
      },
      alerts: getAlerts(),
    };
  }

  let stats: OverviewStat[] = [];
  for (const stat of getStats()) {
    stats.push(stat);
  }

  const getOldestUpdatedAt = (
    prevStat: OverviewNormalStat,
    currStat: OverviewNormalStat,
  ) => {
    if (currStat.updatedAt === null) {
      return prevStat;
    } else if (prevStat.updatedAt === null) {
      return currStat;
    } else if (currStat.updatedAt < prevStat.updatedAt) {
      return currStat;
    } else {
      return prevStat;
    }
  };

  const printDataWarning = (loading: String, stats: OverviewStat[]) => {
    if (loading === 'loaded') {
      const filteredStats = stats.filter(
        (element) => element.stat.type !== AvailableHeroStats.Alerts,
      ) as OverviewNormalStat[];
      const oldestUpdatedAt =
        filteredStats.reduce(getOldestUpdatedAt, filteredStats[0])?.updatedAt ||
        '';
      if (oldestUpdatedAt) {
        const oldestDate = new Date(oldestUpdatedAt).getTime();
        const now = new Date().getTime();
        return t('updateDataWarning', {
          minsAgo: Math.floor((now - oldestDate) / (1000 * 60)),
        });
      }
    }
    return t('defaultUpdateDataWarning');
  };

  return (
    <>
      <Helmet>
        <title>{t('overview.title')}</title>
      </Helmet>
      <div className="OverviewContainer p-xl">
        <AuraGlow header footer />
        <Grid>
          <GridItem colSpan="12">
            <PageHeading
              title="overview.title"
              tooltip="overview.tooltip"
              additionalTitle={selectedProperty?.name}
            >
              <div className="OverviewContainer__warning--edit">
                {selectedMdu?.id &&
                  userInfo?.permissions.includes(Permission.createProperty) && (
                    <Button
                      classes={(current: ButtonStyles) => ({
                        ...current,
                        root: `${current.root} OverviewContainer__editButton`,
                      })}
                      styleVariant="tertiary"
                      onClick={() => setEditMduModalOpen(true)}
                    >
                      {t('overview.editButtonLabel')}
                    </Button>
                  )}
                <div className="OverviewContainer__warning">
                  <Icons.ClockHistoryIcon width={16} />
                  <div>{printDataWarning(loadingStatus, stats)}</div>
                </div>
              </div>
            </PageHeading>
          </GridItem>
        </Grid>
        <div className="m-y-m">
          <Grid>
            {loadingStatus === 'loaded' &&
              stats &&
              stats.map((item: OverviewStat) => {
                return (
                  <GridItem
                    colSpan="3"
                    tabletColSpan="6"
                    key={`${item.stat.type}-${
                      statLabelsAndColPositions[item.stat.type]
                    }}`}
                  >
                    <LargeHeroStat
                      {...item}
                      setAlertsModalOpen={setAlertsModalOpen}
                    />
                  </GridItem>
                );
              })}
          </Grid>
        </div>
        <div className="m-y-m">
          <Grid>
            {loadingStatus === 'loaded' && stats && (
              <>
                <GridItem colSpan="6" rowSpan="2">
                  <Box
                    classes={(current) => ({
                      ...current,
                      root: `${current.root} OverviewContainer__box AuraGlow--card AuraGlow--card--big`,
                    })}
                  >
                    <Heading level={3}>{t('overview.chart.title')}</Heading>
                    <Toggler
                      toggleElements={chartToggleElements}
                      value={selectedTimeRange}
                      classes={(current) => ({
                        ...current,
                        root: `${current.root} OverviewContainer__toggler`,
                        label: `${current.label} OverviewContainer__toggler-label`,
                      })}
                      onToggle={(selection) =>
                        setSelectedTimeRange(
                          selection.key as SpeedTestChartFilters,
                        )
                      }
                    />
                    <div className="OverviewContainer__chartContainer">
                      <div className="OverviewContainer__chartContainer__legend">
                        {speedTestData &&
                          speedTestData.length > 0 &&
                          chartGroups.map((item) => (
                            <Status
                              key={item.label}
                              color={item.stroke}
                              label={item.label}
                              variant="legend"
                            />
                          ))}
                      </div>
                      <AreaChart
                        legendPosition="none"
                        data={speedTestData || []}
                        groups={chartGroups}
                        tickFormatter={tickFormatterBitrate}
                        xAxisTickInterval={
                          selectedTimeRange === SpeedTestChartFilters.Last7Days
                            ? 1
                            : 5
                        }
                        fillOpacity={0.4}
                        isStacked={false}
                      />
                    </div>
                  </Box>
                </GridItem>
                <GridItem colSpan="6" rowSpan="2">
                  <div className="OverviewContainer__mapContainer">
                    {selectedMdu && (
                      <LeafletMap
                        markers={markers}
                        lat={
                          markers?.[0].geoInfo.lat || selectedMdu.geoInfo.lat
                        }
                        long={
                          markers?.[0].geoInfo.long || selectedMdu.geoInfo.lat
                        }
                        zoomControl
                        zoom={17}
                      />
                    )}
                  </div>
                </GridItem>
              </>
            )}
          </Grid>
        </div>
      </div>
      {selectedMdu?.id && (
        <CreateMduModal
          isOpen={editMduModalOpen}
          selectedPropertyId={selectedMdu?.id || ''}
          onRequestClose={() => {
            setEditMduModalOpen(false);
          }}
        />
      )}
      <AlertsModal
        isOpen={alertsModalOpen}
        onRequestClose={() => setAlertsModalOpen(false)}
        alerts={
          (stats.find((stat) => Array.isArray(stat.alerts)) || { alerts: [] })
            .alerts as LargeHeroStatAlert[]
        }
      />
    </>
  );
};

export default memo(OverviewContainer);
