import * as yup from 'yup';

const validateWifiPassword = ({
  requiredMessage,
  minLengthMessage,
  maxLengthMessage,
  specialCharacters,
  isRequired = true,
}: {
  requiredMessage: string;
  minLengthMessage: string;
  maxLengthMessage: string;
  specialCharacters: string;
  isRequired?: boolean;
}) => {
  if (isRequired) {
    return yup
      .string()
      .required(requiredMessage)
      .min(8, minLengthMessage)
      .max(63, maxLengthMessage)
      .test('includes-special', specialCharacters, (value) => {
        if (['ö', 'ä', 'ü', 'ß'].some((char) => value?.includes(char))) {
          return false;
        }
        return true;
      });
  }
  return yup
    .string()
    .min(8, minLengthMessage)
    .max(63, maxLengthMessage)
    .test('includes-special', specialCharacters, (value) => {
      if (['ö', 'ä', 'ü', 'ß'].some((char) => value?.includes(char))) {
        return false;
      }
      return true;
    });
};

export default validateWifiPassword;
