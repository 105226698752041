import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DependencyContainer from '../../../DependencyContainer';
import { useFetchData } from '../../../hooks/useFetchData';
import { selectedPartnerSelector } from '../../../state';
import { PropertyNetwork } from '../types';

import { selectedMduIdAtom } from '../../editorView/propertyState';
import {
  propertyNetworksAtom,
  propertyNetworksLoadingStatusAtom,
  propertyNetworksSelectedPageAtom,
} from '../propertyNetworksState';

type UsePropertyNetworksValues = {
  loading: boolean;
  error: any;
  runFetch: () => Promise<void>;
};

const { propertyNetworksService } = new DependencyContainer();

export const usePropertyNetworks = (): UsePropertyNetworksValues => {
  const setPropertyNetworks = useSetRecoilState(propertyNetworksAtom);
  const selectedMduId = useRecoilValue(selectedMduIdAtom);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const setPropertyNetworksSelectedPage = useSetRecoilState(
    propertyNetworksSelectedPageAtom,
  );

  const setPropertyNetworksLoadingStatus = useSetRecoilState(
    propertyNetworksLoadingStatusAtom,
  );

  const [loading, , error, runFetch] = useFetchData<PropertyNetwork[], any>(
    async () => {
      if (selectedMduId && selectedPartner) {
        return await propertyNetworksService.getAllPropertyNetworks(
          selectedMduId,
          selectedPartner.id,
        );
      }
    },
    (networks) => {
      setPropertyNetworks(networks);
      setPropertyNetworksLoadingStatus('loaded');
    },
    [selectedMduId, selectedPartner],
  );

  useEffect(() => {
    setPropertyNetworksSelectedPage(0);
  }, [selectedMduId]);

  useEffect(() => {
    if (loading) {
      setPropertyNetworksLoadingStatus('loading');
    }
    if (error) {
      setPropertyNetworksLoadingStatus('error');
    }
  }, [loading, error]);

  return {
    loading,
    error,
    runFetch,
  };
};
