import { AutoCompleteFilter } from 'plume-ui/dist/components/AutoComplete/AutoComplete';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { selectedMduIdAtom } from '../features/editorView/propertyState';
import { allTenants } from '../features/tenants/tenantsState';
import { allUnits } from '../features/units/unitsState';
import { multiDwellingUnitsAtom } from '../state';
import { UpriseAutocompleteResultsItem } from '../types';
import useRenderAnonymized from './useRenderAnonymized';

const useGlobalSearch = (): {
  globalSearchFn: (query: string) => Promise<UpriseAutocompleteResultsItem[]>;
  availableFilters: AutoCompleteFilter[];
} => {
  const { t } = useTranslation();
  const { renderAnonymized } = useRenderAnonymized();

  const mdus = useRecoilValue(multiDwellingUnitsAtom);
  const units = useRecoilValue(allUnits);
  const tenants = useRecoilValue(allTenants);

  const selectedMduId = useRecoilValue(selectedMduIdAtom);
  const [availableFilters, setAvailableFilters] = useState<
    AutoCompleteFilter[]
  >([
    {
      name: 'property',
      label: 'Property',
    },
  ]);

  const globalSearchFn = (query: string) => {
    query = query || '';

    const filteredMdus = mdus
      .filter((mdu) => mdu.name.toLowerCase().includes(query.toLowerCase()))
      .map((mdu) => ({
        id: mdu.id,
        label: renderAnonymized(mdu.name),
        type: 'property',
      }));

    const filteredUnits = units
      .filter((unit) => unit.name.toLowerCase().includes(query.toLowerCase()))
      .map((unit) => ({
        id: unit.id,
        propertyId: unit.property.id,
        label: renderAnonymized(unit.name),
        type: 'unit',
        unitType: unit.type,
      }));

    const filteredTenants = tenants
      .filter((tenant) =>
        tenant.name.toLowerCase().includes(query.toLowerCase()),
      )
      .map((tenant) => ({
        id: tenant.id,
        propertyId: tenant.propertyId,
        label: renderAnonymized(tenant.name),
        type: 'tenant',
        tenantType: tenant.type,
        tenantStatus: tenant.status,
      }));

    const filteredAccountIds = tenants
      .filter((tenant) =>
        tenant.accountId?.toLowerCase().includes(query.toLowerCase()),
      )
      .map((tenant) => ({
        id: tenant.id,
        propertyId: tenant.propertyId,
        label: renderAnonymized(tenant.accountId || ''),
        tenantName: renderAnonymized(tenant.name),
        type: 'accountId',
        tenantType: tenant.type,
        tenantStatus: tenant.status,
      }));
    const filters = [];
    if (filteredMdus.length) {
      filters.push({
        name: 'property',
        label: t('globalSearch.sectionLabels.properties'),
      });
    }

    if (selectedMduId) {
      if (filteredUnits.length) {
        filters.push({
          name: 'unit',
          label: t('globalSearch.sectionLabels.units'),
        });
      }

      if (filteredTenants.length) {
        filters.push({
          name: 'tenant',
          label: t('globalSearch.sectionLabels.tenants'),
        });
      }

      if (filteredAccountIds.length) {
        filters.push({
          name: 'accountId',
          label: t('globalSearch.sectionLabels.accountId'),
        });
      }
    }

    setAvailableFilters(filters);

    return Promise.resolve([
      ...filteredMdus,
      ...filteredUnits,
      ...filteredTenants,
      ...filteredAccountIds,
    ]);
  };

  return {
    globalSearchFn,
    availableFilters,
  };
};

export default useGlobalSearch;
