import { memo } from 'react';

type AuraGlowProps = {
  header?: boolean;
  footer?: boolean;
};

const AuraGlow = ({ header, footer }: AuraGlowProps) => {
  return (
    <div className="AuraGlow">
      {header && <div className="AuraGlow--header"></div>}
      {footer && <div className="AuraGlow--footer"></div>}
    </div>
  );
};

export default memo(AuraGlow);
