import { notify } from 'plume-ui';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DependencyContainer from '../../../DependencyContainer';
import useWithConfirmation from '../../../hooks/useWithConfirmation';
import { MixPanelEvents } from '../../../mixPanelEvents';
import {
  multiDwellingUnitsAtom,
  selectedPartnerSelector,
  unitModalStateAtom,
  userInfoAtom,
} from '../../../state';
import { MDU, ModalState, ModalType, Permission, Role } from '../../../types';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { allUnits } from '../../units/unitsState';
import { useUsers } from '../../users/hooks/useUsers';
import {
  bulkUploadMduAtom,
  selectedMduIdAtom,
  selectedMduIdInPropertyEditorAtom,
  uploadModalTypeAtom,
} from '../propertyState';

export type PropertyAction = {
  onClick: (property: MDU) => void;
  label: string;
  isVisible: (property: MDU) => boolean; // determines whether to show an action or not
  isDisabled?: boolean; // determines whether to disable an action or not
  subtexts?: string[];
};

const { mduService } = new DependencyContainer();

const usePropertyActions = (): ((property: MDU) => PropertyAction[]) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userInfoAtom);
  const setSelectedMduId = useSetRecoilState(selectedMduIdAtom);
  const setMdus = useSetRecoilState(multiDwellingUnitsAtom);
  const units = useRecoilValue(allUnits);
  const withConfirmation = useWithConfirmation();
  const trackEvent = useTrackEvent();
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const setSelectedMduIdInEditor = useSetRecoilState(
    selectedMduIdInPropertyEditorAtom,
  );
  const setUnitModalState = useSetRecoilState(unitModalStateAtom);
  const setBulkUploadMdu = useSetRecoilState(bulkUploadMduAtom);
  const setUploadModalType = useSetRecoilState(uploadModalTypeAtom);
  const { runFetch: fetchUsers } = useUsers(false);

  const deleteAction = async (propertyId: string) => {
    if (!selectedPartner) {
      return;
    }
    try {
      await mduService.deleteMdu(propertyId, selectedPartner.id);
      setMdus((prev) => prev.filter((mdu) => mdu.id !== propertyId));
      fetchUsers();
      notify({
        title: t('success'),
        body: t('propertyEditor.actions.delete.propertyDeleted'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.DELETE_MDU_SUCCESS,
        additionalContent: {
          ID: propertyId,
        },
      });
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.DELETE_MDU_FAILURE,
        additionalContent: {
          ID: propertyId,
        },
      });
    }
  };

  const addUnit = (propertyId: string) => {
    setSelectedMduId(propertyId);
    setUnitModalState({
      state: ModalState.Open,
      type: ModalType.Create,
    });
  };

  const hasPermissionForAll = (permissions: Permission[]) => {
    return permissions.every((p) => userInfo?.permissions.includes(p));
  };

  const hasPermission = (permission: Permission) => {
    return userInfo?.permissions.includes(permission);
  };

  const reasonIsNotAdmin = () => {
    return t('propertyEditor.onlyAdminsAreAllowed');
  };

  const reasonIsReadOnly = () => {
    return t('propertyEditor.readOnlyIsNotAllowed');
  };

  const propertyActions: PropertyAction[] = [
    {
      onClick: (property: MDU) => setSelectedMduIdInEditor(property.id),
      label: t('propertyEditor.table.actionsDropdown.edit'),
      isVisible: (property: MDU) => true,
      isDisabled: !hasPermission(Permission.updateProperty),
      subtexts: !hasPermission(Permission.updateProperty)
        ? [reasonIsNotAdmin()]
        : undefined,
    },
    {
      onClick: (property: MDU) => addUnit(property.id),
      label: t('propertyEditor.table.actionsDropdown.addUnit'),
      isVisible: (property: MDU) => true,
      isDisabled:
        userInfo?.role !== Role.Support &&
        !hasPermissionForAll([
          Permission.createBusinessUnit,
          Permission.createResidentialUnit,
        ]),
      subtexts: !hasPermissionForAll([
        Permission.createBusinessUnit,
        Permission.createResidentialUnit,
      ])
        ? [reasonIsReadOnly()]
        : undefined,
    },
    {
      onClick: (property: MDU) => {
        setUploadModalType('bulkUpload');
        setSelectedMduId(property.id);
        setBulkUploadMdu(property);
      },
      label: t('propertyEditor.table.actionsDropdown.bulkUpload'),
      isVisible: (property: MDU) => true,
      isDisabled:
        userInfo?.role !== Role.Support &&
        !hasPermissionForAll([
          Permission.bulkCreateNodes,
          Permission.bulkCreateUnits,
        ]),
      subtexts: !hasPermissionForAll([
        Permission.bulkCreateNodes,
        Permission.bulkCreateUnits,
      ])
        ? [reasonIsNotAdmin()]
        : [t('propertyEditor.table.actionsDropdown.unitsAndInventory')],
    },
    {
      onClick: (property: MDU) => {
        setUploadModalType('importTenants');
        setSelectedMduId(property.id);
        setBulkUploadMdu(property);
      },
      label: t('propertyEditor.table.actionsDropdown.importTenants'),
      isVisible: (property: MDU) => true,
      isDisabled:
        userInfo?.role !== Role.Support &&
        !hasPermission(Permission.bulkImportTenants),
      subtexts: !hasPermission(Permission.bulkImportTenants)
        ? [reasonIsNotAdmin()]
        : [t('propertyEditor.table.actionsDropdown.fromFrontline')],
    },
    {
      onClick: (property: MDU) => {
        setUploadModalType('exitTenants');
        setSelectedMduId(property.id);
        setBulkUploadMdu(property);
      },
      label: t('propertyEditor.table.actionsDropdown.exitTenants'),
      isVisible: (property: MDU) => true,
      isDisabled:
        userInfo?.role !== Role.Support &&
        !hasPermission(Permission.bulkExitTenants),
      subtexts: !hasPermission(Permission.bulkExitTenants)
        ? [reasonIsNotAdmin()]
        : [t('propertyEditor.table.actionsDropdown.backToFrontline')],
    },
    {
      onClick: (property: MDU) =>
        withConfirmation({
          title: t('propertyEditor.actions.delete.deleteConfirmation.title'),
          body: t('propertyEditor.actions.delete.deleteConfirmation.body'),
          onConfirm: () => deleteAction(property.id),
        }),
      label: t('propertyEditor.table.actionsDropdown.delete'),
      // we show Delete action only when there are no related Units
      isVisible: (property: MDU) =>
        !units.some((unit) => unit.property?.id === property.id),
      isDisabled: !hasPermission(Permission.deleteProperty),
      subtexts: !hasPermission(Permission.deleteProperty)
        ? [reasonIsNotAdmin()]
        : undefined,
    },
  ];

  const getPropertyActions = (property: MDU) => {
    return propertyActions.filter((action) => action.isVisible(property));
  };

  return getPropertyActions;
};

export default usePropertyActions;
