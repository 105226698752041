import { useFetchData } from '../../../hooks/useFetchData';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AllUnitsResponseData } from '../types';
import { selectedPartnerSelector } from '../../../state';
import DependencyContainer from '../../../DependencyContainer';
import {
  businessUnitsAtom,
  residentialUnitsAtom,
  unitsBusinessSelectedPageAtom,
  unitsLoadingStatusAtom,
  unitsResidentialSelectedPageAtom,
} from '../unitsState';
import { useEffect } from 'react';
import { selectedMduIdAtom } from '../../editorView/propertyState';

type UseUnitsValues = {
  loading: boolean;
  error: any;
  runFetch: () => Promise<void>;
};

const { unitsService } = new DependencyContainer();

export const useUnits = (runOnInit = true): UseUnitsValues => {
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const selectedMduId = useRecoilValue(selectedMduIdAtom);
  const setUnitsLoadingStatus = useSetRecoilState(unitsLoadingStatusAtom);
  const setBusinessUnits = useSetRecoilState(businessUnitsAtom);
  const setResidentialUnits = useSetRecoilState(residentialUnitsAtom);
  const setUnitsBusinessSelectedPage = useSetRecoilState(
    unitsBusinessSelectedPageAtom,
  );
  const setUnitsResidentialSelectedPage = useSetRecoilState(
    unitsResidentialSelectedPageAtom,
  );

  const [loading, , error, runFetch] = useFetchData<
    AllUnitsResponseData,
    unknown
  >(
    async () => {
      if (selectedMduId && selectedPartner) {
        return await unitsService.getAllUnitsForMdu(
          selectedMduId,
          selectedPartner.id,
        );
      }
    },
    ({ residentialUnits, businessUnits }) => {
      setBusinessUnits(businessUnits);
      setResidentialUnits(residentialUnits);
      setUnitsLoadingStatus('loaded');
    },
    [selectedMduId, selectedPartner],
    runOnInit,
  );

  useEffect(() => {
    setUnitsBusinessSelectedPage(0);
    setUnitsResidentialSelectedPage(0);
  }, [selectedMduId]);

  useEffect(() => {
    if (loading) {
      setUnitsLoadingStatus('loading');
    }
    if (error) {
      setUnitsLoadingStatus('error');
    }
  }, [loading, error]);

  return {
    loading,
    error,
    runFetch,
  };
};
