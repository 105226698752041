import { Button } from 'plume-ui';
import React, { FunctionComponent, memo } from 'react';
import FormattedMessage from '../../utils/components/FormattedMessage';
import Submitting from '../Submitting/Submitting';

type SubmitButtonProps = {
  submitInProgress: boolean;
  label?: string;
  inProgressLabel?: string;
  disabled?: boolean;
  onSubmit?: () => void;
};

const SubmitButton: FunctionComponent<SubmitButtonProps> = ({
  submitInProgress,
  label,
  inProgressLabel,
  disabled,
  onSubmit,
}) => {
  return (
    <Button
      styleVariant="superprimary"
      onClick={onSubmit}
      disabled={disabled}
      selected={submitInProgress}
      classes={(current) => ({
        ...current,
        root: `${current.root} m-l-xs SubmitButton`,
      })}
    >
      {submitInProgress ? (
        <Submitting inProgressLabel={inProgressLabel}></Submitting>
      ) : (
        <FormattedMessage id={label ? label : 'submit'} />
      )}
    </Button>
  );
};

export default memo(SubmitButton);
