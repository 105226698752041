import { Routes } from '../../config';
import { generatePath, useHistory } from 'react-router-dom';

export const useRedirectToRoute = () => {
  const history = useHistory();
  return (routeName: Routes, params?: any, from?: string) => {
    const path = generatePath(routeName, params || {});
    history.push(path, { from });
  };
};
