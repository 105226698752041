export enum TimeRange {
  Last7days = 'last7days',
  Last30days = 'last30days',
}

export const isTimeRange = (value: any): value is TimeRange => {
  return Object.values(TimeRange).includes(value);
};

export enum FrequencyBand {
  TwoPointFour = '2.4G',
  Five = '5G',
}

export const isFrequencyBand = (value: any): value is FrequencyBand => {
  return Object.values(FrequencyBand).includes(value);
};

export type ReferenceLine = {
  x: string;
  position: 'start' | 'middle' | 'end';
  tooltipHeading: string;
  tooltipLabel: string;
};

export type HarmonyChannelInterference = {
  [x in FrequencyBand]: BandChannelInterference[];
};

export type BandChannelInterference = {
  countLowInterference: number;
  countMediumInterference: number;
  countHighInterference: number;
  dt: string;
  frequencyBand: FrequencyBand;
};

export type HarmonyChannelDistribution = {
  [x in FrequencyBand]: BandChannelDistribution[];
};

export type BandChannelDistribution = {
  dt: string;
  frequencyBand: FrequencyBand;
  frequencyChannels: {
    frequencyChannel: number;
    channelLocationCount: number;
  }[];
};

export type HarmonyHappinessIndex = {
  [x in FrequencyBand]: BandHappinessIndex[];
};

export type BandHappinessIndex = {
  countHighHappiness: number;
  countLowHappiness: number;
  countMediumHappiness: number;
  dt: string;
  frequencyBand: FrequencyBand;
};

export type HarmonyTxAirtimeUtilization = {
  [x in FrequencyBand]: BandTxAirtimeUtilization[];
};

export type BandTxAirtimeUtilization = {
  dt: string;
  frequencyBand: FrequencyBand;
  medianTxAirtimeUtilization: number;
  p25TxAirtimeUtilization: number;
  p75TxAirtimeUtilization: number;
};
