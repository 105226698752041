import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import React, { FunctionComponent, memo } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import DependencyContainer from './DependencyContainer';
import ForAuthenticated from './ForAuthenticated';
import { LOGIN_SCREEN_ROUTE, Routes } from './config';
import { getOktaClientId, getOktaIssuer } from './environment';
import LoginCallbackError from './features/login/components/LoginCallbackError';
import LoginContainer from './features/login/containers/LoginContainer';
import TenantsContainer from './features/tenants/containers/TenantsContainer';
import AppLayout from './layouts/AppLayout';
import './root.scss';
import { LoginUrls } from './urls';
import { useRedirectToRoute } from './utils/hooks/useRedirectToRoute';

const redirectUri = LoginUrls.getRedirectUriForOkta();
const oktaAuth = new OktaAuth({
  issuer: getOktaIssuer(),
  clientId: getOktaClientId(),
  redirectUri,
  postLogoutRedirectUri: redirectUri,
  scopes: ['openid', 'email', 'uprise'],
  responseType: 'id_token',
  tokenManager: {
    autoRenew: true,
  },
});
const { loginService } = new DependencyContainer();
const restoreOriginalUri = async (oktaAuth: OktaAuth, originalUri: string) => {
  const targetRoute = loginService.getTargetRoute();
  if (targetRoute) {
    window.location.href = targetRoute;
    loginService.removeTargetRoute();
    return;
  }
  const url = originalUri
    ? toRelativeUrl(originalUri, window.location.origin)
    : Routes.Index;
  window.location.href = url;
};

const AppContainer: FunctionComponent = () => {
  const { pathname } = useLocation();
  const redirectToRoute = useRedirectToRoute();
  const onAuthRequired = () => {
    if (pathname === LOGIN_SCREEN_ROUTE) {
      return;
    }
    loginService.setTargetRoute(pathname);
    redirectToRoute(LOGIN_SCREEN_ROUTE);
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={onAuthRequired}
      restoreOriginalUri={restoreOriginalUri}
    >
      <div className="AppContainer">
        <Switch>
          <Route path={Routes.Login} component={LoginContainer} />
          <Route path={Routes.LoginCallback}>
            <LoginCallback errorComponent={LoginCallbackError} />
          </Route>
          <SecureRoute
            path={[Routes.Partner, Routes.PartnerHome, Routes.Config]}
          >
            <ForAuthenticated>
              <AppLayout>
                <TenantsContainer />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <Route>
            <Redirect to={Routes.PartnerHome} />
          </Route>
        </Switch>
      </div>
    </Security>
  );
};

export default memo(AppContainer);
