import { User, UserDto } from '../types';
import { AxiosResponse } from 'axios';
import { UpriseApiResponse } from '../../../types';
import { HttpClient } from '../../../HttpClient';
import { UpriseApiUrls } from '../../../urls';

type DeleteUserResponse = {
  count: number;
};

export default class UsersClient extends HttpClient {
  async fetchUsers(
    partnerId: string,
    page: number = 1,
  ): Promise<AxiosResponse<UpriseApiResponse<User[]>>> {
    return await this.get<AxiosResponse<UpriseApiResponse<User[]>>>(
      UpriseApiUrls.getUsersUrl(page, partnerId),
    );
  }

  async addUser(
    newUser: UserDto,
    partnerId: string,
  ): Promise<AxiosResponse<User>> {
    return await this.post<AxiosResponse<User>>(
      UpriseApiUrls.getAddUserUrl(partnerId),
      newUser,
    );
  }

  async updateUser(
    updateUser: UserDto,
    partnerId: string,
    userId: string,
  ): Promise<AxiosResponse<User>> {
    return await this.patch<AxiosResponse<User>>(
      UpriseApiUrls.getUpdateUserUrl(partnerId, userId),
      updateUser,
    );
  }

  async deleteUser(
    userId: string,
    partnerId: string,
  ): Promise<AxiosResponse<DeleteUserResponse>> {
    return await this.delete<AxiosResponse<DeleteUserResponse>>(
      UpriseApiUrls.getDeleteUserUrl(userId, partnerId),
    );
  }
}
