import { HeroStat, PendingContent, Spinner } from 'plume-ui';
import { HeroStatStyles } from 'plume-ui/dist/components/HeroStat/HeroStat';
import { PendingContentStyles } from 'plume-ui/dist/components/PendingContent/PendingContent';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import LeafletMap from '../../components/LeafletMap';
import { Routes } from '../../config';
import useRenderAnonymized from '../../hooks/useRenderAnonymized';
import { multiDwellingUnitsAtom, selectedPartnerSelector } from '../../state';
import { MDU } from '../../types';
import { useRedirectToRoute } from '../../utils/hooks/useRedirectToRoute';
import { selectedMduIdAtom } from '../editorView/propertyState';
import { prepareMarkersForMap } from '../portfolio/helpers';
import { HeroDataItem } from '../portfolio/types';

const MapViewContainer = ({
  heroData,
  statsError,
  statsLoading,
}: {
  heroData: HeroDataItem[];
  statsError: any;
  statsLoading: boolean;
}) => {
  const { t } = useTranslation();
  const { renderAnonymizedLeafletMarkers } = useRenderAnonymized();
  const redirectToRoute = useRedirectToRoute();
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const multiDwellingUnits = useRecoilValue(multiDwellingUnitsAtom);
  const setSelectedMduId = useSetRecoilState(selectedMduIdAtom);

  const onMarkerClick = (mdu: MDU) => {
    setSelectedMduId(mdu.id);
    redirectToRoute(Routes.Overview, {
      propertyId: mdu.id,
      partnerId: selectedPartner!.id,
    });
  };

  return (
    <div className="MapViewContainer">
      <PerfectScrollbar
        className="MapViewContainer__hero-stats__container"
        style={{ maxWidth: '100%' }}
      >
        <PendingContent
          classes={(current: PendingContentStyles) => ({
            ...current,
            root: `${current.root} MapViewContainer__hero-stats`,
          })}
          loader={Spinner}
          loading={statsLoading}
          isError={Boolean(statsError)}
        >
          {heroData.map((data: HeroDataItem) => (
            <div
              className="MapViewContainer__hero-stat"
              key={`${data.title}-herostat`}
            >
              <HeroStat
                statLabel={t(data.title)}
                stat={data.val}
                icon={data.icon}
                iconColor={
                  data.iconColor === 'gray' ? undefined : data.iconColor
                }
                classes={(current: HeroStatStyles) => ({
                  ...current,
                  statLabel: `${current.statLabel} MapViewContainer__hero-stat--label`,
                  iconContainer: `${
                    current.iconContainer
                  } MapViewContainer__hero-stat__container ${
                    data.iconColor === 'gray'
                      ? 'HeroStat__iconContainer__color--gray'
                      : ''
                  }`,
                })}
              />
            </div>
          ))}
        </PendingContent>
      </PerfectScrollbar>
      <div className="MapViewContainer__map-wrapper">
        <LeafletMap
          markers={renderAnonymizedLeafletMarkers(
            prepareMarkersForMap(multiDwellingUnits),
          )}
          onMarkerClick={onMarkerClick}
          dragging
          zoomControl
        />
      </div>
    </div>
  );
};

export default memo(MapViewContainer);
