import cx from 'classnames';
import { Icons, Tooltip } from 'plume-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { conditionallyTruncate } from '../../../components/StickyActionTable/util';
import useRenderAnonymized from '../../../hooks/useRenderAnonymized';
import { UnitNodeConnectionStates } from '../../units/types';
import {
  PropertyNetwork,
  PropertyNetworkDetails,
  PropertyNetworkStatus,
} from '../types';

const propertyNetworkStatusTranslationMap: Record<
  PropertyNetworkStatus,
  string
> = {
  [PropertyNetworkStatus.LocationOffline]:
    'units.connectionStatus.locationOffline',
  [PropertyNetworkStatus.NodeOffline]: 'units.connectionStatus.nodeOffline',
  [PropertyNetworkStatus.NodeQoeAlert]: 'units.connectionStatus.nodeQoeAlert',
  [PropertyNetworkStatus.NoNodes]: 'units.connectionStatus.noNodes',
  [PropertyNetworkStatus.Online]: 'units.connectionStatus.online',
};

const propertyNetworkStatusColorCodeMap: Record<
  PropertyNetworkStatus,
  string
> = {
  [PropertyNetworkStatus.LocationOffline]: 'error',
  [PropertyNetworkStatus.NodeOffline]: 'warning',
  [PropertyNetworkStatus.NodeQoeAlert]: 'warning',
  [PropertyNetworkStatus.NoNodes]: 'unknown',
  [PropertyNetworkStatus.Online]: 'ok',
};

export const retrieveConnectionStatus = (
  propertyNetwork: PropertyNetwork,
  details?: PropertyNetworkDetails | null,
): PropertyNetworkStatus => {
  if (details === null) {
    return PropertyNetworkStatus.NoNodes;
  }
  if (details === undefined) {
    if (propertyNetwork.isLocationOffline) {
      return PropertyNetworkStatus.LocationOffline;
    }
    if (propertyNetwork.isNodeOffline) {
      return PropertyNetworkStatus.NodeOffline;
    }
    if (propertyNetwork.isNodeQoeAlert) {
      return PropertyNetworkStatus.NodeQoeAlert;
    }
    return PropertyNetworkStatus.Online;
  }
  const countOfflineNodes = details.nodes.filter(
    (n) =>
      !n.connectionState ||
      n.connectionState === UnitNodeConnectionStates.Offline ||
      n.connectionState === UnitNodeConnectionStates.Unavailable,
  ).length;
  const countOnlineNodes = details.nodes.filter(
    (n) => n.connectionState === UnitNodeConnectionStates.Online,
  ).length;
  const countTotalNodes = details.nodes.length;
  let connectionStatus: PropertyNetworkStatus;

  if (countOfflineNodes === countTotalNodes) {
    connectionStatus = PropertyNetworkStatus.LocationOffline;
  } else if (countOnlineNodes < countTotalNodes) {
    connectionStatus = PropertyNetworkStatus.NodeOffline;
  } else {
    connectionStatus = PropertyNetworkStatus.Online;
  }
  const hasAtLeastOneNodeWithAlerts = details.nodes.find((n) =>
    Boolean(n.alerts?.length),
  );
  if (hasAtLeastOneNodeWithAlerts !== undefined) {
    connectionStatus = PropertyNetworkStatus.NodeQoeAlert;
  }
  return connectionStatus;
};

export type UsePropertyNetworksMappingsValues = {
  renderPropertyNetworkName: (propertyNetwork: PropertyNetwork) => JSX.Element;
  renderConnectionStatus: (
    propertyNetwork: PropertyNetwork,
    details?: PropertyNetworkDetails | null,
  ) => string;
  getConnectionStatusColorCode: (
    propertyNetwork: PropertyNetwork,
    details?: PropertyNetworkDetails | null,
  ) => any;
};

const usePropertyNetworksMappings = (): UsePropertyNetworksMappingsValues => {
  const { t } = useTranslation();
  const { renderAnonymized } = useRenderAnonymized();

  const renderPropertyNetworkName = (propertyNetwork: PropertyNetwork) => {
    const classNames = cx({
      unrealized: !propertyNetwork.stateMeta.realized,
    });
    return (
      <div className="PropertyNetworksContainer__name">
        <span className={classNames}>
          {conditionallyTruncate(renderAnonymized(propertyNetwork.name), 50)}
        </span>
        {propertyNetwork.iotWifi?.enabled && (
          <Tooltip openInPortal label={t('propertyNetworks.iotActive')}>
            <Icons.WifiIcon width={16} height={16} />
          </Tooltip>
        )}
      </div>
    );
  };

  const renderConnectionStatus = (
    propertyNetwork: PropertyNetwork,
    details?: PropertyNetworkDetails | null,
  ) => {
    const connectionStatus = retrieveConnectionStatus(propertyNetwork, details);
    const translationRef =
      propertyNetworkStatusTranslationMap[connectionStatus];
    return t(translationRef);
  };

  const getConnectionStatusColorCode = (
    propertyNetwork: PropertyNetwork,
    details?: PropertyNetworkDetails | null,
  ) => {
    const connectionStatus = retrieveConnectionStatus(propertyNetwork, details);
    return propertyNetworkStatusColorCodeMap[connectionStatus];
  };

  return {
    renderPropertyNetworkName,
    renderConnectionStatus,
    getConnectionStatusColorCode,
  };
};
export default usePropertyNetworksMappings;
