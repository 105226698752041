import { atom, selector } from 'recoil';
import { InventoryNode, SortableNodeFieldNames } from './types';
import { ModalState } from '../../types';
import { FieldSort } from 'plume-ui/dist/components/Table/Table';
import { LoadingStatus } from '../units/types';
import { DEFAULT_PAGE_SIZE } from '../../config';
import { renderAssignedText } from './utils';

export const inventoryNodesAtom = atom<InventoryNode[]>({
  key: 'inventoryNodesAtom',
  default: [],
});

export const inventorySelectedFilterAtom = atom<string>({
  key: 'inventorySelectedFilterAtom',
  default: '',
});

export const inventorySelectedPageAtom = atom<number>({
  key: 'inventorySelectedPageAtom',
  default: 0,
});

export const addNodeModalStateAtom = atom<ModalState>({
  key: 'addNodeModalStateAtom',
  default: ModalState.Dismissed,
});

export const assignNodeModalStateAtom = atom<ModalState>({
  key: 'assignNodeModalStateAtom',
  default: ModalState.Dismissed,
});

export const selectedNodeAtom = atom<InventoryNode | null>({
  key: 'selectedNodeAtom',
  default: null,
});

export const nodesSortAtom = atom<FieldSort | null>({
  key: 'nodesSortAtom',
  default: null,
});

export const nodesLoadingStatusAtom = atom<LoadingStatus>({
  key: 'nodesLoadingStatusAtom',
  default: 'loading',
});

export const sortedNodes = selector<InventoryNode[]>({
  key: 'sortedNodes',
  get: ({ get }) => {
    const nodes = get(inventoryNodesAtom);
    const sort = get(nodesSortAtom);
    if (sort) {
      return sortNodes([...nodes], sort);
    }
    return nodes;
  },
});

const sortNodes = (
  nodes: InventoryNode[],
  sort: FieldSort,
): InventoryNode[] => {
  const fieldName = sort.fieldName as SortableNodeFieldNames;
  switch (fieldName) {
    case 'assignedTo':
      return nodes.sort((a: InventoryNode, b: InventoryNode) => {
        return renderAssignedText(a) < renderAssignedText(b)
          ? -1 * sort.direction
          : 1 * sort.direction;
      });
    default:
      return nodes.sort(
        (a, b) => a[fieldName]!.localeCompare(b[fieldName]!) * sort.direction,
      );
  }
};

const filteredNodes = selector<InventoryNode[]>({
  key: 'filteredNodes',
  get: ({ get }) => {
    let nodes = get(sortedNodes);
    const filter = get(inventorySelectedFilterAtom);

    if (filter) {
      nodes = nodes.filter((node) =>
        node.id.toLocaleLowerCase().includes(filter.toLocaleLowerCase()),
      );
    }

    return nodes;
  },
});

export const currentNodesPage = selector<InventoryNode[]>({
  key: 'currentNodesPage',
  get: ({ get }) => {
    let nodes = get(filteredNodes);
    const selectedPage = get(inventorySelectedPageAtom);

    return nodes.slice(
      selectedPage * DEFAULT_PAGE_SIZE,
      (selectedPage + 1) * DEFAULT_PAGE_SIZE,
    );
  },
});

export const filteredNodesCount = selector<number>({
  key: 'filteredNodesCount',
  get: ({ get }) => {
    return get(filteredNodes).length;
  },
});
