import { Icons } from 'plume-ui';
import { GroupContent } from 'plume-ui/dist/components/Menu/Menu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Routes, routesRequiringProperty } from '../config';
import {
  selectedMDUSelector,
  selectedPartnerIdAtom,
  userInfoAtom,
} from '../state';
import { Permission } from '../types';
import { isDevelopment, isLocal } from '../utils/environment';
import { useGetCurrentRoute } from '../utils/hooks/useGetCurrentRoute';
import { useRedirectToRoute } from '../utils/hooks/useRedirectToRoute';
import useFeatureFlags, { FeatureFlags } from './useFeatureFlags';

const useMenu = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { t } = useTranslation();
  const getCurrentRoute = useGetCurrentRoute();
  const redirectToRoute = useRedirectToRoute();
  const selectedMdu = useRecoilValue(selectedMDUSelector);
  const selectedPartnerId = useRecoilValue(selectedPartnerIdAtom);
  const userInfo = useRecoilValue(userInfoAtom);

  const currentRoute = getCurrentRoute();
  const isRouteDisabled = (route: Routes) =>
    selectedMdu === undefined && routesRequiringProperty.includes(route);

  const getPartnerParams = () => ({
    partnerId: selectedPartnerId,
  });

  const getPropertyParams = () => ({
    propertyId: selectedMdu!.id,
    partnerId: selectedPartnerId,
  });

  const menuGroupPortfolio = [
    {
      name: t('menu.portfolio'),
      iconLeft: <Icons.PropertiesIcon width={24} />,
      iconLeftSelected: <Icons.PropertiesFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () => redirectToRoute(Routes.Portfolio, getPartnerParams()),
      isSelected: currentRoute === Routes.Portfolio,
    },
    {
      name: t('menu.users'),
      iconLeft: <Icons.PeopleTwoIcon width={24} />,
      iconLeftSelected: <Icons.PeopleTwoFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () => redirectToRoute(Routes.Users, getPartnerParams()),
      isSelected: currentRoute === Routes.Users,
      isDisabled:
        userInfo === undefined ||
        !userInfo.permissions.includes(Permission.getUsers),
    },
  ];

  const menuGroupOperations = [
    {
      name: t('menu.pmDashboard'),
      iconLeft: <Icons.StarIcon width={24} />,
      iconLeftSelected: <Icons.StarIcon width={24} />,
      isSelected: currentRoute === Routes.PMDashboard,
      onItemClick: () =>
        redirectToRoute(Routes.PMDashboard, getPropertyParams()),
    },
    {
      name: t('menu.maintenance'),
      iconLeft: <Icons.StarIcon width={24} />,
      iconLeftSelected: <Icons.StarIcon width={24} />,
      isSelected: [Routes.Maintenance, Routes.Ticket].includes(currentRoute),
      onItemClick: () =>
        redirectToRoute(Routes.Maintenance, getPropertyParams()),
    },
    {
      name: t('menu.documents'),
      iconLeft: <Icons.StarIcon width={24} />,
      iconLeftSelected: <Icons.StarIcon width={24} />,
      isSelected: currentRoute === Routes.Documents,
      onItemClick: () => redirectToRoute(Routes.Documents, getPropertyParams()),
    },
  ];

  const menuGroupManagement = [
    {
      name: t('menu.overview'),
      iconLeft: <Icons.GraphBarIcon width={24} />,
      iconLeftSelected: <Icons.GraphBarFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () =>
        selectedMdu !== undefined
          ? redirectToRoute(Routes.Overview, getPropertyParams())
          : null,
      isSelected: currentRoute === Routes.Overview,
      isDisabled: isRouteDisabled(Routes.Overview),
    },
    {
      name: t('menu.units'),
      iconLeft: <Icons.PhotoSelectIcon width={24} />,
      iconLeftSelected: <Icons.PhotoSelectFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () =>
        selectedMdu !== undefined
          ? redirectToRoute(Routes.Units, getPropertyParams())
          : null,
      isSelected: currentRoute === Routes.Units,
      isDisabled: isRouteDisabled(Routes.Units),
    },
    {
      name: t('menu.tenants'),
      iconLeft: <Icons.HomeMenuIcon width={24} />,
      iconLeftSelected: <Icons.CustomDeviceProfilesFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () =>
        selectedMdu !== undefined
          ? redirectToRoute(Routes.Tenants, getPropertyParams())
          : null,
      isSelected: [Routes.Tenants, Routes.Lease].includes(currentRoute),
      isDisabled: selectedMdu === undefined,
    },
    {
      name: t('menu.propertyNetworks'),
      iconLeft: <Icons.NetworkIcon width={24} />,
      iconLeftSelected: <Icons.NetworkIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () =>
        selectedMdu !== undefined
          ? redirectToRoute(Routes.PropertyNetworks, getPropertyParams())
          : null,
      isSelected: currentRoute === Routes.PropertyNetworks,
      isDisabled: selectedMdu === undefined,
    },
    {
      name: t('menu.inventory'),
      iconLeft: <Icons.HaystackIcon width={24} />,
      iconLeftSelected: <Icons.PodHaystackFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () =>
        selectedMdu !== undefined
          ? redirectToRoute(Routes.Inventory, getPropertyParams())
          : null,
      isSelected: currentRoute === Routes.Inventory,
      isDisabled: selectedMdu === undefined,
    },
    {
      name: t('menu.harmony'),
      iconLeft: <Icons.LineChartIcon width={24} />,
      iconLeftSelected: <Icons.LineChartIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () =>
        selectedMdu !== undefined
          ? redirectToRoute(Routes.Harmony, getPropertyParams())
          : null,
      isSelected: currentRoute === Routes.Harmony,
      isDisabled: selectedMdu === undefined,
    },
  ];

  if (isFeatureEnabled(FeatureFlags.IotNetwork)) {
    menuGroupManagement.push({
      name: t('menu.iot'),
      iconLeft: <Icons.WifiIcon width={24} />,
      iconLeftSelected: <Icons.WifiFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () =>
        selectedMdu !== undefined
          ? redirectToRoute(Routes.Iot, getPropertyParams())
          : null,
      isSelected: currentRoute === Routes.Iot,
      isDisabled: selectedMdu === undefined,
    });
  }

  const menuGroupItems: GroupContent[] = [
    {
      items: menuGroupPortfolio,
    },
    {
      items: menuGroupOperations,
      fullName: t('menu.propertyManagement'),
      shortName: t('menu.propertyManagementCondensed'),
      hideGroup: (!isLocal() && !isDevelopment()) || !selectedMdu,
    },
    {
      items: menuGroupManagement,
      fullName: t('menu.connectivity'),
      shortName: t('menu.connectivityCondensed'),
      hideGroup: !selectedMdu,
    },
  ];

  return (): GroupContent[] => menuGroupItems;
};

export default useMenu;
