import { useRecoilValue } from 'recoil';
import {
  anonymizeEmail,
  anonymizeString,
  chooseRandomFloridaLocation,
} from '../anonymize';
import { LeafletMarker } from '../components/LeafletMap/LeafletMap';
import { anonymizedAtom } from '../state';

const useRenderAnonymized = () => {
  const anonymized = useRecoilValue(anonymizedAtom);

  const renderAnonymized = (string?: string) => {
    if (!string) return '';
    if (anonymized) return anonymizeString(string);
    return string;
  };

  const renderAnonymizedLeafletMarkers = (markers: LeafletMarker[]) => {
    if (!markers) return [];
    if (anonymized) {
      return markers.map((marker) => chooseRandomFloridaLocation(marker));
    }
    return markers;
  };

  const renderAnonymizedEmail = (string?: string) => {
    if (anonymized) return anonymizeEmail(string);
    return string;
  };

  return {
    renderAnonymized,
    renderAnonymizedLeafletMarkers,
    renderAnonymizedEmail,
  };
};

export default useRenderAnonymized;
