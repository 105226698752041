import { Field, Form, Formik, FormikValues } from 'formik';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  notify,
  Toggler,
} from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import { TogglerElementTypes } from 'plume-ui/dist/components/Toggler/Toggler';
import React, { FunctionComponent, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as yup from 'yup';
import DropdownInput, {
  DropdownInputOption,
  DropdownInputStyles,
} from '../../../components/DropdownInput/DropdownInput';
import InputField from '../../../components/InputField/InputField';
import ModalHeaderWithProperty from '../../../components/ModalHeaderWithProperty/ModalHeaderWithProperty';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import DependencyContainer from '../../../DependencyContainer';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { selectedMDUSelector, selectedPartnerSelector } from '../../../state';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import { prepareErrorMessageForInput } from '../../../utils/prepareErrorMessageForInput';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { useUnitsUpdateActions } from '../../units/hooks/useUnitsUpdateActions';
import { Unit, UnitType } from '../../units/types';
import {
  businessUnitsAtom,
  residentialUnitsAtom,
} from '../../units/unitsState';
import { inventoryNodesAtom, selectedNodeAtom } from '../inventoryState';
import { NodeStatus } from '../types';

type AssignNodeModalProps = {
  isOpen: boolean;
  onRequestClose: (success: boolean) => void;
};

const addStylesToInputField = (current: any) => ({
  ...current,
  root: `${current.root} AssignNodeModal__input`,
});

const addStylesToDropdownField = (current: DropdownInputStyles) => ({
  ...current,
  root: `${current.root} AssignNodeModal__dropdown m-t-s`,
});

const addStylesForToggler = (current: any) => ({
  ...current,
  root: `${current.root} AssignNodeModal__toggler`,
  label: `${current.label} AssignNodeModal__toggler-label`,
});

const { inventoryService } = new DependencyContainer();

type AssignNodeFormValues = {
  type: UnitType;
  node_id: string;
  unit_number: string;
};

const formInputFieldsDefinition: Array<{
  name: keyof AssignNodeFormValues;
  labelId: string;
  wrap_label?: string;
  type: 'input' | 'unit_selection';
  options?: DropdownInputOption[];
}> = [
  {
    name: 'node_id',
    labelId: 'inventory.actions.assign.node_id',
    type: 'input',
  },
  {
    name: 'unit_number',
    labelId: 'inventory.actions.assign.unit_number',
    wrap_label: 'inventory.actions.assign.wrapLabel',
    type: 'unit_selection',
  },
];

const AssignNodeModal: FunctionComponent<AssignNodeModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const [selectedUnitType, setSelectedUnitType] = useState(UnitType.Residence);
  const [selectedNode, setSelectedNode] = useRecoilState(selectedNodeAtom);
  const residentialUnits = useRecoilValue(residentialUnitsAtom);
  const businessUnits = useRecoilValue(businessUnitsAtom);
  const selectedMdu = useRecoilValue(selectedMDUSelector);
  const trackEvent = useTrackEvent();
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const setNodes = useSetRecoilState(inventoryNodesAtom);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const { updateUnit } = useUnitsUpdateActions();

  if (!selectedMdu || !selectedNode || !selectedPartner) {
    return null;
  }

  const togglerValues: TogglerElementTypes[] = [
    {
      key: UnitType.Residence,
      label: t('inventory.actions.assign.type.residential'),
    },
    {
      key: UnitType.Business,
      label: t('inventory.actions.assign.type.commercial'),
    },
  ];

  const getFormInitialValues = (): AssignNodeFormValues => {
    return {
      type: selectedUnitType,
      node_id: selectedNode?.id,
      unit_number: '',
    };
  };

  const getUnitOptions = () => {
    if (selectedUnitType === UnitType.Residence) {
      return residentialUnits.map((unit) => ({
        value: unit.id,
        label: unit.name,
      }));
    }
    return businessUnits.map((unit) => ({
      value: unit.id,
      label: unit.name,
    }));
  };

  const onClose = (success: boolean) => {
    setSelectedNode(null);
    onRequestClose(success);
  };

  const onSubmit = async (values: FormikValues) => {
    if (submitInProgress) {
      return;
    }
    setSubmitInProgress(true);
    try {
      let unit: Unit;
      if (selectedUnitType === UnitType.Residence) {
        unit = residentialUnits.find(
          (u) => u.id === values.unit_number?.trim(),
        )!;
      } else {
        unit = businessUnits.find((u) => u.id === values.unit_number?.trim())!;
      }

      const updatedUnit = await inventoryService.assignNodeToUnit(
        selectedMdu.id,
        selectedPartner.id,
        selectedNode,
        unit,
        selectedUnitType,
      );

      updateUnit(updatedUnit, selectedUnitType);

      const unitInfo = {
        id: unit.id,
        name: unit.name,
      };
      const strategy =
        selectedUnitType === UnitType.Residence
          ? 'residentialUnit'
          : 'businessUnit';

      const updatedNode = {
        ...selectedNode,
        [strategy]: unitInfo,
        status: NodeStatus.Assigned,
      };

      setNodes((currentNodes) =>
        currentNodes.map((n) => (n.id === selectedNode.id ? updatedNode : n)),
      );
      setSelectedNode(null);
      notify({
        title: t('success'),
        body: t('inventory.actions.assign.nodeAssigned'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.ASSIGN_INVENTORY_NODE_TO_UNIT_SUCCESS,
        additionalContent: {
          unitId: values.unit_number?.trim(),
          propertyId: selectedMdu!.id,
          nodeId: selectedNode.id,
        },
      });
      setSubmitInProgress(false);
      onClose(true);
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.ASSIGN_INVENTORY_NODE_TO_UNIT_FAILURE,
        additionalContent: {
          unitId: values.unit_number,
          propertyId: selectedMdu!.id,
          nodeId: selectedNode.id,
        },
      });
      setSubmitInProgress(false);
    }
  };

  const getValidationSchema = () =>
    yup.object().shape({
      unit_number: yup
        .string()
        .min(3, t('formValidation.minLength', { length: 3 }))
        .trim()
        .max(128, t('formValidation.maxFields', { length: 128 }))
        .required(t('inventory.actions.assign.unit_number_required')),
    });

  const getUnitField = (field: any, values: any, errors: any) => {
    const props = {
      name: field.name,
      value: values[field.name],
      messages: prepareErrorMessageForInput(field.name, errors),
      label: t(field.labelId),
    };
    return (
      <Field
        {...props}
        classes={addStylesToDropdownField}
        component={DropdownInput}
        defaultLabel={t(field.labelId)}
        expandDirection="auto"
        noItemsWarning={t('inventory.actions.assign.noUnitsWarning')}
        openInPortal
        options={getUnitOptions()}
        wrapLabel={t(field.wrap_label)}
      />
    );
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Formik
      initialValues={getFormInitialValues()}
      validationSchema={getValidationSchema()}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values, errors, setFieldValue, dirty, submitForm }) => (
        <Form>
          <Modal
            appElement={document.getElementById('root') as HTMLElement}
            isOpen={isOpen}
            onRequestClose={() => onClose(false)}
            classes={(current: ModalStyles) => ({
              ...current,
              root: `${current.root} AssignNodeModal`,
            })}
          >
            <ModalHeaderWithProperty
              propertyName={selectedMdu.name}
              title={t('inventory.actions.assign.title')}
            />

            <ModalBody
              classes={(current) => ({
                ...current,
                root: `${current.root} AssignNodeModal__body`,
              })}
            >
              <div className="AssignNodeModal__content">
                <Toggler
                  toggleElements={togglerValues}
                  value={selectedUnitType}
                  variant="large"
                  onToggle={async (selection) => {
                    setFieldValue('unit_number', '');
                    await Promise.resolve(); // Needed to stop flashing with wrong value
                    setSelectedUnitType(selection.key as UnitType);
                  }}
                  classes={addStylesForToggler}
                />
                {formInputFieldsDefinition?.length > 0 && (
                  <>
                    {formInputFieldsDefinition.map((field) => {
                      return (
                        <React.Fragment key={field.name}>
                          {field.type === 'unit_selection' &&
                            getUnitField(field, values, errors)}
                          {field.type === 'input' && (
                            <Field
                              key={field.name}
                              name={field.name}
                              component={InputField}
                              value={values[field.name]}
                              label={t(field.labelId)}
                              classes={addStylesToInputField}
                              noClearIcon
                              disabled
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                styleVariant="tertiary-grey"
                onClick={() => onClose(false)}
              >
                <FormattedMessage id="cancel" />
              </Button>
              <SubmitButton
                disabled={!dirty}
                onSubmit={submitForm}
                submitInProgress={submitInProgress}
                label={t('submit')}
                inProgressLabel={t('submitting')}
              />
            </ModalFooter>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default memo(AssignNodeModal);
