import * as yup from 'yup';

// From https://www.npmjs.com/package/validatorjs
export const isIPv4 = (address: string) => {
  if (typeof address !== 'string') return false;
  var er = /^[0-9]+$/;
  const octets = address.split('.');
  if (octets.length !== 4) return false;
  for (let i = 0; i < octets.length; i++) {
    const element = octets[i];
    if (!er.test(element)) return false;
    var octetValue = parseInt(element);
    if (octetValue >= 256) return false;
  }
  return true;
};

export const validateIPv4 = ({
  invalidAddressMessage,
}: {
  invalidAddressMessage: string;
}) => {
  return yup.string().test({
    name: 'url-valid',
    message: invalidAddressMessage,
    test: (value) => {
      if (isIPv4(value as string)) return true;
      return false;
    },
  });
};
