import {
  BarLineStatistic,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import { ModalBodyStyles } from 'plume-ui/dist/components/Modal/ModalBody';
import React, {
  FunctionComponent,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import ModalHeaderWithProperty from '../../../../components/ModalHeaderWithProperty/ModalHeaderWithProperty';
import DependencyContainer from '../../../../DependencyContainer';
import useWithConfirmation from '../../../../hooks/useWithConfirmation';
import { selectedPartnerSelector } from '../../../../state';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { percentageFormatter } from '../../../../utils/percentageFormatter';
import { useInventory } from '../../../inventory/hooks/useInventory';
import { useUnits } from '../../../units/hooks/useUnits';
import { UnitStatus, UnitType } from '../../../units/types';
import {
  businessUnitsAtom,
  residentialUnitsAtom,
} from '../../../units/unitsState';
import { bulkUploadMduAtom } from '../../propertyState';
import { BulkUploadApiResponse, BulkUploadApiResponseRow } from './UploadModal';

export type UploadModalProps = {
  heading: string;
  bulkUploadResult: BulkUploadApiResponse | null;
  unitsWithInventoryType?: boolean;
  isOpen: boolean;
  onClose: () => void;
};

const UploadDetailsModal: FunctionComponent<UploadModalProps> = ({
  heading,
  bulkUploadResult,
  unitsWithInventoryType,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { runFetch: fetchUnits } = useUnits(false);
  const { runFetch: fetchNodes } = useInventory(false);
  const withConfirmation = useWithConfirmation();
  const selectedMdu = useRecoilValue(bulkUploadMduAtom);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const residentialUnits = useRecoilValue(residentialUnitsAtom);
  const businessUnits = useRecoilValue(businessUnitsAtom);
  const [uploadSuccessCount, setUploadSuccessCount] = useState(0);
  const [uploadFailCount, setUploadFailCount] = useState(0);
  const [activatingUnits, setActivatingUnits] = useState(false);
  const [unitsActivated, setUnitsActivated] = useState(0);

  const mounted = useRef(false);

  const { unitsService } = new DependencyContainer();

  useEffect(() => {
    mounted.current = isOpen;
  });

  useEffect(() => {
    if (bulkUploadResult) {
      let success = 0;
      let successfulRows: BulkUploadApiResponseRow[] = [];
      let failed = 0;
      bulkUploadResult.rows.forEach((row) => {
        if (row.status.toLowerCase() === 'ok') {
          success += 1;
          successfulRows.push(row);
        } else {
          failed += 1;
        }
      });
      setUploadSuccessCount(success);
      setUploadFailCount(failed);

      if (unitsWithInventoryType) {
        setUnitsActivated(0);
        startUnitActivation(successfulRows);
      }
    }
  }, [bulkUploadResult]);

  const onRequestClose = () => {
    if (
      unitsWithInventoryType &&
      uploadSuccessCount > 0 &&
      unitsActivated !== uploadSuccessCount
    ) {
      withConfirmation({
        title: t('uploadModal.detailsModal.activatingUnits.close.title'),
        body: t('uploadModal.detailsModal.activatingUnits.close.body'),
        confirmButtonLabel: t(
          'uploadModal.detailsModal.activatingUnits.close.confirmTitle',
        ),
        cancelButtonLabel: t(
          'uploadModal.detailsModal.activatingUnits.close.cancelTitle',
        ),
        onConfirm: () => {
          fetchUnits();
          fetchNodes();
          setActivatingUnits(false);
          onClose();
        },
      });
    } else {
      setActivatingUnits(false);
      onClose();
    }
  };

  const findUnit = (row: BulkUploadApiResponseRow) => {
    if (row.UNIT_TYPE === 'residential') {
      if (!Array.isArray(residentialUnits) || !residentialUnits.length)
        return undefined;
      const unit = residentialUnits.find(
        (unit) => row.UNIT_NAME.toLowerCase() === unit.name.toLowerCase(),
      );
      return { ...unit, type: UnitType.Residence };
    }
    if (row.UNIT_TYPE === 'business') {
      if (!Array.isArray(businessUnits) || !businessUnits.length)
        return undefined;
      const unit = businessUnits.find(
        (unit) => row.UNIT_NAME.toLowerCase() === unit.name.toLowerCase(),
      );
      return { ...unit, type: UnitType.Business };
    }
  };

  const activateUnit = async (row: BulkUploadApiResponseRow) => {
    const unit = findUnit(row);
    if (!unit || !unit.id) return;
    try {
      await unitsService.updateUnit(
        selectedMdu!.id,
        {
          status: UnitStatus.Unassigned,
        },
        unit.id,
        unit.type,
        selectedPartner!.id,
      );
    } catch (error) {}
    setUnitsActivated((unitsActivated) => unitsActivated + 1);
  };

  const activateBatch = (batch: BulkUploadApiResponseRow[]) => {
    let promises = [];
    for (const row of batch) {
      promises.push(activateUnit(row));
    }
    return promises;
  };

  const startUnitActivation = async (rows: BulkUploadApiResponseRow[]) => {
    if (activatingUnits) return;
    setActivatingUnits(true);
    while (rows.length !== 0) {
      if (!mounted.current) return;
      let batch = [];
      for (let i = 0; i < 5; i++) {
        if (rows.length === 0) break;
        const row = rows.pop();
        if (!row) continue;
        batch.push(row);
      }
      await Promise.all(activateBatch(batch));
    }
    await fetchUnits();
    await fetchNodes();
    setActivatingUnits(false);
  };

  const onDownloadReport = () => {
    if (!bulkUploadResult) return;

    const keys = Object.keys(bulkUploadResult.rows[0]).join(',') + '\n';

    const csvContent =
      keys +
      bulkUploadResult.rows.map((r) => Object.values(r).join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'Report.csv');
    a.click();
  };

  const getModalHeader = () => {
    if (unitsWithInventoryType) {
      if (uploadSuccessCount <= 0)
        return t('uploadModal.detailsModal.importComplete');
      if (uploadSuccessCount > 0) {
        if (unitsActivated === uploadSuccessCount)
          return t('uploadModal.detailsModal.activatingUnits.successHeader');
        return t('uploadModal.detailsModal.activatingUnits.header');
      }
    }
    return heading;
  };

  return (
    <Modal
      appElement={document.getElementById('root') as HTMLElement}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      classes={(current: ModalStyles) => ({
        ...current,
        root: `${current.root} UploadDetailsModal`,
      })}
    >
      <ModalHeaderWithProperty
        propertyName={selectedMdu ? selectedMdu.name : ''}
        title={getModalHeader()}
      />
      <ModalBody
        classes={(current: ModalBodyStyles) => ({
          ...current,
          root: `${current.root} UploadDetailsModal__body`,
        })}
      >
        {unitsWithInventoryType && uploadSuccessCount > 0 && (
          <BarLineStatistic
            current={unitsActivated}
            total={uploadSuccessCount}
            valueFormatter={percentageFormatter}
            classes={(current) => ({
              ...current,
              header: `${current.header} UploadDetailsModal-progress__header`,
              bar: `${current.bar} UploadDetailsModal-progress__bar`,
              barGuide: `${current.barGuide} UploadDetailsModal-progress__barGuide`,
              root: `${current.root} UploadDetailsModal-progress`,
            })}
          />
        )}
        {bulkUploadResult && (
          <>
            {unitsWithInventoryType && uploadSuccessCount > 0 && (
              <div className="UploadDetailsModal__title-wrapper">
                {unitsActivated === uploadSuccessCount && <span>🎉</span>}
                <div className="UploadDetailsModal__title">
                  <FormattedMessage
                    id={
                      unitsActivated === uploadSuccessCount
                        ? 'uploadModal.detailsModal.activatingUnits.successTitle'
                        : 'uploadModal.detailsModal.activatingUnits.title'
                    }
                    params={{
                      activated: unitsActivated,
                      total: uploadSuccessCount,
                    }}
                  />
                </div>
              </div>
            )}
            <div className="UploadDetailsModal__details">
              <p>
                <FormattedMessage
                  id="uploadModal.totalRows"
                  params={{ count: bulkUploadResult.rows.length }}
                />
              </p>
              <p className="color-status-success">
                <FormattedMessage
                  id="uploadModal.acceptedRows"
                  params={{ count: uploadSuccessCount }}
                />
              </p>
              <p
                className={
                  uploadFailCount > 0 ? 'color-status-error' : undefined
                }
              >
                <FormattedMessage
                  id="uploadModal.failedRows"
                  params={{ count: uploadFailCount }}
                />
              </p>
            </div>
            {unitsWithInventoryType && uploadSuccessCount > 0 && (
              <div className="UploadDetailsModal__details-message">
                <FormattedMessage
                  id={
                    unitsActivated === uploadSuccessCount
                      ? 'uploadModal.detailsModal.activatingUnits.successMessage'
                      : 'uploadModal.detailsModal.activatingUnits.message'
                  }
                />
              </div>
            )}
          </>
        )}
      </ModalBody>
      {uploadFailCount > 0 && (
        <ModalFooter>
          <Button styleVariant="secondary" onClick={onDownloadReport}>
            <FormattedMessage id="uploadModal.downloadReportButton" />
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default memo(UploadDetailsModal);
