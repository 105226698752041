import { InputMessageStatus } from 'plume-ui/dist/components/InputField/InputField';
import { AutoCompleteResultsItem } from 'plume-ui/src/components/AutoComplete/AutoCompleteResults';
import { DropdownInputOption } from './components/DropdownInput/DropdownInput';
import { TenantStatus, TenantTypes } from './features/tenants/types';
import { UnitType } from './features/units/types';
import { CreatedUpdated } from './utils/types';

export type UpriseApiResponseMeta = {
  currentPage: number;
  itemCount: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
};
export type UpriseApiResponse<T, K extends string = 'items'> = {
  [key in K]: T;
} & {
  meta: UpriseApiResponseMeta;
};

export type UpriseAutocompleteResultsItem = AutoCompleteResultsItem & {
  unitType?: UnitType;
  tenantType?: TenantTypes;
  tenantName?: string;
  propertyId?: string;
  tenantStatus?: TenantStatus;
};

export type Partner = {
  id: string;
  name: string;
};

export type UserInfo = {
  partnerId: string;
  role: Role;
  displayRole?: string;
  permissions: string[];
  frontlineTier1Access: boolean;
};

export enum Role {
  Admin = 'admin',
  PartnerAdmin = 'partnerAdmin',
  PartnerTechnician = 'partnerTechnician',
  PropertyManager = 'propertyManager',
  PartnerSupport = 'partnerSupport',
  ReadOnly = 'readOnly',
  Support = 'support',
}

export enum Permission {
  cascadeDeleteProperty = 'cascadeDeleteProperty',
  createProperty = 'createProperty',
  createUser = 'createUser',
  createBusinessTenant = 'createBusinessTenant',
  createBusinessUnit = 'createBusinessUnit',
  createNode = 'createNode',
  createPropertyNetwork = 'createPropertyNetwork',
  createResidentialTenant = 'createResidentialTenant',
  createResidentialUnit = 'createResidentialUnit',
  deleteBusinessTenant = 'deleteBusinessTenant',
  deleteBusinessUnit = 'deleteBusinessUnit',
  deleteNode = 'deleteNode',
  deleteProperty = 'deleteProperty',
  deletePropertyNetwork = 'deletePropertyNetwork',
  deleteResidentialTenant = 'deleteResidentialTenant',
  deleteResidentialUnit = 'deleteResidentialUnit',
  deleteUnassignedUnits = 'deleteUnassignedUnits',
  deleteUser = 'deleteUser',
  editMduOptimizations = 'editMduOptimizations',
  exitResidentialTenant = 'exitResidentialTenant',
  geoCode = 'geoCode',
  getBusinessTenants = 'getBusinessTenants',
  getBusinessTenant = 'getBusinessTenant',
  getBusinessUnits = 'getBusinessUnits',
  getBusinessUnit = 'getBusinessUnit',
  getBusinessUnitFrontlineLink = 'getBusinessUnitFrontlineLink',
  getCaptivePortalEditorLink = 'getCaptivePortalEditorLink',
  getNodes = 'getNodes',
  getNode = 'getNode',
  getPartnerStats = 'getPartnerStats',
  getProperties = 'getProperties',
  getProperty = 'getProperty',
  getPropertyNetworks = 'getPropertyNetworks',
  getPropertyNetwork = 'getPropertyNetwork',
  getPropertyNetworkFrontlineLink = 'getPropertyNetworkFrontlineLink',
  getPropertyStats = 'getPropertyStats',
  getPropertyWifiPassword = 'getPropertyWifiPassword',
  getResidentialTenants = 'getResidentialTenants',
  getResidentialTenant = 'getResidentialTenant',
  getResidentialUnits = 'getResidentialUnits',
  getResidentialUnit = 'getResidentialUnit',
  getResidentialUnitFrontlineLink = 'getResidentialUnitFrontlineLink',
  getUsers = 'getUsers',
  getUser = 'getUser',
  transferBusinessTenantWithNodes = 'transferBusinessTenantWithNodes',
  transferResidentialTenantWithNodes = 'transferResidentialTenantWithNodes',
  updateBusinessTenant = 'updateBusinessTenant',
  updateBusinessUnit = 'updateBusinessUnit',
  updateProperty = 'updateProperty',
  updatePropertyNetwork = 'updatePropertyNetwork',
  updateResidentialTenant = 'updateResidentialTenant',
  updateResidentialUnit = 'updateResidentialUnit',
  canUpdateUnitBulkPayAccountId = 'canUpdateUnitBulkPayAccountId',
  canUpdateUnitNodes = 'canUpdateUnitNodes',
  updateUser = 'updateUser',
  bulkCreateUnits = 'bulkCreateUnits',
  bulkCreateNodes = 'bulkCreateNodes',
  bulkImportUnitsAndNodes = 'bulkImportUnitsAndNodes',
  bulkImportTenants = 'bulkImportTenants',
  bulkExitTenants = 'bulkExitTenants',
  createIotNetwork = 'createIotNetwork',
  getIotNetwork = 'getIotNetwork',
  updateIotNetwork = 'updateIotNetwork',
  deleteIotNetwork = 'deleteIotNetwork',
  getIotNetworkPassword = 'getIotNetworkPassword',
  getIotNetworkPropagationStatus = 'getIotNetworkPropagationStatus',
  postPropagateIotNetwork = 'postPropagateIotNetwork',
  deletePropagateIotNetwork = 'deletePropagateIotNetwork',
  deleteSinglePropagateIotNetwork = 'deleteSinglePropagateIotNetwork',
  postSinglePropagateIotNetwork = 'postSinglePropagateIotNetwork',
  getIotNetworkDevices = 'getIotNetworkDevices',
  importSingleResidentialTenant = 'importSingleResidentialTenant',
}

export type GeoInfo = {
  lat: number;
  long: number;
};

export type MduOwner = {
  id: string;
  email: string;
};

export type MDU = CreatedUpdated & {
  address: string;
  city: string;
  contact_email: string;
  contact_phone: string;
  country: string;
  email?: string;
  geoInfo: GeoInfo;
  id: string;
  language: string;
  mdu_optimizations_enabled: boolean;
  mdu_optimizations_enabled_last_updated_at: string | null;
  name: string;
  partnerId: string;
  phone: string;
  postal_code: string;
  state: string;
  website: string;
};

export type ConfirmationModalAtom = {
  isOpen: boolean;
  title?: string;
  body?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export enum ModalState {
  Open = 'open',
  Success = 'success',
  Dismissed = 'dismissed',
}

export enum ModalType {
  Edit = 'edit',
  Create = 'create',
}

export type FormField<T> = {
  name: keyof T;
  labelId: string;
  wrapLabel?: string;
  type: 'input' | 'password' | 'number' | 'dropdown' | 'hidden' | 'checkbox';
  messages?: {
    status: InputMessageStatus;
    message: string;
  }[];
  options?: DropdownInputOption[];
};

export type PortfolioStats = {
  alertCount: number;
  alertsUpdatedAt: string;
  businessUnitCount: number;
  claimedNodeCount: number;
  inventoryNodeCount: number;
  inventoryNodesUnassigned: number;
  nodesUpdatedAt: string;
  residentialUnitCount: number;
};

export type Required<T> = {
  [P in keyof T]-?: T[P];
};
