import DependencyContainer from '../../../DependencyContainer';
import { MDU, PortfolioStats } from '../../../types';
import {
  CreateMduDto,
  CreateMduResponse,
  NoLocationError,
  UpdateMduDto,
} from '../types';
import { CreateMduFormValues } from '../../editorView/components/CreateMduModal/CreateMduModal';
import i18n from 'i18next';
import {
  BulkUploadApiResponse,
  UploadType,
} from '../../editorView/components/UploadModal/UploadModal';
import { AxiosResponse } from 'axios';
import { GeoCode } from '../../editorView/types';

export default class MduService {
  constructor(private readonly factory: DependencyContainer) {}

  async getMDUs(partnerId: string): Promise<MDU[]> {
    const response = await this.factory.mduClient.fetchMdus(partnerId);
    return response.data.items.filter(
      (mdu: MDU) =>
        mdu.geoInfo?.lat !== undefined && mdu.geoInfo?.long !== undefined,
    );
  }

  async fetchPortfolioStats(partnerId: string): Promise<PortfolioStats> {
    const response = await this.factory.mduClient.fetchPortfolioStats(
      partnerId,
    );
    return response.data;
  }

  async createMdu(
    dto: CreateMduDto,
    partnerId: string,
  ): Promise<CreateMduResponse> {
    let geolocation: GeoCode;
    try {
      geolocation = await this.factory.geolocationService.lookupGeocode({
        text: `${dto.address}, ${dto.city}, ${dto.state}, ${dto.postal_code}`,
        country: dto.country,
      });
    } catch (error) {
      if (!dto.geoInfo) {
        throw new NoLocationError(i18n.t('geolocationError'));
      } else {
        geolocation = {
          lat: dto.geoInfo.lat,
          long: dto.geoInfo.long,
          label: '',
        };
      }
    }

    const createMduDto: Required<CreateMduDto> = {
      ...dto,
      geoInfo: {
        lat: geolocation.lat,
        long: geolocation.long,
      },
    };
    const response = await this.factory.mduClient.createMdu(
      createMduDto,
      partnerId,
    );
    return response.data;
  }

  async updateMdu(
    id: string,
    dto: UpdateMduDto,
    partnerId: string,
  ): Promise<CreateMduResponse> {
    const response = await this.factory.mduClient.updateMdu(id, dto, partnerId);
    return response.data;
  }

  async deleteMdu(id: string, partnerId: string): Promise<void> {
    await this.factory.mduClient.deleteMdu(id, partnerId);
  }

  async uploadFile(
    mduId: string,
    partnerId: string,
    file: File,
    type: UploadType,
  ): Promise<BulkUploadApiResponse> {
    let response: AxiosResponse<BulkUploadApiResponse>;
    switch (type) {
      case 'units':
        response = await this.factory.mduClient.uploadUnits(
          mduId,
          partnerId,
          file,
        );
        break;
      case 'nodes':
        response = await this.factory.mduClient.uploadNodes(
          mduId,
          partnerId,
          file,
        );
        break;
      case 'unitsWithNodes':
        response = await this.factory.mduClient.uploadUnitsWithNodes(
          mduId,
          partnerId,
          file,
        );
        break;
      case 'importTenants':
        response = await this.factory.mduClient.importTenants(
          mduId,
          partnerId,
          file,
        );
        break;
      case 'exitTenants':
        response = await this.factory.mduClient.exitTenants(
          mduId,
          partnerId,
          file,
        );
    }
    return response.data;
  }

  getCreateMduFormInitialValues(values?: MDU): CreateMduFormValues {
    if (!values) {
      return {
        name: '',
        address: '',
        country: '',
        state: '',
        city: '',
        postal_code: '',
        partnerId: '',
        optimizationsEnabled: true,
        language: '',
      };
    }
    return {
      name: values.name || '',
      address: values.address || '',
      country: values.country || '',
      state: values.state || '',
      city: values.city || '',
      postal_code: values.postal_code || '',
      partnerId: values.partnerId || '',
      lat: values.geoInfo.lat.toString(),
      long: values.geoInfo.long.toString(),
      optimizationsEnabled: values.mdu_optimizations_enabled,
      language: values.language,
    };
  }
}
