import { Field, Formik } from 'formik';
import {
  Button,
  Checkbox,
  DateRangeSelect,
  FilterDropdown,
  InputField,
  TextArea,
} from 'plume-ui';
import { TextAreaStyles } from 'plume-ui/dist/components/InputField/TextAreaInputField';
import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AuraGlow from '../../../components/AuraGlow/AuraGlow';
import FormInputField from '../../../components/FormInputField/FormInputField';
import FormSection from '../../../components/FormSection/FormSection';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { Routes } from '../../../config';
import { selectedMDUSelector, selectedPartnerIdAtom } from '../../../state';
import { isDevelopment, isLocal } from '../../../utils/environment';
import { useRedirectToRoute } from '../../../utils/hooks/useRedirectToRoute';
import { allUnits } from '../../units/unitsState';

const TicketContainer = () => {
  const { t } = useTranslation();
  const redirectToRoute = useRedirectToRoute();

  const selectedPartnerId = useRecoilValue(selectedPartnerIdAtom);
  const selectedProperty = useRecoilValue(selectedMDUSelector);
  const units = useRecoilValue(allUnits);

  const initialFormValues: {
    title?: string;
    createdDate: Date;
    unit?: string;
    description?: string;
    // TODO enum Priority
    priority?: string;
    scheduledDate?: Date;
    notifyOnUpdate?: boolean;
    // TODO type Message
    messages?: any[];
  } = {
    priority: 'Normal',
    createdDate: new Date(),
    notifyOnUpdate: true,
  };

  //   const fields = [{}];

  if (!isLocal() && !isDevelopment()) {
    redirectToRoute(Routes.Index);
  }

  return (
    <>
      <Helmet>
        <title>{t('ticket.createTitle')}</title>
      </Helmet>
      <div className="TicketContainer p-xl">
        <AuraGlow header footer />
        <PageHeading
          title="ticket.createTitle"
          tooltip="ticker.createTooltip"
          onBack={() =>
            redirectToRoute(Routes.Maintenance, {
              propertyId: selectedProperty?.id,
              partnerId: selectedPartnerId,
            })
          }
        >
          <div className="TicketContainer__headerButtons">
            <Button styleVariant="tertiary">{t('ticket.archive')}</Button>
            <Button styleVariant="superprimary">{t('save')}</Button>
          </div>
        </PageHeading>
        <Formik initialValues={initialFormValues} onSubmit={() => {}}>
          {({ values, setFieldValue }) => (
            <>
              <form className="TicketContainer__content">
                <FormSection
                  title={t('ticket.information.header')}
                  rows={[
                    [
                      <Field
                        id="title"
                        name="title"
                        component={InputField}
                        label={t('ticket.information.title')}
                        value={values.title}
                      />,
                      <Field
                        id="createdDate"
                        name="createdDate"
                        component={FormInputField}
                        label={t('ticket.information.createdDate')}
                        value={values.createdDate?.toLocaleDateString(
                          undefined,
                          { month: 'long', day: '2-digit', year: 'numeric' },
                        )}
                        disabled
                      />,
                      <FilterDropdown
                        items={units.map((unit) => ({
                          value: unit.id,
                          label: unit.name,
                        }))}
                        onSelect={(dropdownItem) =>
                          setFieldValue('unit', dropdownItem.value)
                        }
                        containerLabel={t('ticket.information.unit')}
                        defaultLabel={
                          units.find((unit) => values.unit === unit.id)?.name ||
                          t('select')
                        }
                      />,
                    ],
                    [
                      <Field
                        id="description"
                        name="description"
                        component={TextArea}
                        classes={(current: TextAreaStyles) => ({
                          ...current,
                          root: `${current.root} TicketContainer__textarea`,
                        })}
                        label={t('ticket.information.description')}
                      />,
                    ],
                  ]}
                />
                <FormSection
                  title={t('ticket.details.header')}
                  rows={[
                    [
                      <FilterDropdown
                        items={[
                          {
                            label: 'Normal',
                            value: 'Normal',
                          },
                        ]}
                        onSelect={(dropdownItem) =>
                          setFieldValue('priority', dropdownItem.value)
                        }
                        containerLabel={t('ticket.details.priority')}
                        defaultLabel={values.priority || t('select')}
                      />,
                      <div className="TicketContainer__dateRange">
                        <p>{t('ticket.details.scheduledDate')}</p>
                        <DateRangeSelect
                          label={values.scheduledDate?.toLocaleDateString(
                            undefined,
                            { month: 'long', day: '2-digit', year: 'numeric' },
                          )}
                          value={values.scheduledDate}
                          singleDate
                          extraOptions={[]}
                          show={false}
                          openInPortal
                          onSelect={(date) =>
                            setFieldValue('scheduledDate', date)
                          }
                        />
                      </div>,
                      <div className="TicketContainer__checkbox">
                        <Checkbox
                          label={''}
                          onChange={() =>
                            setFieldValue(
                              'notifyOnUpdate',
                              !values.notifyOnUpdate,
                            )
                          }
                          checked={values.notifyOnUpdate}
                        />
                        <div className="TicketContainer__checkboxLabel">
                          {t('ticket.notificationLabel')}
                          <span>{t('ticket.notificationHint')}</span>
                        </div>
                      </div>,
                    ],
                  ]}
                />
              </form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default memo(TicketContainer);
