import { useEffect, useState } from 'react';
import Unknown from '../../../assets/svg/unknown.svg';
import { MetasaurusUrls } from '../../../urls';

const useIcon = (iconV3: string, size?: 'small' | 'medium' | 'large') => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [icon, setIcon] = useState<string | null>(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        if (!iconV3) {
          setIcon(Unknown);
        } else {
          const iconV3Url = MetasaurusUrls.getIconV3Url(iconV3, size);
          const response = await fetch(iconV3Url);
          if (!response.ok) setIcon(Unknown);
          if (response.ok) setIcon(iconV3Url);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchImage();
  }, [iconV3]);
  return {
    loading,
    error,
    icon,
  };
};

export default useIcon;
