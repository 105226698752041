import { HeroStat, Icons } from 'plume-ui';
import { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { PMDashboardStatType } from '../../types';

type PMDashboardStatCardProps = {
  children?: ReactNode;
  stat: number;
  type: PMDashboardStatType;
};

const PMDashboardStatCard = ({
  children,
  stat,
  type,
}: PMDashboardStatCardProps) => {
  const { t } = useTranslation();

  const getIcon = () => {
    switch (type) {
      case PMDashboardStatType.Payments:
        return <Icons.StarIcon width={24} height={24} />;
      case PMDashboardStatType.Leases:
        return <Icons.CalendarIcon width={24} height={24} />;
      case PMDashboardStatType.Tickets:
        return <Icons.StarIcon width={24} height={24} />;
    }
  };

  return (
    <div className="PMDashboardStatCard">
      <HeroStat
        classes={(current) => ({
          ...current,
          root: 'PMDashboardStatCard__stat',
          iconContainer: `PMDashboardStatCard__statIcon PMDashboardStatCard__statIcon--${type}`,
        })}
        icon={getIcon()}
        statLabel={t(`pmDashboard.stats.${type}`)}
        stat={stat || 0}
      />
      <div className="PMDashboardStatCard__items">{children}</div>
    </div>
  );
};

export default memo(PMDashboardStatCard);
