import InventoryClient from './features/inventory/services/InventoryClient';
import InventoryService from './features/inventory/services/InventoryService';
import IotClient from './features/iot/services/IotClient';
import IotService from './features/iot/services/IotService';
import LoginClient from './features/login/services/LoginClient';
import LoginService from './features/login/services/LoginService';
import StatClient from './features/overview/services/StatClient';
import StatService from './features/overview/services/StatService';
import GeolocationClient from './features/portfolio/services/GeolocationClient';
import GeolocationService from './features/portfolio/services/GeolocationService';
import MduClient from './features/portfolio/services/MduClient';
import MduService from './features/portfolio/services/MduService';
import PropertyNetworksClient from './features/property-networks/services/PropertyNetworkClient';
import PropertyNetworksService from './features/property-networks/services/PropertyNetworksService';
import TenantsClient from './features/tenants/services/TenantsClient';
import TenantsService from './features/tenants/services/TenantsService';
import UnitsClient from './features/units/services/UnitsClient';
import UnitsService from './features/units/services/UnitsService';
import UsersClient from './features/users/services/UsersClient';
import UsersService from './features/users/services/UsersService';
import VersionClient from './services/VersionClient';
import VersionService from './services/VersionService';

export default class DependencyContainer {
  // services
  geolocationService = new GeolocationService(this);
  inventoryService = new InventoryService(this);
  loginService = new LoginService(this);
  mduService = new MduService(this);
  propertyNetworksService = new PropertyNetworksService(this);
  statService = new StatService(this);
  tenantService = new TenantsService(this);
  unitsService = new UnitsService(this);
  usersService = new UsersService(this);
  versionService = new VersionService(this);
  iotService = new IotService(this);

  // clients
  geolocationClient = new GeolocationClient(this);
  inventoryClient = new InventoryClient(this);
  loginClient = new LoginClient(this);
  mduClient = new MduClient(this);
  propertyNetworksClient = new PropertyNetworksClient(this);
  statClient = new StatClient(this);
  tenantClient = new TenantsClient(this);
  unitsClient = new UnitsClient(this);
  usersClient = new UsersClient(this);
  versionClient = new VersionClient(this);
  iotClient = new IotClient(this);
}
