export const tickFormatterBitrate = (value: string) => {
  const parsedValue = parseInt(value, 10);
  if (parsedValue > 1000) {
    const valueInMillion = parsedValue / 1000;
    return `${valueInMillion}G`;
  }
  return `${parsedValue.toString()}M`;
};

export const tableFormatterBitrate = (value: string) => {
  const parsedValue = parseInt(value, 10);
  if (Number.isNaN(parsedValue) || parsedValue === 0) {
    return;
  } else if (parsedValue > 1000) {
    const valueInMillion = parsedValue / 1000;
    return `${valueInMillion} Gbps`;
  }
  return `${parsedValue.toString()} Mbps`;
};
