import axios from 'axios';
import { notify } from 'plume-ui';
import i18n from 'i18next';
import { getBackendUrl } from './environment';
import React from 'react';

const axiosInstance = axios.create({
  baseURL: getBackendUrl(),
  timeout: 5000,
  headers: { Accept: 'application/json' },
  transitional: {
    silentJSONParsing: false,
    forcedJSONParsing: true,
    clarifyTimeoutError: true,
  },
});

axiosInstance.interceptors.response.use(undefined, (error) => {
  switch (error.response.status) {
    case 422: {
      if (error.response.data.message) {
        if (error.response.data.details) {
          notify({
            title: i18n.t('error'),
            body: `${error.response.data.message} ${error.response.data.details}`,
            type: 'error',
          });
        } else {
          notify({
            title: i18n.t('error'),
            body: error.response.data.message,
            type: 'error',
          });
        }
      } else {
        notify({
          title: i18n.t('error'),
          body: i18n.t('errors.422'),
          type: 'error',
        });
      }
      break;
    }

    case 404: {
      notify({
        title: i18n.t('error'),
        body: i18n.t('somethingWentWrong'),
        type: 'error',
      });
      break;
    }

    case 401: {
      window.location.href = '/login';
      break;
    }

    case 400: {
      const messages = error.response?.data?.message || [];
      if (!messages.length) {
        notify({
          title: i18n.t('error'),
          body: i18n.t('somethingWentWrong'),
          type: 'error',
        });
        return;
      }
      if (messages.length > 1) {
        notify({
          title: i18n.t('multipleErrors'),
          // @ts-ignore
          body: (
            <ul className="Notification__error-list">
              {messages.map((m: string) => (
                <li>
                  <p>{m}</p>
                </li>
              ))}
            </ul>
          ),
          type: 'error',
        });
        return;
      }
      notify({
        title: i18n.t('error'),
        body: messages[0],
        type: 'error',
      });
      break;
    }

    default:
      notify({
        title: i18n.t('error'),
        body: i18n.t('somethingWentWrong'),
        type: 'error',
      });
  }

  return Promise.reject(error);
});

export default axiosInstance;
