import { Grid, GridItem, Icons, Pagination, Status, Tooltip } from 'plume-ui';
import React, {
  ChangeEvent,
  FunctionComponent,
  memo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import StickyActionTable from '../../../components/StickyActionTable/StickyActionTable';
import { conditionallyTruncate } from '../../../components/StickyActionTable/util';
import TableActionButton from '../../../components/TableActionButton/TableActionButton';
import TableFilterRow from '../../../components/TableFilter/TableFilterRow';
import { DEFAULT_PAGE_SIZE, Routes } from '../../../config';
import useRenderAnonymized from '../../../hooks/useRenderAnonymized';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { selectedPartnerIdAtom, userInfoAtom } from '../../../state';
import { MDU, Permission, Role } from '../../../types';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import { useRedirectToRoute } from '../../../utils/hooks/useRedirectToRoute';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { AvailableScreens } from '../../trackingAnalytics/types';
import CreateMduModal from '../components/CreateMduModal/CreateMduModal';
import UploadModal from '../components/UploadModal/UploadModal';
import usePropertyActions from '../hooks/usePropertyActions';
import {
  bulkUploadMduAtom,
  filteredPropertiesCount,
  getCurrentPropertyPage,
  propertiesSelectedFilterAtom,
  propertiesSelectedPageAtom,
  propertySortAtom,
  selectedMduIdAtom,
  selectedMduIdInPropertyEditorAtom,
  uploadModalTypeAtom,
} from '../propertyState';

const EditorViewContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const { renderAnonymized } = useRenderAnonymized();
  const trackEvent = useTrackEvent();
  const userInfo = useRecoilValue(userInfoAtom);
  const multiDwellingUnits = useRecoilValue(getCurrentPropertyPage);
  const [addMduModalIsOpened, setAddMduModalIsOpened] = useState(false);
  const [bulkUploadMdu, setBulkUploadMdu] = useRecoilState(bulkUploadMduAtom);
  const getPropertyActions = usePropertyActions();
  const [selectedMduId, setSelectedMduId] = useRecoilState(selectedMduIdAtom);
  const redirectToRoute = useRedirectToRoute();
  const [
    selectedMduInPropertyEditor,
    setSelectedMduInPropertyEditor,
  ] = useRecoilState<string | undefined>(selectedMduIdInPropertyEditorAtom);
  const selectedPartnerId = useRecoilValue(selectedPartnerIdAtom);
  const uploadModalType = useRecoilValue(uploadModalTypeAtom);

  const [currentPropertyPage, setCurrentPropertyPage] = useRecoilState(
    propertiesSelectedPageAtom,
  );

  useEffect(() => {
    if (selectedMduInPropertyEditor) {
      setAddMduModalIsOpened(true);
    }
  }, [selectedMduInPropertyEditor]);

  useEffect(() => {
    if (!multiDwellingUnits.length && currentPropertyPage > 0) {
      setSelectedPage(currentPropertyPage - 1);
    }
  }, [multiDwellingUnits, currentPropertyPage]);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.PropertyEditor,
      },
    });
    return () => {
      setSelectedFilter('');
    };
  }, []);

  const onSelectProperty = (propertyId: string) => {
    setSelectedMduId(propertyId);
    console.log(`Selected Property: ${propertyId}`);
    redirectToRoute(Routes.Overview, {
      propertyId,
      partnerId: selectedPartnerId,
    });
  };

  const setPropertySort = useSetRecoilState(propertySortAtom);
  const [selectedFilter, setSelectedFilter] = useRecoilState(
    propertiesSelectedFilterAtom,
  );
  const totalFilteredItems = useRecoilValue(filteredPropertiesCount);
  const setSelectedPage = useSetRecoilState(propertiesSelectedPageAtom);

  const tableHeaderRow = [
    {
      name: t('propertyEditor.table.name'),
      fieldName: 'name',
      sortable: true,
      render: (mdu: MDU) => conditionallyTruncate(renderAnonymized(mdu.name)),
    },
    {
      name: t('propertyEditor.table.address'),
      fieldName: 'address',
      sortable: true,
      render: (mdu: MDU) => renderAnonymized(mdu.address),
    },
    {
      name: t('propertyEditor.table.state'),
      fieldName: 'state',
      sortable: true,
    },
    {
      name: t('propertyEditor.table.country'),
      fieldName: 'country',
      sortable: true,
    },
    {
      name: (
        <>
          {t('propertyEditor.table.mdu_optimizations_enabled')}
          <span>
            <Tooltip
              heading={t('propertyEditor.table.harmony.title')}
              label={t('propertyEditor.table.harmony.text')}
              openInPortal
              overrideMaxCharacters
            >
              <Icons.InfoIcon />
            </Tooltip>
          </span>
        </>
      ),
      fieldName: 'mdu_optimizations_enabled',
      sortable: true,
      render: (mdu: MDU) => {
        return mdu.mdu_optimizations_enabled ? t('on') : t('off');
      },
    },
    {
      name: t('propertyEditor.table.selection.select'),
      sortable: false,
      fieldName: 'selectProperty',
      render: (mdu: MDU) => {
        const isSelected = mdu.id === selectedMduId;
        const labelId = isSelected
          ? 'propertyEditor.table.selection.isSelected'
          : 'propertyEditor.table.selection.isNotSelected';
        const color = isSelected ? 'ok' : 'error';
        if (isSelected) {
          return (
            <Status
              classes={(current) => ({
                ...current,
                indicator: `${current.indicator} EditorViewContainer__table-status-indicator`,
              })}
              color={color}
              label={t(labelId)}
            />
          );
        }
        return (
          <button
            type="button"
            className="EditorViewContainer__select-property-btn"
            onClick={() => onSelectProperty(mdu.id)}
          >
            <FormattedMessage id="propertyEditor.table.selection.viewProperty" />
          </button>
        );
      },
    },
    {
      sortable: false,
      fieldName: 'actions',
      render: (mdu: MDU) => {
        const propertyActions = getPropertyActions(mdu);
        return (
          <TableActionButton
            actions={propertyActions}
            item={mdu}
          ></TableActionButton>
        );
      },
    },
  ];
  const mdus = multiDwellingUnits.map((m: MDU) => ({
    id: m.id,
    name: m.name,
    state: m.state,
    country: m.country,
    address: m.address,
    mdu_optimizations_enabled: m.mdu_optimizations_enabled,
  }));

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value === undefined ? '' : e.target.value;
    setSelectedFilter(value);
    setSelectedPage(0);
  };

  const hasPermission = (permission: Permission) => {
    return userInfo?.permissions.includes(permission);
  };

  return (
    <>
      <div className="EditorViewContainer">
        <Grid>
          <GridItem colSpan="12">
            <TableFilterRow
              placeholder="propertyEditor.search"
              value={selectedFilter}
              onSearch={handleSearch}
              resultCount={totalFilteredItems}
              actionButton={{
                label: 'propertyEditor.actions.create.buttonLabel',
                onClick: () => setAddMduModalIsOpened(true),
                isDisabled:
                  userInfo?.role !== Role.Support &&
                  !hasPermission(Permission.createProperty),
              }}
            />
          </GridItem>
          <GridItem colSpan="12">
            <div>
              <StickyActionTable
                headerRow={tableHeaderRow as any}
                dataRows={mdus as any}
                externalSort
                onSortChange={setPropertySort}
                truncateCellContent={false}
              />
              {Math.ceil(totalFilteredItems / DEFAULT_PAGE_SIZE) > 0 && (
                <Pagination
                  classes={(current) => ({
                    ...current,
                    root: `${current.root} EditorViewContainer__pagination`,
                  })}
                  expandDirection={'top'}
                  totalPageCount={Math.ceil(
                    totalFilteredItems / DEFAULT_PAGE_SIZE,
                  )}
                  onPageSelect={setCurrentPropertyPage}
                  currentPage={currentPropertyPage}
                />
              )}
            </div>
          </GridItem>
        </Grid>
        {mdus.length === 0 && (
          <div className="EditorViewContainer__placeholder" />
        )}
      </div>
      <CreateMduModal
        isOpen={addMduModalIsOpened}
        selectedPropertyId={selectedMduInPropertyEditor}
        onRequestClose={() => {
          setAddMduModalIsOpened(false);
          setSelectedMduInPropertyEditor(undefined);
        }}
      />
      <UploadModal
        isOpen={!!bulkUploadMdu}
        type={uploadModalType}
        onRequestClose={() => {
          setBulkUploadMdu(null);
        }}
      />
    </>
  );
};

export default memo(EditorViewContainer);
