import { Field } from 'formik';
import { InputField } from 'plume-ui';
import { InputFieldStyles } from 'plume-ui/dist/components/InputField/InputField';
import { InputFieldProps } from 'plume-ui/src/components/InputField/InputField';

type FormInputFieldProps = InputFieldProps & { isEditing: boolean };

const FormInputField = (props: FormInputFieldProps) => {
  const { isEditing, ...inputProps } = props;

  return (
    <Field
      component={InputField}
      disabled={!isEditing}
      {...inputProps}
      classes={(current: InputFieldStyles) => ({
        ...current,
        root: `${current.root} FormInputField ${
          !isEditing && 'FormInputField--disabled'
        }`,
      })}
    />
  );
};

export default FormInputField;
