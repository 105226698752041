import { Icons, Modal, ModalBody, Status, Table } from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import ModalHeaderWithProperty from '../../../components/ModalHeaderWithProperty/ModalHeaderWithProperty';
import usePreventBodyScroll from '../../../hooks/usePreventBodyScroll';
import useRenderAnonymized from '../../../hooks/useRenderAnonymized';
import { selectedMDUSelector } from '../../../state';
import { UnitType } from '../../units/types';
import { alertTypeToLabel } from '../util';
import {
  LargeHeroStatAlert,
  LargeHeroStatAlertType,
} from './HeroStat/LargeHeroStatAlerts';

enum NetworkType {
  Residence = UnitType.Residence,
  Business = UnitType.Business,
  PropertyNetwork = 'PropertyNetwork',
}

type AlertsModalRow = {
  networkType: NetworkType;
  unitName: string;
  alertType: LargeHeroStatAlertType;
};

const AlertsModal = ({
  isOpen,
  onRequestClose,
  alerts,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
  alerts: LargeHeroStatAlert[];
}) => {
  usePreventBodyScroll(isOpen);
  const { t } = useTranslation();
  const { renderAnonymized } = useRenderAnonymized();
  const selectedMdu = useRecoilValue(selectedMDUSelector);

  const tableHeaderRow: Record<string, unknown>[] = [
    {
      fieldName: 'icon',
      render: (row: AlertsModalRow) => {
        const getIcon = () => {
          switch (row.networkType) {
            case NetworkType.Residence:
              return <Icons.HomeIcon width={24} />;
            case NetworkType.Business:
              return <Icons.BriefCaseIcon width={20} />;
            case NetworkType.PropertyNetwork:
              return <Icons.NetworkIcon width={24} />;
          }
        };
        return <div className="AlertsModal__table--icon">{getIcon()}</div>;
      },
    },
    {
      name: t('overview.alertsModal.table.unit'),
      fieldName: 'unit',
      render: (row: AlertsModalRow) => (
        <div className="AlertsModal__table--name">
          {renderAnonymized(row.unitName)}
        </div>
      ),
    },
    {
      name: t('overview.alertsModal.table.alert'),
      fieldName: 'alert',
      render: (row: AlertsModalRow) => (
        <Status
          classes={(current) => ({
            ...current,
            indicator: `${current.indicator} AlertsModal__table--alert${
              row.alertType === LargeHeroStatAlertType.NodeQoeAlert
                ? '--warn'
                : ''
            }`,
          })}
          label={t(alertTypeToLabel(row.alertType))}
        />
      ),
    },
  ];

  const getTableDataRows = (): AlertsModalRow[] => {
    if (!alerts) return [];
    return alerts.map((alert) => ({
      networkType: NetworkType[alert.unitType || NetworkType.PropertyNetwork],
      unitName: alert.unitName || alert.propertyNetworkName || '',
      alertType: alert.alertType,
    }));
  };

  if (!selectedMdu) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      classes={(current: ModalStyles) => ({
        ...current,
        root: `${current.root} AlertsModal`,
      })}
    >
      <ModalHeaderWithProperty
        propertyName={selectedMdu.name}
        title={t('overview.alertsModal.title')}
      />
      <ModalBody
        classes={(current: ModalStyles) => ({
          ...current,
          root: `${current.root} AlertsModal__body`,
        })}
      >
        <Table
          headerRow={tableHeaderRow}
          dataRows={getTableDataRows()}
          truncateCellContent={false}
          stickyHeader
        />
      </ModalBody>
    </Modal>
  );
};

export default memo(AlertsModal);
