import { IotFormValues } from './components/IotModal/IotModal';
import {
  IotDevice,
  IotSecurityPolicy,
  SecurityPolicyProtection,
  SecurityPolicyWhitelist,
} from './types';

export const deviceHasAlarms = (iotDevice: IotDevice) => {
  if (
    !iotDevice ||
    iotDevice.health?.score === undefined ||
    iotDevice.health?.score === null
  )
    return false;
  return iotDevice.health?.score < 3;
};

const iotDeviceStatusColors = {
  connected: '#17e3ae', // good500
  disconnected: '#8f9397', // still400
  alarm: '#fa1478', // sore500
  quarantined: '#ddb33c', // warm600
};

export const getIotDeviceStatusColor = (device: IotDevice) => {
  if (device.quarantined) return iotDeviceStatusColors.quarantined;
  if (deviceHasAlarms(device)) return iotDeviceStatusColors.alarm;
  return iotDeviceStatusColors[device.connectionState];
};

export const defaultSecurityPolicy: IotSecurityPolicy = {
  spamPhisProtectionEnabled: true,
  iotProtectionEnabled: true,
  remoteAccessProtectionEnabled: true,
  whitelists: {
    fqdn: [],
    ipv4: [],
    ipv6: [],
  },
};

export const isSecurityPolicyDirty = (values: IotFormValues) => {
  if (
    Object.values(SecurityPolicyProtection).reduce((dirty, protection) => {
      if (dirty) return true;
      if (values[protection] !== defaultSecurityPolicy[protection]) return true;
      return false;
    }, false)
  ) {
    return true;
  }
  if (
    Object.values(SecurityPolicyWhitelist).reduce((dirty, whitelist) => {
      if (dirty) return true;
      if (
        values[whitelist].length !==
        defaultSecurityPolicy.whitelists[whitelist].length
      )
        return true;
      return false;
    }, false)
  ) {
    return true;
  }
  return false;
};
