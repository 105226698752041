import DependencyContainer from '../../../DependencyContainer';
import { unitDetailsCacheTTLInSeconds } from '../../../config';
import {
  getCachedItemWithTTL,
  setCachedItemWithTTL,
} from '../../../helpers/cache';
import {
  AllUnitsResponseData,
  BusinessUnit,
  CreateBusinessUnitResponse,
  CreateResidentialUnitResponse,
  CreateUnitDto,
  GenericUnit,
  ResidentialUnit,
  Unit,
  UnitDetails,
  UnitType,
  UpdateUnitDto,
} from '../types';

export default class UnitsService {
  constructor(private readonly factory: DependencyContainer) {}

  async getAllUnitsForMdu(
    mduId: string,
    partnerId: string,
  ): Promise<AllUnitsResponseData> {
    let [residentialUnits, businessUnits] = await Promise.all([
      this.getAllResidentialUnitsForMdu(mduId, partnerId),
      this.getAllBusinessUnitsForMdu(mduId, partnerId),
    ]);
    return {
      residentialUnits,
      businessUnits,
    };
  }

  private async getAllResidentialUnitsForMdu(
    mduId: string,
    partnerId: string,
  ): Promise<ResidentialUnit[]> {
    const unitsResponse = await this.factory.unitsClient.fetchResidentialUnits(
      partnerId,
      mduId,
    );
    let units = [...unitsResponse.data.items];
    if (unitsResponse.data.meta.totalPages > 1) {
      const additionalPageUnitsResponse = await Promise.all(
        Array.from(Array(unitsResponse.data.meta.totalPages - 1)).map(
          (_, i) => {
            return this.factory.unitsClient.fetchResidentialUnits(
              partnerId,
              mduId,
              i + 2,
            );
          },
        ),
      );
      additionalPageUnitsResponse.forEach((response) => {
        units = [...units, ...response.data.items];
      });
    }
    return units;
  }

  private async getAllBusinessUnitsForMdu(
    mduId: string,
    partnerId: string,
  ): Promise<BusinessUnit[]> {
    const unitsResponse = await this.factory.unitsClient.fetchBusinessUnits(
      partnerId,
      mduId,
    );
    let units = [...unitsResponse.data.items];
    if (unitsResponse.data.meta.totalPages > 1) {
      const additionalPageUnitsResponse = await Promise.all(
        Array.from(Array(unitsResponse.data.meta.totalPages - 1)).map(
          (_, i) => {
            return this.factory.unitsClient.fetchBusinessUnits(
              partnerId,
              mduId,
              i + 2,
            );
          },
        ),
      );
      additionalPageUnitsResponse.forEach((response) => {
        units = [...units, ...response.data.items];
      });
    }
    return units;
  }

  async getUnitDetails(
    unit: GenericUnit,
    partnerId: string,
    propertyId: string,
    type: UnitType,
    withCache = true,
  ): Promise<UnitDetails | null> {
    const cachedKeyName = `unit_details_${unit.id}`;
    if (withCache) {
      const detailsFromCache = getCachedItemWithTTL<UnitDetails>(cachedKeyName);

      if (detailsFromCache) {
        return detailsFromCache;
      }
    }

    if (!unit.nodes.length) {
      return {
        nodes: [],
        status: unit.status,
        id: unit.id,
        property: unit.property,
      };
    }

    const fetchDetailsStrategy =
      type === UnitType.Business
        ? 'fetchBusinessUnitDetails'
        : 'fetchResidentialUnitDetails';
    try {
      const detailsResponse = await this.factory.unitsClient[
        fetchDetailsStrategy
      ](unit.id, partnerId, propertyId);
      const fetchedDetails = detailsResponse.data;
      setCachedItemWithTTL(
        cachedKeyName,
        fetchedDetails,
        unitDetailsCacheTTLInSeconds,
      );
      return fetchedDetails;
    } catch (error) {
      console.error(`Error getting unit details for unit ${unit.id}`, error);
      return null;
    }
  }

  async createUnit(
    mduId: string,
    type: UnitType,
    dto: CreateUnitDto,
    partnerId: string,
  ): Promise<
    CreateBusinessUnitResponse | CreateResidentialUnitResponse | undefined
  > {
    if (type === UnitType.Residence) {
      const response = await this.factory.unitsClient.createResidentialUnit(
        mduId,
        dto,
        partnerId,
      );
      return response.data;
    }
    if (type === UnitType.Business) {
      const response = await this.factory.unitsClient.createBusinessUnit(
        mduId,
        dto,
        partnerId,
      );
      return response.data;
    }
  }

  async updateUnit(
    mduId: string,
    unit: UpdateUnitDto,
    unitId: string,
    type: UnitType,
    partnerId: string,
  ): Promise<Unit | undefined> {
    if (type === UnitType.Residence) {
      const response = await this.factory.unitsClient.updateResidentialUnit(
        unitId,
        mduId,
        unit,
        partnerId,
      );
      return response.data;
    }
    if (type === UnitType.Business) {
      const response = await this.factory.unitsClient.updateBusinessUnit(
        unitId,
        mduId,
        unit,
        partnerId,
      );
      return response.data;
    }
  }

  async deleteUnit(
    type: UnitType,
    id: string,
    mduId: string,
    partnerId: string,
  ): Promise<void> {
    if (type === UnitType.Residence) {
      await this.factory.unitsClient.deleteResidentialUnit(
        id,
        mduId,
        partnerId,
      );
      return;
    }
    if (type === UnitType.Business) {
      await this.factory.unitsClient.deleteBusinessUnit(id, mduId, partnerId);
      return;
    }
  }
}
