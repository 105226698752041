import _ from 'lodash';
import { Tooltip } from 'plume-ui';

export const conditionallyTruncate = (
  string: string,
  maxLength: number = 45,
) => {
  if (!_.isString(string)) return <></>;
  if (!_.isNumber(maxLength)) maxLength = 45;
  if (string.length > maxLength) {
    return (
      <Tooltip label={string} openInPortal>
        {`${string.substring(0, maxLength).trim()}...`}
      </Tooltip>
    );
  } else {
    return <>{string}</>;
  }
};
