import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DependencyContainer from '../../../DependencyContainer';
import { useFetchData } from '../../../hooks/useFetchData';
import { selectedPartnerSelector } from '../../../state';
import { selectedMduIdAtom } from '../../editorView/propertyState';
import {
  hasIotNetworkSelector,
  iotDevicesAtom,
  iotNetworkAtom,
} from '../state';

const { iotService } = new DependencyContainer();

const useIotDevices = () => {
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const selectedPropertyId = useRecoilValue(selectedMduIdAtom);
  const iotNetwork = useRecoilValue(iotNetworkAtom);
  const hasIotNetwork = useRecoilValue(hasIotNetworkSelector);
  const setIotDevices = useSetRecoilState(iotDevicesAtom);

  const [loading, , error, runFetch] = useFetchData(
    async () => {
      if (
        selectedPropertyId &&
        selectedPartner &&
        hasIotNetwork &&
        iotNetwork?.propertyNetworkId
      ) {
        return await iotService.getIotDevices(
          iotNetwork.propertyNetworkId,
          selectedPropertyId,
          selectedPartner.id,
        );
      }
    },
    (iotDevices) => {
      if (Array.isArray(iotDevices)) setIotDevices(iotDevices);
    },
    [iotNetwork],
  );

  useEffect(() => {
    setIotDevices([]);
  }, [selectedPartner, selectedPropertyId]);

  return {
    loading,
    error,
    runFetch,
  };
};

export default useIotDevices;
