import { LeafletMarker } from './components/LeafletMap/LeafletMap';
import { randomFloridaLocation } from './florida';

export const anonymizeString = (string?: string) => {
  if (!string) return '';
  return `${string.at(0)}**********${string.at(-1)}`;
};

export const anonymizeEmail = (email?: string) => {
  if (!email) return;
  const [locale, domain] = email.split('@');
  const anonymizedLocale = `${locale.at(0)}********${locale.at(-1)}`;
  const anonymizedDomain = `${domain.at(0)}********${domain.at(-1)}`;
  return `${anonymizedLocale}@${anonymizedDomain}`;
};

export const chooseRandomFloridaLocation = (marker: LeafletMarker) => {
  let newMarker = { ...marker };
  newMarker.geoInfo = randomFloridaLocation(
    marker.geoInfo.lat,
    marker.geoInfo.long,
  );
  return newMarker;
};
