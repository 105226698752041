import { FunctionComponent } from 'react';

export type AdjustableComponent<P, S> = FunctionComponent<
  P & { classes?: ClassesModifier<S> }
>;

export type ClassesModifier<T> = (styles: T) => T;

export const useClasses = <T extends { [key: string]: string }>(
  defaultStyles: T,
  classes?: ClassesModifier<T>,
) => {
  return classes ? classes(defaultStyles) : defaultStyles;
};
