import DependencyContainer from '../../../DependencyContainer';
import {
  CreatePropertyNetworkDto,
  PropertyNetwork,
  PropertyNetworkDetails,
  UpdatePropertyNetworkDto,
} from '../types';
import {
  getCachedItemWithTTL,
  setCachedItemWithTTL,
} from '../../../helpers/cache';
import { propertyNetworksCacheTTLInSeconds } from '../../../config';

export default class PropertyNetworkService {
  constructor(private readonly factory: DependencyContainer) {}

  async getAllPropertyNetworks(
    mduId: string,
    partnerId: string,
  ): Promise<PropertyNetwork[]> {
    const response = await this.factory.propertyNetworksClient.fetchPropertyNetworks(
      mduId,
      partnerId,
    );

    let propertyNetworks = [...response.data.items];

    if (response.data.meta.totalPages > 1) {
      const additionalPagePropertyNetworksResponse = await Promise.all(
        Array.from(Array(response.data.meta.totalPages - 1)).map((_, i) => {
          return this.factory.propertyNetworksClient.fetchPropertyNetworks(
            mduId,
            partnerId,
            i + 2,
          );
        }),
      );
      additionalPagePropertyNetworksResponse.forEach((response) => {
        propertyNetworks = [...propertyNetworks, ...response.data.items];
      });
    }
    return propertyNetworks;
  }

  async deletePropertyNetwork(
    mduId: string,
    partnerId: string,
    propertyNetworkId: string,
  ): Promise<void> {
    await this.factory.propertyNetworksClient.deletePropertyNetwork(
      mduId,
      partnerId,
      propertyNetworkId,
    );
  }

  async createPropertyNetwork(
    mduId: string,
    dto: CreatePropertyNetworkDto,
    partnerId: string,
  ): Promise<PropertyNetwork> {
    let response = await this.factory.propertyNetworksClient.createPropertyNetwork(
      mduId,
      dto,
      partnerId,
    );
    response.data.averageDownloadSpeed = 0;
    response.data.averageUploadSpeed = 0;

    return response.data;
  }

  async updatePropertyNetwork(
    mduId: string,
    dto: UpdatePropertyNetworkDto,
    partnerId: string,
    propertyNetworkId: string,
  ): Promise<PropertyNetwork> {
    let response = await this.factory.propertyNetworksClient.updatePropertyNetwork(
      mduId,
      dto,
      partnerId,
      propertyNetworkId,
    );
    return response.data;
  }

  async getPropertyNetworkDetails(
    propertyNetwork: PropertyNetwork,
    partnerId: string,
    propertyId: string,
  ): Promise<PropertyNetworkDetails | null> {
    const cachedKeyName = `property_network_details_${propertyNetwork.id}`;
    const detailsFromCache = getCachedItemWithTTL<PropertyNetworkDetails>(
      cachedKeyName,
    );

    if (detailsFromCache) {
      return detailsFromCache;
    }

    if (!propertyNetwork.nodes.length) {
      return {
        nodes: [],
        status: 'status',
        id: propertyNetwork.id,
        property: propertyNetwork.property,
      };
    }

    try {
      const detailsResponse = await this.factory.propertyNetworksClient.getPropertyNetworkDetails(
        propertyNetwork.id,
        propertyId,
        partnerId,
      );
      const fetchedDetails = detailsResponse.data;
      setCachedItemWithTTL(
        cachedKeyName,
        fetchedDetails,
        propertyNetworksCacheTTLInSeconds,
      );
      return fetchedDetails;
    } catch (error) {
      console.error(
        `Error getting property network details for property network ${propertyNetwork.id}`,
        error,
      );
      return null;
    }
  }
}
