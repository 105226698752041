import { Box, Button, TopBar } from 'plume-ui';
import { BoxStyles } from 'plume-ui/dist/components/Box/Box';
import React, { FunctionComponent, memo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import DependencyContainer from '../../../DependencyContainer';
import { MixPanelEvents } from '../../../mixPanelEvents';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import { useRedirectToRoute } from '../../../utils/hooks/useRedirectToRoute';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { AvailableScreens } from '../../trackingAnalytics/types';
import LoginContainerAuthError from '../components/LoginContainerAuthError';
import useLoginWithOkta from '../hooks/useLoginWithOkta';

const { loginService } = new DependencyContainer();

const LoginContainer: FunctionComponent = () => {
  const { login, isAuthenticated } = useLoginWithOkta();
  const redirectToRoute = useRedirectToRoute();
  const authError = loginService.getAuthError();
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Login,
      },
    });
  }, []);

  if (isAuthenticated) {
    const targetRoute = loginService.getTargetRoute();
    redirectToRoute(targetRoute);
  }

  return (
    <>
      <Helmet>
        <title>{t('pageTitles.login')}</title>
      </Helmet>
      <TopBar />
      <div className="LoginContainer p-xl">
        <Box
          classes={(current: BoxStyles) => ({
            ...current,
            root: `${current.root} LoginContainer__box`,
          })}
        >
          <h3 className="LoginContainer__heading">
            <FormattedMessage id="signIn" />
          </h3>
          <h1 className="LoginContainer__app-name">
            <FormattedMessage id="appName" />
          </h1>
          {!!authError && <LoginContainerAuthError {...authError} />}
          <div className="LoginContainer__login-button">
            <Button styleVariant="superprimary" onClick={() => login()}>
              <FormattedMessage id="signIn" />
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
};

export default memo(LoginContainer);
