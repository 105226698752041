import { Heading } from 'plume-ui';
import React from 'react';

type FormSectionProps = {
  headerButton?: JSX.Element;
  hide?: boolean;
  rows?: JSX.Element[][];
  title?: string;
  titleSize?: 'xl' | 'xxl';
};

const FormSection = ({
  headerButton,
  hide,
  rows,
  title,
  titleSize,
}: FormSectionProps) => {
  if (hide) return null;
  return (
    <div className="FormSection">
      <div className="FormSection__header">
        <Heading size={titleSize || 'xxl'}>{title}</Heading>
        {headerButton}
      </div>
      {rows?.map((section, i) => (
        <div className="FormSection__row" key={i}>
          {section.map((sectionItem, i) => (
            <React.Fragment key={i}>{sectionItem}</React.Fragment>
          ))}
        </div>
      ))}
    </div>
  );
};

export default FormSection;
