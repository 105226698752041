import { AxiosResponse } from 'axios';
import { HttpClient } from '../../../HttpClient';
import { PropertyStats } from '../types';
import { UpriseApiUrls } from '../../../urls';

export default class StatClient extends HttpClient {
  async fetchStatsForOverviewPage(
    mduId: string,
    partnerId: string,
  ): Promise<AxiosResponse<PropertyStats>> {
    return this.get<AxiosResponse<PropertyStats>>(
      UpriseApiUrls.getPropertyStatsUrl(mduId, partnerId),
    );
  }

  async fetchStatsForOverviewV2Page(
    mduId: string,
    partnerId: string,
  ): Promise<AxiosResponse<PropertyStats>> {
    return this.get<AxiosResponse<PropertyStats>>(
      UpriseApiUrls.getPropertyStatsV2Url(mduId, partnerId),
    );
  }
}
