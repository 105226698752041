import { FormikErrors } from 'formik';
import { InputMessageStatus } from 'plume-ui/dist/components/InputField/InputField';

export const prepareErrorMessageForInput = (
  name: string,
  errors: FormikErrors<any>,
) => {
  if (!errors[name]) {
    return undefined;
  }

  return [
    {
      status: 'error' as InputMessageStatus,
      message: errors[name] as string,
    },
  ];
};

export const prepareErrorMessageForInputArray = (
  name: string,
  index: number,
  errors: FormikErrors<{ [x: string]: string[] | string }>,
) => {
  if (
    !errors[name] ||
    !Array.isArray(errors[name]) ||
    index >= (errors[name] as any)?.length ||
    !(errors[name] as any)[index]
  ) {
    return undefined;
  }
  return [
    {
      status: 'error',
      message: (errors[name] as any)[index],
    },
  ];
};
