import { Button } from 'plume-ui';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Routes } from '../../../../config';
import useRenderAnonymized from '../../../../hooks/useRenderAnonymized';
import { selectedPartnerIdAtom } from '../../../../state';
import {
  AdjustableComponent,
  useClasses,
} from '../../../../utils/hooks/useClasses';
import { useRedirectToRoute } from '../../../../utils/hooks/useRedirectToRoute';
import { selectedMduIdAtom } from '../../../editorView/propertyState';
import {
  propertyNetworksFilterStatusAtom,
  propertyNetworksSelectedFilterAtom,
} from '../../../property-networks/propertyNetworksState';
import { UnitType } from '../../../units/types';
import {
  unitsFilterStatusAtom,
  unitsSelectedFilterAtom,
  unitsSelectedTypeAtom,
} from '../../../units/unitsState';
import { alertTypeToLabel } from '../../util';

export type LargeHeroStatAlertsProps = {
  alerts: LargeHeroStatAlert[];
};

export type LargeHeroStatAlertsStyles = {
  root: string;
  alert: string;
  alertLabel: string;
  alertValue: string;
};

export enum LargeHeroStatAlertType {
  NodeOffline = 'nodeOffline',
  LocationOffline = 'locationOffline',
  NodeQoeAlert = 'nodeQoeAlert',
}

export type LargeHeroStatAlert = {
  unitName?: string;
  unitType?: UnitType;
  propertyNetworkName?: string;
  alertType: LargeHeroStatAlertType;
};

const LargeHeroStatAlerts: AdjustableComponent<
  LargeHeroStatAlertsProps,
  LargeHeroStatAlertsStyles
> = ({ classes, alerts }) => {
  const { t } = useTranslation();
  const { renderAnonymized } = useRenderAnonymized();
  const redirectToRoute = useRedirectToRoute();
  const setUnitsFilterStatus = useSetRecoilState(unitsFilterStatusAtom);
  const setSelectedUnitsFilter = useSetRecoilState(unitsSelectedFilterAtom);
  const setSelectedUnitType = useSetRecoilState(unitsSelectedTypeAtom);
  const setPropertyNetworksFilterStatus = useSetRecoilState(
    propertyNetworksFilterStatusAtom,
  );
  const setSelectedPropertyNetworksFilter = useSetRecoilState(
    propertyNetworksSelectedFilterAtom,
  );
  const partnerId = useRecoilValue(selectedPartnerIdAtom);
  const propertyId = useRecoilValue(selectedMduIdAtom);

  const styles = useClasses(
    {
      root: 'LargeHeroStat__alerts',
      alert: 'LargeHeroStat__alert',
      alertLabel: 'LargeHeroStat__alert-label',
      alertValue: 'LargeHeroStat__alert-value',
    },
    classes,
  );

  const goToAlertedUnit = (unitName: string, unitType: UnitType) => {
    setUnitsFilterStatus('');
    setSelectedUnitType(unitType);
    setSelectedUnitsFilter(unitName);
    const params = {
      partnerId,
      propertyId,
    };
    redirectToRoute(Routes.Units, params);
  };

  const goToAlertedPropertyNetwork = (propertyNetworkName: string) => {
    setPropertyNetworksFilterStatus('');
    setSelectedPropertyNetworksFilter(propertyNetworkName);
    const params = {
      partnerId,
      propertyId,
    };
    redirectToRoute(Routes.PropertyNetworks, params);
  };

  const alertButton = ({
    unitName,
    unitType,
    propertyNetworkName,
    alertType,
  }: LargeHeroStatAlert) => {
    return (
      <Button
        classes={(current) => ({
          ...current,
          root: `${current.root} LargeHeroStat__alert-btn`,
        })}
        styleVariant="tertiary"
        onClick={() => {
          if (unitName && unitType) {
            goToAlertedUnit(unitName, unitType);
            return;
          }
          if (propertyNetworkName) {
            goToAlertedPropertyNetwork(propertyNetworkName);
            return;
          }
        }}
      >
        {t(alertTypeToLabel(alertType))}
      </Button>
    );
  };

  const sliceAlerts = () => {
    if (alerts.length > 3) {
      return alerts.slice(0, 3);
    }
    return alerts;
  };

  return (
    <div className={styles.root}>
      {sliceAlerts().map((alert, index) => {
        return (
          <div className={styles.alert} key={index}>
            <span className={styles.alertLabel}>
              {renderAnonymized(alert.unitName || alert.propertyNetworkName)}
            </span>
            <span className={styles.alertValue}>{alertButton(alert)}</span>
          </div>
        );
      })}
    </div>
  );
};

export default memo(LargeHeroStatAlerts);
