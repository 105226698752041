import { FieldSort } from 'plume-ui/dist/components/Table/Table';
import { atom, selector } from 'recoil';
import { DEFAULT_PAGE_SIZE } from '../../config';
import { ModalState } from '../../types';
import { LoadingStatus } from '../units/types';
import { PropertyNetwork, SortablePropertyNetworksFieldNames } from './types';

export const propertyNetworksAtom = atom<PropertyNetwork[]>({
  key: 'propertyNetworksAtom',
  default: [],
});

export const propertyNetworksLoadingStatusAtom = atom<LoadingStatus>({
  key: 'propertyNetworksStateAtom',
  default: 'loading',
});

export const propertyNetworksSelectedPageAtom = atom<number>({
  key: 'propertyNetworksSelectedPageAtom',
  default: 0,
});

export const propertyNetworksSelectedFilterAtom = atom<string>({
  key: 'propertyNetworksSelectedFilterAtom',
  default: '',
});

export const propertyNetworksFilterStatusAtom = atom<string>({
  key: 'propertyNetworksFilterStatusAtom',
  default: '',
});

export const propertyNetworksSortAtom = atom<FieldSort | null>({
  key: 'propertyNetworksSortAtom',
  default: null,
});

export const sortedPropertyNetworks = selector<PropertyNetwork[]>({
  key: 'sortedPropertyNetworks',
  get: ({ get }) => {
    const propertyNetworks = get(propertyNetworksAtom);
    const sort = get(propertyNetworksSortAtom);
    if (sort) {
      return sortPropertyNetworks([...propertyNetworks], sort);
    }
    return propertyNetworks;
  },
});

const sortPropertyNetworks = (
  networks: PropertyNetwork[],
  sort: FieldSort,
): PropertyNetwork[] => {
  const fieldName = sort.fieldName as SortablePropertyNetworksFieldNames;
  switch (fieldName) {
    case 'averageDownloadSpeed':
    case 'averageUploadSpeed': {
      return networks.sort(
        (a, b) => (a[fieldName] - b[fieldName]) * sort.direction,
      );
    }
    case 'propertySsid':
      return networks.sort(
        (a, b) =>
          a.propertyWifi.ssid.localeCompare(b.propertyWifi.ssid) *
          sort.direction,
      );
    case 'communitySsid':
      return networks.sort((a, b) => {
        const aSsid = (a.communityWifi?.enabled && a.communityWifi?.ssid) || '';
        const bSsid = (b.communityWifi?.enabled && b.communityWifi?.ssid) || '';
        return aSsid.localeCompare(bSsid) * sort.direction;
      });
    case 'name':
      return networks.sort(
        (a, b) => a.name.localeCompare(b.name) * sort.direction,
      );
    default:
      return networks;
  }
};

export const allFilteredPropertyNetworks = selector<PropertyNetwork[]>({
  key: 'allFilteredPropertyNetworks',
  get: ({ get }) => {
    let propertyNetworks = get(sortedPropertyNetworks);
    const filter = get(propertyNetworksSelectedFilterAtom);
    const statusFilter = get(propertyNetworksFilterStatusAtom);

    if (statusFilter === 'alerted') {
      propertyNetworks = propertyNetworks.filter(
        (propertyNetwork) =>
          propertyNetwork.isLocationOffline ||
          propertyNetwork.isNodeQoeAlert ||
          propertyNetwork.isNodeOffline,
      );
    }

    if (filter) {
      propertyNetworks = propertyNetworks.filter((propertyNetwork) =>
        propertyNetwork.name
          .toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase()),
      );
    }

    return propertyNetworks;
  },
});

export const currentPropertyNetworksPage = selector<PropertyNetwork[]>({
  key: 'currentPropertyNetworksPage',
  get: ({ get }) => {
    const propertyNetworks = get(allFilteredPropertyNetworks);
    const selectedPage = get(propertyNetworksSelectedPageAtom);

    return propertyNetworks.slice(
      selectedPage * DEFAULT_PAGE_SIZE,
      (selectedPage + 1) * DEFAULT_PAGE_SIZE,
    );
  },
});

export const getFilteredPropertyNetworkCount = selector<number>({
  key: 'getFilteredPropertyNetworkCount',
  get: ({ get }) => {
    return get(allFilteredPropertyNetworks).length;
  },
});

export const managePropertyNetworksModalStateAtom = atom<ModalState>({
  key: 'managePropertyNetworksModalStateAtom',
  default: ModalState.Dismissed,
});

export const selectedPropertyNetworkAtom = atom<PropertyNetwork | null>({
  key: 'selectedPropertyNetworkAtom',
  default: null,
});

export const selectedPropertyNetworkToViewNodesAtom = atom<PropertyNetwork | null>(
  {
    key: 'selectedPropertyNetworkToViewNodesAtom',
    default: null,
  },
);
