import { FieldInputProps, FormikProps } from 'formik';
import { InputField as InputFieldFromUi } from 'plume-ui';
import { InputFieldStyles } from 'plume-ui/dist/components/InputField/InputField';
import React, { ChangeEvent, memo } from 'react';
import { AdjustableComponent } from '../../utils/hooks/useClasses';

type InputFieldMessage = {
  status: 'error' | 'success' | 'hint';
  message: string;
};

type InputFieldProps = {
  messages: InputFieldMessage[];
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  disabled?: boolean;
  noClearIcon?: boolean;
} & JSX.IntrinsicElements['input'];

const InputField: AdjustableComponent<InputFieldProps, InputFieldStyles> = ({
  messages,
  field,
  form,
  disabled,
  noClearIcon = false,
  ...props
}) => {
  const onInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    form.setFieldValue(field.name, value);
  };

  return (
    <InputFieldFromUi
      messages={messages}
      name={field.name}
      value={field.value}
      noClearIcon={noClearIcon}
      disabled={disabled}
      {...props}
      onInput={onInput}
    />
  );
};

export default memo(InputField);
