import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { anonymizedAtom } from '../state';

const useConfig = () => {
  const [anonymized, setAnonymized] = useRecoilState(anonymizedAtom);

  useEffect(() => {
    const anonymize = window.localStorage.getItem('anonymize');
    setAnonymized(Boolean(JSON.parse(anonymize || 'false')));
  }, []);

  useEffect(() => {
    window.localStorage.setItem('anonymize', `${anonymized}`);
  }, [anonymized]);
};

export default useConfig;
