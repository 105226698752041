import { BasicNode, NodeReference } from '../inventory/types';

export type LoadingStatus = 'loading' | 'loaded' | 'error';

export enum UnitStatus {
  Assigned = 'assigned',
  Unassigned = 'unassigned',
  Unavailable = 'unavailable',
  Inactive = 'inactive',
}

export enum UnitConnectionStatus {
  LocationOffline = 'locationOffline',
  NodeQoeAlert = 'nodeQoeAlert',
  NodeOffline = 'nodeOffline',
  NoNodes = 'noNodes',
  Online = 'online',
  Inactive = 'inactive',
}

export enum UnitType {
  Business = 'Business',
  Residence = 'Residence',
}

export type Reference = {
  id: string;
  name: string;
};

export type StateMeta = {
  targetStatus: string;
  realized: boolean;
  lastError: string;
  lastReference: string;
};

export enum UnitIotPropagationStatus {
  Completed = 'completed',
  Failed = 'failed',
  InProgress = 'in progress',
  Disabled = 'disabled',
}

export type Unit = ResidentialUnit | BusinessUnit;

export type ResidentialUnit = CommonUnitProperties & {
  residentialTenant: Reference | null;
  iotConfiguration: {
    configurationHash: string | null;
    iot_network_manually_disabled: boolean | null;
    lastIotRealizationError: string | null;
    propagationRealized: boolean;
    propagationStatus: UnitIotPropagationStatus;
  } | null;
};

export type BusinessUnit = CommonUnitProperties & {
  businessTenant: Reference | null;
};

export type CommonUnitProperties = {
  averageDownloadSpeed?: number;
  averageUploadSpeed?: number;
  customerId: string | null;
  id: string;
  isLocationOffline?: boolean;
  isNodeOffline?: boolean;
  isNodeQoeAlert?: boolean;
  locationId: string | null;
  accountId: string | null;
  name: string;
  nodes: BasicNode[];
  property: Reference;
  retailPay: boolean;
  stateMeta: StateMeta;
  status: UnitStatus | null;
};

export type ResidentialUnitDtoResponse = {
  customerId: string;
  id: string;
  isLocationOffline: boolean;
  isNodeOffline: boolean;
  isNodeQoeAlert: boolean;
  locationId: string;
  name: string;
  nodes: BasicNode[];
  property: { id: string; name: string };
  residentialTenant: Reference | null;
  stateMeta: StateMeta;
  status: UnitStatus;
};

export type BusinessUnitDtoResponse = {
  businessTenant: Reference | null;
  customerId: string;
  id: string;
  isLocationOffline: boolean;
  isNodeOffline: boolean;
  locationId: string;
  name: string;
  nodes: BasicNode[];
  property: { id: string; name: string };
  stateMeta: StateMeta;
  status: UnitStatus;
};

export type AllUnitsResponseData = {
  residentialUnits: ResidentialUnit[];
  businessUnits: BusinessUnit[];
};

export type GenericUnit = CommonUnitProperties & {
  tenant: Reference | null;
  type: UnitType;
};

export type CreateUnitDto = {
  name: string;
  nodes?: NodeReference[];
  status: UnitStatus;
  retailPay?: boolean;
  accountId?: string;
};

export type CreateResidentialUnitResponse = {
  type: UnitType.Residence;
} & ResidentialUnit;

export type CreateBusinessUnitResponse = {
  type: UnitType.Business;
} & BusinessUnit;

export type UpdateUnitDto = UpdateResidentialUnitDto | UpdateBusinessUnitDto;

export type UpdateResidentialUnitDto = Partial<ResidentialUnit>;

export type UpdateBusinessUnitDto = Partial<BusinessUnit>;

export type UnitNodeHealth = {
  score: number;
  status: string;
  details?: string;
};
export enum UnitNodeConnectionStates {
  Online = 'connected',
  Offline = 'disconnected',
  Unavailable = 'unavailable',
}

export type NodeWithStatus = {
  id: string;
  unitId: string;
  connectionState: UnitNodeConnectionStates;
  health: UnitNodeHealth;
  alerts: string[];
};

export type UnitDetails = {
  id: string;
  nodes: NodeWithStatus[];
  status: string;
  property: Reference;
};

export type SortableUnitFieldNames =
  | 'status'
  | 'averageUploadSpeed'
  | 'averageDownloadSpeed'
  | 'retailPay'
  | 'accountId'
  | 'name';
