import DependencyContainer from '../../../DependencyContainer';
import { CreateIotNetworkDTO, IotNetwork, UpdateIotNetworkDTO } from '../types';

export default class IotService {
  constructor(private readonly factory: DependencyContainer) {}

  async createIotNetwork(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
    createIotNetworkDTO: CreateIotNetworkDTO,
  ): Promise<IotNetwork> {
    const response = await this.factory.iotClient.createIotNetwork(
      propertyNetworkId,
      mduId,
      partnerId,
      createIotNetworkDTO,
    );

    return response.data;
  }

  async getIotNetwork(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) {
    const response = await this.factory.iotClient.getIotNetwork(
      propertyNetworkId,
      mduId,
      partnerId,
    );

    return response.data;
  }

  async updateIotNetwork(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
    updateIotNetworkDTO: UpdateIotNetworkDTO,
  ): Promise<IotNetwork> {
    const response = await this.factory.iotClient.updateIotNetwork(
      propertyNetworkId,
      mduId,
      partnerId,
      updateIotNetworkDTO,
    );

    return response.data;
  }

  async deleteIotNetwork(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) {
    const response = await this.factory.iotClient.deleteIotNetwork(
      propertyNetworkId,
      mduId,
      partnerId,
    );

    return response.data;
  }

  async propagateIotNetwork(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) {
    const response = await this.factory.iotClient.propagateIotNetwork(
      propertyNetworkId,
      mduId,
      partnerId,
    );

    return response.data;
  }

  async propagateSingleIotNetwork(
    unitId: string,
    mduId: string,
    partnerId: string,
  ) {
    const response = await this.factory.iotClient.propagateSingleIotNetwork(
      unitId,
      mduId,
      partnerId,
    );

    return response.data;
  }

  async propagateDeleteIotNetwork(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) {
    const response = await this.factory.iotClient.propagateDeleteIotNetwork(
      propertyNetworkId,
      mduId,
      partnerId,
    );

    return response.data;
  }

  async propagateDeleteSingleIotNetwork(
    unitId: string,
    mduId: string,
    partnerId: string,
  ) {
    const response = await this.factory.iotClient.propagateDeleteSingleIotNetwork(
      unitId,
      mduId,
      partnerId,
    );

    return response.data;
  }

  async getIotDevices(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ) {
    const response = await this.factory.iotClient.getIotDevices(
      propertyNetworkId,
      mduId,
      partnerId,
    );

    return response.data;
  }

  async getIotPropagationStatus(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
    transactionId: string,
  ) {
    const response = await this.factory.iotClient.getIotPropagationStatus(
      propertyNetworkId,
      mduId,
      partnerId,
      transactionId,
    );

    return response.data;
  }
}
