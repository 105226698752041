import _ from 'lodash';
import { BandChannelDistribution, FrequencyBand, TimeRange } from './types';
import moment from 'moment';

export const harmonyChartDateFormat = 'MMM DD';

export const channelIndexMap: Record<string, Record<number, number>> = {
  '2.4G': {
    1: 0,
    6: 1,
    11: 2,
  },
  '5G': {
    44: 0,
    60: 1,
    108: 2,
    124: 3,
    157: 4,
  },
};

export const filterStatsByDate = <T extends { dt: string }>(
  stats: T[],
  timeRange: number,
  shiftByNDays?: number,
): T[] => {
  if (!Array.isArray(stats)) return [];
  return stats.filter((stat) =>
    isWithinNumberOfDays(stat.dt, timeRange, shiftByNDays),
  );
};

export const isWithinNumberOfDays = (
  dateTime: string,
  timeRange: number,
  shiftByNDays?: number,
) => {
  if (_.isNumber(shiftByNDays)) timeRange += shiftByNDays;
  return moment(dateTime).isSameOrAfter(moment().subtract(timeRange, 'days'));
};

export const isValidChannel = (
  frequencyBand: FrequencyBand,
  frequencyChannel: number,
) => {
  return channelIndexMap[frequencyBand]?.hasOwnProperty(frequencyChannel);
};

export const getValuesByChannel = (
  frequencyBand: FrequencyBand,
  stat: BandChannelDistribution,
) => {
  const values = Array(Object.keys(channelIndexMap[frequencyBand]).length).fill(
    0,
  );
  const totalCount = stat.frequencyChannels.reduce(
    (totalCount, currentChannel) => {
      if (!isValidChannel(frequencyBand, currentChannel.frequencyChannel)) {
        return totalCount;
      }
      return totalCount + currentChannel.channelLocationCount;
    },
    0,
  );
  stat.frequencyChannels.forEach((channel) => {
    if (!isValidChannel(frequencyBand, channel.frequencyChannel)) return;
    if (totalCount === 0) return;
    values[channelIndexMap[frequencyBand][channel.frequencyChannel]] =
      channel.channelLocationCount / totalCount;
  });
  return values;
};

export const padHarmonyAreaChartData = <T extends { xAxisLabel: string }>(
  chartData: T[],
  timeRange: number,
  zeroValues: Omit<T, 'xAxisLabel' | 'dt' | 'frequencyBand'>,
  shiftByNDays?: number,
): T[] => {
  if (!Array.isArray(chartData)) return [];
  if (timeRange <= 0) return [];
  return _.range(timeRange).map((_ignore, i) => {
    const xAxisLabel = moment()
      // hide at most two days for SDATA-6378
      .subtract(timeRange - i + (-1 + (shiftByNDays || 0)), 'days')
      .format(harmonyChartDateFormat);
    const data =
      chartData.find((dataPoint) => dataPoint.xAxisLabel === xAxisLabel) ||
      zeroValues;
    return {
      xAxisLabel,
      ...data,
    };
  }) as T[];
};

export const padHarmonyBarChartData = (
  chartData: { name: string; values: number[] }[],
  timeRange: number,
  zeroValues: number[] = [0, 0, 0],
  shiftByNDays?: number,
): { name: string; values: number[] }[] => {
  if (!Array.isArray(chartData)) return [];
  if (timeRange <= 0) return [];
  return _.range(timeRange).map((_, i) => {
    const name = moment()
      // hide at most two days for SDATA-6378
      .subtract(timeRange - i + (-1 + (shiftByNDays || 0)), 'days')
      .format(harmonyChartDateFormat);
    const values =
      chartData.find((dataPoint) => dataPoint.name === name)?.values ||
      zeroValues;
    return {
      name,
      values,
    };
  });
};

export const timeRangeToNumber = (timeRange: TimeRange) => {
  switch (timeRange) {
    case TimeRange.Last7days:
      return 7;
    case TimeRange.Last30days:
      return 30;
    default:
      return 7;
  }
};
