import { useSetRecoilState } from 'recoil';
import { useTenants } from '../../tenants/hooks/useTenants';
import { BusinessUnit, ResidentialUnit, Unit, UnitType } from '../types';
import { businessUnitsAtom, residentialUnitsAtom } from '../unitsState';

type UseUnitsUpdateActionsValues = {
  addUnit: (unit: Unit, type: UnitType) => void;
  updateUnit: (unit: Partial<Unit>, type: UnitType) => void;
  removeUnit: (unitId: string, type: UnitType) => void;
};

export const useUnitsUpdateActions = (): UseUnitsUpdateActionsValues => {
  const { runFetch: fetchTenants } = useTenants(false);
  const setResidentialUnits = useSetRecoilState(residentialUnitsAtom);
  const setBusinessUnits = useSetRecoilState(businessUnitsAtom);

  const updateUnit = <T extends Partial<Unit>>(unit: T, type: UnitType) => {
    if (type === UnitType.Residence) {
      setResidentialUnits((previousUnits) =>
        previousUnits.map((u) =>
          u.id === unit.id
            ? {
                ...JSON.parse(JSON.stringify(u)),
                ...JSON.parse(JSON.stringify(unit)),
              }
            : u,
        ),
      );
    } else {
      setBusinessUnits((previousUnits) =>
        previousUnits.map((u) =>
          u.id === unit.id
            ? {
                ...JSON.parse(JSON.stringify(u)),
                ...JSON.parse(JSON.stringify(unit)),
              }
            : u,
        ),
      );
    }
    fetchTenants();
  };

  const addUnit = (unit: Unit, type: UnitType) => {
    if (type === UnitType.Residence) {
      setResidentialUnits((previousUnits) => [
        ...previousUnits,
        unit as ResidentialUnit,
      ]);
    } else {
      setBusinessUnits((previousUnits) => [
        ...previousUnits,
        unit as BusinessUnit,
      ]);
    }
  };

  const removeUnit = (unitId: string, type: UnitType) => {
    if (type === UnitType.Residence) {
      setResidentialUnits((previousUnits) =>
        previousUnits.filter((u) => u.id !== unitId),
      );
    } else {
      setBusinessUnits((previousUnits) =>
        previousUnits.filter((u) => u.id !== unitId),
      );
    }
  };

  return {
    addUnit,
    updateUnit,
    removeUnit,
  };
};
