import { AxiosResponse } from 'axios';
import { HttpClient } from '../../../HttpClient';
import { UpriseApiResponse } from '../../../types';
import { UpriseApiUrls } from '../../../urls';
import {
  BusinessUnit,
  CreateBusinessUnitResponse,
  CreateResidentialUnitResponse,
  CreateUnitDto,
  ResidentialUnit,
  UnitDetails,
  UpdateBusinessUnitDto,
  UpdateResidentialUnitDto,
} from '../types';

export default class UnitsClient extends HttpClient {
  async fetchResidentialUnits(
    partnerId: string,
    mduId: string,
    page: number = 1,
  ): Promise<AxiosResponse<UpriseApiResponse<ResidentialUnit[]>>> {
    return await this.get<AxiosResponse<UpriseApiResponse<ResidentialUnit[]>>>(
      UpriseApiUrls.getGetResidentialUnitsUrl(mduId, page, partnerId),
    );
  }

  async fetchBusinessUnits(
    partnerId: string,
    mduId: string,
    page: number = 1,
  ): Promise<AxiosResponse<UpriseApiResponse<BusinessUnit[]>>> {
    return await this.get<AxiosResponse<UpriseApiResponse<BusinessUnit[]>>>(
      UpriseApiUrls.getGetBusinessUnitsUrl(mduId, page, partnerId),
    );
  }

  async fetchResidentialUnitDetails(
    unitId: string,
    partnerId: string,
    mduId: string,
  ): Promise<AxiosResponse<UnitDetails>> {
    return await this.get<AxiosResponse<UnitDetails>>(
      UpriseApiUrls.getGetResidentialUnitDetailsUrl(unitId, mduId, partnerId),
    );
  }

  async fetchBusinessUnitDetails(
    unitId: string,
    partnerId: string,
    mduId: string,
  ): Promise<AxiosResponse<UnitDetails>> {
    return await this.get<AxiosResponse<UnitDetails>>(
      UpriseApiUrls.getGetBusinessUnitDetailsUrl(unitId, mduId, partnerId),
    );
  }

  async createBusinessUnit(
    mduId: string,
    dto: CreateUnitDto,
    partnerId: string,
  ): Promise<AxiosResponse<CreateBusinessUnitResponse>> {
    return await this.post<AxiosResponse<CreateBusinessUnitResponse>>(
      UpriseApiUrls.getCreateBusinessUnitUrl(mduId, partnerId),
      dto,
    );
  }

  async createResidentialUnit(
    mduId: string,
    dto: CreateUnitDto,
    partnerId: string,
  ): Promise<AxiosResponse<CreateResidentialUnitResponse>> {
    return await this.post<AxiosResponse<CreateResidentialUnitResponse>>(
      UpriseApiUrls.getCreateResidentialUnitUrl(mduId, partnerId),
      dto,
    );
  }

  async updateBusinessUnit(
    unitId: string,
    mduId: string,
    dto: UpdateBusinessUnitDto,
    partnerId: string,
  ): Promise<AxiosResponse<BusinessUnit>> {
    return await this.patch<AxiosResponse<BusinessUnit>>(
      UpriseApiUrls.getUpdateBusinessUnitUrl(unitId, mduId, partnerId),
      dto,
    );
  }

  async updateResidentialUnit(
    unitId: string,
    mduId: string,
    dto: UpdateResidentialUnitDto,
    partnerId: string,
  ): Promise<AxiosResponse<ResidentialUnit>> {
    return await this.patch<AxiosResponse<ResidentialUnit>>(
      UpriseApiUrls.getUpdateResidentialUnitUrl(unitId, mduId, partnerId),
      dto,
    );
  }

  async deleteBusinessUnit(
    id: string,
    mduId: string,
    partnerId: string,
  ): Promise<void> {
    await this.delete(
      UpriseApiUrls.getDeleteBusinessUnitUrl(id, mduId, partnerId),
    );
  }

  async deleteResidentialUnit(
    id: string,
    mduId: string,
    partnerId: string,
  ): Promise<void> {
    await this.delete(
      UpriseApiUrls.getDeleteResidentialUnitUrl(id, mduId, partnerId),
    );
  }
}
