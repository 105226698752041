import { MaintenanceTicket, MaintenanceTicketStatus } from './types';

export const mockTickets: MaintenanceTicket[] = [
  {
    status: MaintenanceTicketStatus.Active,
    title: 'Electrical',
    unitId: 'Unit 101',
    closeReason: '',
    createdAt: new Date(),
    createdBy: '',
    description: '',
    id: '1',
    propertyId: '',
    scheduledMaintenanceDate: new Date(),
    tenantAvailability: '',
    unitAccess: false,
    updatedAt: new Date(),
    urgency: '',
  },
  {
    status: MaintenanceTicketStatus.Active,
    title: 'Electrical',
    unitId: 'Unit 101',
    closeReason: '',
    createdAt: new Date(),
    createdBy: '',
    description: '',
    id: '2',
    propertyId: '',
    scheduledMaintenanceDate: new Date(),
    tenantAvailability: '',
    unitAccess: false,
    updatedAt: new Date(),
    urgency: '',
  },
  {
    status: MaintenanceTicketStatus.Active,
    title: 'Electrical',
    unitId: 'Unit 101',
    closeReason: '',
    createdAt: new Date(),
    createdBy: '',
    description: '',
    id: '3',
    propertyId: '',
    tenantAvailability: '',
    unitAccess: false,
    updatedAt: new Date(),
    urgency: '',
  },
  {
    status: MaintenanceTicketStatus.Active,
    title: 'Electrical',
    unitId: 'Unit 101',
    closeReason: '',
    createdAt: new Date(),
    createdBy: '',
    description: '',
    id: '4',
    propertyId: '',
    tenantAvailability: '',
    unitAccess: false,
    updatedAt: new Date(),
    urgency: '',
  },
  {
    status: MaintenanceTicketStatus.Active,
    title: 'Electrical',
    unitId: 'Unit 101',
    closeReason: '',
    createdAt: new Date(),
    createdBy: '',
    description: '',
    id: '5',
    propertyId: '',
    tenantAvailability: '',
    unitAccess: false,
    updatedAt: new Date(),
    urgency: '',
  },
  {
    status: MaintenanceTicketStatus.Active,
    title: 'Electrical',
    unitId: 'Unit 101',
    closeReason: '',
    createdAt: new Date(),
    createdBy: '',
    description: '',
    id: '6',
    propertyId: '',
    tenantAvailability: '',
    unitAccess: false,
    updatedAt: new Date(),
    urgency: '',
  },
  {
    status: MaintenanceTicketStatus.Active,
    title: 'Electrical',
    unitId: 'Unit 101',
    closeReason: '',
    createdAt: new Date(),
    createdBy: '',
    description: '',
    id: '7',
    propertyId: '',
    tenantAvailability: '',
    unitAccess: false,
    updatedAt: new Date(),
    urgency: '',
  },
  {
    status: MaintenanceTicketStatus.Active,
    title: 'Electrical',
    unitId: 'Unit 101',
    closeReason: '',
    createdAt: new Date(),
    createdBy: '',
    description: '',
    id: '8',
    propertyId: '',
    tenantAvailability: '',
    unitAccess: false,
    updatedAt: new Date(),
    urgency: '',
  },
];
