import { User } from '../types';
import { useTranslation } from 'react-i18next';
import { notify } from 'plume-ui';
import DependencyContainer from '../../../DependencyContainer';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../mixPanelEvents';
import useWithConfirmation from '../../../hooks/useWithConfirmation';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { TrackEventParams } from '../../trackingAnalytics/types';
import {
  createUserModalStateAtom,
  selectedPartnerSelector,
  userInfoAtom,
} from '../../../state';
import { selectedUserAtom, usersAtom } from '../usersState';
import { ModalState, Permission } from '../../../types';

export type UserAction = {
  onClick: (user: User) => void;
  label: string;
  isVisible: (user: User) => boolean; // determines whether to show action or not
  isDisabled?: boolean; // determines whether to disable an action or not
  subtexts?: string[];
};

enum Actions {
  Delete,
}

const actionToMixpanelEventMap: Record<
  Actions,
  {
    success: MixPanelEvents;
    failure: MixPanelEvents;
    body: string;
  }
> = {
  [Actions.Delete]: {
    success: MixPanelEvents.DELETE_USER_SUCCESS,
    failure: MixPanelEvents.DELETE_USER_FAILURE,
    body: 'users.actions.delete.deleted',
  },
};

const useUserActions = (): ((user: User) => UserAction[]) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const userInfo = useRecoilValue(userInfoAtom);
  const withConfirmation = useWithConfirmation();
  const setUsers = useSetRecoilState(usersAtom);
  const setSelectedUser = useSetRecoilState(selectedUserAtom);
  const setCreateUserModalState = useSetRecoilState(createUserModalStateAtom);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const { usersService } = new DependencyContainer();

  const deleteAction = async (user: User) => {
    if (!selectedPartner) {
      return;
    }
    await usersService.deleteUser(user, selectedPartner.id);

    setUsers((currentUsers) => currentUsers.filter((t) => t.id !== user.id));
  };

  const executeAndNotify = async (
    executeFn: (user: User) => Promise<any>,
    user: User,
    action: Actions.Delete,
  ) => {
    try {
      await executeFn(user);

      trackEvent(({
        eventName: actionToMixpanelEventMap[action].success,
        additionalContent: {
          userId: user.id,
        },
      } as any) as TrackEventParams);

      notify({
        title: t('success'),
        body: t(actionToMixpanelEventMap[action].body),
        type: 'success',
      });
    } catch (error) {
      trackEvent(({
        eventName: actionToMixpanelEventMap[action].failure,
        additionalContent: {
          userId: user.id,
        },
      } as any) as TrackEventParams);
    }
  };

  const hasPermission = (permission: Permission) => {
    return userInfo?.permissions.includes(permission);
  };

  const reasonIsReadOnly = () => {
    return t('users.readOnlyIsNotAllowed');
  };

  const availableUserActions: UserAction[] = [
    {
      onClick: (user: User) => {
        setSelectedUser(user);
        setCreateUserModalState(ModalState.Open);
      },
      label: t('users.table.actionsDropdown.edit'),
      isVisible: () => true,
      isDisabled: !hasPermission(Permission.updateUser),
      subtexts: !hasPermission(Permission.updateUser)
        ? [reasonIsReadOnly()]
        : undefined,
    },
    {
      onClick: (user: User) =>
        withConfirmation({
          title: t('users.actions.delete.deleteConfirmation.title'),
          body: t('users.actions.delete.deleteConfirmation.body'),
          onConfirm: () => executeAndNotify(deleteAction, user, Actions.Delete),
        }),
      label: t('users.table.actionsDropdown.delete'),
      isVisible: () => true,
      isDisabled: !hasPermission(Permission.deleteUser),
      subtexts: !hasPermission(Permission.deleteUser)
        ? [reasonIsReadOnly()]
        : undefined,
    },
  ];

  return (user: User) =>
    availableUserActions.filter((action) => action.isVisible(user));
};

export default useUserActions;
