import { FieldSort } from 'plume-ui/dist/components/Table/Table';
import { atom, selector } from 'recoil';
import { DEFAULT_PAGE_SIZE } from '../../config';
import { UnitPaymentType } from './components/UnitModal/types';
import {
  BusinessUnit,
  GenericUnit,
  LoadingStatus,
  ResidentialUnit,
  SortableUnitFieldNames,
  UnitType,
} from './types';

export const residentialUnitsAtom = atom<ResidentialUnit[]>({
  key: 'residentialUnitsAtom',
  default: [],
});

export const businessUnitsAtom = atom<BusinessUnit[]>({
  key: 'businessUnitsAtom',
  default: [],
});

export const unitsLoadingStatusAtom = atom<LoadingStatus>({
  key: 'unitsLoadingStatusAtom',
  default: 'loading',
});

export const unitsSelectedTypeAtom = atom<UnitType>({
  key: 'unitsSelectedTypeAtom',
  default: UnitType.Residence,
});

export const unitsSelectedFilterAtom = atom<string>({
  key: 'unitsSelectedFilterAtom',
  default: '',
});

export const unitsFilterStatusAtom = atom<string>({
  key: 'unitsFilterStatusAtom',
  default: '',
});

export const unitsBusinessSelectedPageAtom = atom<number>({
  key: 'unitsBusinessSelectedPageAtom',
  default: 0,
});

export const unitsResidentialSelectedPageAtom = atom<number>({
  key: 'unitsResidentialSelectedPageAtom',
  default: 0,
});

export const allUnits = selector<GenericUnit[]>({
  key: 'allUnitsSelector',
  get: ({ get }) => {
    const businessUnits = get(businessUnitsAtom);
    const residentialUnits = get(residentialUnitsAtom);
    const genericResidentialUnits: GenericUnit[] = residentialUnits.map(
      (unit) => ({
        ...unit,
        tenant: unit.residentialTenant,
        type: UnitType.Residence,
      }),
    );
    const genericBusinessUnits: GenericUnit[] = businessUnits.map((unit) => ({
      ...unit,
      tenant: unit.businessTenant,
      type: UnitType.Business,
    }));
    return [
      ...JSON.parse(JSON.stringify(genericBusinessUnits)),
      ...JSON.parse(JSON.stringify(genericResidentialUnits)),
    ];
  },
});

export const selectedUnitToEditAtom = atom<GenericUnit | null>({
  key: 'selectedUnitToEditAtom',
  default: null,
});

export const unitsSortAtom = atom<FieldSort | null>({
  key: 'unitsSortAtom',
  default: null,
});

export const sortedResidentialUnits = selector<GenericUnit[]>({
  key: 'sortedResidentialUnits',
  get: ({ get }) => {
    const residentialUnits = get(residentialUnitsAtom);
    const genericUnits: GenericUnit[] = residentialUnits.map((unit) => ({
      ...unit,
      tenant: unit.residentialTenant,
      type: UnitType.Residence,
    }));
    const sort = get(unitsSortAtom);
    if (sort) {
      return sortUnits(genericUnits, sort);
    }
    return genericUnits;
  },
});

export const sortedBusinessUnits = selector<GenericUnit[]>({
  key: 'sortedBusinessUnits',
  get: ({ get }) => {
    const businessUnits = get(businessUnitsAtom);
    const genericUnits: GenericUnit[] = businessUnits.map((unit) => ({
      ...unit,
      tenant: unit.businessTenant,
      type: UnitType.Business,
    }));
    const sort = get(unitsSortAtom);
    if (sort) {
      return sortUnits(genericUnits, sort);
    }
    return genericUnits;
  },
});

const sortUnits = (units: GenericUnit[], sort: FieldSort): GenericUnit[] => {
  const fieldName = sort.fieldName as SortableUnitFieldNames;
  switch (fieldName) {
    case 'averageDownloadSpeed':
    case 'averageUploadSpeed': {
      return units.sort((a, b) => {
        if (!a[fieldName] || isNaN(a[fieldName]!)) a[fieldName] = 0;
        if (!a[fieldName] || isNaN(b[fieldName]!)) b[fieldName] = 0;
        return (a[fieldName]! - b[fieldName]!) * sort.direction;
      });
    }
    case 'retailPay': {
      return units.sort((a, b) => {
        let aEnumerated = 0;
        let bEnumerated = 0;
        aEnumerated = +a[fieldName];
        bEnumerated = +b[fieldName];
        if (!a[fieldName]) aEnumerated = -1;
        if (!a[fieldName]) bEnumerated = -1;
        return (aEnumerated + bEnumerated) * sort.direction;
      });
    }
    default: {
      return units.sort(
        (a, b) =>
          (a[fieldName] || '').localeCompare(b[fieldName] || '') *
          sort.direction,
      );
    }
  }
};

const allFilteredUnits = selector<GenericUnit[]>({
  key: 'filteredUnits',
  get: ({ get }) => {
    const businessUnits = get(sortedBusinessUnits);
    const residentialUnits = get(sortedResidentialUnits);
    const selectedType = get(unitsSelectedTypeAtom);
    const statusFilter = get(unitsFilterStatusAtom);
    const filter = get(unitsSelectedFilterAtom);

    let units =
      selectedType === UnitType.Business ? businessUnits : residentialUnits;

    if (statusFilter) {
      if (statusFilter === 'alerted') {
        units = units.filter(
          (unit) =>
            unit.isLocationOffline || unit.isNodeQoeAlert || unit.isNodeOffline,
        );
      } else if (statusFilter === UnitPaymentType.Retail) {
        units = units.filter((unit) => unit.retailPay);
      } else if (statusFilter === UnitPaymentType.Bulk) {
        units = units.filter((unit) => !unit.retailPay);
      } else {
        units = units.filter(
          (unit) =>
            unit.status?.toLocaleLowerCase() ===
            statusFilter.toLocaleLowerCase(),
        );
      }
    }

    if (filter) {
      units = units.filter((unit) =>
        unit.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()),
      );
    }

    return units;
  },
});

export const currentUnitsPage = selector<GenericUnit[]>({
  key: 'currentUnitsPage',
  get: ({ get }) => {
    const units = get(allFilteredUnits);
    const selectedType = get(unitsSelectedTypeAtom);
    const selectedPage =
      selectedType === UnitType.Business
        ? get(unitsBusinessSelectedPageAtom)
        : get(unitsResidentialSelectedPageAtom);

    return units.slice(
      selectedPage * DEFAULT_PAGE_SIZE,
      (selectedPage + 1) * DEFAULT_PAGE_SIZE,
    );
  },
});

export const getFilteredUnitCount = selector<number>({
  key: 'getFilteredUnitCount',
  get: ({ get }) => {
    return get(allFilteredUnits).length;
  },
});
