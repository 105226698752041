import { Field, Form, Formik, FormikValues } from 'formik';
import { Button, Modal, ModalBody, ModalFooter, notify } from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import React, { FunctionComponent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as yup from 'yup';
import DependencyContainer from '../../../../DependencyContainer';
import DropdownInput, {
  DropdownInputOption,
  DropdownInputStyles,
} from '../../../../components/DropdownInput/DropdownInput';
import FormikInputCheckbox from '../../../../components/FormikInputCheckbox/FormikInputCheckbox';
import InputField from '../../../../components/InputField/InputField';
import ModalHeaderWithProperty from '../../../../components/ModalHeaderWithProperty/ModalHeaderWithProperty';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';
import { MixPanelEvents } from '../../../../mixPanelEvents';
import {
  selectedMDUSelector,
  selectedPartnerSelector,
  userInfoAtom,
} from '../../../../state';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { prepareErrorMessageForInput } from '../../../../utils/prepareErrorMessageForInput';
import { useInventory } from '../../../inventory/hooks/useInventory';
import {
  businessTenantsAtom,
  residentialTenantsAtom,
  selectedTenantToRelocateAtom,
  tenantsSelectedTypeAtom,
  unitToMoveInTenant,
} from '../../../tenants/tenantsState';
import {
  BusinessTenant,
  ResidentialTenant,
  TenantTypes,
} from '../../../tenants/types';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { useUnits } from '../../../units/hooks/useUnits';
import { useUnitsUpdateActions } from '../../../units/hooks/useUnitsUpdateActions';
import { Unit, UnitStatus, UnitType } from '../../../units/types';
import {
  businessUnitsAtom,
  residentialUnitsAtom,
} from '../../../units/unitsState';
import { Permission } from '../../../../types';

type RelocateTenantModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
};

type FormField = {
  name: keyof RelocateTenantFormValues;
  labelId: string;
  wrapLabel?: string;
  type: 'unit_selection' | 'checkbox';
  options?: DropdownInputOption[];
};

const addStylesToCheckbox = (current: any) => ({
  ...current,
  root: `${current.root} RelocateTenantModal__checkbox`,
});

const addStylesToDropdownField = (current: DropdownInputStyles) => ({
  ...current,
  root: `${current.root} RelocateTenantModal__dropdown m-t-s`,
});

const { tenantService } = new DependencyContainer();

type RelocateTenantFormValues = {
  move_nodes_with_tenant: boolean;
  unit_number: string;
};

const tenantToUnitMap = {
  [TenantTypes.Business]: UnitType.Business,
  [TenantTypes.Residence]: UnitType.Residence,
};

const RelocateTenantModal: FunctionComponent<RelocateTenantModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userInfoAtom);
  const selectedTenantType = useRecoilValue(tenantsSelectedTypeAtom);
  const [
    selectedTenantToRelocate,
    setSelectedTenantToRelocate,
  ] = useRecoilState(selectedTenantToRelocateAtom);
  const { updateUnit } = useUnitsUpdateActions();
  const setResidentialTenants = useSetRecoilState(residentialTenantsAtom);
  const setBusinessTenants = useSetRecoilState(businessTenantsAtom);
  const residentialUnits = useRecoilValue(residentialUnitsAtom);
  const businessUnits = useRecoilValue(businessUnitsAtom);
  const selectedMdu = useRecoilValue(selectedMDUSelector);
  const trackEvent = useTrackEvent();
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const unitToMoveIn = useRecoilValue(unitToMoveInTenant);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const { runFetch: refetchUnits } = useUnits(false);
  const { runFetch: refetchNodes } = useInventory(false);

  const formInputFieldsDefinition: Array<FormField> = [
    {
      name: 'move_nodes_with_tenant',
      labelId: 'tenants.actions.relocate.form.move_nodes_with_tenant.label',
      type: 'checkbox',
    },
    {
      name: 'unit_number',
      labelId: 'tenants.actions.relocate.form.unit_number.label',
      wrapLabel: 'tenants.actions.relocate.form.unit_number.wrapLabel',
      type: 'unit_selection',
    },
  ];

  const getFormInitialValues = (): RelocateTenantFormValues => {
    return {
      move_nodes_with_tenant: false,
      unit_number: unitToMoveIn ? unitToMoveIn.id : '',
    };
  };

  const onClose = () => {
    setSelectedTenantToRelocate(null);
    onRequestClose();
  };

  const unitOptions = useMemo(() => {
    let filteredUnits: Unit[];
    if (selectedTenantType === TenantTypes.Residence) {
      filteredUnits = residentialUnits.filter(
        (unit) => unit.status === UnitStatus.Unassigned,
      );
    } else {
      filteredUnits = businessUnits.filter(
        (unit) => unit.status === UnitStatus.Unassigned,
      );
    }
    return filteredUnits.map((unit) => ({
      value: unit.id,
      label: unit.name,
    }));
  }, [selectedTenantType, residentialUnits, businessUnits]);

  const updateTenantList = (
    tenant: BusinessTenant | ResidentialTenant,
    type: TenantTypes,
  ) => {
    if (type === TenantTypes.Residence) {
      setResidentialTenants((currentTenants) =>
        currentTenants.map((t) =>
          t.id !== tenant.id ? t : (tenant as ResidentialTenant),
        ),
      );
    } else {
      setBusinessTenants((currentTenants) =>
        currentTenants.map((t) =>
          t.id !== tenant.id ? t : (tenant as BusinessTenant),
        ),
      );
    }
  };

  const onSubmit = async (values: FormikValues) => {
    if (
      !selectedMdu ||
      !selectedPartner ||
      !selectedTenantToRelocate ||
      submitInProgress
    ) {
      return;
    }
    setSubmitInProgress(true);
    const moveOutUnit = selectedTenantToRelocate!.unit?.id;
    try {
      const updatedTenant = await tenantService.relocateTenant(
        selectedMdu.id,
        selectedTenantToRelocate.id,
        values.unit_number,
        values.move_nodes_with_tenant,
        selectedTenantToRelocate.type,
        selectedPartner.id,
      );
      updateTenantList(updatedTenant, selectedTenantType);

      if (values.move_nodes_with_tenant) {
        refetchUnits();
        refetchNodes();
      } else {
        updateUnit(
          {
            id: values.unit_number,
            status: UnitStatus.Assigned,
          },
          unitToMoveIn
            ? unitToMoveIn.type
            : tenantToUnitMap[selectedTenantType],
        );

        updateUnit(
          {
            id: moveOutUnit,
            status: UnitStatus.Unassigned,
          },
          unitToMoveIn
            ? unitToMoveIn.type
            : tenantToUnitMap[selectedTenantType],
        );
      }

      notify({
        title: t('success'),
        body: t('tenants.actions.relocate.tenantRelocated'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.RELOCATE_TENANT_SUCCESS,
        additionalContent: {
          propertyId: selectedMdu!.id,
        },
      });
      setSubmitInProgress(false);
      onClose();
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.RELOCATE_TENANT_FAILURE,
        additionalContent: {
          propertyId: selectedMdu!.id,
        },
      });
      setSubmitInProgress(false);
    }
  };

  const getValidationSchema = () =>
    yup.object().shape({
      unit_number: yup
        .string()
        .min(3, t('formValidation.minLength', { length: 3 }))
        .max(128, t('formValidation.maxFields', { length: 128 }))
        .required(t('tenants.actions.relocate.form.unit_number.required')),
    });

  if (!selectedMdu) {
    return null;
  }

  const getUnitField = (field: any, values: any, errors: any) => {
    const props = {
      name: field.name,
      value: values[field.name],
      messages: prepareErrorMessageForInput(field.name, errors),
      label: t(field.labelId),
    };
    return (
      <Field
        {...props}
        component={field.type === 'unit_selection' ? DropdownInput : InputField}
        options={unitOptions}
        wrapLabel={t(field.wrapLabel)}
        defaultLabel={t(field.labelId)}
        classes={addStylesToDropdownField}
        openInPortal
        expandDirection="auto"
        noItemsWarning={t('tenants.actions.relocate.noUnitsWarning')}
      />
    );
  };

  const getInvitationCheckbox = (field: FormField) => {
    if (
      selectedTenantToRelocate?.type === TenantTypes.Residence &&
      !userInfo?.permissions.includes(
        Permission.transferResidentialTenantWithNodes,
      )
    )
      return;
    if (
      selectedTenantToRelocate?.type === TenantTypes.Business &&
      !userInfo?.permissions.includes(
        Permission.transferBusinessTenantWithNodes,
      )
    )
      return;
    return (
      <div className="RelocateTenantModal__checkbox">
        <Field
          component={FormikInputCheckbox}
          name={field.name}
          label={t(field.labelId)}
          classes={addStylesToCheckbox}
        />
      </div>
    );
  };

  return (
    <Modal
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={isOpen}
      onRequestClose={onClose}
      classes={(current: ModalStyles) => ({
        ...current,
        root: `${current.root} RelocateTenantModal`,
      })}
    >
      <ModalHeaderWithProperty
        propertyName={selectedMdu.name}
        title={t('tenants.actions.relocate.modalTitle')}
        additionalSubtitle={
          <div className="RelocateTenantModal__header-unit">
            {selectedTenantToRelocate?.name}
          </div>
        }
      />
      <Formik
        initialValues={getFormInitialValues()}
        validationSchema={getValidationSchema()}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({ values, errors, dirty, submitForm }) => (
          <Form>
            <>
              <ModalBody>
                <div className="RelocateTenantModal__content">
                  {formInputFieldsDefinition?.length > 0 && (
                    <div className="RelocateTenantModal__fields">
                      {formInputFieldsDefinition.map((field) => {
                        return (
                          <React.Fragment key={field.name}>
                            {field.type === 'unit_selection' &&
                              getUnitField(field, values, errors)}
                            {field.type === 'checkbox' &&
                              getInvitationCheckbox(field)}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  styleVariant="tertiary-grey"
                  onClick={onClose}
                >
                  <FormattedMessage id="cancel" />
                </Button>
                <SubmitButton
                  disabled={!dirty}
                  onSubmit={submitForm}
                  submitInProgress={submitInProgress}
                  label={t('saveChanges')}
                  inProgressLabel={t('savingChanges')}
                />
              </ModalFooter>
            </>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default memo(RelocateTenantModal);
