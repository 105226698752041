import _ from 'lodash';
import moment from 'moment';
import {
  Grid,
  GridItem,
  Icons,
  PendingContent,
  Spinner,
  Toggler,
  Tooltip,
} from 'plume-ui';
import { TogglerElementTypes } from 'plume-ui/dist/components/Toggler/Toggler';
import { FunctionComponent, memo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AuraGlow from '../../../components/AuraGlow/AuraGlow';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { selectedMDUSelector } from '../../../state';
import { useOverviewStats } from '../../overview/hooks/useOverviewStats';
import ChannelDistributionChart from '../components/ChannelDistributionChart';
import ChannelInterferenceChart from '../components/ChannelInterferenceChart';
import HappinessIndexChart from '../components/HappinessIndexChart';
import TxAirtimeUtilizationChart from '../components/TxAirtimeUtilizationChart';
import useHarmonyToggleElements from '../hooks/useHarmonyToggleElements';
import { harmonyNewestDataTimestampSelector } from '../state';
import {
  FrequencyBand,
  ReferenceLine,
  TimeRange,
  isFrequencyBand,
  isTimeRange,
} from '../types';
import {
  harmonyChartDateFormat,
  isWithinNumberOfDays,
  timeRangeToNumber,
} from '../util';

const HarmonyContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const { loading } = useOverviewStats();
  const {
    bandToggleElements,
    timeRangeToggleElements,
  } = useHarmonyToggleElements();
  const selectedProperty = useRecoilValue(selectedMDUSelector);
  const harmonyNewestDataTimestamp = useRecoilValue(
    harmonyNewestDataTimestampSelector,
  );
  const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRange>(
    TimeRange.Last7days,
  );
  const [selectedBand, setSelectedBand] = useState<FrequencyBand>(
    FrequencyBand.TwoPointFour,
  );

  const onTimeRangeToggle = (value: TogglerElementTypes) => {
    if (isTimeRange(value.key)) {
      setSelectedTimeRange(value.key);
    }
  };

  const onBandToggle = (value: TogglerElementTypes) => {
    if (isFrequencyBand(value.label)) {
      setSelectedBand(value.label);
    }
  };

  const renderHarmonyStatusLabel = () => {
    if (!selectedProperty) return;
    const format = 'MMM DD';
    const timestamp =
      selectedProperty.mdu_optimizations_enabled_last_updated_at;
    const has_timestamp = _.isString(timestamp);
    const harmony_on = selectedProperty.mdu_optimizations_enabled;
    if (has_timestamp && harmony_on) {
      return t('harmony.statusLabel.timestamp.on', {
        timestamp: moment(timestamp).format(format),
      });
    }
    if (has_timestamp && !harmony_on) {
      return t('harmony.statusLabel.timestamp.off', {
        timestamp: moment(timestamp).format(format),
      });
    }
    if (!has_timestamp && harmony_on) {
      return t('harmony.statusLabel.on');
    }
    if (!has_timestamp && !harmony_on) {
      return t('harmony.statusLabel.off');
    }
  };

  const renderHarmonyDataLabel = () => {
    if (!selectedProperty || !harmonyNewestDataTimestamp) return;
    const format = 'MMM DD';
    const timestamp = moment(harmonyNewestDataTimestamp).format(format);
    return <div>{t('harmony.statusLabel.data', { timestamp })}</div>;
  };

  const getReferenceLine = (): ReferenceLine | undefined => {
    if (!selectedProperty) return;
    const timestamp =
      selectedProperty.mdu_optimizations_enabled_last_updated_at;
    if (timestamp === null) return;
    if (isWithinNumberOfDays(timestamp, timeRangeToNumber(selectedTimeRange))) {
      const harmony_on = selectedProperty.mdu_optimizations_enabled;
      const date = moment(timestamp).format(harmonyChartDateFormat);
      const tooltipHeading = harmony_on
        ? 'harmony.chart.referenceLine.on'
        : 'harmony.chart.referenceLine.off';
      const translated = t(tooltipHeading);
      return {
        x: date,
        position: 'end',
        tooltipHeading: translated,
        tooltipLabel: date,
      };
    }
  };

  const checkIfHoveringChart = () => {
    const hoveredElements = Array.from(document.querySelectorAll(':hover'));
    const rechartsSurface = hoveredElements.find((element) =>
      element.classList.contains('recharts-surface'),
    );
    if (!rechartsSurface) {
      const referenceLines = Array.from(
        document.querySelectorAll('.recharts-reference-line-line'),
      );
      referenceLines.forEach((referenceLine) => {
        referenceLine.classList.remove('BarChart__referenceLine--active');
        referenceLine.classList.remove('AreaChart__referenceLine--active');
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('harmony.title')}</title>
      </Helmet>
      <div
        className="HarmonyContainer p-xl"
        onMouseMove={() => checkIfHoveringChart()}
      >
        <AuraGlow header footer />
        <PageHeading
          title="harmony.title"
          tooltip="harmony.tooltip"
          additionalTitle={selectedProperty?.name}
        >
          {renderHarmonyStatusLabel() && (
            <div className="HarmonyContainer__statusLabel">
              <Icons.InfoIcon
                width={16}
                style={{ transform: 'rotate(180deg)' }}
              />
              <div>{renderHarmonyStatusLabel()}</div>
              {renderHarmonyDataLabel()}
            </div>
          )}
        </PageHeading>
        <div className="HarmonyContainer__filters">
          {t('harmony.filters')}
          <div className="HarmonyContainer__filters__togglers">
            <Toggler
              classes={(current) => ({
                ...current,
                root: `${current.root} HarmonyContainer__filters__toggler`,
              })}
              onToggle={onTimeRangeToggle}
              toggleElements={timeRangeToggleElements}
              value={selectedTimeRange}
            />
            <Tooltip label={t('harmony.filtersTooltip')} position="right">
              <Icons.InfoIcon width={16} height={16} />
            </Tooltip>
            <Toggler
              classes={(current) => ({
                ...current,
                root: `${current.root} HarmonyContainer__filters__toggler`,
              })}
              onToggle={onBandToggle}
              toggleElements={bandToggleElements}
              value={selectedBand}
            />
          </div>
        </div>
        <div className="HarmonyContainer__cards">
          <Grid>
            <GridItem colSpan="6" rowSpan="2">
              <PendingContent hideContent loader={Spinner} loading={loading}>
                <HappinessIndexChart
                  frequencyBand={selectedBand}
                  referenceLine={getReferenceLine()}
                  timeRange={selectedTimeRange}
                />
              </PendingContent>
            </GridItem>
            <GridItem colSpan="6" rowSpan="2">
              <PendingContent hideContent loader={Spinner} loading={loading}>
                <ChannelInterferenceChart
                  frequencyBand={selectedBand}
                  referenceLine={getReferenceLine()}
                  timeRange={selectedTimeRange}
                />
              </PendingContent>
            </GridItem>
            <GridItem colSpan="6" rowSpan="2">
              <PendingContent hideContent loader={Spinner} loading={loading}>
                <ChannelDistributionChart
                  frequencyBand={selectedBand}
                  referenceLine={getReferenceLine()}
                  timeRange={selectedTimeRange}
                />
              </PendingContent>
            </GridItem>

            <GridItem colSpan="6" rowSpan="2">
              <PendingContent hideContent loader={Spinner} loading={loading}>
                <TxAirtimeUtilizationChart
                  frequencyBand={selectedBand}
                  referenceLine={getReferenceLine()}
                  timeRange={selectedTimeRange}
                />
              </PendingContent>
            </GridItem>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default memo(HarmonyContainer);
