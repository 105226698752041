import { Field, Form, Formik } from 'formik';
import { Button, Modal, ModalBody, ModalFooter, notify } from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import React, { FunctionComponent, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as yup from 'yup';
import InputField from '../../../components/InputField/InputField';
import ModalHeaderWithProperty from '../../../components/ModalHeaderWithProperty/ModalHeaderWithProperty';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import DependencyContainer from '../../../DependencyContainer';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { selectedMDUSelector, selectedPartnerSelector } from '../../../state';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import { prepareErrorMessageForInput } from '../../../utils/prepareErrorMessageForInput';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { inventoryNodesAtom } from '../inventoryState';

type CreateNodeModalProps = {
  isOpen: boolean;
  onRequestClose: (success: boolean) => void;
};

const addStylesToInputField = (current: any) => ({
  ...current,
  root: `${current.root} CreateNodeModal__input`,
});

type CreateNodeFormValues = {
  node_id: string;
};

const { inventoryService } = new DependencyContainer();

const CreateNodeModal: FunctionComponent<CreateNodeModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const selectedMdu = useRecoilValue(selectedMDUSelector);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const setInventoryNodes = useSetRecoilState(inventoryNodesAtom);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const trackEvent = useTrackEvent();

  const onClose = (success: boolean) => {
    onRequestClose(success);
  };

  const getFormInitialValues = (): CreateNodeFormValues => {
    return {
      node_id: '',
    };
  };

  const formInputFieldsDefinition: Array<{
    name: keyof CreateNodeFormValues;
    labelId: string;
    type: 'input';
  }> = [
    {
      name: 'node_id',
      labelId: 'inventory.actions.add.form.nodeId.label',
      type: 'input',
    },
  ];

  const getValidationSchema = () =>
    yup.object().shape({
      node_id: yup
        .string()
        .min(3, t('formValidation.minLength', { length: 3 }))
        .trim()
        .max(128, t('formValidation.maxFields', { length: 128 }))
        .required(t('inventory.actions.add.form.nodeId.required')),
    });

  const onSubmit = async (values: CreateNodeFormValues) => {
    if (!selectedMdu || !selectedPartner || submitInProgress) {
      return;
    }

    setSubmitInProgress(true);
    const newNode = {
      id: values.node_id?.trim(),
    };
    try {
      const createdNode = await inventoryService.addNode(
        selectedMdu.id,
        newNode,
        selectedPartner.id,
      );
      setInventoryNodes((nodes) => [...nodes, createdNode]);

      notify({
        title: t('success'),
        body: t('inventory.actions.add.nodeAdded'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.ADD_INVENTORY_NODE_SUCCESS,
        additionalContent: {
          nodeId: values.node_id?.trim(),
          propertyId: selectedMdu!.id,
        },
      });
      setSubmitInProgress(false);
      onClose(true);
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.ADD_INVENTORY_NODE_FAILURE,
        additionalContent: {
          nodeId: values.node_id?.trim(),
          propertyId: selectedMdu!.id,
        },
      });
      setSubmitInProgress(false);
    }
  };
  if (!selectedMdu || !isOpen) {
    return null;
  }

  return (
    <Formik
      initialValues={getFormInitialValues()}
      validationSchema={getValidationSchema()}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values, errors, dirty, submitForm }) => (
        <Form>
          <Modal
            appElement={document.getElementById('root') as HTMLElement}
            isOpen={isOpen}
            onRequestClose={() => onClose(false)}
            classes={(current: ModalStyles) => ({
              ...current,
              root: `${current.root} CreateNodeModal`,
            })}
          >
            <ModalHeaderWithProperty
              propertyName={selectedMdu.name}
              title={t('inventory.actions.add.modalTitle')}
            />

            <ModalBody>
              <div className="CreateNodeModal__content">
                {formInputFieldsDefinition?.length > 0 && (
                  <div className="CreateNodeModal__fields">
                    {formInputFieldsDefinition.map((field) => {
                      return (
                        <React.Fragment key={field.name}>
                          {field.type === 'input' && (
                            <Field
                              key={field.name}
                              name={field.name}
                              component={InputField}
                              value={values[field.name]}
                              messages={prepareErrorMessageForInput(
                                field.name,
                                errors,
                              )}
                              label={t(field.labelId)}
                              classes={addStylesToInputField}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                styleVariant="tertiary-grey"
                onClick={() => onClose(false)}
              >
                <FormattedMessage id="cancel" />
              </Button>
              <SubmitButton
                disabled={!dirty}
                onSubmit={submitForm}
                submitInProgress={submitInProgress}
              />
            </ModalFooter>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default memo(CreateNodeModal);
