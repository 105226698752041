import { Dropdown, DropdownItem, IconButton, Icons } from 'plume-ui';
import React, { memo } from 'react';

export type TableAction<T> = {
  onClick: (item: T) => void;
  label: string;
  isVisible: (item: T) => boolean;
  isDisabled?: boolean;
  subtexts?: string[];
};

type TableFilterRowProps = {
  actions: TableAction<any>[];
  item: any;
};

const TableActionButton = ({ actions, item }: TableFilterRowProps) => {
  return (
    <Dropdown
      listPosition="right"
      closeOnItemClick
      openInPortal
      expandDirection="auto"
      classes={(current) => ({
        ...current,
        root: `${current.root} TableActionButton__dropdown`,
        list: `${current.list} TableActionButton__list`,
      })}
      button={
        <IconButton>
          <Icons.DotsVerticalIcon />
        </IconButton>
      }
    >
      {actions.map((action) => (
        <DropdownItem
          key={action.label}
          onClick={() => setTimeout(() => action.onClick(item))}
          disabled={action.isDisabled}
          subtexts={action.subtexts}
        >
          {action.label}
        </DropdownItem>
      ))}
    </Dropdown>
  );
};

export default memo(TableActionButton);
