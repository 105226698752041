import { StateMeta } from '../units/types';

export enum TenantStatus {
  Active = 'active',
  Assigned = 'assigned',
  Inactive = 'inactive',
  Suspended = 'suspended',
  Error = 'error',
}

export enum TenantTypes {
  Business = 'Business',
  Residence = 'Residence',
}

export type Tenant = BaseTenant & {
  unit?: TenantsUnitReference | null;
  type: TenantTypes;
};

export type TenantsUnitReference = {
  accountId: string | null;
  id: string;
  name: string;
  retailPay: boolean;
};

export type BaseTenant = {
  accountId: string | null;
  customerId: string | null;
  email: string;
  id: string;
  locationId: string | null;
  name: string;
  propertyId: string;
  stateMeta: StateMeta;
  status: TenantStatus;
  welcomeEmailSent: boolean;
};

export type BusinessTenant = BaseTenant & {
  businessUnit?: TenantsUnitReference | null;
};

export type ResidentialTenant = BaseTenant & {
  residentialUnit?: TenantsUnitReference | null;
};

export type StatusTenantCount = {
  [x in TenantStatus]: number;
};

export type NewTenant = {
  name: string;
  email: string;
  unitId: string;
  type: TenantTypes;
  accountId?: string;
  status: TenantStatus;
};

export type NewResidentialTenantDto = {
  name: string;
  email: string;
  status: TenantStatus;
  accountId?: string;
  residentialUnit: TenantsUnitReference | null;
};

export type NewBusinessTenantDto = {
  name: string;
  email: string;
  status: TenantStatus;
  accountId?: string;
  businessUnit: TenantsUnitReference | null;
};

export type UpdatedTenant = UpdateTenantDto;

export type UpdateTenantDto = {
  id?: string;
  name?: string;
  email?: string;
  accountId?: string;
  businessUnit?: TenantsUnitReference;
  residenceUnit?: TenantsUnitReference;
};

export type SortableTenantFieldNames =
  | 'status'
  | 'accountId'
  | 'unitName'
  | 'name';
