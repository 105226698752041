import { FieldSort } from 'plume-ui/dist/components/Table/Table';
import { atom, selector } from 'recoil';
import { DEFAULT_PAGE_SIZE } from '../../config';
import { GenericUnit, LoadingStatus } from '../units/types';
import { mapToTenant } from './helpers';
import {
  BusinessTenant,
  ResidentialTenant,
  SortableTenantFieldNames,
  StatusTenantCount,
  Tenant,
  TenantStatus,
  TenantTypes,
} from './types';

export const businessTenantsAtom = atom<BusinessTenant[]>({
  key: 'businessTenantsAtom',
  default: [],
});

export const residentialTenantsAtom = atom<ResidentialTenant[]>({
  key: 'residentialTenantsAtom',
  default: [],
});

export const tenantsSelectedTypeAtom = atom<TenantTypes>({
  key: 'tenantsSelectedTypeAtom',
  default: TenantTypes.Residence,
});

export const tenantsSelectedFilterAtom = atom<string>({
  key: 'tenantsSelectedFilterAtom',
  default: '',
});

export const tenantsSelectedStatusAtom = atom<TenantStatus | null>({
  key: 'tenantsSelectedStatusAtom',
  default: TenantStatus.Active,
});

export const residentialTenantsSelectedPageAtom = atom<number>({
  key: 'residentialTenantsSelectedPageAtom',
  default: 0,
});

export const tenantsLoadingStatusAtom = atom<LoadingStatus>({
  key: 'tenantsLoadingStatusAtom',
  default: 'loading',
});

export const businessTenantsSelectedPageAtom = atom<number>({
  key: 'businessTenantsSelectedPageAtom',
  default: 0,
});

export const unitToMoveInTenant = atom<GenericUnit | null>({
  key: 'unitToMoveInTenant',
  default: null,
});

export const selectedTenantToEditAtom = atom<Tenant | null>({
  key: 'selectedTenantAtom',
  default: null,
});

export const selectedTenantToRelocateAtom = atom<Tenant | null>({
  key: 'selectedTenantToRelocateAtom',
  default: null,
});

export const tenantsSortAtom = atom<FieldSort | null>({
  key: 'tenantsSortAtom',
  default: null,
});

export const allTenants = selector<Tenant[]>({
  key: 'allTenantsSelector',
  get: ({ get }) => {
    const businessTenants = get(businessTenantsAtom);
    const residentialTenants = get(residentialTenantsAtom);
    return [
      ...residentialTenants.map((t) => mapToTenant(t, TenantTypes.Residence)),
      ...businessTenants.map((t) => mapToTenant(t, TenantTypes.Business)),
    ];
  },
});

export const sortedResidentialTenants = selector<Tenant[]>({
  key: 'sortedResidentialTenants',
  get: ({ get }) => {
    const residentialTenants = get(residentialTenantsAtom);
    const tenants: Tenant[] = residentialTenants.map((t) =>
      mapToTenant(t, TenantTypes.Residence),
    );
    const sort = get(tenantsSortAtom);
    if (sort) {
      return sortTenants(tenants, sort);
    }
    return tenants;
  },
});

export const sortedBusinessTenants = selector<Tenant[]>({
  key: 'sortedBusinessTenants',
  get: ({ get }) => {
    const businessTenants = get(businessTenantsAtom);
    const tenants: Tenant[] = businessTenants.map((t) =>
      mapToTenant(t, TenantTypes.Business),
    );
    const sort = get(tenantsSortAtom);
    if (sort) {
      return sortTenants(tenants, sort);
    }
    return tenants;
  },
});

const sortTenants = (tenants: Tenant[], sort: FieldSort): Tenant[] => {
  const fieldName = sort.fieldName as SortableTenantFieldNames;
  return tenants.sort((a, b) => {
    if (fieldName === 'unitName')
      return (
        (a.unit?.name || '').localeCompare(b.unit?.name || '') * sort.direction
      );
    return (
      (a[fieldName] || '').localeCompare(b[fieldName] || '') * sort.direction
    );
  });
};

const allFilteredTenants = selector<Tenant[]>({
  key: 'allFilteredTenants',
  get: ({ get }) => {
    const businessTenants = get(sortedBusinessTenants);
    const residentialTenants = get(sortedResidentialTenants);
    const selectedType = get(tenantsSelectedTypeAtom);
    const filter = get(tenantsSelectedFilterAtom);

    let tenants =
      selectedType === TenantTypes.Business
        ? businessTenants
        : residentialTenants;

    if (filter) {
      tenants = tenants.filter((tenant) =>
        tenant.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()),
      );
    }
    return tenants;
  },
});

const statusFilteredTenants = selector<Tenant[]>({
  key: 'statusFilteredTenants',
  get: ({ get }) => {
    const allTenants = get(allFilteredTenants);
    const status = get(tenantsSelectedStatusAtom);

    if (status) {
      return allTenants.filter((tenant) => {
        if (status === TenantStatus.Error) {
          return tenant.status === null;
        } else {
          return tenant.status && tenant.status.toLocaleLowerCase() === status;
        }
      });
    }

    return allTenants;
  },
});

export const currentTenantsPage = selector<Tenant[]>({
  key: 'currentTenantsPage',
  get: ({ get }) => {
    const tenants = get(statusFilteredTenants);
    const selectedType = get(tenantsSelectedTypeAtom);
    const selectedPage =
      selectedType === TenantTypes.Business
        ? get(businessTenantsSelectedPageAtom)
        : get(residentialTenantsSelectedPageAtom);

    return tenants.slice(
      selectedPage * DEFAULT_PAGE_SIZE,
      (selectedPage + 1) * DEFAULT_PAGE_SIZE,
    );
  },
});

export const getFilteredTenantCount = selector<number>({
  key: 'getFilteredTenantCount',
  get: ({ get }) => {
    return get(allFilteredTenants).length;
  },
});

export const getStatusTenantCount = selector<StatusTenantCount>({
  key: 'getStatusTenantCount',
  get: ({ get }) => {
    const allTenants = get(allFilteredTenants);
    let active = 0;
    let assigned = 0;
    let inactive = 0;
    let suspended = 0;
    let error = 0;

    allTenants.forEach((tenant) => {
      switch (tenant.status) {
        case TenantStatus.Active:
          active = active + 1;
          break;
        case TenantStatus.Assigned:
          assigned = assigned + 1;
          break;
        case TenantStatus.Inactive:
          inactive = inactive + 1;
          break;
        case TenantStatus.Suspended:
          suspended = suspended + 1;
          break;
        default:
          error = error + 1;
          break;
      }
    });

    return {
      active,
      assigned,
      inactive,
      suspended,
      error,
    };
  },
});
