import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { conditionallyTruncate } from '../../../components/StickyActionTable/util';
import EmptyTableCell from '../../../utils/components/EmptyTableCell';
import {
  GenericUnit,
  ResidentialUnit,
  UnitConnectionStatus,
  UnitDetails,
  UnitIotPropagationStatus,
  UnitNodeConnectionStates,
  UnitStatus,
} from '../types';

const unitStatusTranslationMap: Record<UnitStatus, string> = {
  [UnitStatus.Assigned]: 'units.status.assigned',
  [UnitStatus.Unassigned]: 'units.status.unassigned',
  [UnitStatus.Unavailable]: 'units.status.unavailable',
  [UnitStatus.Inactive]: 'units.status.inactive',
};

const unitConnectionStatusTranslationMap: Record<
  UnitConnectionStatus,
  string
> = {
  [UnitConnectionStatus.LocationOffline]:
    'units.connectionStatus.locationOffline',
  [UnitConnectionStatus.NodeOffline]: 'units.connectionStatus.nodeOffline',
  [UnitConnectionStatus.NodeQoeAlert]: 'units.connectionStatus.nodeQoeAlert',
  [UnitConnectionStatus.NoNodes]: 'units.connectionStatus.noNodes',
  [UnitConnectionStatus.Online]: 'units.connectionStatus.online',
  [UnitConnectionStatus.Inactive]: 'units.connectionStatus.inactive',
};

const unitConnectionStatusColorCodeMap: Record<UnitConnectionStatus, string> = {
  [UnitConnectionStatus.LocationOffline]: 'error',
  [UnitConnectionStatus.NodeOffline]: 'warning',
  [UnitConnectionStatus.NodeQoeAlert]: 'warning',
  [UnitConnectionStatus.NoNodes]: 'unknown',
  [UnitConnectionStatus.Online]: 'ok',
  [UnitConnectionStatus.Inactive]: 'unknown',
};

const unitIotStatusColorCodeMap: Record<UnitIotPropagationStatus, string> = {
  [UnitIotPropagationStatus.Failed]: 'error',
  [UnitIotPropagationStatus.InProgress]: 'warning',
  [UnitIotPropagationStatus.Completed]: 'ok',
  [UnitIotPropagationStatus.Disabled]: 'unknown',
};

export const retrieveConnectionStatus = (
  unit: GenericUnit,
  details?: UnitDetails | null,
): UnitConnectionStatus => {
  if (unit.status === UnitStatus.Inactive) {
    return UnitConnectionStatus.Inactive;
  }
  if (details === null) {
    return UnitConnectionStatus.NoNodes;
  }
  if (details === undefined) {
    if (unit.isLocationOffline) {
      return UnitConnectionStatus.LocationOffline;
    }
    if (unit.isNodeOffline) {
      return UnitConnectionStatus.NodeOffline;
    }
    if (unit.isNodeQoeAlert) {
      return UnitConnectionStatus.NodeQoeAlert;
    }
    return UnitConnectionStatus.Online;
  }
  const countOfflineNodes = details.nodes.filter(
    (n) =>
      !n.connectionState ||
      n.connectionState === UnitNodeConnectionStates.Offline ||
      n.connectionState === UnitNodeConnectionStates.Unavailable,
  ).length;
  const countOnlineNodes = details.nodes.filter(
    (n) => n.connectionState === UnitNodeConnectionStates.Online,
  ).length;
  const countTotalNodes = details.nodes.length;
  let connectionStatus: UnitConnectionStatus;

  if (countOfflineNodes === countTotalNodes) {
    connectionStatus = UnitConnectionStatus.LocationOffline;
  } else if (countOnlineNodes < countTotalNodes) {
    connectionStatus = UnitConnectionStatus.NodeOffline;
  } else {
    connectionStatus = UnitConnectionStatus.Online;
  }
  const hasAtLeastOneNodeWithAlerts = details.nodes.find((n) =>
    Boolean(n.alerts?.length),
  );
  if (hasAtLeastOneNodeWithAlerts !== undefined) {
    connectionStatus = UnitConnectionStatus.NodeQoeAlert;
  }
  return connectionStatus;
};

const useUnitMappings = () => {
  const { t } = useTranslation();

  const renderUnitName = (name: string, realized: boolean) => {
    const classNames = cx({
      unrealized: !realized,
    });
    return (
      <span className={classNames}>{conditionallyTruncate(name, 50)}</span>
    );
  };

  const renderUnitStatus = (unitStatus: UnitStatus | null) => {
    if (!unitStatus) return <EmptyTableCell />;
    const translationRef = unitStatusTranslationMap[unitStatus];
    const classNames = cx({
      destressed: unitStatus !== UnitStatus.Assigned,
    });
    return <span className={classNames}>{t(translationRef)}</span>;
  };

  const renderUnitPaymentType = (retailPay: boolean) => {
    return (
      <span>
        {retailPay
          ? t('units.paymentType.retail')
          : t('units.paymentType.bulk')}
      </span>
    );
  };

  const renderConnectionStatus = (
    unit: GenericUnit,
    details?: UnitDetails | null,
  ) => {
    const connectionStatus = retrieveConnectionStatus(unit, details);
    const translationRef = unitConnectionStatusTranslationMap[connectionStatus];
    return {
      connectionStatus,
      translationRef,
    };
  };

  const getConnectionStatusColorCode = (
    unit: GenericUnit,
    details?: UnitDetails | null,
  ) => {
    const connectionStatus = retrieveConnectionStatus(unit, details);
    const colorCode = unitConnectionStatusColorCodeMap[connectionStatus];
    return colorCode;
  };

  const getIotStatusColorCode = (unit: ResidentialUnit) => {
    if (unit.iotConfiguration?.iot_network_manually_disabled) return 'unknown';
    const connectionStatus = unit.iotConfiguration?.propagationStatus;
    if (!connectionStatus) return 'unknown';
    const colorCode = unitIotStatusColorCodeMap[connectionStatus];
    return colorCode;
  };

  return {
    renderUnitName,
    renderUnitStatus,
    renderUnitPaymentType,
    renderConnectionStatus,
    getConnectionStatusColorCode,
    getIotStatusColorCode,
  };
};
export default useUnitMappings;
