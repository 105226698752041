import { atom, selectorFamily } from 'recoil';
import moment from 'moment';
import {
  LoadingStatus,
  OverviewStats,
  SpeedTest,
  SpeedTestChartFilters,
} from './types';

export const overviewStatsLoadingStatusAtom = atom<LoadingStatus>({
  key: 'speedTestDataAtom',
  default: 'loading',
});

export const overviewStatsAtom = atom<OverviewStats | null>({
  key: 'overviewStatsAtom',
  default: null,
});

const isWithinNumberOfDays = (dateTime: string, numDays: number) => {
  return moment(dateTime).isSameOrAfter(moment().subtract(numDays, 'days'));
};

export const selectedSpeedTestDataSelector = selectorFamily<
  SpeedTest[],
  number
>({
  key: 'selectedSpeedTestDataSelector',
  get: (numDays: number) => ({ get }) => {
    const overviewStats = get(overviewStatsAtom);
    const format = 'MMM DD';
    return (
      overviewStats?.speedTests
        .filter((st: SpeedTest) => isWithinNumberOfDays(st.dt, numDays))
        .map((day: SpeedTest) => ({
          xAxisLabel: moment(day.dt).format(format),
          dt: day.dt,
          avg_download_speed: parseFloat(day.avg_download_speed.toFixed(1)),
          max_download_speed: parseFloat(day.max_download_speed.toFixed(1)),
          min_download_speed: parseFloat(day.min_download_speed.toFixed(1)),
        })) || []
    );
  },
});

export const selectedSpeedTestTimeRangeAtom = atom<SpeedTestChartFilters>({
  key: 'selectedSpeedTestTimeRangeAtom',
  default: SpeedTestChartFilters.Last7Days,
});
