import { BandTxAirtimeUtilization, FrequencyBand } from './types';
import { BarChartDataItem } from 'plume-ui/dist/utils/charts/chartTypes';
import { overviewStatsAtom } from '../overview/overviewState';
import { selector, selectorFamily } from 'recoil';
import moment from 'moment';
import {
  filterStatsByDate,
  getValuesByChannel,
  harmonyChartDateFormat,
  padHarmonyAreaChartData,
  padHarmonyBarChartData,
} from './util';

const txAirtimeZeroValues = {
  medianTxAirtimeUtilization: undefined,
  p25TxAirtimeUtilization: undefined,
  p75TxAirtimeUtilization: undefined,
};

export const harmonyNewestDataTimestampSelector = selector<string | undefined>({
  key: 'harmonyNewestDataTimestamp',
  get: ({ get }) => {
    const overviewStats = get(overviewStatsAtom);
    const happinessIndex = [
      ...(overviewStats?.harmonyHappinessIndex?.['2.4G'] || []),
      ...(overviewStats?.harmonyHappinessIndex?.['5G'] || []),
    ];
    const channelInterference = [
      ...(overviewStats?.harmonyChannelInterference?.['2.4G'] || []),
      ...(overviewStats?.harmonyChannelInterference?.['5G'] || []),
    ];
    const channelDistribution = [
      ...(overviewStats?.harmonyChannelDistribution?.['2.4G'] || []),
      ...(overviewStats?.harmonyChannelDistribution?.['5G'] || []),
    ];
    const txAirtimeUtilization = [
      ...(overviewStats?.harmonyTxAirtimeUtilization?.['2.4G'] || []),
      ...(overviewStats?.harmonyTxAirtimeUtilization?.['5G'] || []),
    ];
    if (happinessIndex.length === 0) return;
    if (channelInterference.length === 0) return;
    if (channelDistribution.length === 0) return;
    if (txAirtimeUtilization.length === 0) return;
    const timestamps = [
      happinessIndex.at(-1)?.dt,
      channelInterference.at(-1)?.dt,
      channelDistribution.at(-1)?.dt,
      txAirtimeUtilization.at(-1)?.dt,
    ];
    const newestTimestamp = timestamps.sort().at(-1);
    if (!newestTimestamp) return;
    return newestTimestamp;
  },
});

const harmonyDataOffset = selector<number>({
  key: 'harmonyDataOffset',
  get: ({ get }) => {
    const newestTimestamp = get(harmonyNewestDataTimestampSelector);
    if (!newestTimestamp) return 0;
    const delta = moment.duration(moment().diff(moment(newestTimestamp)));
    return Math.min(Math.floor(delta.asDays()), 2);
  },
});

export const happinessIndexSelector = selectorFamily<
  BarChartDataItem[],
  {
    frequencyBand: FrequencyBand;
    timeRange: number;
  }
>({
  key: 'happinessIndex',
  get: ({ frequencyBand, timeRange }) => ({ get }) => {
    const overviewStats = get(overviewStatsAtom);
    const dataOffset = get(harmonyDataOffset);
    if (
      !overviewStats?.harmonyHappinessIndex?.[frequencyBand] ||
      overviewStats?.harmonyHappinessIndex[frequencyBand].length === 0
    ) {
      return [];
    }
    const stats = overviewStats.harmonyHappinessIndex[frequencyBand];
    const filteredStats = filterStatsByDate(stats, timeRange, dataOffset);
    if (filteredStats.length === 0) return [];
    const transformedStats = filteredStats.map((happinessIndex) => {
      const totalCount =
        happinessIndex.countHighHappiness +
        happinessIndex.countMediumHappiness +
        happinessIndex.countLowHappiness;
      return {
        name: moment(happinessIndex.dt).format(harmonyChartDateFormat),
        values: [
          happinessIndex.countHighHappiness / totalCount,
          happinessIndex.countMediumHappiness / totalCount,
          happinessIndex.countLowHappiness / totalCount,
        ],
      };
    });
    const paddedStats = padHarmonyBarChartData(
      transformedStats,
      timeRange,
      Array(3).fill(0),
      dataOffset,
    );
    return paddedStats;
  },
});

export const channelInterferenceSelector = selectorFamily<
  BarChartDataItem[],
  {
    frequencyBand: FrequencyBand;
    timeRange: number;
  }
>({
  key: 'channelInterference',
  get: ({ frequencyBand, timeRange }) => ({ get }) => {
    const overviewStats = get(overviewStatsAtom);
    const dataOffset = get(harmonyDataOffset);
    if (
      !overviewStats?.harmonyChannelInterference?.[frequencyBand] ||
      overviewStats?.harmonyChannelInterference[frequencyBand].length === 0
    ) {
      return [];
    }
    const stats = overviewStats.harmonyChannelInterference[frequencyBand];
    const filteredStats = filterStatsByDate(stats, timeRange, dataOffset);
    if (filteredStats.length === 0) return [];
    const transformedStats = filteredStats.map((channelInterference) => {
      const totalCount =
        channelInterference.countLowInterference +
        channelInterference.countMediumInterference +
        channelInterference.countHighInterference;
      return {
        name: moment(channelInterference.dt).format(harmonyChartDateFormat),
        values: [
          channelInterference.countLowInterference / totalCount,
          channelInterference.countMediumInterference / totalCount,
          channelInterference.countHighInterference / totalCount,
        ],
      };
    });
    const paddedStats = padHarmonyBarChartData(
      transformedStats,
      timeRange,
      Array(3).fill(0),
      dataOffset,
    );
    return paddedStats;
  },
});

export const channelDistributionSelector = selectorFamily<
  BarChartDataItem[],
  {
    frequencyBand: FrequencyBand;
    timeRange: number;
    chartGroups: {}[];
  }
>({
  key: 'channelDistribution',
  get: ({ frequencyBand, timeRange, chartGroups }) => ({ get }) => {
    const overviewStats = get(overviewStatsAtom);
    const dataOffset = get(harmonyDataOffset);
    if (
      !overviewStats?.harmonyChannelDistribution?.[frequencyBand] ||
      overviewStats?.harmonyChannelDistribution[frequencyBand].length === 0
    ) {
      return [];
    }
    const stats = overviewStats.harmonyChannelDistribution[frequencyBand];
    const filteredStats = filterStatsByDate(stats, timeRange, dataOffset);
    if (filteredStats.length === 0) return [];
    const transformedStats = filteredStats.map((filteredStat) => {
      return {
        name: moment(filteredStat.dt).format(harmonyChartDateFormat),
        values: getValuesByChannel(frequencyBand, filteredStat),
      };
    });
    const paddedStats = padHarmonyBarChartData(
      transformedStats,
      timeRange,
      Array(chartGroups.length).fill(0),
      dataOffset,
    );
    return paddedStats;
  },
});

export const txAirtimeUtilizationSelector = selectorFamily<
  BandTxAirtimeUtilization[],
  { frequencyBand: FrequencyBand; timeRange: number }
>({
  key: 'txAirtimeUtilization',
  get: ({ frequencyBand, timeRange }) => ({ get }) => {
    const overviewStats = get(overviewStatsAtom);
    const dataOffset = get(harmonyDataOffset);
    if (
      !overviewStats?.harmonyTxAirtimeUtilization?.[frequencyBand] ||
      overviewStats?.harmonyTxAirtimeUtilization?.[frequencyBand].length === 0
    ) {
      return [];
    }
    const stats = overviewStats.harmonyTxAirtimeUtilization[frequencyBand];
    const filteredStats = filterStatsByDate(stats, timeRange, dataOffset);
    if (filteredStats.length === 0) return [];
    const transformedStats = filteredStats.map((txAirtimeUtilization) => ({
      ...txAirtimeUtilization,
      xAxisLabel: moment(txAirtimeUtilization.dt).format(
        harmonyChartDateFormat,
      ),
    }));
    const paddedStats = padHarmonyAreaChartData(
      transformedStats,
      timeRange,
      // @ts-expect-error
      txAirtimeZeroValues,
      dataOffset,
    );
    return paddedStats;
  },
});
