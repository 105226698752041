export const isAtLeastVersion = (
  version: string,
  apiVersion: string,
): boolean => {
  if (!apiVersion) {
    return false;
  }
  const apiVersionArray = apiVersion.split('.');
  const versionArray = version.split('.');

  if (apiVersionArray[0] !== versionArray[0]) {
    return apiVersionArray[0] > versionArray[0];
  }

  if (apiVersionArray[1] !== versionArray[1]) {
    return (
      Number.parseInt(apiVersionArray[1]) > Number.parseInt(versionArray[1])
    );
  }

  const parsedPatchVersion = apiVersionArray[2]
    ? apiVersionArray[2].match(/\d+/)![0]
    : 0;
  return parsedPatchVersion >= versionArray[2];
};
