import { notify } from 'plume-ui';
import { useTranslation } from 'react-i18next';
import { TableAction } from '../../../components/TableActionButton/TableActionButton';
import { useFrontlineUrl } from '../../../hooks/useFrontlineUrl';
import { IotDevice } from '../types';

type IotDeviceAction = TableAction<{
  iotDevice: IotDevice;
}>;

const useIotDeviceActions = () => {
  const { t } = useTranslation();
  const { generateFrontlineUrl } = useFrontlineUrl();

  const iotDeviceActions: IotDeviceAction[] = [
    {
      onClick: async ({ iotDevice }) => {
        if (!iotDevice.customerId || !iotDevice.locationId) {
          notify({
            title: t('error'),
            body: t('iot.actions.frontline.error'),
            type: 'error',
          });
          return;
        }
        return window.open(
          generateFrontlineUrl({
            customerId: iotDevice.customerId,
            locationId: iotDevice.locationId,
            mac: iotDevice.mac,
          }),
          '_blank',
        );
      },
      label: t('iot.actions.frontline.label'),
      isVisible: () => true,
    },
  ];

  return () => iotDeviceActions;
};

export default useIotDeviceActions;
