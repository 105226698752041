import { AreaChart, Icons, Status, Tooltip } from 'plume-ui';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { HARMONY_SYNC_ID } from '../../../config';
import { useHarmonyChartGroups } from '../hooks/useHarmonyChartGroups';
import { txAirtimeUtilizationSelector } from '../state';
import {
  BandTxAirtimeUtilization,
  FrequencyBand,
  ReferenceLine,
  TimeRange,
} from '../types';
import { timeRangeToNumber } from '../util';

const TxAirtimeUtilizationChart = ({
  frequencyBand,
  referenceLine,
  timeRange,
}: {
  frequencyBand: FrequencyBand;
  referenceLine?: ReferenceLine;
  timeRange: TimeRange;
}) => {
  const { t } = useTranslation();
  const { txAirtimeUtilizationGroups } = useHarmonyChartGroups();
  const chartData = useRecoilValue<BandTxAirtimeUtilization[]>(
    txAirtimeUtilizationSelector({
      frequencyBand,
      timeRange: timeRangeToNumber(timeRange),
    }),
  );
  return (
    <div className="HarmonyContainer__card AuraGlow--card">
      <div className="HarmonyContainer__card__title">
        {t('harmony.chart.txAirtime.title')}
        <Tooltip
          label={t('harmony.chart.txAirtime.tooltip')}
          openInPortal
          overrideMaxCharacters
        >
          <Icons.InfoIcon width={16} />
        </Tooltip>
      </div>
      <div className="HarmonyContainer__chartLegend">
        {Array.isArray(chartData) &&
          chartData.length > 0 &&
          txAirtimeUtilizationGroups.map((item) => (
            <Status
              key={item.label}
              color={item.stroke}
              label={item.label}
              variant="legend"
            />
          ))}
      </div>
      <div className="HarmonyContainer__card__chartContainer--area">
        <AreaChart
          data={chartData}
          groups={txAirtimeUtilizationGroups}
          isStacked={false}
          legendPosition="none"
          referenceLine={referenceLine}
          syncId={HARMONY_SYNC_ID}
          xAxisTickInterval={timeRange === TimeRange.Last7days ? 1 : 5}
        />
      </div>
    </div>
  );
};

export default memo(TxAirtimeUtilizationChart);
