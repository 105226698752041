import _ from 'lodash';
import { Button, Chip, InputField, Switch, Toggler } from 'plume-ui';
import { FormEvent, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';
import { prepareErrorMessageForInput } from '../../../../utils/prepareErrorMessageForInput';
import { validateIPv4 } from '../../../../utils/validateIPv4';
import { validateIPv6 } from '../../../../utils/validateIPv6';
import { validateUrl } from '../../../../utils/validateUrl';
import { SecurityPolicyProtection, SecurityPolicyWhitelist } from '../../types';
import { isSecurityPolicyDirty } from '../../util';
import { IotFormValues } from '../IotModal/IotModal';

type SecurityPolicyProps = {
  values: IotFormValues;
  errors: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  setFieldError: (field: string, message: string | undefined) => void;
};

const SecurityPolicy = ({
  values,
  errors,
  setFieldValue,
  setFieldError,
}: SecurityPolicyProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [whitelistType, setWhitelistType] = useState(
    SecurityPolicyWhitelist.Fqdn,
  );
  const [site, setSite] = useState<string>();
  const [isCustom, setIsCustom] = useState(false);
  const initialValues = useRef(values);

  useEffect(() => {
    if (isSecurityPolicyDirty(values)) {
      setIsCustom(true);
    } else {
      setIsCustom(false);
    }
  }, [values]);

  const setDefaultState = () => {
    setWhitelistType(SecurityPolicyWhitelist.Fqdn);
    setSite(undefined);
    setIsOpen(false);
  };

  const onOpen = () => {
    initialValues.current = values;
    setIsOpen(true);
  };

  const onSave = () => {
    // Trigger rerender to reset scroll
    setFieldValue(
      SecurityPolicyProtection.IotProtection,
      _.clone(values[SecurityPolicyProtection.IotProtection]),
    );
    setDefaultState();
  };

  const onCancel = () => {
    Object.values(SecurityPolicyProtection).forEach((protection) => {
      setFieldValue(protection, initialValues.current[protection]);
    });
    Object.values(SecurityPolicyWhitelist).forEach((whitelist) => {
      setFieldValue(whitelist, initialValues.current[whitelist]);
    });
    setDefaultState();
  };

  const addSite = () => {
    if (!site) return;
    if (
      values[whitelistType]?.find((addedSite: string) => addedSite === site)
    ) {
      setFieldError(
        whitelistType,
        t('iot.securityPolicy.errors.siteAlreadyAdded'),
      );
      setSite(undefined);
      return;
    }
    try {
      switch (whitelistType) {
        case SecurityPolicyWhitelist.Fqdn:
          validateUrl({
            invalidUrlMessage: t('iot.securityPolicy.errors.invalidUrl'),
          }).validateSync(site);
          break;
        case SecurityPolicyWhitelist.IPv4:
          validateIPv4({
            invalidAddressMessage: t('iot.securityPolicy.errors.invalidIPv4'),
          }).validateSync(site);
          break;
        case SecurityPolicyWhitelist.IPv6:
          validateIPv6({
            invalidAddressMessage: t('iot.securityPolicy.errors.invalidIPv6'),
          }).validateSync(site);
          break;
      }
      const sites = _.cloneDeep(values[whitelistType]);
      sites.push(site);
      setFieldError(whitelistType, undefined);
      setFieldValue(whitelistType, sites);
    } catch (error) {
      setFieldError(whitelistType, (error as ValidationError).message);
    } finally {
      setSite(undefined);
    }
  };

  const removeSite = (siteToRemove: string) => {
    const sites = _.cloneDeep(values[whitelistType]);
    setFieldValue(
      whitelistType,
      sites.filter((site: string) => site !== siteToRemove),
    );
    setSite(undefined);
  };

  const toggles = Object.values(SecurityPolicyProtection).map((protection) => (
    <div key={protection} className="SecurityPolicy__toggle">
      {t(`iot.securityPolicy.${protection}`)}
      <Switch
        title={values[protection] ? t('on') : t('off')}
        selected={values[protection]}
        onToggle={() => {
          setFieldValue(protection, !values[protection]);
        }}
      />
    </div>
  ));

  return (
    <div className="SecurityPolicy">
      <div className="SecurityPolicy__header">
        <div className="SecurityPolicy__label">
          <div>{t('iot.securityPolicy.label')}</div>
          <div>
            {isCustom
              ? t('iot.securityPolicy.type.custom')
              : t('iot.securityPolicy.type.default')}
          </div>
        </div>
        <div className="SecurityPolicy__hint">
          {t('iot.securityPolicy.hint')}
          {!isOpen && (
            <Button styleVariant="tertiary" onClick={onOpen}>
              {t('iot.securityPolicy.changePolicy')}
            </Button>
          )}
        </div>
      </div>
      {isOpen && (
        <div className="SecurityPolicy__form">
          <div className="SecurityPolicy__toggles">{toggles}</div>
          <div className="SecurityPolicy__whitelist">
            <div className="SecurityPolicy__toggler">
              {t('iot.securityPolicy.toggler.label')}
              <Toggler
                toggleElements={Object.values(SecurityPolicyWhitelist).map(
                  (whitelist) => ({
                    key: whitelist,
                    label: t(`iot.securityPolicy.toggler.${whitelist}`),
                  }),
                )}
                onToggle={(selection) => {
                  setWhitelistType(selection.key as SecurityPolicyWhitelist);
                }}
              />
            </div>
            <InputField
              name="site"
              id="site"
              value={site}
              messages={
                prepareErrorMessageForInput(whitelistType, errors) || []
              }
              onInput={(e: FormEvent<HTMLInputElement>) =>
                setSite(e.currentTarget.value || undefined)
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') addSite();
              }}
              label={t('iot.securityPolicy.site')}
            />
            <Button
              classes={(current) => ({
                ...current,
                root: `${current.root} SecurityPolicy__addSite`,
                container: `${current.container} SecurityPolicy__addSite__container`,
              })}
              styleVariant="tertiary"
              onClick={() => addSite()}
            >
              {t('iot.securityPolicy.addSite')}
            </Button>
            {Array.isArray(values[whitelistType]) &&
              Boolean(values[whitelistType].length > 0) && (
                <div className="SecurityPolicy__siteList">
                  <div className="SecurityPolicy__siteList__label">
                    {t('iot.securityPolicy.currentSiteList')}
                  </div>
                  <div className="SecurityPolicy__siteList__list">
                    {values[whitelistType].map((site: string) => (
                      <Chip
                        key={site}
                        removable
                        onClick={() => removeSite(site)}
                      >
                        {site}
                      </Chip>
                    ))}
                  </div>
                </div>
              )}
          </div>
          <div className="SecurityPolicy__buttons">
            <Button styleVariant="tertiary-grey" onClick={onCancel}>
              {t('cancel')}
            </Button>
            <Button styleVariant="superprimary" onClick={onSave}>
              {t('save')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(SecurityPolicy);
