import { AxiosResponse } from 'axios';
import { MDU, PortfolioStats, UpriseApiResponse } from '../../../types';
import { HttpClient } from '../../../HttpClient';
import {
  CreateMduDto,
  CreateMduResponse,
  UpdateMduDto,
  UpdateMduResponse,
} from '../types';
import { UpriseApiUrls } from '../../../urls';
import { BulkUploadApiResponse } from '../../editorView/components/UploadModal/UploadModal';

export default class MduClient extends HttpClient {
  async fetchMdus(
    partnerId: string,
  ): Promise<AxiosResponse<UpriseApiResponse<MDU[]>>> {
    return await this.get<AxiosResponse<UpriseApiResponse<MDU[]>>>(
      UpriseApiUrls.getGetMdusUrl(partnerId),
    );
  }

  async fetchPortfolioStats(
    partnerId: string,
  ): Promise<AxiosResponse<PortfolioStats>> {
    return await this.get<AxiosResponse<PortfolioStats>>(
      UpriseApiUrls.getPortfolioStatsV2Url(partnerId),
    );
  }

  async createMdu(
    dto: Required<CreateMduDto>,
    partnerId: string,
  ): Promise<AxiosResponse<CreateMduResponse>> {
    return await this.post<AxiosResponse<CreateMduResponse>>(
      UpriseApiUrls.getCreateMduUrl(partnerId),
      dto,
    );
  }

  async updateMdu(
    id: string,
    dto: Omit<Omit<UpdateMduDto, 'city'>, 'postalcode'>,
    partnerId: string,
  ): Promise<AxiosResponse<UpdateMduResponse>> {
    return await this.patch<AxiosResponse<UpdateMduResponse>>(
      UpriseApiUrls.getUpdateMduUrl(id, partnerId),
      dto,
    );
  }

  async deleteMdu(id: string, partnerId: string): Promise<void> {
    await this.delete(UpriseApiUrls.getDeleteMduUrl(id, partnerId));
  }

  async uploadUnits(
    mduId: string,
    partnerId: string,
    file: File,
  ): Promise<AxiosResponse<BulkUploadApiResponse>> {
    const token = this.factory.loginService.getUser().idToken;
    const formData = new FormData();
    formData.append('file', file);
    return await this.post(
      UpriseApiUrls.getUploadUnitsUrl(mduId, partnerId),
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }

  async uploadNodes(
    mduId: string,
    partnerId: string,
    file: File,
  ): Promise<AxiosResponse<BulkUploadApiResponse>> {
    const token = this.factory.loginService.getUser().idToken;
    const formData = new FormData();
    formData.append('file', file);
    return await this.post(
      UpriseApiUrls.getUploadNodesUrl(mduId, partnerId),
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }

  async uploadUnitsWithNodes(
    mduId: string,
    partnerId: string,
    file: File,
  ): Promise<AxiosResponse<BulkUploadApiResponse>> {
    const token = this.factory.loginService.getUser().idToken;
    const formData = new FormData();
    formData.append('file', file);
    return await this.post(
      UpriseApiUrls.getUploadUnitsWithNodesUrl(mduId, partnerId),
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }

  async importTenants(
    mduId: string,
    partnerId: string,
    file: File,
  ): Promise<AxiosResponse<BulkUploadApiResponse>> {
    const token = this.factory.loginService.getUser().idToken;
    const formData = new FormData();
    formData.append('file', file);
    return await this.post(
      UpriseApiUrls.getImportTenantsUrl(mduId, partnerId),
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }

  async exitTenants(
    mduId: string,
    partnerId: string,
    file: File,
  ): Promise<AxiosResponse<BulkUploadApiResponse>> {
    const token = (await this.factory.loginService.getUser()).idToken;
    const formData = new FormData();
    formData.append('file', file);
    return await this.post(
      UpriseApiUrls.getExitTenantsUrl(mduId, partnerId),
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }
}
