import { Field, Form, Formik, FormikValues } from 'formik';
import {
  Button,
  DropdownFilterType,
  FilterDropdownGroup,
  FilterDropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  notify,
} from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import { DropdownFilter } from 'plume-ui/src/components/FilterDropdownGroup/FilterDropdownGroup';
import React, { FunctionComponent, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as yup from 'yup';
import DependencyContainer from '../../../../DependencyContainer';
import InputField from '../../../../components/InputField/InputField';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';
import useRenderAnonymized from '../../../../hooks/useRenderAnonymized';
import { MixPanelEvents } from '../../../../mixPanelEvents';
import {
  multiDwellingUnitsAtom,
  selectedPartnerSelector,
} from '../../../../state';
import { MDU, Role } from '../../../../types';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import validateEmail from '../../../../utils/isEmail/validateEmail';
import { prepareErrorMessageForInput } from '../../../../utils/prepareErrorMessageForInput';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { selectedUserAtom, usersAtom } from '../../usersState';

type ManageUserModalProps = {
  isOpen: boolean;
  onRequestClose: (success: boolean) => void;
};

const addStylesToInputField = (current: any) => ({
  ...current,
  root: `${current.root} ManageUserModal__input`,
});

type CreateUserFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  properties: string[];
};

const { usersService } = new DependencyContainer();

const ManageUserModal: FunctionComponent<ManageUserModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const { renderAnonymized } = useRenderAnonymized();
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const allProperties = useRecoilValue(multiDwellingUnitsAtom);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom);
  const [users, setUsers] = useRecoilState(usersAtom);

  const trackEvent = useTrackEvent();

  const getPropertyOptions = () => {
    return allProperties.map((property) => ({
      label: renderAnonymized(property.name),
      value: property.id,
    }));
  };

  const filterGroupItems: Record<string, DropdownFilter> = {
    properties: {
      label: t('users.actions.create.form.properties.label'),
      defaultLabel: t('users.actions.create.form.properties.selectLabel'),
      type: DropdownFilterType.MULTI,
      noSearchMatchMessage: t('users.actions.create.form.properties.noMatch'),
      searchPlaceholder: t('users.actions.create.form.properties.search'),
      searchBar: true,
      openInPortal: true,
      noItemsWarning: t(
        'users.actions.create.form.properties.noPropertiesWarning',
      ),
      items: getPropertyOptions(),
      expandDirection: 'auto',
    },
  };

  const onClose = (success: boolean) => {
    setSelectedUser(null);
    setSubmitInProgress(false);
    onRequestClose(success);
  };

  const getFormInitialValues = (): CreateUserFormValues => {
    if (selectedUser) {
      return {
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        email: selectedUser.email,
        properties: selectedUser.properties.map((property) => property.id),
      };
    }
    return {
      firstName: '',
      lastName: '',
      email: '',
      properties: [],
    };
  };

  const formInputFieldsDefinition: Array<{
    name: keyof CreateUserFormValues;
    labelId: string;
    type: 'input';
  }> = [
    {
      name: 'firstName',
      labelId: 'users.actions.create.form.firstName.label',
      type: 'input',
    },
    {
      name: 'lastName',
      labelId: 'users.actions.create.form.lastName.label',
      type: 'input',
    },
    {
      name: 'email',
      labelId: 'users.actions.create.form.email.label',
      type: 'input',
    },
  ];

  const getValidationSchema = () =>
    yup.object().shape({
      firstName: yup
        .string()
        .min(3, t('formValidation.minLength', { length: 3 }))
        .trim()
        .max(50, t('formValidation.maxFields', { length: 50 }))
        .required(t('users.actions.create.form.firstName.required')),
      lastName: yup
        .string()
        .min(3, t('formValidation.minLength', { length: 3 }))
        .trim()
        .max(50, t('formValidation.maxFields', { length: 50 }))
        .required(t('users.actions.create.form.lastName.required')),
      email: yup
        .string()
        .required(t('users.actions.create.form.email.required'))
        .email(t('users.actions.create.form.email.invalid'))
        .test(
          'is-valid-email',
          t('users.actions.create.form.email.invalid'),
          (value) => validateEmail(value || ''),
        )
        .test(
          'user-unique',
          t('users.actions.create.form.email.unique'),
          (value) => {
            for (const user of users) {
              if (user.email === selectedUser?.email) continue;
              if (user.email === value) return false;
            }
            return true;
          },
        )
        .trim()
        .max(100, t('formValidation.maxFields', { length: 100 })),
    });

  const onSubmit = async (values: FormikValues) => {
    if (submitInProgress) {
      return;
    }
    setSubmitInProgress(true);
    if (selectedUser) {
      onUpdate(values);
    } else {
      onCreate(values);
    }
  };

  const onCreate = async (values: FormikValues) => {
    if (!selectedPartner) {
      return;
    }

    try {
      const newUserDto = {
        firstName: values.firstName?.trim(),
        lastName: values.lastName?.trim(),
        email: values.email?.trim(),
        role: Role.PropertyManager,
        properties: values.properties
          .map(
            (selectedPropertyId: string) =>
              allProperties.find(
                (property) => property.id === selectedPropertyId,
              )!,
          )
          .map((property: MDU) => ({ id: property.id, name: property.name })),
      };

      const addedUser = await usersService.addUser(
        newUserDto,
        selectedPartner.id,
      );

      setUsers((currentUsers) => [...currentUsers, addedUser]);

      notify({
        title: t('success'),
        body: t('users.actions.create.userAdded'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.ADD_USER_SUCCESS,
        additionalContent: {
          email: values.email,
        },
      });
      setSubmitInProgress(false);
      onClose(true);
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.ADD_USER_FAILURE,
        additionalContent: {
          email: values.email?.trim(),
        },
      });
      setSubmitInProgress(false);
    }
  };

  const onUpdate = async (values: FormikValues) => {
    if (!selectedPartner) {
      return;
    }
    setSubmitInProgress(true);

    try {
      const updateUserDto = {
        firstName: values.firstName?.trim(),
        lastName: values.lastName?.trim(),
        email: values.email?.trim(),
        role: Role.PropertyManager,
        properties: values.properties
          .map(
            (selectedPropertyId: string) =>
              allProperties.find(
                (property) => property.id === selectedPropertyId,
              )!,
          )
          .map((property: MDU) => ({ id: property.id, name: property.name })),
      };

      await usersService.updateUser(
        updateUserDto,
        selectedPartner.id,
        selectedUser!.id,
      );

      setUsers((currentUsers) =>
        currentUsers.map((user) =>
          user.id === selectedUser?.id ? { ...user, ...updateUserDto } : user,
        ),
      );

      notify({
        title: t('success'),
        body: t('users.actions.edit.userUpdated'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.EDIT_USER_SUCCESS,
        additionalContent: {
          email: values.email?.trim(),
        },
      });
      setSubmitInProgress(false);
      onClose(true);
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.EDIT_USER_FAILURE,
        additionalContent: {
          email: values.email,
        },
      });
      setSubmitInProgress(false);
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Formik
      initialValues={getFormInitialValues()}
      validationSchema={getValidationSchema()}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values, errors, setValues, dirty, submitForm }) => (
        <Form>
          <Modal
            appElement={document.getElementById('root') as HTMLElement}
            ariaHideApp={false}
            isOpen={isOpen}
            onRequestClose={() => onClose(false)}
            classes={(current: ModalStyles) => ({
              ...current,
              root: `${current.root} ManageUserModal`,
            })}
          >
            <ModalHeader
              title={
                selectedUser
                  ? t('users.actions.edit.modalTitle')
                  : t('users.actions.create.modalTitle')
              }
            />

            <ModalBody>
              <div className="ManageUserModal__content">
                {formInputFieldsDefinition?.length > 0 && (
                  <div className="ManageUserModal__fields">
                    {formInputFieldsDefinition.map((field) => {
                      return (
                        <React.Fragment key={field.name}>
                          {field.type === 'input' && (
                            <Field
                              key={field.name}
                              name={field.name}
                              component={InputField}
                              value={values[field.name]}
                              messages={prepareErrorMessageForInput(
                                field.name,
                                errors,
                              )}
                              label={t(field.labelId)}
                              classes={addStylesToInputField}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}
                <FilterDropdownGroup
                  items={filterGroupItems}
                  selectedItems={{
                    properties: filterGroupItems.properties.items.filter(
                      (property) => values.properties.includes(property.value),
                    ),
                  }}
                  onSelect={(
                    filterStateKey: string,
                    filter: FilterDropdownItem | FilterDropdownItem[],
                  ) => {
                    setValues({
                      ...values,
                      properties: (filter as FilterDropdownItem[]).map(
                        (e) => e.value,
                      ),
                    });
                  }}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                styleVariant="tertiary-grey"
                onClick={() => onClose(false)}
              >
                <FormattedMessage id="cancel" />
              </Button>
              <SubmitButton
                disabled={!dirty}
                onSubmit={submitForm}
                submitInProgress={submitInProgress}
                label={selectedUser ? t('saveChanges') : t('submit')}
                inProgressLabel={selectedUser ? t('savingChanges') : undefined}
              />
            </ModalFooter>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default memo(ManageUserModal);
