import { TimeRange } from '../types';
import { TogglerElementTypes } from 'plume-ui/dist/components/Toggler/Toggler';
import { useTranslation } from 'react-i18next';

const useHarmonyToggleElements = () => {
  const { t } = useTranslation();
  const bandToggleElements: TogglerElementTypes[] = [
    {
      key: '2.4G',
      label: '2.4G',
    },
    {
      key: '5G',
      label: '5G',
    },
  ];

  const timeRangeToggleElements: TogglerElementTypes[] = [
    {
      key: TimeRange.Last7days,
      label: t('harmony.chart.days', { count: 7 }),
    },
    {
      key: TimeRange.Last30days,
      label: t('harmony.chart.days', { count: 30 }),
    },
  ];

  return {
    bandToggleElements,
    timeRangeToggleElements,
  };
};

export default useHarmonyToggleElements;
