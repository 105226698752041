import { AxiosRequestConfig, Method } from 'axios';
import * as https from 'https';
import DependencyContainer from './DependencyContainer';
import axiosInstance from './axiosSetup';

export interface HttpClientProtocol {
  get<T>(endpoint: string): Promise<T>;
  post<T>(
    endpoint: string,
    data?: any,
    configOverwrite?: AxiosRequestConfig,
  ): Promise<T>;
  put<T>(
    endpoint: string,
    data?: any,
    configOverwrite?: AxiosRequestConfig,
  ): Promise<T>;
  patch<T>(endpoint: string, data?: any): Promise<T>;
  delete<T>(endpoint: string, data?: any): Promise<T>;
}

export class HttpClient implements HttpClientProtocol {
  constructor(protected readonly factory: DependencyContainer) {
    this.factory = factory;
  }

  async get<T>(
    endpoint: string,
    configOverwrite?: AxiosRequestConfig,
    skipAuthorization: boolean = false,
  ): Promise<T> {
    const config = await this.getAxiosRequestConfig(
      'GET',
      endpoint,
      configOverwrite,
      skipAuthorization,
    );
    return axiosInstance.get(endpoint, config as AxiosRequestConfig);
  }

  async post<T>(
    endpoint: string,
    data: any,
    configOverwrite?: AxiosRequestConfig,
  ): Promise<T> {
    const config = await this.getAxiosRequestConfig(
      'POST',
      endpoint,
      configOverwrite,
    );
    return await axiosInstance.post(
      endpoint,
      data,
      config as AxiosRequestConfig,
    );
  }

  async put<T>(
    endpoint: string,
    data: any,
    configOverwrite?: AxiosRequestConfig,
  ): Promise<T> {
    const config = await this.getAxiosRequestConfig(
      'PUT',
      endpoint,
      configOverwrite,
    );
    return await axiosInstance.put(
      endpoint,
      data,
      config as AxiosRequestConfig,
    );
  }

  async patch<T>(endpoint: string, data: any): Promise<T> {
    const config = await this.getAxiosRequestConfig('PATCH', endpoint);
    return await axiosInstance.patch(
      endpoint,
      data,
      config as AxiosRequestConfig,
    );
  }

  async delete<T>(endpoint: string, data?: any): Promise<T> {
    const config = await this.getAxiosRequestConfig('DELETE', endpoint, {
      data,
    });
    return await axiosInstance.delete(endpoint, config as AxiosRequestConfig);
  }

  private async getAxiosRequestConfig(
    method: Method,
    endpoint: string,
    configOverwrite?: AxiosRequestConfig,
    skipAuthorization: boolean = false,
  ): Promise<AxiosRequestConfig> {
    let token = undefined;
    try {
      token = (await this.factory.loginService.getUser()).idToken;
    } catch (error) {}
    if (skipAuthorization) {
      token = undefined;
    }
    const headers = token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : undefined;

    const config: AxiosRequestConfig = {
      url: endpoint,
      method,
      timeout: 60000,
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
      headers,
      ...configOverwrite,
    };

    return Promise.resolve(config);
  }
}
