import DependencyContainer from '../../../DependencyContainer';
import {
  BusinessTenant,
  NewTenant,
  ResidentialTenant,
  TenantsUnitReference,
  TenantTypes,
  UpdatedTenant,
} from '../types';
import TenantsClient from './TenantsClient';

export default class TenantsService {
  constructor(private readonly factory: DependencyContainer) {}

  async getAllTenants(
    mduId: string,
    partnerId: string,
  ): Promise<{
    residentialTenants: ResidentialTenant[];
    businessTenants: BusinessTenant[];
  }> {
    let [residentialTenants, businessTenants] = await Promise.all([
      this.getAllResidentialTenantsForMdu(mduId, partnerId),
      this.getAllBusinessTenantsForMdu(mduId, partnerId),
    ]);
    return {
      residentialTenants,
      businessTenants,
    };
  }

  private async getAllResidentialTenantsForMdu(
    mduId: string,
    partnerId: string,
  ): Promise<ResidentialTenant[]> {
    const tenantsResponse = await this.factory.tenantClient.fetchResidentialTenants(
      mduId,
      partnerId,
    );
    let tenants = [...tenantsResponse.data.items];
    if (tenantsResponse.data.meta.totalPages > 1) {
      const additionalPageTenantsResponse = await Promise.all(
        Array.from(Array(tenantsResponse.data.meta.totalPages - 1)).map(
          (_, i) => {
            return this.factory.tenantClient.fetchResidentialTenants(
              mduId,
              partnerId,
              i + 2,
            );
          },
        ),
      );
      additionalPageTenantsResponse.forEach((response) => {
        tenants = [...tenants, ...response.data.items];
      });
    }
    return tenants;
  }

  private async getAllBusinessTenantsForMdu(
    mduId: string,
    partnerId: string,
  ): Promise<BusinessTenant[]> {
    const tenantsResponse = await this.factory.tenantClient.fetchBusinessTenants(
      mduId,
      partnerId,
    );
    let tenants = [...tenantsResponse.data.items];
    if (tenantsResponse.data.meta.totalPages > 1) {
      const additionalPageTenantsResponse = await Promise.all(
        Array.from(Array(tenantsResponse.data.meta.totalPages - 1)).map(
          (_, i) => {
            return this.factory.tenantClient.fetchBusinessTenants(
              mduId,
              partnerId,
              i + 2,
            );
          },
        ),
      );
      additionalPageTenantsResponse.forEach((response) => {
        tenants = [...tenants, ...response.data.items];
      });
    }
    return tenants;
  }

  async addTenant(
    mduId: string,
    newTenant: NewTenant,
    partnerId: string,
  ): Promise<BusinessTenant | ResidentialTenant> {
    const tenant = {
      name: newTenant.name,
      email: newTenant.email,
      accountId: newTenant.accountId,
      status: newTenant.status,
    };
    const mapTypeToAction: Record<TenantTypes, keyof TenantsClient> = {
      [TenantTypes.Business]: 'addBusinessTenant',
      [TenantTypes.Residence]: 'addResidentialTenant',
    };
    const strategy = mapTypeToAction[newTenant.type];
    const tenantUnitReference = {
      id: newTenant.unitId,
    };
    const newTenantDto = {
      ...tenant,
      [newTenant.type === TenantTypes.Business
        ? 'businessUnit'
        : 'residentialUnit']: tenantUnitReference,
    };
    // @ts-ignore
    const response: any = await this.factory.tenantClient[strategy](
      mduId,
      newTenantDto,
      partnerId,
    );
    return response.data;
  }

  async updateTenant(
    mduId: string,
    updatedTenant: UpdatedTenant,
    tenantId: string,
    type: TenantTypes,
    partnerId: string,
  ): Promise<BusinessTenant | ResidentialTenant> {
    const mapTypeToAction: Record<TenantTypes, keyof TenantsClient> = {
      [TenantTypes.Business]: 'updateBusinessTenant',
      [TenantTypes.Residence]: 'updateResidentialTenant',
    };
    const strategy = mapTypeToAction[type];
    //@ts-ignore
    const response: any = await this.factory.tenantClient[strategy](
      mduId,
      updatedTenant,
      tenantId,
      partnerId,
    );
    return response.data;
  }

  async relocateTenant(
    mduId: string,
    tenantId: string,
    newUnitId: string,
    move_nodes_with_tenant: boolean = false,
    type: TenantTypes,
    partnerId: string,
  ): Promise<BusinessTenant | ResidentialTenant> {
    const unitReference = {
      id: newUnitId,
    };
    const movingNodesPatchPayload = {
      ...unitReference,
    };
    const keepingNodesPatchPayload = {
      ...(type === TenantTypes.Business && {
        businessUnit: unitReference as TenantsUnitReference,
      }),
      ...(type === TenantTypes.Residence && {
        residentialUnit: unitReference as TenantsUnitReference,
      }),
    };
    const mapTypeToAction: Record<TenantTypes, keyof TenantsClient> = {
      [TenantTypes.Business]: 'relocateBusinessTenant',
      [TenantTypes.Residence]: 'relocateResidentialTenant',
    };
    const strategy = mapTypeToAction[type];
    //@ts-ignore
    const response: any = await (this.factory.tenantClient[strategy] as any)(
      mduId,
      move_nodes_with_tenant
        ? movingNodesPatchPayload
        : keepingNodesPatchPayload,
      move_nodes_with_tenant,
      tenantId,
      partnerId,
    );
    return response.data;
  }

  async deleteTenant(
    mduId: string,
    tenantId: string,
    type: TenantTypes,
    partnerId: string,
  ): Promise<BusinessTenant | ResidentialTenant> {
    const mapActionToType: Record<TenantTypes, keyof TenantsClient> = {
      [TenantTypes.Business]: 'deleteBusinessTenant',
      [TenantTypes.Residence]: 'deleteResidentialTenant',
    };
    const strategy = mapActionToType[type];
    //@ts-ignore
    const response: any = await this.factory.tenantClient[strategy](
      mduId,
      tenantId,
      partnerId,
    );

    return response.data;
  }

  async exitResidentialTenant(
    mduId: string,
    tenantId: string,
    partnerId: string,
  ): Promise<ResidentialTenant> {
    const response: any = await this.factory.tenantClient.exitResidentialTenant(
      mduId,
      tenantId,
      partnerId,
    );

    return response.data;
  }

  async inviteTenant(
    mduId: string,
    tenantId: string,
    type: TenantTypes,
    partnerId: string,
  ): Promise<BusinessTenant | ResidentialTenant> {
    const mapActionToType: Record<TenantTypes, keyof TenantsClient> = {
      [TenantTypes.Business]: 'inviteBusinessTenant',
      [TenantTypes.Residence]: 'inviteResidentialTenant',
    };
    const strategy = mapActionToType[type];
    //@ts-ignore
    const response: any = await this.factory.tenantClient[strategy](
      mduId,
      tenantId,
      partnerId,
    );

    return response.data;
  }

  async reinviteTenant(
    mduId: string,
    tenantId: string,
    type: TenantTypes,
    partnerId: string,
  ): Promise<BusinessTenant | ResidentialTenant> {
    const mapActionToType: Record<TenantTypes, keyof TenantsClient> = {
      [TenantTypes.Business]: 'reinviteBusinessTenant',
      [TenantTypes.Residence]: 'reinviteResidentialTenant',
    };
    const strategy = mapActionToType[type];
    //@ts-ignore
    const response: any = await this.factory.tenantClient[strategy](
      mduId,
      tenantId,
      partnerId,
    );

    return response.data;
  }

  async suspendTenant(
    mduId: string,
    tenantId: string,
    type: TenantTypes,
    partnerId: string,
  ): Promise<BusinessTenant | ResidentialTenant> {
    const mapActionToType: Record<TenantTypes, keyof TenantsClient> = {
      [TenantTypes.Business]: 'suspendBusinessTenant',
      [TenantTypes.Residence]: 'suspendResidentialTenant',
    };
    const strategy = mapActionToType[type];
    //@ts-ignore
    const response: any = await this.factory.tenantClient[strategy](
      mduId,
      tenantId,
      partnerId,
    );

    return response.data;
  }

  async activateTenant(
    mduId: string,
    tenantId: string,
    type: TenantTypes,
    partnerId: string,
  ): Promise<BusinessTenant | ResidentialTenant> {
    const mapActionToType: Record<TenantTypes, keyof TenantsClient> = {
      [TenantTypes.Business]: 'activateBusinessTenant',
      [TenantTypes.Residence]: 'activateResidentialTenant',
    };
    const strategy = mapActionToType[type];
    //@ts-ignore
    const response: any = await this.factory.tenantClient[strategy](
      mduId,
      tenantId,
      partnerId,
    );

    return response.data;
  }

  async getTenantById(
    mduId: string,
    tenantId: string,
    type: TenantTypes,
    partnerId: string,
  ): Promise<BusinessTenant | ResidentialTenant> {
    const strategy =
      type === TenantTypes.Business
        ? 'fetchBusinessTenantById'
        : 'fetchResidentialTenantById';
    //@ts-ignore
    const response: any = await this.factory.tenantClient[strategy](
      tenantId,
      mduId,
      partnerId,
    );

    return response.data;
  }
}
