import { useOktaAuth } from '@okta/okta-react';
import {
  AutoComplete,
  ConfirmationModal,
  Dropdown,
  DropdownSelectableItem,
  Icons,
  Menu,
  Notifications,
  TopBar,
  notify,
} from 'plume-ui';
import { AutoCompleteStyles } from 'plume-ui/dist/components/AutoComplete/AutoComplete';
import { MenuStyles } from 'plume-ui/dist/components/Menu/Menu';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import DependencyContainer from '../DependencyContainer';
import {
  APP_URL,
  LOGIN_SCREEN_ROUTE,
  Routes,
  adminRoutes,
  routesRequiringProperty,
} from '../config';
import ConfigContainer from '../features/config/containers/ConfigContainer';
import RelocateTenantModal from '../features/editorView/components/RelocateTenantModal/RelocateTenantModal';
import { selectedMduIdAtom } from '../features/editorView/propertyState';
import { useInventory } from '../features/inventory/hooks/useInventory';
import IotModal from '../features/iot/components/IotModal/IotModal';
import useIotNetworks from '../features/iot/hooks/useIotNetworks';
import { iotModalStateAtom } from '../features/iot/state';
import PortfolioContainer from '../features/portfolio/containers/PortfolioContainer';
import { usePropertyNetworks } from '../features/property-networks/hooks/usePropertyNetworks';
import CreateTenantModal from '../features/tenants/components/CreateTenantModal/CreateTenantModal';
import { useTenants } from '../features/tenants/hooks/useTenants';
import {
  businessTenantsAtom,
  businessTenantsSelectedPageAtom,
  residentialTenantsAtom,
  residentialTenantsSelectedPageAtom,
  tenantsSelectedFilterAtom,
  tenantsSelectedStatusAtom,
  tenantsSelectedTypeAtom,
} from '../features/tenants/tenantsState';
import { TenantStatus, TenantTypes } from '../features/tenants/types';
import { useTrackEvent } from '../features/trackingAnalytics/hooks/useTrackEvent';
import { MixpanelService } from '../features/trackingAnalytics/services/mixPanel.service';
import { UnitModal } from '../features/units/components/UnitModal/UnitModal';
import { useUnits } from '../features/units/hooks/useUnits';
import { UnitType } from '../features/units/types';
import {
  businessUnitsAtom,
  residentialUnitsAtom,
  selectedUnitToEditAtom,
  unitsBusinessSelectedPageAtom,
  unitsResidentialSelectedPageAtom,
  unitsSelectedFilterAtom,
  unitsSelectedTypeAtom,
} from '../features/units/unitsState';
import ManageUserModal from '../features/users/components/ManageUserModal/ManageUserModal';
import UsersContainer from '../features/users/containers/UsersContainer';
import { useUsers } from '../features/users/hooks/useUsers';
import { generateKey } from '../helpers/generate-key';
import { useApiVersion } from '../hooks/useApiVerision';
import { useClearFilters } from '../hooks/useClearFilters';
import useConfig from '../hooks/useConfig';
import useGlobalSearch from '../hooks/useGlobalSearch';
import useMenu from '../hooks/useMenu';
import useRenderAnonymized from '../hooks/useRenderAnonymized';
import { MixPanelEvents } from '../mixPanelEvents';
import {
  confirmationModalAtom,
  createTenantModalStateAtom,
  createUserModalStateAtom,
  multiDwellingUnitsAtom,
  partnersAtom,
  relocateTenantModalStateAtom,
  selectedPartnerIdAtom,
  selectedPartnerSelector,
  unitModalStateAtom,
  userInfoAtom,
} from '../state';
import {
  MDU,
  ModalState,
  ModalType,
  Partner,
  Permission,
  Role,
  UpriseAutocompleteResultsItem,
} from '../types';
import { useGetCurrentRoute } from '../utils/hooks/useGetCurrentRoute';
import { useRedirectToRoute } from '../utils/hooks/useRedirectToRoute';
import PropertyRoutes from './PropertyRoutes';

const { loginService, mduService } = new DependencyContainer();

const getTopbarName = () => {
  const { firstName } = loginService.getUser();
  return `${firstName}`;
};

const getFullName = () => {
  const { firstName, lastName } = loginService.getUser();
  return `${firstName} ${lastName}`;
};

const getBadgeInitials = () => {
  const { firstName, lastName } = loginService.getUser();
  if (!Boolean(firstName) || !firstName.length) return;
  if (!Boolean(lastName) || !lastName.length) return;
  return `${firstName[0]}${lastName[0]}`;
};

const mapRoleToDisplayNameTranslation: Record<Role, any> = {
  [Role.Admin]: 'roleDisplayNames.admin',
  [Role.PartnerAdmin]: 'roleDisplayNames.partnerAdmin',
  [Role.PartnerTechnician]: 'roleDisplayNames.partnerTechnician',
  [Role.PropertyManager]: 'roleDisplayNames.propertyManager',
  [Role.PartnerSupport]: 'roleDisplayNames.partnerSupport',
  [Role.ReadOnly]: 'roleDisplayNames.readOnly',
  [Role.Support]: 'roleDisplayNames.support',
};

const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const { oktaAuth } = useOktaAuth();
  const { t } = useTranslation();
  const { renderAnonymized } = useRenderAnonymized();
  const { partnerId } = useParams<any>();
  const { clearFilters } = useClearFilters();
  const { globalSearchFn, availableFilters } = useGlobalSearch();

  useConfig();
  useApiVersion();
  useUnits();
  useTenants();
  useInventory();
  usePropertyNetworks();
  useIotNetworks();
  useUsers();

  const trackEvent = useTrackEvent();
  const getCurrentRoute = useGetCurrentRoute();
  const redirectToRoute = useRedirectToRoute();
  const getMenuItems = useMenu();

  const setSelectedPartnerId = useSetRecoilState(selectedPartnerIdAtom);
  const setProperties = useSetRecoilState(multiDwellingUnitsAtom);

  const setBusinessUnits = useSetRecoilState(businessUnitsAtom);
  const setResidentialUnits = useSetRecoilState(residentialUnitsAtom);
  const setBusinessTenants = useSetRecoilState(businessTenantsAtom);
  const setResidentialTenants = useSetRecoilState(residentialTenantsAtom);

  const setUnitsSelectedType = useSetRecoilState(unitsSelectedTypeAtom);
  const setTenantsSelectedType = useSetRecoilState(tenantsSelectedTypeAtom);
  const setTenantsSelectedStatus = useSetRecoilState(tenantsSelectedStatusAtom);

  const setResidentialTenantsSelectedPage = useSetRecoilState(
    residentialTenantsSelectedPageAtom,
  );
  const setBusinessTenantsSelectedPage = useSetRecoilState(
    businessTenantsSelectedPageAtom,
  );

  const setCurrentBusinessUnitsPage = useSetRecoilState(
    unitsBusinessSelectedPageAtom,
  );
  const setCurrentResidentialUnitsPage = useSetRecoilState(
    unitsResidentialSelectedPageAtom,
  );

  const setSelectedUnitsFilter = useSetRecoilState(unitsSelectedFilterAtom);
  const setTenantsSelectedFilter = useSetRecoilState(tenantsSelectedFilterAtom);

  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const properties = useRecoilValue(multiDwellingUnitsAtom);

  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const [partners, setPartners] = useRecoilState(partnersAtom);
  const [selectedPropertyId, setSelectedPropertyId] = useRecoilState(
    selectedMduIdAtom,
  );

  // Modals
  const [iotModalState, setIotModalState] = useRecoilState(iotModalStateAtom);
  const [selectedUnitToEdit, setSelectedUnitToEdit] = useRecoilState(
    selectedUnitToEditAtom,
  );
  const [confirmationModal, setConfirmationModal] = useRecoilState(
    confirmationModalAtom,
  );
  const [unitModalState, setUnitModalState] = useRecoilState(
    unitModalStateAtom,
  );
  const [createTenantModalState, setCreateTenantModalState] = useRecoilState(
    createTenantModalStateAtom,
  );
  const [
    relocateTenantModalState,
    setRelocateTenantModalState,
  ] = useRecoilState(relocateTenantModalStateAtom);
  const [createUserModalState, setCreateUserModalState] = useRecoilState(
    createUserModalStateAtom,
  );

  useEffect(() => {
    Promise.all([loginService.getUserInfo(), loginService.getPartners()])
      .then((results) => {
        let [userInfoResponse, partnersResponse] = results;

        if (!Object.values(Role).includes(userInfoResponse.role)) {
          logout();
          return;
        }

        userInfoResponse.displayRole = t(
          mapRoleToDisplayNameTranslation[userInfoResponse?.role],
        );

        console.log(`Role: ${userInfoResponse.displayRole}`);
        setUserInfo(userInfoResponse);
        MixpanelService.getInstance().setRole(userInfoResponse.role);

        setPartners([...partnersResponse]);

        const currentRoute = getCurrentRoute();

        switch (currentRoute) {
          case Routes.Users:
            if (!userInfoResponse.permissions.includes(Permission.getUsers)) {
              redirectToRoute(Routes.Portfolio, {
                partnerId: selectedPartner!.id,
              });
            }
            break;
          default:
            break;
        }
      })
      .catch((error: any) => {
        debugger;
        console.error('error getting userInfo or partners');
      });
  }, []);

  useEffect(() => {
    if (!selectedUnitToEdit) return;
    setUnitModalState({
      state: ModalState.Open,
      type: ModalType.Edit,
    });
  }, [selectedUnitToEdit]);

  useEffect(() => {
    clearFilters();
    setBusinessTenants([]);
    setBusinessUnits([]);
    setResidentialUnits([]);
    setResidentialTenants([]);
  }, [selectedPropertyId]);

  useEffect(() => {
    if (partners.length) {
      const noSelectedPartner = !partners.find((p) => p.id === partnerId);
      if (noSelectedPartner) {
        if (partnerId) {
          notify({
            type: 'alert',
            title: t('warnings.noPartnerTitle'),
            body: t('warnings.noPartnerMessage'),
          });
        }
        setSelectedPartnerId(partners[0].id);
        console.log(`Partner ID: ${partners[0].id}`);
        if (
          getCurrentRoute() === Routes.Config &&
          userInfo?.role === Role.Support
        )
          return;
        redirectToRoute(Routes.Portfolio, { partnerId: partners[0].id });
      } else {
        setSelectedPartnerId(partnerId);
        console.log(`Partner ID: ${partnerId}`);
      }
    }
  }, [partnerId, partners]);

  useEffect(() => {
    if (selectedPartner) {
      mduService
        .getMDUs(selectedPartner.id)
        .then((response) => {
          if (!response || !response.length) {
            redirectToRoute(Routes.Portfolio, {
              partnerId: selectedPartner.id,
            });
          }
          setProperties(response);
          if (response.length === 1) {
            const propertyId = response[0].id;
            setSelectedPropertyId(propertyId);
            const currentRoute = getCurrentRoute();
            if (
              !routesRequiringProperty.includes(currentRoute) &&
              !adminRoutes.includes(currentRoute)
            ) {
              redirectToRoute(Routes.Overview, {
                partnerId: selectedPartner.id,
                propertyId: propertyId,
              });
            }
          }
        })
        .catch((error) => console.error(error));
    }
  }, [selectedPartner]);

  const logout = async () => {
    loginService.removeAuthError();
    trackEvent({
      eventName: MixPanelEvents.LOGOUT,
    });
    await oktaAuth.signOut({
      postLogoutRedirectUri: APP_URL + LOGIN_SCREEN_ROUTE,
    });
  };

  const onePropertyExists = properties.length === 1;
  const multiplePropertiesExist = properties.length > 1;

  const getPropertyDropdownLabel = () => {
    return (
      renderAnonymized(
        properties.find((mdu) => mdu.id === selectedPropertyId)?.name,
      ) || t('menu.selectProperty')
    );
  };

  const onSelectProperty = (property: MDU) => {
    setSelectedPropertyId(property.id);
    const currentRoute = getCurrentRoute();
    const params = {
      propertyId: property.id,
      partnerId: selectedPartner!.id,
    };
    if (currentRoute === Routes.Portfolio) {
      redirectToRoute(Routes.Overview, params);
    } else {
      redirectToRoute(currentRoute, params);
    }
  };

  const onSelectPartner = (partner: Partner) => {
    if (partner.id === selectedPartner?.id) return;
    setSelectedPropertyId(undefined);
    setSelectedPartnerId(partner.id);
    redirectToRoute(Routes.Portfolio, { partnerId: partner.id });
  };

  const handleSearchItemClick = (item: UpriseAutocompleteResultsItem) => {
    const params = {
      propertyId: item.propertyId,
      partnerId: selectedPartner!.id,
    };

    switch (item.type) {
      case 'property':
        setSelectedPropertyId(item.id);
        const currentRoute = getCurrentRoute();
        if (currentRoute === Routes.Portfolio) {
          redirectToRoute(Routes.Overview, { ...params, propertyId: item.id });
        }
        break;

      case 'unit': {
        setSelectedUnitsFilter(item.label);
        setUnitsSelectedType(item.unitType!);
        if (item.unitType === UnitType.Business) {
          setCurrentBusinessUnitsPage(0);
        } else {
          setCurrentResidentialUnitsPage(0);
        }
        redirectToRoute(Routes.Units, params);
        break;
      }

      case 'accountId': {
        setTenantsSelectedFilter(item.tenantName!);
        setTenantsSelectedType(item.tenantType!);
        setTenantsSelectedStatus(item.tenantStatus!);
        redirectToRoute(Routes.Tenants, params);
        break;
      }

      case 'tenant': {
        setTenantsSelectedFilter(item.label);
        setTenantsSelectedType(item.tenantType!);
        setTenantsSelectedStatus(
          item.tenantStatus === null ? TenantStatus.Error : item.tenantStatus!,
        );
        if (item.tenantType === TenantTypes.Business) {
          setBusinessTenantsSelectedPage(0);
        } else {
          setResidentialTenantsSelectedPage(0);
        }
        redirectToRoute(Routes.Tenants, params);
        break;
      }
    }
  };

  const getRightTopBarContent = () => {
    return (
      <div className="AppLayout__property-partner">
        {onePropertyExists && (
          <div className="AppLayout__property">
            <Icons.MapPinIcon width={16} />
            <span>{getPropertyDropdownLabel()}</span>
          </div>
        )}
        {multiplePropertiesExist && (
          <Dropdown
            classes={(current) => ({
              ...current,
              button: `${current.root} AppLayout__property-selector`,
              label: `${current.root} AppLayout__property-selector__label`,
              iconLeft: `${current.root} AppLayout__property-selector__iconLeft`,
            })}
            listPosition={'right'}
            expandDirection={'bottom'}
            closeOnItemClick={true}
            searchBar
            noSearchMatchMessage={t('noResults')}
            searchPlaceholder={t('searchByName')}
            iconLeft={<Icons.MapPinIcon width={16} />}
            label={getPropertyDropdownLabel()}
            openInPortal
          >
            {properties.map((mdu) => (
              <DropdownSelectableItem
                selected={mdu.id === selectedPropertyId}
                onClick={() => onSelectProperty(mdu)}
                key={mdu.id}
              >
                {renderAnonymized(mdu.name)}
              </DropdownSelectableItem>
            ))}
          </Dropdown>
        )}

        {partners.length === 1 && (
          <div className="AppLayout__partner">{selectedPartner?.name}</div>
        )}
        {partners.length > 1 && (
          <Dropdown
            classes={(current) => ({
              ...current,
              button: `${current.root} AppLayout__partner-selector`,
              label: `${current.root} AppLayout__partner-selector__label`,
            })}
            listPosition={'right'}
            expandDirection={'bottom'}
            closeOnItemClick={true}
            searchBar
            noSearchMatchMessage={t('noResults')}
            searchPlaceholder={t('searchByName')}
            label={renderAnonymized(selectedPartner?.name)}
            openInPortal
          >
            {partners.map((item: Partner) => (
              <DropdownSelectableItem
                key={generateKey()}
                selected={item.id === selectedPartner?.id}
                onClick={() => onSelectPartner(item)}
              >
                {renderAnonymized(item.name)}
              </DropdownSelectableItem>
            ))}
          </Dropdown>
        )}
      </div>
    );
  };

  const userDropdownItems = (username: string, email: string, role: string) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '4px',
      }}
    >
      <div style={{ color: '#dee0e2' }}>{username}</div>
      <div
        style={{
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '14px',
        }}
      >
        {email}
      </div>
      <div
        style={{
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '14px',
        }}
      >
        {role}
      </div>
    </div>
  );

  return (
    <div className="AppLayout" data-testid="page">
      <TopBar
        classes={(current) => ({
          ...current,
          root: `${current.root} AppLayout__top-bar`,
          centerSlot: `${current.centerSlot} AppLayout__top-bar-center-slot`,
          rightmostElements: `${current.rightmostElements} AppLayout__top-bar__rightmostElements`,
        })}
        userDropdown={{
          username: getTopbarName(),
          signOutAction: () => logout(),
          variant: 'withAvatar',
          badge: {
            badgeContent: getBadgeInitials() || '',
            size: 'standard',
            hoverEffect: true,
          },
        }}
        userDropdownInfo={userDropdownItems(
          getFullName(),
          loginService.getUser().email,
          userInfo?.displayRole || '',
        )}
        centerSlotRenderer={() => (
          <AutoComplete
            classes={(current: AutoCompleteStyles) => ({
              ...current,
              wrapper: `${current.wrapper} AppLayout__top-bar-center-slot__searchBar`,
              input: `${current.input} AppLayout__top-bar-center-slot__searchBar--input`,
            })}
            availableFilters={availableFilters}
            configuration={{
              clearIcon: false,
              displayFilters: false,
              icon: <Icons.SearchIcon />,
              clearQuery: true,
              maxHeight: 400,
              placeholderText: t('searchAllProperties'),
            }}
            onSearch={globalSearchFn}
            onItemClick={handleSearchItemClick}
          />
        )}
        rightSlotRenderer={getRightTopBarContent}
      />
      <div className="AppLayout__row">
        <Menu
          classes={(current: MenuStyles) => ({
            ...current,
            root: `${current.root} AppLayout__menu`,
            wrapper: `${current.wrapper} AppLayout__menu--wrapper`,
            appName: `${current.appName} AppLayout__menu--appName`,
          })}
          appName={'Uprise'}
          groupedItems={getMenuItems()}
        />
        <div className="AppLayout__content" style={{ width: '100%' }}>
          <Switch>
            <Route exact path={Routes.Portfolio}>
              <PortfolioContainer />
            </Route>
            <Route exact path={Routes.Users}>
              <UsersContainer />
            </Route>

            <Route path={Routes.Property}>
              <PropertyRoutes />
            </Route>

            <Route exact path={Routes.Config}>
              <ConfigContainer />
            </Route>

            <Route>
              <Redirect to={Routes.PartnerHome} />
            </Route>
          </Switch>
        </div>
      </div>

      {/*                   Modals                  */}
      <IotModal
        isOpen={iotModalState.state === ModalState.Open}
        onRequestClose={() => {
          setIotModalState({
            state: ModalState.Dismissed,
            type: ModalType.Create,
          });
        }}
      />
      <UnitModal
        isOpen={unitModalState.state === ModalState.Open}
        onRequestClose={(success) => {
          setUnitModalState({
            state: success ? ModalState.Success : ModalState.Dismissed,
            type: ModalType.Create,
          });
          setSelectedUnitToEdit(null);
        }}
      />
      <CreateTenantModal
        isOpen={createTenantModalState === ModalState.Open}
        onRequestClose={(success) =>
          setCreateTenantModalState(
            success ? ModalState.Success : ModalState.Dismissed,
          )
        }
      />
      <RelocateTenantModal
        isOpen={relocateTenantModalState === ModalState.Open}
        onRequestClose={() => setRelocateTenantModalState(ModalState.Dismissed)}
      />
      <ManageUserModal
        isOpen={createUserModalState === ModalState.Open}
        onRequestClose={(success) =>
          setCreateUserModalState(
            success ? ModalState.Success : ModalState.Dismissed,
          )
        }
      />
      <ConfirmationModal
        isOpen={confirmationModal.isOpen}
        confirmTitle={
          confirmationModal.confirmButtonLabel || t('confirmation.confirm')
        }
        cancelTitle={
          confirmationModal.cancelButtonLabel || t('confirmation.cancel')
        }
        title={confirmationModal.title || t('confirmation.title')}
        onRequestClose={() =>
          setConfirmationModal((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
        onConfirm={() => confirmationModal.onConfirm?.()}
      >
        {confirmationModal.body || t('confirmation.body')}
      </ConfirmationModal>
      <Notifications />
    </div>
  );
};

export default memo(AppLayout);
