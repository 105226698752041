import { FieldSort } from 'plume-ui/dist/components/Table/Table';
import { atom, selector } from 'recoil';
import { DEFAULT_PAGE_SIZE } from '../../config';
import { LoadingStatus } from '../units/types';
import { SortableUserFieldNames, User } from './types';
import { renderUserName } from './utils';

export const usersAtom = atom<User[]>({
  key: 'usersAtom',
  default: [],
});

export const usersSelectedFilterAtom = atom<string>({
  key: 'usersSelectedFilterAtom',
  default: '',
});

export const usersSelectedPageAtom = atom<number>({
  key: 'usersSelectedPageAtom',
  default: 0,
});

export const selectedUserAtom = atom<User | null>({
  key: 'selectedUserAtom',
  default: null,
});

export const usersSortAtom = atom<FieldSort | null>({
  key: 'usersSortAtom',
  default: null,
});

export const usersLoadingStatusAtom = atom<LoadingStatus>({
  key: 'usersLoadingStatusAtom',
  default: 'loading',
});

export const sortedUsers = selector<User[]>({
  key: 'sortedUsers',
  get: ({ get }) => {
    const users = get(usersAtom);
    const sort = get(usersSortAtom);
    if (sort) {
      return sortUsers([...users], sort);
    }
    return users;
  },
});

const sortUsers = (users: User[], sort: FieldSort): User[] => {
  const fieldName = sort.fieldName as SortableUserFieldNames;
  if (fieldName === 'name') {
    return users.sort(
      (a, b) =>
        renderUserName(a)!.localeCompare(renderUserName(b)) * sort.direction,
    );
  } else {
    return users.sort(
      (a, b) => a[fieldName]!.localeCompare(b[fieldName]!) * sort.direction,
    );
  }
};

const filteredUsers = selector<User[]>({
  key: 'filteredUsers',
  get: ({ get }) => {
    let users = get(sortedUsers);
    const filter = get(usersSelectedFilterAtom);

    if (filter) {
      users = users.filter((user) =>
        renderUserName(user)
          .toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase()),
      );
    }

    return users;
  },
});

export const currentUsersPage = selector<User[]>({
  key: 'currentUsersPage',
  get: ({ get }) => {
    let users = get(filteredUsers);
    const selectedPage = get(usersSelectedPageAtom);

    return users.slice(
      selectedPage * DEFAULT_PAGE_SIZE,
      (selectedPage + 1) * DEFAULT_PAGE_SIZE,
    );
  },
});

export const filteredUsersCount = selector<number>({
  key: 'filteredUsersCount',
  get: ({ get }) => {
    return get(filteredUsers).length;
  },
});
