import { Field, Form, Formik, FormikValues } from 'formik';
import {
  Button,
  DropdownFilter,
  DropdownFilterType,
  FilterDropdownGroup,
  FilterDropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  Toggler,
  notify,
} from 'plume-ui';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as yup from 'yup';
import DependencyContainer from '../../../../DependencyContainer';
import InputField from '../../../../components/InputField/InputField';
import ModalHeaderWithProperty from '../../../../components/ModalHeaderWithProperty/ModalHeaderWithProperty';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';
import { MixPanelEvents } from '../../../../mixPanelEvents';
import {
  selectedMDUSelector,
  selectedPartnerSelector,
  unitModalStateAtom,
  userInfoAtom,
} from '../../../../state';
import { ModalType, Permission } from '../../../../types';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import {
  prepareErrorMessageForInput,
  prepareErrorMessageForInputArray,
} from '../../../../utils/prepareErrorMessageForInput';
import { useInventory } from '../../../inventory/hooks/useInventory';
import { inventoryNodesAtom } from '../../../inventory/inventoryState';
import { NodeStatus } from '../../../inventory/types';
import { allTenants } from '../../../tenants/tenantsState';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { useUnitsUpdateActions } from '../../hooks/useUnitsUpdateActions';
import {
  CreateUnitDto,
  Unit,
  UnitStatus,
  UnitType,
  UpdateUnitDto,
} from '../../types';
import {
  allUnits,
  selectedUnitToEditAtom,
  unitsSelectedTypeAtom,
} from '../../unitsState';
import { UnitFormValues, UnitPaymentType } from './types';
import {
  addStylesAddNodeButton,
  addStylesToPaymentTypeToggler,
  addStylesToUnitModal,
  addStylesToUnitModalBody,
  addStylesToUnitModalInputField,
  addStylesToUnitModalToggler,
  formInputFieldsDefinition,
  getSelectedNodes,
  getUnassignedAndSelectedNodes,
  getUnassignedNodes,
  hasNoDuplicateNodes,
  hasNoDuplicateUnitName,
} from './utils';

type UnitModalProps = {
  isOpen: boolean;
  onRequestClose: (success: boolean) => void;
};

const UnitModal: FunctionComponent<UnitModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const { runFetch: fetchInventoryNodes } = useInventory(false);
  const { addUnit, updateUnit } = useUnitsUpdateActions();

  const [nodeSerialNumbers, setNodeSerialNumbers] = useState<string[]>([]);
  const [submitting, setSubmitting] = useState(false);

  const units = useRecoilValue(allUnits);
  const tenants = useRecoilValue(allTenants);
  const userInfo = useRecoilValue(userInfoAtom);
  const selectedMdu = useRecoilValue(selectedMDUSelector);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const unitModalState = useRecoilValue(unitModalStateAtom);
  const [inventoryNodes, setInventoryNodes] = useRecoilState(
    inventoryNodesAtom,
  );
  const [selectedUnitType, setSelectedUnitType] = useRecoilState(
    unitsSelectedTypeAtom,
  );
  const [selectedUnitToEdit, setSelectedUnitToEdit] = useRecoilState(
    selectedUnitToEditAtom,
  );

  const { unitsService } = new DependencyContainer();

  const selectedNodes = getSelectedNodes(inventoryNodes, selectedUnitToEdit);

  const getPaymentTypeInitialValue = () => {
    if (typeof selectedUnitToEdit?.retailPay === 'boolean') {
      if (selectedUnitToEdit.retailPay) {
        return UnitPaymentType.Retail;
      } else {
        return UnitPaymentType.Bulk;
      }
    }
    return UnitPaymentType.Bulk;
  };

  const getFormInitialValues = (): UnitFormValues => ({
    name: selectedUnitToEdit?.name || '',
    paymentType: getPaymentTypeInitialValue(),
    accountId: selectedUnitToEdit?.accountId || undefined,
    nodes: selectedNodes.map((node) => node.id),
  });

  const getValidationSchema = () => {
    return yup.object().shape({
      name: yup
        .string()
        .min(3, t('formValidation.minLength', { length: 3 }))
        .trim()
        .max(128, t('formValidation.maxFields', { length: 128 }))
        .required(t('units.actions.create.form.name.required'))
        .test('Unique', t('units.actions.create.form.name.unique'), (value) =>
          hasNoDuplicateUnitName(
            units,
            value,
            selectedUnitType,
            selectedUnitToEdit?.id,
          ),
        ),
      serialNumber: yup.array().of(
        yup
          .string()
          .trim()
          .test(
            'node-unique',
            t('units.actions.edit.form.name.uniqueNode'),
            (value, context) => {
              return hasNoDuplicateNodes(
                // [from] not typed since https://github.com/DefinitelyTyped/DefinitelyTyped/issues/49512
                [
                  // @ts-expect-error
                  ...(context.from[0].value.nodes || []),
                  // @ts-expect-error
                  ...(context.from[0].value.serialNumber || []),
                ],
                value,
              );
            },
          )
          .test(
            'unit-accountId-unique',
            t('tenants.actions.create.form.account_id.uniqueUnit'),
            (value) => {
              if (value === null) return true;
              for (const unit of units) {
                if (!unit.retailPay) {
                  if (unit.accountId === value) return false;
                }
              }
              return true;
            },
          ),
      ),
      accountId: yup
        .string()
        .nullable()
        .optional()
        .trim()
        .max(36, t('formValidation.maxFields', { length: 36 }))
        .test(
          'accountId-unique',
          t('units.actions.create.form.accountId.unique'),
          (value, context) => {
            // @ts-expect-error
            if (context.from[0].value.paymentType === UnitPaymentType.Retail)
              return true;
            for (const unit of [...units]) {
              if (unit.accountId === selectedUnitToEdit?.accountId) continue;
              if (!unit.retailPay && unit.accountId === value) return false;
            }
            return true;
          },
        )
        .test(
          'resident-accountId-unique',
          t('units.actions.create.form.accountId.uniqueResident'),
          (value) => {
            if (value === null) return true;
            for (const tenant of tenants) {
              if (tenant.accountId === value) return false;
            }
            return true;
          },
        ),
    });
  };

  const updateNodes = (unit: Partial<Unit>) => {
    if (!unit.id || !unit.name) return;
    if (nodeSerialNumbers.length) {
      fetchInventoryNodes();
      return;
    }
    const addedNodes = unit.nodes?.filter(
      (n) => !selectedNodes.some((sn) => sn.id === n.id),
    );
    const removedNodes = selectedNodes.filter(
      (n) => !unit.nodes?.some((sn) => sn.id === n.id),
    );
    setInventoryNodes((nodes) => {
      return nodes.map((n) => {
        if (addedNodes?.some((an) => an.id === n.id)) {
          const updatedNode = { ...n, status: NodeStatus.Assigned };
          if (selectedUnitType === UnitType.Business) {
            updatedNode.businessUnit = { id: unit.id!, name: unit.name! };
          } else {
            updatedNode.residentialUnit = { id: unit.id!, name: unit.name! };
          }
          return updatedNode;
        }

        if (removedNodes.some((rn) => rn.id === n.id)) {
          const updatedNode = { ...n, status: NodeStatus.Unassigned };
          if (selectedUnitType === UnitType.Business) {
            updatedNode.businessUnit = null;
          } else {
            updatedNode.residentialUnit = null;
          }
          return updatedNode;
        }

        return n;
      });
    });
  };

  const onSubmit = async (values: FormikValues) => {
    if (!selectedMdu || !selectedPartner || submitting) {
      return;
    }

    let trimmedSerialNumbers: string[] = [];
    if (values.serialNumber && Array.isArray(values.serialNumber)) {
      trimmedSerialNumbers = values.serialNumber.filter(
        (e: string) => e && e.trim() !== '',
      );
    }

    setSubmitting(true);

    try {
      if (unitModalState.type === ModalType.Create) {
        const createUnitDto: CreateUnitDto = {
          name: values.name?.trim().toUpperCase(),
          nodes: [
            ...values.nodes.map(
              (selectedFilter: string) =>
                inventoryNodes.find((node) => node.id === selectedFilter)!,
            ),
            ...trimmedSerialNumbers.map((id: string) => ({ id })),
          ],
          status: UnitStatus.Unassigned,
          retailPay: values.paymentType === UnitPaymentType.Retail,
        };

        if (values.paymentType === UnitPaymentType.Bulk) {
          createUnitDto.accountId = values.accountId;
        }

        if (!userInfo?.permissions.includes(Permission.canUpdateUnitNodes)) {
          delete createUnitDto.nodes;
        }

        const unit = await unitsService.createUnit(
          selectedMdu.id,
          selectedUnitType,
          createUnitDto,
          selectedPartner.id,
        );

        if (!unit) return;
        if (unit.nodes?.length) updateNodes(unit);

        addUnit(unit, selectedUnitType);

        notify({
          title: t('success'),
          body: t('units.actions.create.unitAdded'),
          type: 'success',
        });
        trackEvent({
          eventName: MixPanelEvents.ADD_UNIT_SUCCESS,
          additionalContent: {
            id: values.id,
            propertyId: selectedMdu.id,
          },
        });
      }

      if (unitModalState.type === ModalType.Edit) {
        const updateUnitDTO: UpdateUnitDto = {
          name: values.name?.trim().toUpperCase(),
          nodes: [
            ...values.nodes.map(
              (selectedFilter: string) =>
                inventoryNodes.find((node) => node.id === selectedFilter)!,
            ),
            ...trimmedSerialNumbers.map((id: string) => ({ id })),
          ],
          retailPay: values.paymentType === UnitPaymentType.Retail,
        };

        if (values.paymentType === UnitPaymentType.Bulk) {
          updateUnitDTO.accountId = values.accountId;
        }

        if (!userInfo?.permissions.includes(Permission.canUpdateUnitNodes)) {
          delete updateUnitDTO.nodes;
        }

        const unit = await unitsService.updateUnit(
          selectedMdu?.id,
          updateUnitDTO,
          selectedUnitToEdit!.id,
          selectedUnitToEdit!.type,
          selectedPartner.id,
        );

        if (!unit) return;
        updateNodes(unit);
        updateUnit(unit, selectedUnitType);

        notify({
          title: t('success'),
          body: t('units.actions.edit.unitUpdated'),
          type: 'success',
        });
        trackEvent({
          eventName: MixPanelEvents.UPDATE_UNIT_SUCCESS,
          additionalContent: {
            unitId: selectedUnitToEdit!.id,
            propertyId: selectedMdu!.id,
          },
        });
      }
      onClose();
    } catch (error) {
      setSubmitting(false);
      trackEvent({
        eventName: MixPanelEvents.ADD_UNIT_FAILURE,
        additionalContent: {
          propertyId: selectedMdu.id,
        },
      });
    }
  };

  const onClose = () => {
    setSubmitting(false);
    setNodeSerialNumbers([]);
    setSelectedUnitToEdit(null);
    onRequestClose(true);
  };

  const getUnitTypeTogglerValues = () => [
    {
      key: UnitType.Residence,
      label: t('units.filters.residential'),
    },
    {
      key: UnitType.Business,
      label: t('units.filters.commercial'),
    },
  ];

  const getPaymentTypeTogglerValues = () => [
    {
      key: UnitPaymentType.Bulk,
      label: t('units.paymentType.bulk'),
    },
    {
      key: UnitPaymentType.Retail,
      label: t('units.paymentType.retail'),
    },
  ];

  const getFilterGroupItems = (): Record<string, DropdownFilter> => ({
    nodes: {
      label: t('units.actions.create.form.nodes.label'),
      defaultLabel: t('units.actions.create.form.nodes.selectLabel'),
      type: DropdownFilterType.MULTI,
      noSearchMatchMessage: t('units.actions.create.form.nodes.noMatch'),
      searchPlaceholder: t('units.actions.create.form.nodes.search'),
      searchBar: true,
      openInPortal: true,
      expandDirection: 'auto',
      noItemsWarning: t('units.actions.create.form.nodes.noNodesWarning'),
      items:
        unitModalState.type === ModalType.Edit
          ? getUnassignedAndSelectedNodes(inventoryNodes, selectedNodes)
          : getUnassignedNodes(inventoryNodes),
    },
  });

  if (!selectedUnitToEdit && unitModalState.type === ModalType.Edit) {
    return null;
  }

  if (!selectedMdu || !isOpen || !selectedPartner) {
    return null;
  }

  return (
    <Formik
      initialValues={getFormInitialValues()}
      validationSchema={getValidationSchema()}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values, errors, setValues, setFieldValue, dirty, submitForm }) => (
        <Form>
          <Modal
            appElement={document.getElementById('root') as HTMLElement}
            isOpen={isOpen}
            onRequestClose={onClose}
            classes={addStylesToUnitModal}
          >
            <ModalHeaderWithProperty
              propertyName={selectedMdu.name}
              title={t(`units.actions.${unitModalState.type}.modalTitle`)}
            />
            <>
              <ModalBody classes={addStylesToUnitModalBody}>
                {unitModalState.type === ModalType.Create && (
                  <Toggler
                    variant="large"
                    toggleElements={getUnitTypeTogglerValues()}
                    value={selectedUnitType}
                    onToggle={(selection) =>
                      setSelectedUnitType(selection.key as UnitType)
                    }
                    classes={addStylesToUnitModalToggler}
                  />
                )}
                {formInputFieldsDefinition.map((field) => {
                  if (field.type === 'toggler') {
                    return (
                      <div className="UnitModal__paymentType">
                        <div>{t('units.paymentType.label')}</div>
                        <Toggler
                          key={field.name}
                          toggleElements={getPaymentTypeTogglerValues()}
                          value={values[field.name] as string}
                          onToggle={(selection) =>
                            setFieldValue(field.name, selection.key)
                          }
                          classes={addStylesToPaymentTypeToggler}
                        />
                      </div>
                    );
                  }
                  return (
                    <Field
                      key={field.name}
                      name={field.name}
                      component={InputField}
                      requiredAsterix={true}
                      value={values[field.name]}
                      messages={prepareErrorMessageForInput(field.name, errors)}
                      label={t(field.labelId)}
                      classes={addStylesToUnitModalInputField}
                    />
                  );
                })}
                {values.paymentType === UnitPaymentType.Bulk &&
                  userInfo?.permissions.includes(
                    Permission.canUpdateUnitBulkPayAccountId,
                  ) && (
                    <div className="UnitModal__accountIdField">
                      <Field
                        name={'accountId'}
                        component={InputField}
                        value={values.accountId}
                        noClearIcon
                        messages={[
                          {
                            status: 'hint',
                            message: t(
                              'units.actions.create.form.accountId.hint',
                            ),
                          },
                          ...(prepareErrorMessageForInput(
                            'accountId',
                            errors,
                          ) || []),
                        ]}
                        label={t('units.actions.create.form.accountId.label')}
                        classes={addStylesToUnitModalInputField}
                      />
                    </div>
                  )}
                <FilterDropdownGroup
                  items={getFilterGroupItems()}
                  selectedItems={{
                    nodes: getFilterGroupItems().nodes.items.filter((node) =>
                      values.nodes.includes(node.value),
                    ),
                  }}
                  onSelect={(_, filter) => {
                    setValues({
                      ...values,
                      nodes: (filter as FilterDropdownItem[]).map(
                        (e) => e.value,
                      ),
                    });
                  }}
                  disabled={
                    !userInfo?.permissions.includes(
                      Permission.canUpdateUnitNodes,
                    )
                  }
                />
                {nodeSerialNumbers.map((serialNumber, index) => (
                  <Field
                    key={`serialNumber[${index}]`}
                    name={`serialNumber[${index}]`}
                    component={InputField}
                    value={serialNumber}
                    label={t('units.actions.create.form.nodes.placeholder')}
                    messages={prepareErrorMessageForInputArray(
                      'serialNumber',
                      index,
                      errors as any,
                    )}
                    classes={addStylesToUnitModalInputField}
                  />
                ))}
                {unitModalState.type === ModalType.Edit &&
                  userInfo?.permissions.includes(
                    Permission.canUpdateUnitNodes,
                  ) &&
                  (nodeSerialNumbers.length < 5 ? (
                    <Button
                      styleVariant="tertiary"
                      classes={addStylesAddNodeButton}
                      onClick={() => {
                        setNodeSerialNumbers([...nodeSerialNumbers, '']);
                      }}
                    >
                      {t('units.actions.create.form.nodes.assignNewNode')}
                    </Button>
                  ) : (
                    <span className="UnitModal__noMoreNodes">
                      {t('units.actions.create.form.nodes.noMoreNodes')}
                    </span>
                  ))}
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  styleVariant="tertiary-grey"
                  onClick={() => onClose()}
                >
                  <FormattedMessage id="cancel" />
                </Button>
                <SubmitButton
                  disabled={!dirty}
                  submitInProgress={submitting}
                  onSubmit={submitForm}
                />
              </ModalFooter>
            </>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export { UnitModal };
