import { AxiosResponse } from 'axios';
import { UpriseApiResponse } from '../../../types';
import { HttpClient } from '../../../HttpClient';
import {
  CreatePropertyNetworkDto,
  PropertyNetwork,
  PropertyNetworkDetails,
  UpdatePropertyNetworkDto,
} from '../types';
import { UpriseApiUrls } from '../../../urls';

export default class PropertyNetworksClient extends HttpClient {
  async fetchPropertyNetworks(
    mduId: string,
    partnerId: string,
    page: number = 1,
  ): Promise<AxiosResponse<UpriseApiResponse<PropertyNetwork[]>>> {
    return await this.get<AxiosResponse<UpriseApiResponse<PropertyNetwork[]>>>(
      UpriseApiUrls.getPropertyNetworksUrl(mduId, page, partnerId),
    );
  }

  async deletePropertyNetwork(
    mduId: string,
    partnerId: string,
    propertyNetworkId: string,
  ): Promise<void> {
    return await this.delete(
      UpriseApiUrls.getDeletePropertyNetworksUrl(
        mduId,
        partnerId,
        propertyNetworkId,
      ),
    );
  }

  async createPropertyNetwork(
    mduId: string,
    dto: CreatePropertyNetworkDto,
    partnerId: string,
  ): Promise<AxiosResponse<PropertyNetwork>> {
    return await this.post(
      UpriseApiUrls.getAddPropertyNetworksUrl(mduId, partnerId),
      dto,
    );
  }

  async updatePropertyNetwork(
    mduId: string,
    dto: UpdatePropertyNetworkDto,
    partnerId: string,
    propertyNetworkId: string,
  ): Promise<AxiosResponse<PropertyNetwork>> {
    return await this.patch(
      UpriseApiUrls.getUpdatePropertyNetworksUrl(
        mduId,
        partnerId,
        propertyNetworkId,
      ),
      dto,
    );
  }

  async getPropertyNetworkDetails(
    propertyNetworkId: string,
    mduId: string,
    partnerId: string,
  ): Promise<AxiosResponse<PropertyNetworkDetails>> {
    return await this.get<AxiosResponse<PropertyNetworkDetails>>(
      UpriseApiUrls.getPropertyNetworkDetailsUrl(
        propertyNetworkId,
        mduId,
        partnerId,
      ),
    );
  }
}
