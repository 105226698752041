import * as yup from 'yup';

// From https://www.npmjs.com/package/validatorjs
export const isIPv6 = (address: string) => {
  if (typeof address !== 'string') return false;
  var er = /^[0-9a-f]+$/;
  const hextets = address.split(':');
  const colons = address.match(/::/);
  if (colons !== null && (address.match(/::/g) || '').length > 1) return false;
  if (
    address[0] === ':' &&
    (colons === null || (colons !== null && colons.index !== 0))
  )
    return false;
  if (
    address[address.length - 1] === ':' &&
    (colons === null ||
      (colons !== null && colons.index !== address.length - 2))
  )
    return false;
  if (3 > hextets.length) return false;
  var isEdgeCase =
    hextets.length === 9 &&
    colons !== null &&
    (colons.index === 0 || colons.index === address.length - 2);
  if (hextets.length > 8 && !isEdgeCase) return false;
  if (hextets.length !== 8 && colons === null) return false;
  for (let i = 0; i < hextets.length; i++) {
    const element = hextets[i];
    if (element.length === 0) continue;
    if (!er.test(element)) return false;
    if (element.length > 4) return false;
  }
  return true;
};

export const validateIPv6 = ({
  invalidAddressMessage,
}: {
  invalidAddressMessage: string;
}) => {
  return yup.string().test({
    name: 'url-valid',
    message: invalidAddressMessage,
    test: (value) => {
      if (isIPv6(value as string)) return true;
      return false;
    },
  });
};
