import { ModalState, ModalType } from '../../types';

export type IotSecurityPolicy = {
  spamPhisProtectionEnabled: boolean;
  iotProtectionEnabled: boolean;
  remoteAccessProtectionEnabled: boolean;
  whitelists: {
    [x in SecurityPolicyWhitelist]: string[];
  };
};

export enum SecurityPolicyProtection {
  OnlineProtection = 'spamPhisProtectionEnabled',
  IotProtection = 'iotProtectionEnabled',
  RemoteAccessProtection = 'remoteAccessProtectionEnabled',
}

export enum SecurityPolicyWhitelist {
  Fqdn = 'fqdn',
  IPv4 = 'ipv4',
  IPv6 = 'ipv6',
}

export type IotModalState = {
  type: ModalType;
  state: ModalState;
};

export type IotNetwork = {
  ssid: string;
  enabled: boolean;
  configurationHash: string;
  password?: string;
  wpaMode: 'psk2' | 'sae' | 'psk-mixed' | 'sae-mixed';
  vlanConfig: {
    vlanId: number;
  };
  iotSecurityPolicy: {
    spamPhisProtectionEnabled: boolean;
    iotProtectionEnabled: boolean;
    remoteAccessProtectionEnabled: boolean;
    whitelists: {
      fqdn: string[];
      ipv4: string[];
      ipv6: string[];
    };
  };
};

export type CreateIotNetworkDTO = {
  ssid: string;
  password: string;
  vlanConfig: {
    vlanId: number;
  };
  iotSecurityPolicy?: {
    spamPhisProtectionEnabled?: boolean;
    iotProtectionEnabled?: boolean;
    remoteAccessProtectionEnabled?: boolean;
    whitelists?: {
      fqdn?: string[];
      ipv4?: string[];
      ipv6?: string[];
    };
  };
};
export type CreateIotNetworkResponseDTO = IotNetwork & {
  transactionId: string;
};

export type UpdateIotNetworkDTO = {
  ssid?: string;
  password?: string;
  vlanConfig?: {
    vlanId?: number;
  };
  iotSecurityPolicy?: {
    spamPhisProtectionEnabled?: boolean;
    iotProtectionEnabled?: boolean;
    remoteAccessProtectionEnabled?: boolean;
    whitelists?: {
      fqdn?: string[];
      ipv4?: string[];
      ipv6?: string[];
    };
  };
};
export type UpdateIotNetworkResponseDTO = IotNetwork & {
  transactionId: string;
};

export type IotNetworkStatus = {
  id: string;
  propertyId: string;
  totalPropagationCount: number;
  failedPropagationCount: number;
  successfulPropagationCount: number;
  propagationStatus: 'completed' | 'completedWithFailures' | 'inProgress';
  failedLocationIds: string[];
};

export enum IotDeviceHealthStatus {
  Excellent = 'excellent',
  Good = 'good',
  Fair = 'fair',
  Poor = 'poor',
  Error = 'error',
  Calculating = 'calculating',
}

export enum IotDeviceConnectionState {
  Connected = 'connected',
  Disconnected = 'disconnected',
}

export type IotDevice = {
  locationId: string;
  customerId: string;
  mac: string;
  deviceType: {
    iconV2: string;
    iconV3: string;
    name: string;
  };
  connectionState: IotDeviceConnectionState;
  health?: {
    score: number;
    status: IotDeviceHealthStatus;
    details: string;
  };
  quarantined?: boolean;
};
