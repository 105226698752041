export type EnvironmentId =
  | 'local'
  | 'ci'
  | 'dogfood'
  | 'beta'
  | 'gamma'
  | 'kappa'
  | 'iota'
  | 'phi';

export type Environment = {
  id: EnvironmentId;
  oktaBaseUrl: string;
  oktaIssuer: string;
  oktaClientId: string;
  backendUrl: string;
  frontlineTier1Url: string;
  frontlineUrl: string;
};

const localEnv: Environment = {
  id: 'local',
  oktaBaseUrl: 'https://external-dev.sso.plume.com',
  oktaIssuer: 'https://external-dev.sso.plume.com/oauth2/default',
  oktaClientId: '0oa15gowr3x9H7qHn0h8',
  backendUrl: 'https://eks-ext.usw2.dev.ci.plume.tech',
  frontlineTier1Url: 'https://ci.central.plume.com',
  frontlineUrl: 'https://ci.noc.plume.com',
};

const environments: Environment[] = [
  localEnv,
  {
    id: 'ci',
    oktaBaseUrl: 'https://external-dev.sso.plume.com',
    oktaIssuer: 'https://external-dev.sso.plume.com/oauth2/default',
    oktaClientId: '0oa15gowr3x9H7qHn0h8',
    backendUrl: 'https://eks-ext.usw2.dev.ci.plume.tech',
    frontlineTier1Url: 'https://ci.central.plume.com',
    frontlineUrl: 'https://ci.noc.plume.com',
  },
  {
    id: 'dogfood',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oagtwnw7z7i84vvG357',
    backendUrl: 'https://eks-ext.usw2.dogfood.dog1.plume.tech',
    frontlineTier1Url: 'https://dogfood.central.plume.com',
    frontlineUrl: 'https://dogfood.noc.plume.com',
  },
  {
    id: 'beta',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oagtwutdaC3GCiTI357',
    backendUrl: 'https://eks-ext.usw2.prod.beta.plumenet.io',
    frontlineTier1Url: 'https://beta.central.plume.com',
    frontlineUrl: 'https://beta.noc.plume.com',
  },
  {
    id: 'gamma',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oagtwz2bbR6m3rLO357',
    backendUrl: 'https://eks-ext.usw2.prod.gamma.plumenet.io',
    frontlineTier1Url: 'https://gamma.central.plume.com',
    frontlineUrl: 'https://gamma.noc.plume.com',
  },
  {
    id: 'kappa',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oagtx7w69VvLm3Ml357',
    backendUrl: 'https://eks-ext.euc1.prod.kappa.plumenet.io',
    frontlineTier1Url: 'https://kappa.central.plume.com',
    frontlineUrl: 'https://kappa.noc.plume.com',
  },
  {
    id: 'iota',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oao7lxtmltZfLG2C357',
    backendUrl: 'https://eks-ext.apne1.prod.iota.plumenet.io',
    frontlineTier1Url: 'https://iota.central.plume.com',
    frontlineUrl: 'https://iota.noc.plume.com',
  },
  {
    id: 'phi',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oa1460eihq4wWMqB358',
    backendUrl: 'https://eks-ext.apse1.prod.phi.plumenet.io',
    frontlineTier1Url: 'https://phi.central.plume.com',
    frontlineUrl: 'https://phi.noc.plume.com',
  },
];

export const getEnvironment = (): Environment => {
  const hostnameBits = window.location.hostname.split('.');

  if (hostnameBits.length > 3) {
    const envFromUrl = hostnameBits[hostnameBits.length - 4];
    const env = environments.find((e) => e.id === envFromUrl);
    return env || localEnv;
  } else {
    return localEnv;
  }
};

const getEnvProp = (propName: keyof Environment) => {
  const env = getEnvironment();
  return env[propName];
};

export const getOktaClientId = () => {
  return getEnvProp('oktaClientId');
};

export const getOktaBaseUrl = () => {
  return getEnvProp('oktaBaseUrl');
};

export const getOktaIssuer = () => {
  return getEnvProp('oktaIssuer');
};

export const getBackendUrl = () => {
  return getEnvProp('backendUrl');
};

export const getFrontlineUrl = () => {
  return getEnvProp('frontlineUrl');
};

export const getFrontlineTier1Url = () => {
  return getEnvProp('frontlineTier1Url');
};
