import {
  Button,
  Grid,
  GridItem,
  Pagination,
  PendingContent,
  Spinner,
  Toggler,
} from 'plume-ui';
import { TogglerElementTypes } from 'plume-ui/dist/components/Toggler/Toggler';
import React, {
  ChangeEvent,
  FunctionComponent,
  memo,
  useEffect,
  useMemo,
} from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import AuraGlow from '../../../components/AuraGlow/AuraGlow';
import PageHeading from '../../../components/PageHeading/PageHeading';
import StickyActionTable from '../../../components/StickyActionTable/StickyActionTable';
import TableActionButton from '../../../components/TableActionButton/TableActionButton';
import TableFilterRow from '../../../components/TableFilter/TableFilterRow';
import { DEFAULT_PAGE_SIZE, Routes } from '../../../config';
import useRenderAnonymized from '../../../hooks/useRenderAnonymized';
import { MixPanelEvents } from '../../../mixPanelEvents';
import {
  createTenantModalStateAtom,
  selectedMDUSelector,
  selectedPartnerIdAtom,
  userInfoAtom,
} from '../../../state';
import { ModalState, Permission, Role } from '../../../types';
import EmptyTableCell from '../../../utils/components/EmptyTableCell';
import { isDevelopment, isLocal } from '../../../utils/environment';
import { useRedirectToRoute } from '../../../utils/hooks/useRedirectToRoute';
import { selectedMduIdAtom } from '../../editorView/propertyState';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { AvailableScreens } from '../../trackingAnalytics/types';
import EditTenantModal from '../components/EditTenantModal/EditTenantModal';
import useTenantActions from '../hooks/useTenantActions';
import useTenantMappings from '../hooks/useTenantMappings';
import {
  businessTenantsSelectedPageAtom,
  currentTenantsPage,
  getFilteredTenantCount,
  getStatusTenantCount,
  residentialTenantsSelectedPageAtom,
  selectedTenantToEditAtom,
  tenantsLoadingStatusAtom,
  tenantsSelectedFilterAtom,
  tenantsSelectedStatusAtom,
  tenantsSelectedTypeAtom,
  tenantsSortAtom,
} from '../tenantsState';
import { Tenant, TenantStatus, TenantTypes } from '../types';

const TenantsContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const redirectToRoute = useRedirectToRoute();
  const { renderAnonymized } = useRenderAnonymized();
  const partnerId = useRecoilValue(selectedPartnerIdAtom);
  const propertyId = useRecoilValue(selectedMduIdAtom);
  const [selectedTenantToEdit, setSelectedTenantToEdit] = useRecoilState(
    selectedTenantToEditAtom,
  );
  const {
    renderTenantName,
    renderTenantAccountId,
    renderTenantStatus,
  } = useTenantMappings();
  const userInfo = useRecoilValue(userInfoAtom);
  const [selectedTenantType, setSelectedTenantType] = useRecoilState(
    tenantsSelectedTypeAtom,
  );
  const [
    currentResidentialTenantsPage,
    setCurrentResidentialTenantsPage,
  ] = useRecoilState(residentialTenantsSelectedPageAtom);
  const [
    currentBusinessTenantsPage,
    setCurrentBusinessTenantsPage,
  ] = useRecoilState(businessTenantsSelectedPageAtom);
  const totalFilteredItems = useRecoilValue(getFilteredTenantCount);
  const setTenantSort = useSetRecoilState(tenantsSortAtom);
  const [selectedFilter, setSelectedFilter] = useRecoilState(
    tenantsSelectedFilterAtom,
  );
  const [selectedStatus, setSelectedStatus] = useRecoilState(
    tenantsSelectedStatusAtom,
  );
  const statusTenantCount = useRecoilValue(getStatusTenantCount);
  const trackEvent = useTrackEvent();
  const setCreateTenantModalState = useSetRecoilState(
    createTenantModalStateAtom,
  );
  const getTenantActions = useTenantActions();
  const [tenantsLoadingStatus] = useRecoilState(tenantsLoadingStatusAtom);
  const tenants = useRecoilValue(currentTenantsPage);
  const selectedProperty = useRecoilValue(selectedMDUSelector);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Tenants,
      },
    });
    return () => {
      setSelectedFilter('');
    };
  }, []);

  const currentPage: number = useMemo(() => {
    return selectedTenantType === TenantTypes.Business
      ? currentBusinessTenantsPage
      : currentResidentialTenantsPage;
  }, [
    selectedTenantType,
    currentResidentialTenantsPage,
    currentBusinessTenantsPage,
  ]);

  useEffect(() => {
    if (!tenants.length && currentPage > 0) {
      if (selectedTenantType === TenantTypes.Business) {
        setCurrentBusinessTenantsPage(currentPage - 1);
      } else {
        setCurrentResidentialTenantsPage(currentPage - 1);
      }
    }
  }, [tenants, currentPage]);

  const tableHeaderRow: Record<string, unknown>[] = [
    {
      name: t('tenants.table.tenant'),
      fieldName: 'name',
      sortable: true,
      render: (tenant: Tenant) =>
        renderTenantName(
          renderAnonymized(tenant.name),
          tenant.stateMeta.realized,
        ),
    },
    {
      name: t('tenants.table.unit'),
      fieldName: 'unitName',
      sortable: true,
      render: (tenant: Tenant) =>
        renderAnonymized(tenant.unit?.name) || <EmptyTableCell />,
    },
    {
      name: t('tenants.table.accountId'),
      fieldName: 'accountId',
      sortable: true,
      render: (tenant: Tenant) => renderTenantAccountId(tenant),
    },
    {
      name: t('tenants.table.status'),
      fieldName: 'status',
      sortable: true,
      render: (tenant: Tenant) =>
        renderTenantStatus(tenant.status as TenantStatus),
    },
    {
      fieldName: 'actions',
      sortable: false,
      render: (tenant: Tenant) => {
        const tenantActions = getTenantActions(tenant);
        if (tenantActions.length === 0) {
          return null;
        }
        return (
          <TableActionButton
            actions={tenantActions}
            item={tenant}
          ></TableActionButton>
        );
      },
    },
  ];

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value === undefined ? '' : e.target.value;
    setSelectedFilter(value);
  };

  const handleSetPage = (page: number) => {
    if (selectedTenantType === TenantTypes.Business) {
      setCurrentBusinessTenantsPage(page);
    } else {
      setCurrentResidentialTenantsPage(page);
    }
  };

  const hasPermissionForAll = (permissions: Permission[]) => {
    return permissions.every((p) => userInfo?.permissions.includes(p));
  };

  const tenantStatusHandler = (selection: TogglerElementTypes) => {
    setSelectedStatus(selection?.key as TenantStatus);
  };

  return (
    <>
      <Helmet>
        <title>{t('tenants.title')}</title>
      </Helmet>
      <div className="TenantsContainer p-xl">
        <AuraGlow header footer />
        <Grid>
          <GridItem colSpan="12" tabletColSpan="6">
            <PageHeading
              title="tenants.title"
              tooltip="tenants.tooltip"
              additionalTitle={selectedProperty?.name}
            />
          </GridItem>
          <GridItem colSpan="12" tabletColSpan="6">
            <Toggler
              toggleElements={[
                {
                  key: TenantTypes.Residence,
                  label: t('tenants.filters.residential'),
                },
                {
                  key: TenantTypes.Business,
                  label: t('tenants.filters.commercial'),
                },
              ]}
              value={selectedTenantType}
              variant="large"
              onToggle={(selection) =>
                setSelectedTenantType(selection.key as TenantTypes)
              }
            />
            <div className="TenantsContainer__actionButtons">
              {(isLocal() || isDevelopment()) && (
                <Button
                  styleVariant="tertiary"
                  onClick={() =>
                    redirectToRoute(Routes.Lease, { partnerId, propertyId })
                  }
                >
                  {t('tenants.leaseButtonLabel')}
                </Button>
              )}
              <Button
                styleVariant="superprimary"
                onClick={() => setCreateTenantModalState(ModalState.Open)}
                id="TenantsContainer__create-account-btn"
                disabled={
                  userInfo?.role !== Role.Support &&
                  !hasPermissionForAll([
                    Permission.createBusinessTenant,
                    Permission.createResidentialTenant,
                  ])
                }
              >
                {t('tenants.buttonLabel')}
              </Button>
            </div>
          </GridItem>
          <GridItem colSpan="12" tabletColSpan="6" justify={'start'}>
            <TableFilterRow
              placeholder="tenants.search"
              value={selectedFilter}
              onSearch={handleSearch}
              resultCount={totalFilteredItems}
            />
          </GridItem>
          <GridItem colSpan="12" tabletColSpan="6">
            <Toggler
              toggleElements={[
                {
                  key: TenantStatus.Active,
                  label: t('tenants.filterStatus.active', {
                    count: statusTenantCount?.active,
                  }),
                },
                {
                  key: TenantStatus.Assigned,
                  label: t('tenants.filterStatus.assigned', {
                    count: statusTenantCount?.assigned,
                  }),
                },
                ...(statusTenantCount.error
                  ? [
                      {
                        key: TenantStatus.Error,
                        label: t('tenants.filterStatus.error', {
                          count: statusTenantCount?.error,
                        }),
                      },
                    ]
                  : []),
              ]}
              value={selectedStatus || ''}
              variant="large"
              onToggle={(selection) => tenantStatusHandler(selection)}
            />
          </GridItem>
          <GridItem colSpan="12" tabletColSpan="6">
            <PendingContent
              loading={tenantsLoadingStatus === 'loading'}
              loader={Spinner}
              isError={tenantsLoadingStatus === 'error'}
              hideContent
            >
              <StickyActionTable
                headerRow={tableHeaderRow}
                dataRows={tenants as Record<string, any>[]}
                truncateCellContent={false}
                externalSort
                onSortChange={setTenantSort}
                noResultsMessage={t('tenants.table.noResults')}
              />
              {selectedStatus &&
                Math.ceil(
                  statusTenantCount[selectedStatus] / DEFAULT_PAGE_SIZE,
                ) > 0 && (
                  <Pagination
                    classes={(current) => ({
                      ...current,
                      root: `${current.root} TenantsContainer__pagination`,
                    })}
                    expandDirection={'top'}
                    totalPageCount={
                      Math.ceil(
                        statusTenantCount[selectedStatus] / DEFAULT_PAGE_SIZE,
                      ) || 1
                    }
                    onPageSelect={handleSetPage}
                    currentPage={currentPage}
                  />
                )}
            </PendingContent>
          </GridItem>
        </Grid>
      </div>
      <EditTenantModal
        isOpen={!!selectedTenantToEdit}
        onRequestClose={() => setSelectedTenantToEdit(null)}
      />
    </>
  );
};

export default memo(TenantsContainer);
